.campaignStatus {
  composes: block from './results-page.module.css';
  padding: 20px;
  width: 100%;
}

.campaignStatus div[class*='top'],
.campaignStatus div[class*='bottom'] {
  width: 100%;
}

.campaignStatus div[class*='top'] > * {
  display: flex;
  align-items: center;
}

.campaignStatus div[class='lastUpdate'] * {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-gray60);
}

.campaignStatus div[class='status'] > *:first-child {
  margin-right: 10px;
}

.campaignStatus div[class*='bottom'] {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
}

.campaignStatus div[class*='bottom'] > div > span {
  font-weight: 700;
}

.progressBar {
  margin-top: 15px;
}

.progressBar > div:first-child {
  border-radius: 8px;
}
