.reportDropdown {
  font-family: inherit;
  white-space: nowrap;
  background-color: var(--color-gray00);
  padding: 10px;
  box-shadow: 0 9px 40px rgba(var(--color-gray80-rgb), 0.14),
    0 3.75px 16.71px rgba(var(--color-gray80-rgb), 0.1),
    0 2px 8.93px rgba(var(--color-gray80-rgb), 0.08),
    0 1.12px 5px rgba(var(--color-gray80-rgb), 0.07),
    0 0.59px 2.66px rgba(var(--color-gray80-rgb), 0.06),
    0 0.24px 1.1px rgba(var(--color-gray80-rgb), 0.04);
  max-width: 400px;
}

.metricsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.deliverySection {
  width: 200px;
  margin: 10px;
}

.engagementSection {
  width: 200px;
  margin: 20px;
}

.progressBar {
  margin-top: 10px;
  margin-bottom: 10px;
  color: blue;
}

.reportSection {
  border-top: 1px solid rgba(var(--color-gray80-rgb), 0.2);
  padding: 10px 5px 5px 5px;
  margin-top: 12px;
}

.reportUrl {
  line-height: 130%;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-brandTintDark);
  text-decoration: none;
  margin-top: 15px;
}

.animate {
  animation-name: open;
  animation-duration: 0.2s;
}

.contentStatsSection {
  margin-top: 5px;
  margin-bottom: 10px;
}

.durationOverride {
  margin-top: 70px;
}

.durationSection {
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.statRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5px;
}

.statRowLabel {
  text-transform: capitalize;
  margin-right: 10px;
}

.statMajor {
  font-size: 14px;
  line-height: 135%;
  color: var(--color-gray90);
}

.statMajorNumber {
  font-weight: 600;
}

.statMinor {
  font-size: 12px;
  color: var(--color-gray70);
}

@keyframes open {
  0% {
    width: 0;
    overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    width: 300px;
  }
}

.dropdown {
  top: 18px;
  right: -40px;
}

.tooltip {
  width: 350px;
}

.tooltipTitle {
  padding: 10px;
}

.workflowStatsSection {
  margin-top: 5px 0;
  width: 150px;
}
