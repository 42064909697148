.wrapper {
  composes: panel kai-flex-row from global;
  margin-bottom: 15px;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  background-color: var(--color-gray00) !important;
}

.exportButton {
  background: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  box-sizing: border-box;
  border-radius: 6px;
  width: 100px;
  height: 36px;
  margin-left: 7px;
}

.exportButton svg {
  vertical-align: middle;
  margin-right: 5px;
}

.searchBarPanel {
  composes: kai-flex-row flex-centered from global;
  color: var(--color-gray50);
  padding: 6px;
  width: 100%;
  border: solid 1px var(--color-gray10);

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  border-radius: 6px !important;
  flex: 1 1;
}

.searchBarLabel {
  composes: kai-flex-row flex-centered from global;
  flex: 1;
}

.searchBarLabel svg {
  margin-left: 5px;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 6;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  position: relative;
  background-color: var(--color-gray00);
  width: 500px;
  padding: 28px 40px;
  border-radius: 10px;
  margin: 10% auto;
  text-align: center;
}

.modalHeader i {
  margin-right: 10px;
}
