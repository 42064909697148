.button {
  composes: pill from global;
  background-color: var(--color-gray10);
  cursor: pointer;
  min-width: 130px;
}

.button span {
  width: 100%;
}

.dropdown {
  composes: dropdown from global;
  min-width: 230px;
  box-sizing: border-box;
  background-color: var(--color-gray00);
}

.noShadow {
  box-shadow: none;
}

.row {
  composes: kai-flex-row from global;
  padding: 3px 15px;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.rowCheckbox {
  padding-right: 6px;
  margin-top: 1px;
  margin-left: 1px;
}

.rowLabel {
  flex: 1;
  width: 100%;
}

.NoMatches {
  text-align: center;
  padding: 32px;
  white-space: nowrap;
}
