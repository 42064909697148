.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  position: relative;
  background-color: var(--color-gray00);
  width: 500px;
  padding: 26px;
  border-radius: 10px;
  margin: 10% auto;
}

.modalHeader {
  padding: 10px 5px;
}

.modalHeader > h2 {
  padding-right: 10px;
  font-size: 20px;
}

.modalHeaderClose {
  position: absolute;
  right: 40px;
  top: 32px;
  cursor: pointer;
  font-size: 16px;
  color: #888;
  border: none;
  background: none;
}

.modalBody {
  padding: 24px 5px;
}

.modalLabel {
  color: var(--color-gray70);
  line-height: 150%;
  font-size: 16px;
  margin-bottom: 15px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
}

.actionName {
  border: 1px solid var(--color-gray10);
  border-radius: 6px;
  display: block;
  padding: 10px 15px;
  color: var(--color-gray70);
}

.modalAccept {
  padding: 8px 20px;
  min-width: 119px;
  height: 24px;
  background: var(--color-gray02);
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-brandTintDark);
  margin: 0 8px;
  cursor: pointer;
}

.modalDeny {
  padding: 8px 20px;
  width: 119px;
  height: 24px;
  background: none;
  border: none;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-gray90);
  cursor: pointer;
}

.alert {
  margin-bottom: 20px;
}

.alertIcon {
  color: var(--color-yellowFull);
}
