
.modalHeader {
  height: 72px;
  padding: 16px 24px;
  font-size: var(--font-size-heading);
  line-height: 150%;
  font-weight: 600;
}

.modalBody {
  border-top: 1px solid var(--color-gray05);
  border-bottom: 1px solid var(--color-gray05);
  min-height: 71px;
  padding: 24px;
  color: var(--color-gray60);
  font-size: var(--font-size-body);
}

.modalFooter {
  width: 100%;
  padding: 16px 24px;
}

.modalWrapper {
  width: 680px;
}

.modalWrapper > div {
  width: 100%;
}

.modalWrapper button {
  margin-left: 8px;
}
