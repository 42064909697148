.disableCheckbox {
  opacity: 0.6;
}

.Label {
  display: flex;
}

.Label .lcon {
  margin-right: 10px;
}

.Fieldset {
  padding: 0;
}

.Fieldset input {
  height: 40px;
}

.errorsContainer {
  width: 100%;
  padding-top: 5px;
}

.error {
  color: var(--color-redFull);
  font-size: var(--font-size-caption);
}

.comboboxPopover {
  z-index: calc(var(--z-index-navigation-footer) - 1);
}
