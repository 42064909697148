.inputAndClear {
  display: flex;
  flex: 1;
  white-space: nowrap;
}

.multiValueTextInput {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.multiValueTextInput input[type='text'] {
  background-color: inherit;
  flex: 1;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 14px;
  height: 34px;
  margin: 4px 0 4px 4px;
}

.selected {
  composes: kai-text-sm kai-flex-row from global;
  composes: button pill from 'shared/styles/buttons.module.css';
  font-weight: 600;
  min-height: 31px;
  margin: 4px;
  background-color: var(--color-gray05);
}

.selected > span {
  overflow: hidden;
  white-space: normal;
  display: block;
}

.clear {
  composes: kai-inline-flex-row kai-text-sm from global;
  font-weight: 600;
  text-decoration: none;
  color: var(--color-gray80);
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}

.clear span {
  font-weight: 300;
  position: relative;
  margin-right: 2px;
}

.remove {
  font-size: 13px;
  color: var(--color-gray30);
  display: inline-block;
  margin-left: 8px;
  margin-right: -4px;
  margin-bottom: -3px;
  padding: 0;
  background-color: transparent;
  border-style: none;
}

.icon {
  padding-left: 16px;
}

.callToAction {
  color: var(--color-gray90);
  font-size: 14px;
  opacity: 0.7;
  padding-left: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: text;
}
