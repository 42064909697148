.delete {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(127, 127, 127, 0.9);
  color: var(--color-gray00);
  z-index: var(--z-index-shared-confirm-delete);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete strong {
  display: block;
  margin-bottom: 15px;
  font-size: 18px;
  text-shadow: 1px 1px 0 black;
}

.delete .confirm {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
}

.delete .confirm svg {
  margin-left: 10px;
}

.delete .cancel {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  color: var(--color-gray00);
}
