.selector {
  width: 100%;
}

.dropdown {
  box-shadow: rgba(0, 0, 0, 0.2) 3px 4px 14px 0, rgba(0, 0, 0, 0.05) 0 0 0 1px;
  box-sizing: border-box;
  background-color: var(--color-gray00);
}

.dropdown .count {
  font-size: 15px;
}

.dropdown .row {
  padding: 0 20px;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown .description {
  font-size: 13px;
  line-height: 1.38;
  color: rgb(121, 135, 145);
  margin: 5px 0 6px;
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dropdown .userCount {
  font-size: 13px;
  color: rgb(121, 135, 145);
}

.dropdown .userCount span {
  margin-left: 4px;
}

.dropdown .rowMinimal {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dropdown .title {
  font-size: var(--font-size-body);
  color: var(--color-gray90);
}

.dropdown .rowMinimal .userCount {
  font-size: var(--font-size-caption);
  color: var(--color-gray60);
}
