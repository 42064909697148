.error {
  color: var(--color-redFull);
  font-size: 14px;
  margin-top: 12px;
}

.video {
  display: flex;
  min-height: 300px;
  align-items: stretch;
}

.video div {
  flex: 1;
}

.video iframe {
  width: 100%;
  height: 100%;
}

.embedded {
  height: 120px;
  margin-right: 5px;
}

.placeholder {
  composes: flex-column flex-centered from global;
  flex: 1;
  height: 260px;
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  border-radius: 6px;
}

.videoField > div:first-child {
  width: 136px;
  margin-top: 6px;
}

.videoPreviewUploader > div:last-child > button:first-child {
  padding: 0;
  background-color: var(--color-gray00);
  width: 100%;
}

.videoPreviewUploader > div:last-child > button > div:first-child {
  width: 100%;
}

.videoPreviewUploader > div:last-child > button:last-child {
  top: 40px;
  right: 40px;
  position: absolute;
  cursor: pointer;
  background-color: var(--color-gray90);
  border-radius: 20px;
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.removeImage > svg {
  height: 14px;
}

.removeImage > svg > rect,
.removeImage > svg > path {
  stroke: var(--color-gray00);
}

.videoTranscodeJobPercentComplete {
  margin-top: 10px;
}

.sourceFieldContainer {
  display: flex;
  align-items: center;
}

.currentSource {
  margin-right: 5px;
}

.captionTextarea {
  border: 1px solid var(--color-gray30);
  color: var(--color-gray90);
  border-radius: 4px;
  background: var(--color-gray00);
  width: 100%;
  resize: none;
  padding: 10px;
  margin-bottom: 10px;
}

.captionButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
