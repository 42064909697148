.container {
  position: relative;
  width: 100%;
}

.container textarea {
  width: 100%;
  border: 1px solid var(--color-gray30);
  color: var(--color-gray90);
  border-radius: 4px;
  background: var(--color-gray00);
  resize: vertical;
  padding: 9px 16px 36px;
  margin: 0;
  font-family: Inter, sans-serif;
}

.container textarea.error {
  border: 1px solid var(--color-redFull);
  color: var(--color-redFull);
}

.count {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 9px 16px;
  color: var(--color-gray70);
  user-select: none;
  display: flex;
}

.expandableTextarea {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  border-top-left-radius: 0 !important;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  border-bottom-left-radius: 0 !important;
  overflow: hidden;
  height: 30px;
}

.count .number {
  padding-left: 5px;
  display: inline-block;
}

.Textarea:focus {
  border: 1px solid var(--color-brandTintDark);
  outline: none;
  box-shadow: 0 0 0 4px var(--color-brandOutline);
}

.count > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LetterCount {
  font-size: 12px;
  text-align: right;
  margin-top: 5px;
}
