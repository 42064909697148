.container > * + * {
  border-top: 1px solid var(--color-gray05);
}

.header {
  display: flex;
  flex-direction: row;
  padding: 24px 32px;
  justify-content: space-between;
}

.modalTitle {
  display: flex;
  flex-direction: column;
}

.body {
  padding: 32px 48px;
  overflow-y: hidden;
}

.contentFilterBar {
  margin-bottom: 32px;
}
