.container {
  composes: publish-main-content from global;
  display: flex;
}

.header {
  font-size: calc(1em + 20pt);
  font-weight: 800;
}

.aside {
  width: 300px;
  background: var(--color-gray05);
  padding: 20px;
}

.main {
  flex: 1;
}
