.list {
  list-style-type: none;
  overflow: visible;
}

.headerContainer {
  composes: kai-text-md kai-flex-row flex-spread from global;
  padding: 5px 20px 0 60px;
  flex: 1;
  width: 100%;
}

.shortcutHeader {
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 600;
}

.shortcutText {
  color: var(--color-gray90);
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: normal;
  display: inline;
  line-height: 150%;
  min-height: 40px;
  font-size: var(--font-size-body);
}

.title {
  margin-left: 32px;
  width: 20%;
}

.status {
  margin-left: 56px;
  width: 100px;
  text-transform: capitalize;
}

.audience {
  width: calc(150px + 20%);
}

.createShortcutContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
}

.newShortcutEnabledToggle {
  display: flex;
  align-items: center;
}

.dragHandle {
  color: var(--color-gray80);
  text-align: center;
  background-color: transparent;
  letter-spacing: 2px;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  cursor: grab;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 8px;
  transform: rotate(0deg);
}

.listItem {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.bannerContainer {
  width: 100%;
}

.section {
  margin-bottom: 32px;
}

.itemTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.itemDesc {
  display: grid;
  grid-template-columns: 1fr 2fr;
  font-size: 14px;
  font-weight: 400;
  color: #1b1a1f;
  margin-bottom: 32px;
  align-items: center;
}

.itemValue {
  font-size: 14px;
  font-weight: 400;
}

.listLink {
  display: contents;
  justify-content: space-between;
  width: 100%;
}

.listLink > div {
  display: flex;
  flex-direction: column;
  align-self: 'flex-start';
  height: 100%;
}

.loadingContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
