.description {
  font-weight: bold;
  align-self: flex-start;
}

.chart {
  flex-shrink: 0;
  flex-basis: 30%;
}

.chart > div {
  width: 100%;
}

.advancedContainer {
  composes: flex-column from global;
  padding-top: 4px;
}

.advancedContainer > :first-child {
  font-size: calc(1em - 2pt);
  color: var(--color-gray70);
  margin-bottom: 10px;
}

.advancedRow {
  composes: kai-flex-row from global;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 32px;
}

.advancedRow > :first-child {
  flex-basis: 160px;
}

.advancedRow > :last-child {
  font-weight: bold;
}

.topicsWrapper {
  composes: kai-flex-row from global;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  flex-wrap: wrap;
}

.topic {
  width: calc(50% - 1px);
  position: relative;
  background-color: var(--color-gray05);
  margin-bottom: 1px;
}

.topic:nth-last-child(1),
.topic:nth-last-child(2) {
  margin-bottom: 0;
}

.topic svg {
  vertical-align: middle;
}

.topicBackground {
  height: 40px;
  width: 40px;
  background-color: var(--color-gray30);
  background-position: center;
  background-size: cover;
}

.templatePriority {
  width: 50%;
}
