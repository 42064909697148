.title {
  margin: 10px 0;
}

.dropdown {
  width: 100%;
}

.button {
  composes: button-reset from global;
  background-color: var(--color-gray10);
  padding: 8px;
  display: inline-block;
}

.inputWrapper > div {
  width: 100%;
}

.description {
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.filterTitle {
  font-size: 14px;
  line-height: 25.5px;
  margin: 0;
  padding-left: 10px;
  font-weight: 400;
}

.filterDescription {
  composes: kai-line-clamp-3 from global;
  white-space: normal;
  font-size: 12px;
  line-height: 16.2px;
  height: 3.8em;
}

.filterCheckbox {
  align-self: flex-start;
}

.topicsFilter {
  width: 300px;
}
