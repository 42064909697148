/* Overriding default styles to match kai designs */

.rc-menu {
  max-width: 200px;
  word-break: break-word;
  border-radius: 8px;
  box-shadow:
    0 21.53px 25.84px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.rc-menu__item {
  padding: 0;
  justify-content: stretch;
}

.rc-menu__item--hover {
  background-color: inherit;
}

.rc-menu__item--wrapper {
  flex-grow: 1;
  font-size: 14px;
  border-radius: 6px;
  padding: 6px 8px;
  margin: 0 8px;
}

.rc-menu__item--description {
  font-style: italic;
  font-size: 12px;
  color: var(--color-gray70);
}

.rc-menu__item--hover .rc-menu__item--wrapper {
  background-color: var(--color-gray02);
}

/* copy of rc-menu__item--wrapper */

.rc-menu__submenu > .rc-menu__item {
  flex-grow: 1;
  font-size: 14px;
  border-radius: 6px;
  padding: 6px 8px;
  margin: 0 8px;
}

.rc-menu__submenu .rc-menu__item--hover {
  background-color: var(--color-gray02);
}

/* radio and checkboxes */

.rc-menu__item--type-radio .rc-menu__item--wrapper,
.rc-menu__item--type-checkbox .rc-menu__item--wrapper {
  padding-left: 32px;
  position: relative;
}

/* get rid of the default icons */

.rc-menu__item--type-radio::before,
.rc-menu__item--type-radio.rc-menu__item--checked::before {
  content: none;
}

.rc-menu__item--type-radio.rc-menu__item--checked .rc-menu__item--wrapper::before {
  content: '';
  box-sizing: content-box;
  position: absolute;
  left: 5px;
  top: 6px;
  height: 16px;
  width: 16px;
  background-color: black;
  border-radius: 100%;
  border: 1px solid black;
}

.rc-menu__item--type-radio.rc-menu__item--checked .rc-menu__item--wrapper::after {
  content: '';
  position: absolute;
  top: 11px;
  left: 10px;
  height: 6px;
  width: 6px;
  background-color: white;
  border-radius: 100%;
  border: 1px solid white;
}

.rc-menu__item--type-radio .rc-menu__item--wrapper ::before {
  content: '';
  box-sizing: content-box;
  position: absolute;
  left: 5px;
  top: 6px;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 1px solid var(--color-gray80);
}

.rc-menu__item--type-checkbox::after {
  content: '';
  box-sizing: content-box;
  position: absolute;
  left: 13px;
  top: 6px;
  height: 16px;
  width: 16px;
  border: 1px solid var(--color-gray80);
}

.rc-menu__item--type-checkbox.rc-menu__item--checked::before {
  top: 6px;
  left: 16px;
  z-index: 1;
}

/* submenu chevrons */

.rc-menu__submenu > .rc-menu__item::after {
  right: 0;
  color: var(--color-gray70);
}
