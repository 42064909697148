.textInput {
  width: 100%;
}

.charCounter {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  color: var(--color-gray80);
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 4px;
}

.audienceSubsection__Subheading {
  display: inline-block;
  composes: Body from 'DesignSystem/Typography/typography.module.css';
  color: var(--color-gray50);
  margin-bottom: 16px;
}

.audienceSubsection__HiddenAudience {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  color: var(--color-gray50);
  margin-top: 8px;
  font-style: italic;
}

.folderSubsection__Subheading {
  display: block;
  composes: Body from 'DesignSystem/Typography/typography.module.css';
  margin-bottom: 16px;
}

.error {
  color: var(--color-redFullAlt);
  font-size: var(--font-size-caption);
  margin-top: 8px;
}
