/*
  Implements Form Elements: https://www.figma.com/file/M7wSClgpb8SBCkYBGmasGr/WIP-Studio?node-id=3191%3A296
 */

.text-input {
  background: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 14px;
  line-height: 155%;
  color: var(--color-gray90);
}

textarea.text-input {
  resize: vertical;
  padding: 10px;
}

.light-container .text-input {
  background-color: var(--color-gray05);
  border-color: var(--color-gray05);
}

.text-input:focus {
  /* var(--color-brandTintDark), opacity: 0.1; */
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
  border-radius: 4px;
  outline: 0;
}

.text-input:disabled {
  background-color: var(--color-gray02);
  color: var(--color-gray50);
  border-color: var(--color-gray30);
}

.full-width {
  width: 100%;
}

label.checkbox-input {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
}

.focus {
  border: 1px solid var(--color-gray90);
  box-sizing: border-box;
  border-radius: 6px;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
}

hr {
  border: none;
  height: 1px;
  background-color: rgba(127, 127, 127, 0.2);
  margin: 16px 0;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--color-gray70);
}

::-moz-placeholder { /* Firefox 19+ */
  color: var(--color-gray70);
}

:-ms-input-placeholder { /* IE 10+ */
  color: var(--color-gray70);
}

:-moz-placeholder { /* Firefox 18- */
  color: var(--color-gray70);
}
