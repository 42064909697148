.form {
  width: 500px;
  padding: 25px 0;
}

.fieldset {
  border: none;
  margin: 0 0 20px;
  padding: 5px 0;
}

.legend {
  color: var(--color-gray70);
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
}

.subjectLineWrapper,
.previewTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subjectLineInput {
  margin-left: 10px;
  flex: 1;
}

.previewTextInput {
  flex: 1;
}

.dropdown {
  width: 500px;
  box-shadow: rgba(0, 0, 0, 0.2) 3px 4px 14px 0, rgba(0, 0, 0, 0.05) 0 0 0 1px;
  box-sizing: border-box;
  background-color: var(--color-gray00);
}

.dropdown .row {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.dropdown .userEmail {
  font-size: 13px;
  margin-top: 3px;
  color: rgb(121, 135, 145);
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
}

.buttonsWrapper {
  display: flex;
  justify-content: right;
}

.buttonsWrapper button {
  margin-left: 10px;
}

.statusWrapper {
  display: flex;
  justify-content: right;
  margin-top: 10px;
  color: var(--color-greenFull);
  min-height: 20px;
}
