.modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gray00);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: var(--z-index-publisher-template-modal);
}

.chooseButton {
  background-color: var(--color-gray70);
  display: flex;
  cursor: default;
  flex-direction: row-reverse;
}

.chooseButton > *:last-child {
  margin-right: 10px;
  background: var(--color-gray50);
  width: 35px;
  height: 35px;
  display: flex;
  padding-top: 5px;
  align-items: flex-start; /* "center" doesn't look right */
  justify-content: center;
  border-radius: 50%;
}

.modal section :global .-NavLink- {
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: var(--color-gray90);
  font-weight: 600;
  text-decoration: none;
}

.modal section :global .-NavLink-:hover {
  color: var(--color-brandTintDark);
}

.modal section :global .-NavLink- > *:first-child {
  background-color: var(--color-gray10);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  transition: all 0.1s linear;
}

.modal section :global .-NavLink-:hover > *:first-child {
  color: var(--color-gray00);
  background-color: var(--color-brandTintDark);
}
