.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: var(--color-brandFull);
  top: 0;
  left: 0;
  z-index: 1001;
}

.loadingPrompt {
  margin-top: 20px;
  color: var(--color-gray00);
}

.dropzone {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: var(--color-brandFull);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray00);
  z-index: 1000;
}
