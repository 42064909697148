.dragDropHandle {
  display: none;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: var(--color-brandShadeLight);
  color: var(--color-gray00);
  z-index: 101;
  padding: 7px 10px;
  font-size: 12px;
}

.visibility {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 16px;
}
