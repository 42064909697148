/*
 Boxes, panels, sections, things that group components visually
 */

/* a normal white rounded panel, with a hint of drop shadow */
.panel {
  background-color: var(--color-gray02);
}

/* like the panel, but raised higher with more of a floating effect */
.floating {
  background-color: var(--color-gray00);
  border-radius: 11px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* some flex box conventions
 (renamed to avoid tailwind name collision) */
.kai-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-top {
  align-items: flex-start;
}

.kai-inline-flex-row {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
}

.flex-centered {
  justify-content: center;
  align-items: center;
}

.flex-spread {
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
}

.flex-fill {
  flex: 1;
  width: 100%;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-justify-end {
  justify-self: flex-end;
}

/* makes the scrollbar disappear until hover, good for embedded content */
.lazy-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.lazy-scrollbar::-webkit-scrollbar-track {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  -webkit-box-shadow: none !important;
  background-color: transparent;
}

.lazy-scrollbar::-webkit-scrollbar {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  width: 3px !important;
  background-color: transparent;
}

.lazy-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* aligns publishing sidebars with "continue" button */

:root {
  --publish-sidebar-width: 307px;
  --publisher-menu-width: 230px;
}

@media screen and (min-width: 1280px) {
  :root {
    --publish-sidebar-width: 338px;
  }
}

@media screen and (min-width: 1366px) {
  :root {
    --publish-sidebar-width: 359px;
  }
}

@media screen and (min-width: 1600px) {
  :root {
    --publish-sidebar-width: 390px;
  }
}

.publish-sidebar {
  width: var(--publish-sidebar-width);
  min-width: var(--publish-sidebar-width);
  height: 100%;
}

/* This depends on the 60px height of the bottom bar in the publisher theme.  We should probably DRY that up. */
.publish-main-content {
  height: calc(100vh - 75px);
  flex: 1;
}

.has-box-shadow {
  box-shadow: 0 21.53px 25.84px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}
