.wrapper {
  height: 60px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  border: none;
  border-radius: 4px;
  background-color: var(--color-brandShadeDark);
  color: var(--color-gray00);
  text-align: center;
  display: block;
  font-size: 15px;
  width: 81px;
  height: 42px;
  outline: none;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  background-color: #d0e2ee; /* TODO: fix me, use a variable or opacity or something not-hardcoded */
}
