.Preview__Container {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
}

.Preview {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.Dropzone {
  border: 1px dashed var(--color-gray30);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px 0;
  gap: 24px;
}

.Dropzone__Content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  gap: 8px;
}

.Actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  background-color: var(--color-gray05);
}

.ActionButton {
  background-color: transparent;

  &:hover {
    background-color: var(--color-gray10);
  }
}

.ReplaceButton {
  composes: ActionButton;
  color: var(--color-brandFull);
}

.DeleteButton {
  composes: ActionButton;
  padding: 4px 8px;
  height: unset;
}

.Loading__Container {
  width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
