.arrows {
  background: var(--color-gray10);
  border: 1px solid var(--color-gray30);
  box-sizing: border-box;
  border-radius: 6px;
  height: 32px;
  width: 76px;
  composes: kai-flex-row from global;
}

.pager {
  display: flex;
  width: 100%;
  font-size: 12px;
  line-height: 19.2px;
  color: var(--color-gray90);
  align-items: center;
  padding-top: 10px;
  justify-content: space-around;
}

.arrows button {
  border: none;
  height: 100%;
  width: 50%;
  background: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.arrows button:disabled {
  cursor: default;
  opacity: 0.2;
}

.arrows button:first-child {
  border-right: 1px solid var(--color-gray30);
}

.wrapper {
  composes: flex-column from global;
  padding: 0 10px;
}
