.alignmentButton {
  margin-right: 4px;
  padding-top: 4px;
  background-color: var(--color-gray00);
  cursor: pointer;
  border: 0;
}

.alignmentButton:hover {
  background-color: var(--color-gray10);
}

.active {
  background-color: var(--color-gray10);
}

.iconsBox {
  align-content: flex-end;
}

.iconButton span {
  font-size: 16px;
}
