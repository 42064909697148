:root {
  /* stylelint-disable-next-line length-zero-no-unit */
  --main-editor-sidebar-width: 0px; /* Taking the 'px' away from this var breaks it */
  --main-editor-grid-width: calc(100vw - var(--main-editor-sidebar-width));
  --main-editor-grid-columns: 9;
  --main-editor-grid-gap-width: 24px;
  --main-editor-grid-gutter-width: 48px;
  --main-editor-grid-col-width:
    calc(
      ( var(--main-editor-grid-width) - var(--main-editor-grid-gap-width) * 8 - var(--main-editor-grid-gutter-width) * 2 )
      /
      var(--main-editor-grid-columns)
    );
  --main-editor-header-columns: 2;
  --main-editor-main-columns: 7;
  --main-editor-header-width: calc(var(--main-editor-grid-col-width) * var(--main-editor-header-columns) + var(--main-editor-grid-gap-width) * (var(--main-editor-header-columns) - 1));
  --main-editor-main-width: calc(var(--main-editor-grid-col-width) * var(--main-editor-main-columns) + var(--main-editor-grid-gap-width) * (var(--main-editor-main-columns) - 1));
  --main-editor-footer-height: 75px;
}

@media (min-width: 1000px) {
  :root {
    --main-editor-sidebar-width: 216px;
  }
}

@media (min-width: 1150px) {
  :root {
    --main-editor-sidebar-width: 307px;
  }
}

@media (min-width: 1280px) {
  :root {
    --main-editor-sidebar-width: 338px;
  }
}

@media (min-width: 1366px) {
  :root {
    --main-editor-sidebar-width: 359px;
  }
}

@media (min-width: 1600px) {
  :root {
    --main-editor-sidebar-width: 390px;
  }
}

.main-editor {
  min-height: 100vh;
  background-color: white;
}

.main-editor-wrapper {
  position: absolute;
  right: 0;
  bottom: var(--main-editor-footer-height);
  top: 0;
  padding-left: calc(var(--main-editor-grid-gutter-width) + var(--main-editor-grid-gap-width));
  padding-right: calc(var(--main-editor-sidebar-width) + var(--main-editor-grid-gutter-width));
  overflow: auto;
  scroll-padding-top: 60px;
}

@media screen and (max-width: 1280px) {
  .main-editor-wrapper {
    left: 0;
  }
}

@media screen and (min-width: 1281px) {
  .main-editor-wrapper {
    left: var(--main-editor-header-width);
  }
}

.main-editor-sidebar-wrapper {
  position: fixed;
  width: var(--main-editor-sidebar-width);
  right: 0;
  top: 0;
  background-color: var(--color-gray02);
  bottom: var(--main-editor-footer-height);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-editor-sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.split-bars .main-editor-wrapper,
.split-bars .main-editor-sidebar-wrapper {
  top: 48px;
  bottom: 64px;
  scroll-padding-top: 0;
  max-height: calc(100vh - 112px);
}

@media screen and (max-height: 801px) {
  .split-bars .main-editor-sidebar-wrapper {
    top: 25px;
  }
}

.main-editor header {
  position: fixed;
  left: var(--main-editor-grid-gutter-width);
  top: 0;
  width: var(--main-editor-header-width);
  padding-top: 60px;
}

.split-bars.main-editor header {
  top: 48px;
}

.main-editor header nav {
  margin-top: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-editor header nav .-NavLink- {
  text-decoration: none;
  padding-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray70);
}

.main-editor header nav .-NavLink-:hover {
  color: var(--color-brandTintDark);
}

.main-editor header nav .-Active-,
.main-editor header nav .-Partial- {
  border-bottom: none;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-gray90);
}

.main-editor main {
  display: block;
  padding-top: 60px;
}

.main-editor aside {
  display: inline-block;
  padding-top: 60px;
  overflow-y: auto;
}

.main-editor aside .section {
  padding: 20px var(--main-editor-grid-gap-width);
  border-bottom: 2px solid white;
}

.main-editor aside .section:first-child {
  padding-top: 0;
}

.main-editor aside .sidebar-title {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: var(--color-gray90);
  padding-left: var(--main-editor-grid-gap-width);
}

body:has(.deliver-page-main-editor) {
  overflow-y: hidden;
}
