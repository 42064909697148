.wrapper {
  composes: kai-flex-row has-box-shadow from global;
  background-color: var(--color-gray00);
  align-items: flex-start;
}

.wrapper.frameless {
  box-shadow: none;
  background-color: inherit;
}

.month {
  width: 220px;
  margin: 40px 20px 20px 25px;
}

.month > strong {
  display: none;
}

.month > header {
  display: none;
}

.controls {
  composes: kai-flex-row from global;
  justify-content: space-around;
  padding-top: 20px;
}

.button {
  composes: transparent from global;
}

.monthName {
  font-size: 16px;
  font-weight: 600;
}

.timeWrapper {
  composes: flex-column from global;
  height: 100%;
  border-left: 1px solid var(--color-gray10);
  width: 140px;
}

.optionsWrapper {
  overflow-y: auto;
  composes: flex-column from global;
  align-items: center;
  height: 245px;
}

.amPmSwitchWrapper {
  padding: 15px 17px;
  border-bottom: 1px solid var(--color-gray10);
}

.amPmSwitchWrapper > button {
  width: 52px;
  height: 25px;
  border: none;
  font-weight: 700;
  color: var(--color-gray90);
  cursor: pointer;
}

.amPmSwitchWrapper > .active {
  color: var(--color-gray00);
  background-color: var(--color-brandTintDark);
}

.timeOption {
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.timeOption:hover {
  background-color: var(--color-brandShadeDark);
  color: var(--color-gray00);
}

.selected {
  background-color: var(--color-brandTintDark);
  color: var(--color-gray00);
}

.disabled {
  color: var(--color-gray30);
  background-color: var(--date-range-unselectable-background-color);
}
