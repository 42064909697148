/* generic or resuable styles */

.pill {
  border-radius: 15px;
  white-space: nowrap;
  border: none;
  padding: 0 12px;
  min-height: 32px;
  line-height: 32px;
  text-decoration: none;
}

.button {
  border: solid 1px var(--color-gray80);
  cursor: pointer;
}

.button:disabled {
  cursor: default;
}

.pill :global(.fa) {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  margin-left: 0;
  margin-right: 5px;
}

.transparent {
  border: none;
  background-color: transparent;
}

.pointer {
  cursor: pointer;
}

/* buttons */

.callToAction {
  background-color: var(--color-brandTintDark);
  border: none;
  color: var(--color-gray00);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  height: 40px;
  padding: 8px 20px 8px 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
}

.buttonReset {
  outline: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
}

.buttonReset:focus {
  outline: none;
}

/* This class is used to apply a red flag to a button indicating it has been flagged.
   It will also apply the color red to the flag and all the button's contents. */

.flaggedWithIcon {
  position: relative;
}

.flaggedWithIcon::before {
  font-family: 'Material Symbols Rounded', sans-serif;
  content: 'flag';
  position: absolute;
  left: -30px;
  top: 1px;
  color: var(--color-redFull);
}

/* This class should always denote a "danger" that something has been flagged.
   So we do not allow alternate colorings when it is used. */
.flaggedWithIcon * {
  /* stylelint-disable */

  color: var(--color-redFull) !important;
  text-decoration-color: var(--color-redFull) !important;

  /* stylelint-enable */
}
