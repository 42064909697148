.fullPage {
  display: flex;
  min-height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  background-color: var(--color-gray00);
}

.leftControls {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
}

.dismiss {
  color: var(--color-gray90);
  background-color: inherit;
  border-style: none;
  margin-bottom: 76px;
  text-align: left;
  cursor: pointer;
  padding: 0;
}

.done {
  margin-top: 20px;
  color: var(--color-gray00);
  background-color: var(--color-brandTintDark);
  height: 30px;
  font-weight: 600;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
}

.done:disabled {
  background-color: var(--color-gray30);
  cursor: default;
}

.editorPane {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;
  align-items: center;
}

.editor {
  width: var(--program-modal-editor-width);
  flex: 1;
  margin-top: 20px;
  margin-bottom: 50px;
}

.sidebar {
  composes: publish-sidebar from global;
}

.title {
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-weight: 700;
}

@media screen and (min-width: 1366px) {
  .dismiss {
    font-size: 15px;
  }

  .dismiss > svg {
    width: 30px;
    height: 30px;
  }

  .leftControls {
    padding-left: 45px;
  }

  .title {
    padding-left: 3px;
  }
}

@media screen and (min-width: 1600px) {
  .title {
    padding-left: 72px;
  }

  .dismiss {
    font-size: 20px;
  }

  .dismiss > svg {
    width: 40px;
    height: 40px;
  }

  .leftControls {
    padding-left: 28px;
  }

  .done {
    margin-left: 72px;
  }
}

@media screen and (max-width: 1366px) {
  .title {
    font-size: 24px;
  }
}
