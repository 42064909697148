.noItemsFound {
  padding-top: 70px;
  text-align: center;
}

.noItemsFound div {
  margin-bottom: 20px;
}

.image {
  height: 100px;
  width: 100px;
}
