.email {
  background-color: var(--color-gray00);
  margin: auto;
  max-width: 600px;
  width: 100%;
}

.email .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #e6edf1;
}

.email .buttons svg {
  height: 20px;
  width: auto;
  opacity: 0.5;
  margin: 10px;
}

.content {
  height: 75vh;
  overflow-y: auto;
}
