.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ContentExplorerContainer {
  flex-grow: 1;
  padding-bottom: 20px;
}

.ContentPage {
  padding: 0 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
