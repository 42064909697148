.selector {
  width: 100%;
}

.dropdown {
  box-shadow: rgba(0, 0, 0, 0.2) 3px 4px 14px 0, rgba(0, 0, 0, 0.05) 0 0 0 1px;
  box-sizing: border-box;
  background-color: var(--color-gray00);
}

.dropdown .row {
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background-size: cover;
  background-position: 50% 50%;
}

.avatar > img {
  width: 40px;
  border-radius: 20px;
}

:global .-svg-author path {
  fill: var(--color-gray50);
}

.role {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--color-gray90);
}

.title {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
}
