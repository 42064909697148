button.clear {
  padding: 5px 10px;
  font-size: small;
  color: var(--color-brandTintDark);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

button.disabled {
  padding: 5px 10px;
  font-size: small;
  color: rgba(0, 0, 255, 0.3);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.moreResults {
  font-size: small;
  color: gray;
  font-style: italic;
  padding: 5px 10px;
}

.optionCheckbox {
  margin-right: 10px;
  color: var(--color-gray10);
}

.optionCheckbox i {
  font-size: 20px;
}

.optionCheckbox i[class*='fa-check-square'] {
  color: var(--color-brandTintLight);
}

.optionDiv {
  display: grid;
  grid-template-columns: max-content max-content;
}

.labelSpan {
  hyphens: auto;
  word-wrap: break-word;
  width: 220px;
}

.MultiValueLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 3px;
}

.offsetSpacer {
  position: absolute;
  height: 400px;
  width: 100%;
}
