.error {
  background-color: var(--color-redFull);
  color: var(--color-gray00);
  padding: 6px;
  margin: 0 0 10px 0;
  border-radius: 20px;
  height: 56px;
  width: 356px;
}

.warning {
  background-color: var(--color-yellowFull);
  color: var(--color-yellowShadeDark);
}

.warningButton {
  background-color: var(--color-yellowFull);
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--color-yellowShadeDark) !important;
}

.error > .warningButton:hover {
  background-color: var(--color-yellowFull);
}

.error > span {
  font-size: var(--font-size-body);
  margin-top: 1px;
}

.error > svg {
  margin: 0 6px;
  height: 20px;
}

.error > svg > path {
  stroke: var(--color-gray00);
}

.error > button {
  margin-left: auto;
  color: var(--color-gray00);
  cursor: pointer;
}

.error > button svg {
  cursor: pointer;
}

.error > button:hover {
  background-color: var(--color-redFull);
}

.error > button:focus {
  border: none;
}
