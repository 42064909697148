.mainLayout {
  display: flex;
}

.container {
  align-items: flex-start;
  clear: both;
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
}

.main {
  box-sizing: border-box;
  padding-right: 50px;
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.pageActionWrapper {
  display: flex;
  flex-direction: row-reverse;
  min-height: 54px;
  padding: 6px 5px;
}

.addIcon {
  margin: 4px 13px 0 0;
}

.searchBar {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 40px;
  margin-right: 6px;
  padding: 1px 11px;
  width: 376px;
}

.searchBar:focus-within,
.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.actionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
}

.basic {
  width: 274px;
  height: 80px;
  padding: 0 25px 0 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.statsWrapper {
  display: flex;
}

.statCol {
  display: flex;
  flex-direction: column;
  height: 80px;
  width: 155px;
}

.statCol:last-child {
  margin-right: 0;
}

.statHeader {
  font-size: 9px;
  text-transform: uppercase;
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-weight: 600;
}

.stat {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-gray90);
}

.topicName {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
}

.title:hover {
  content: attr(.title);
}

.description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  margin: 4px 0;
}

.filters {
  display: flex;
  width: 100%;
}

.bannerWrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.statIcon {
  margin-right: 5px;
}
