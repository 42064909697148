.dropdown {
  background-color: var(--color-gray00);
  margin: 0;
  padding: 8px;
  composes: dropdown from global;
  width: 250px;
}

.option {
  display: flex;
}

.option:hover {
  background-color: var(--color-gray05);
  border-radius: 6px;
}

.option button {
  text-align: left;
  flex: 1;
  padding: 5px;
  font-size: 18px;
}

.menuButton {
  min-width: 165px;
  height: 28px;
  display: flex;
  flex-grow: 1;
  background-color: var(--color-gray05);
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
}

.append {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label {
  white-space: nowrap;
  cursor: pointer;
}

.spinnerContainer {
  width: 100%;
}
