.container {
  margin: 20px -20px 45px;
  display: flex;
  justify-content: center;
  min-height: 300px;
}

.empty {
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 3.5px;
  position: relative;
}

.templates {
  margin: -20px auto;
  padding: 0;
  width: 100%;
  position: relative;
}

.campaignTemplateImage {
  height: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.emptyLogo {
  background-color: var(--color-gray05);
  composes: flex-column from global;
  align-items: center;
  justify-content: space-around;
}

.description {
  margin-top: 9px;
  composes: kai-line-clamp-2 from global;
}

.spinner {
  width: calc(100% - 150px);
  composes: flex-column from global;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  height: calc(100% - 20px);
}

.preview {
  text-decoration: none;
  color: inherit;
}

.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1280px) {
  .sidebar {
    padding: 10px 20px;
  }
}

@media screen and (min-width: 1281px) {
  .sidebar {
    padding: 20px 50px;
  }
}

.sidebar strong {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin-top: 50px;
}

.classic {
  padding: 20px;
  flex: 1;
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.previewButton {
  height: 292px;
  width: 100%;
  padding: 0;
  background-color: var(--color-gray02);
}

.previewButton > span:first-child {
  width: 100%;
}

.previewButton > span:first-child > span:first-child {
  width: 100%;
}

.navigationButton {
  height: 60px;
  padding: 0;
  font-weight: 600;
  margin-top: 10px;
}

.iconWrapper {
  background-color: var(--color-gray10);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.simple {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
