[data-inclusive-language-marker] {
  display: inline-block;
  border-bottom: solid 2px var(--color-yellowFull);
}

[data-inclusive-language-marker]:hover {
  background-color: rgba(var(--color-yellowFull-rgb), 0.2);
  cursor: pointer;
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-click-dropdown);
}

.popup div {
  background-color: var(--color-gray00);
  box-shadow: 0 5px 15px rgba(var(--color-gray90-rgb), 0.1);
  border-radius: 10px;
  width: 330px;
}

.popup small {
  display: block;
  padding: 20px 20px 0;
  font-size: 13px;
  font-weight: 700;
}

.popup > div > span {
  padding-bottom: 20px;
  display: block;
}

.popup > div > span > button {
  border: none;
  cursor: pointer;
  margin: 5px 0 0 10px;
  padding: 5px 10px;
  color: var(--color-greenFull);
  background-color: inherit;
  font-size: 18px;
  line-height: 18px;
  font-weight: 900;
}

.popup > div > span > button:hover {
  background-color: rgba(var(--color-greenFull-rgb), 0.2);
}

.popup p {
  padding: 0 20px 20px;
  margin: 0;
  font-size: 13px;
}
