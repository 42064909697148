.cardsContainer {
  composes: kai-flex-row from global;
  gap: 15px;
}

.wrapper {
  border: 1px solid var(--color-gray10);
  border-radius: 8px;
  cursor: pointer;
  padding: 16px;
}

.wrapper.isSelected,
.wrapper:hover {
  background-color: var(--color-gray05);
}

.title {
  composes: Subheading from 'DesignSystem/Typography/typography.module.css';
  composes: Bold from 'DesignSystem/Typography/typography.module.css';
  display: block;
  margin-bottom: 16px;
}

.description {
  composes: Text from 'DesignSystem/Typography/typography.module.css';
  color: var(--color-gray60);
  font-size: 14px;
}
