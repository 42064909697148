.statContent {
  width: 100%;
}

.statList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  row-gap: 16px;
}

.statItemContainer {
  display: flex;
  justify-content: space-between;
}

.statItemTitle {
  font-size: 14px;
  color: var(--color-gray60);
}

.statItemBody {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-gray90);
}
