.clearButton {
  border: none;
  height: auto;
  background-color: unset;
  color: unset;
  cursor: unset;
  opacity: unset;
  padding: unset;
  position: relative;
  left: 2px;
  top: -1px;
  margin-bottom: 5px;
}

.dropdownButton {
  font-size: 15.5pt;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
}

.dropdownButtonState {
  width: 0;
  height: 0;
  margin-left: 3px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  content: '';
}

.inputContainer {
  width: 100%;
  padding: 10px 7px 0 7px;
}

.input {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0;
  padding: 7px 16px 7px 0;
}

.inputLight {
  border: 1px solid var(--black-10);
}

.inputLightDivider {
  border-top: 1px solid var(--black-10);
}

.inputPrefixText {
  border: 1px solid var(--color-gray30);
  height: 100%;
  padding: 7px 7px 7px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0;
  font-size: 14px;
  line-height: 155%;
  font-weight: bold;
}

.popoverContainer {
  display: block;
  background: var(--color-gray00);
  box-shadow: 0 0 24px -5px rgb(0 0 0 / 32%), 0 10px 10px -5px rgb(0 0 0 / 4%);
}

.listItem {
  padding: 8px 16px;
}

.listItem:hover {
  background: var(--color-gray05);
}

.variableName {
  font-size: 16px;
  text-transform: capitalize;
}

.variableDescription {
  display: block;
}

.variableDescription::first-letter {
  text-transform: uppercase;
}

.variableText {
  font-family: inherit;
  font-weight: bold;
  color: var(--color-brandShadeDark);
  background-color: #ebe6fe;
  border-radius: 3px;
  padding: 0 8px;
  text-transform: capitalize;
}
