.Section:not(:first-of-type) {
  margin-top: 48px;
}

.Link {
  width: 235px;
  height: 155px;
  background-color: var(--color-gray00);
  border-radius: 8px;
  border: 1px solid var(--color-gray10);
  margin: 8px;
  padding: 0 20px;
  text-decoration: none;
}

.Link:hover {
  background-color: var(--color-gray02);
}
