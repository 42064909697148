.hoverTooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  white-space: normal;
}

.tooltipTitle {
  font-size: 12px;
}

.tooltip {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.reverseRow {
  flex-direction: row-reverse;
}

.reverseColumn {
  flex-direction: column-reverse;
}

.arrow {
  margin: auto;
  padding: 0;
  width: 0;
  height: 0;
  opacity: 0.8;
}

.rightArrow {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid var(--color-gray90);
}

.leftArrow {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid var(--color-gray90);
}

.bottomArrow {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-top: 7px solid var(--color-gray90);
}

.topArrow {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid var(--color-gray90);
}
