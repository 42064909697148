.domain-grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  row-gap: 1rem;
}

.domain-grid *:nth-child(1n) {
  grid-column: span 2 / span 2;
}

.domain-grid *:nth-child(2n) {
  grid-column: span 10 / span 10;
}
