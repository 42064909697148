
.title {
  margin: 10px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
}

.dropdown {
  background-color: var(--color-gray00);
  composes: dropdown from global;
}

.selector {
  width: 260px;
}

.filterTitle {
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  display: block;
  color: var(--color-gray90);
}
