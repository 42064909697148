.reviewContainer {
  height: calc(100vh - 75px);
  overflow-y: scroll;
  padding: 32px;
}

.heading {
  border-bottom: solid 1px var(--color-gray10);
  padding-top: 60px;
}

.mainSectionContainer {
  width: 826px;
}

.previewSectionContainer {
  margin-left: 34px;
  width: 288px;
}

.ErrorMessageLink {
  text-decoration: none;
  color: var(--color-gray80);
}
