/* All z-index values should be referenced here
   Always compare your z-index against existing ones to ensure proper ranking

   Rules of thumb for z-index...
   • 1-10     for most visible elements within a page
   • 100-110  for global elements that must rank highly (eg modals)
*/

:root {
  /* shared components */
  --z-index-shared-variable-text-input-dropdown: 100;
  --z-index-shared-variable-text-input-channel: 0;
  --z-index-shared-forms-datetime-input-container: 100;
  --z-index-shared-color-preview-popover: 100;
  --z-index-shared-confirm-delete: 1000;
  --z-index-shared-duration-input-selector-wrapper: 3;
  --z-index-shared-overlay: 2;
  --z-index-shared-overlay-content: 3;
  --z-index-shared-overlay-modal: 10000;
  --z-index-navigation-footer: 1000;

  /* makes sure messages and tooltips are always shown */
  --z-index-shared-max: var(--z-index-shared-overlay-modal);
  --z-index-shared-flash-message: calc(var(--z-index-shared-max) + 1);
  --z-index-shared-hover-tooltip: calc(var(--z-index-shared-max) + 1);

  /* shared program-wide styles */
  --z-index-shared-program-layout-sticky-top-nav: 1000;

  /* Audience Builder */
  --z-index-audience-builder-menu-wrapper: 1;
  --z-index-audience-builder-permission-description: 1;
  --z-index-audience-builder-menu-items-wrapper: 2;
  --z-index-audience-builder-editor-modal-wrapper: 6;
  --z-index-audience-builder-editor-facets-total: -1;

  /* Insights */
  --z-index-share-url-modal: 5;

  /* Publisher */
  --z-index-publisher-library-wrapper: 101;
  --z-index-publisher-settings-publish-time-disclaimer: 100;
  --z-index-publisher-settings-no-campaign-duration-disclaimer: 100;
  --z-index-publisher-template-modal: 1001;
  --z-index-publisher-block-controls: 101;
  --z-index-publisher-block-controls-cancel-delete-icon: 10;
  --z-index-publisher-block-controls-confirm-delete-text: 1;
  --z-index-font-selector: 1000;
  --z-index-click-dropdown: 1000;
  --z-index-block-error-banner: 100;

  /* Journeys */
  --z-index-journey-drawer: 99;
  --z-index-journey-canvas-header: 99;
  --z-index-journey-design-editor-modal: 100;
}
