.FlexContainer {
  display: flex;
  flex-wrap: wrap;
}

.Pill {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 20px;
  height: 32px;
  border: 1px solid var(--color-gray10);
  padding: 20px;
}

.TextField {
  width: 100%;
}

.Input {
  margin-bottom: 32px;
}

.Error {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: var(--color-redFull);
}

.Disabled {
  position: relative;
}

.Disabled::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  border-radius: 8px;
  opacity: 0.8;
}

.ReviewTermsButton {
  width: 100px;
}
