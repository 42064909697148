.Pill {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--color-gray40);
}

.Pill span {
  color: var(--color-gray80);
  word-break: break-word;
  white-space: normal;
  display: flex;
  align-items: center;
}

.Pill.SingleLine span {
  height: 19px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Pill .CloseButton {
  border-radius: 10px;
  height: 20px;
  width: 20px;
  right: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-right: -8px;
  margin-left: 4px;
  cursor: pointer;
}

.RegularCloseButton {
  font-size: 10px;
  font-weight: bold;
}

.LargeCloseButton {
  font-size: 20px;
  padding-bottom: 3px;
  font-weight: normal;
}

.Pill .Icon {
  margin-right: 9px;
}

.Pill .Icon svg {
  width: 14px;
  height: 14px;
}

.Pill .CloseButton:hover {
  background-color: var(--color-gray10);
}

.Pill:not(:last-child) {
  margin-right: 6px;
}

.noAction {
  opacity: unset;
}

.Channel {
  background-color: red;
}

/* Exported styles */
.GreenTintDarkPillClass {
  border: 1px solid var(--color-greenTintDark);
  background-color: var(--color-greenTintDark);
}

.Gray10PillClass {
  border: 1px solid var(--color-gray10);
  background-color: var(--color-gray10);
}

.OrangeTintDarkPillClass {
  border: 1px solid var(--color-orangeTintDark);
  background-color: var(--color-orangeTintDark);
}

.WhitePillClass {
  border: 1px solid var(--color-gray40);
  background-color: white;
}
