:root {
  --date-range-in-range-background-color: var(--color-gray10);
  --date-range-unselectable-background-color: var(--color-gray20);
}

.month > header {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: var(--color-gray90);
  text-align: center;
  margin-bottom: 14px;
}

.daySpan span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 50%;
}

.rangeCap span {
  color: var(--color-gray00);
}

.month > div {
  display: flex;
  flex-wrap: wrap;
}

/* use flexbox to constrain each span to occupy 1/7 of the width
   https://stackoverflow.com/a/45384426 */

.month > div > span {
  flex: 1 0 14.2857%;
}

.dayHead {
  color: var(--color-gray30);
  font-weight: 700;
  font-size: 13px;
  width: 40px;
  text-align: center;
  margin-bottom: 9px;
}

.daySpan {
  box-sizing: border-box;
  width: 22px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 2px;
  border: none;
}

.weekDaySpan {
  font-weight: 700;
  flex: 1;
}

.startOfRange.rangeCap span {
  background-color: var(--color-gray90);
}

.endOfRange.rangeCap span {
  background-color: var(--color-brandShadeLight);
  position: relative;
}

.endOfRange.rangeCap span::before {
  content: '';
  border-radius: 100%;
  height: 34px;
  width: 34px;
  border: 2px solid var(--color-brandTintDark);
  position: absolute;
}

.endOfRange.rangeCap:hover span::before {
  border: 2px solid var(--color-brandShadeLight);
}

.outOfMonth {
  color: var(--color-gray30);
}

.inRange {
  border-left: none;
  border-right: none;
  background-color: var(--date-range-in-range-background-color);
}

.weekInRange {
  color: var(--color-gray00);
  background-color: var(--color-brandShadeDark);
  border: none;
  height: 32px;
}

.outOfMonth.inRange {
  color: #ccd;
}

.inRange.startOfRange {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  background-color: var(--date-range-in-range-background-color);
}

.inRange.endOfRange {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background-color: var(--date-range-in-range-background-color);
}

.daySpan:hover span {
  background-color: var(--color-gray30);
}

.weekDaySpan:hover span {
  background-color: initial;
}

.notSelectable,
.notSelectable:hover {
  color: var(--color-gray30);
  cursor: default;
  background-color: var(--date-range-unselectable-background-color);
}

.selectedDate {
  background-color: var(--color-brandTintDark);
  color: var(--color-gray00);
  border-radius: 100%;
}

.hidden {
  opacity: 0;
}

.week {
  width: 100%;
  display: flex;
  height: 32px;
  border-top: 0;
  margin-top: 2px;
}

.week:hover {
  background-color: var(--color-gray05);
}

.startOfWeek {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.endOfWeek {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
