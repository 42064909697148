.singleAction,
.hoverAction {
  background-color: var(--color-gray05);
  border: none;
  border-radius: 6px;
  color: var(--color-gray90);
  cursor: pointer;
  font-weight: bold;
  line-height: 32px;
  margin-left: 10px;
  max-height: 32px;
  padding: 0 15px;
}

.singleAction:hover,
.hoverAction:hover {
  background: var(--color-gray10);
  color: var(--color-gray80);
}

.hoverAction > span {
  margin-right: 10px;
}

.dropdown {
  composes: dropdown from global;
  border-radius: 8px;
  padding: 10px;
}

.dropdownItem {
  border: none;
  background-color: unset;
  padding: 8px 10px;
  text-align: left;
  width: 100%;
}

.dropdownItem:hover {
  background-color: var(--color-gray02);
}
