.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.deleteWarning {
  color: var(--color-gray90);
  margin-bottom: 20px;
  font-size: 14px;
}

.actionButton {
  border: none;
  border-radius: 6px;
  background-color: var(--brand-primary-light-1);
  color: var(--color-brandTintDark);
  font-weight: 600;
  padding: 5px 20px;
  cursor: pointer;
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
