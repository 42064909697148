.settingsButton {
  margin: 0 4px;
  width: 20px;
}

.settingsButton path {
  fill: currentColor;
}

.downloadLink {
  color: var(--color-gray90);
  height: 24px;
  margin-left: 4px;
  text-align: center;
}

.removeCaptionButton {
  width: 18px;
}

.warningIcon {
  color: #fa8c16;
}

.setting {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting > strong:first-child {
  font-size: 14px;
  font-weight: 400;
}

.setting > strong:first-child::first-letter {
  text-transform: uppercase;
}

.expandable {
  cursor: pointer;
}

.deletionModalIcon {
  vertical-align: middle;
  font-size: 30px;
  color: var(--color-orangeFull);
}

.contentsWrapperOld {
  padding: 8px 16px;
}

.contentsWrapper {
  padding: 8px;
  padding-left: 16px;
}

.iconButton {
  width: 24px;
  margin-left: 4px;
}

.failureStatus div {
  gap: 0.5rem;
}

.failureStatus > div button {
  padding: 0.1rem;
}
