.dropzone {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: var(--color-gray00);
  background: repeating-linear-gradient(45deg, var(--color-gray50), var(--color-gray50) 10px, var(--color-gray30) 10px, var(--color-gray30) 20px);
  position: absolute;
  z-index: 2;
}
