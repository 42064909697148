.input {
  background: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
}

.field span {
  display: block;
  align-self: center;
  font-weight: 600;
  font-size: 14px;
}

.title textarea {
  resize: none;
  height: 65px;
  padding: 9px 16px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 22.6px;
}
