.button {
  composes: pill from global;
  background-color: var(--color-gray10);
  cursor: pointer;
  min-width: 130px;
}

.button span {
  width: 100%;
}

.dropdown {
  min-width: 130px;
  max-width: 300px;
  box-shadow: rgba(0, 0, 0, 0.2) 3px 4px 14px 0, rgba(0, 0, 0, 0.05) 0 0 0 1px;
  box-sizing: border-box;
  background-color: var(--color-gray00);
}

.row {
  composes: kai-flex-row from global;
  padding: 3px 9px;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--color-gray90);
  width: 100%;
}

.rowCheckbox {
  padding-right: 6px;
}

.rowLabel {
  flex: 1;
  width: 100%;
}
