.sidebar {
  position: relative;
  width: 347px;
}

.sidebar a,
.sidebar input,
.sidebar button {
  pointer-events: initial;
}

.applyButton,
.cancelButton {
  margin-bottom: 15px;
  padding: 8px 25px;
  background-color: var(--color-brandTintDark);
  border-radius: 6px;
  border: none;
  color: var(--color-gray00);
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  cursor: pointer;
  width: 215px;
}

.cancelButton {
  color: var(--color-gray90);
  background-color: var(--color-gray05);
  font-size: 15px;
}

.applyButton:disabled {
  cursor: default;
  opacity: 0.5;
}

.actionsAside {
  width: 270px;
  margin-left: 50px;
}

.aside {
  width: 270px;
  margin-left: 50px;
  margin-bottom: 30px;
}

.aside ul {
  margin: 0;
  padding: 0;
}

.aside li {
  list-style-type: none;
  margin: 10px 0;
}

.aside button {
  background-color: transparent;
  border: none;
  color: var(--color-gray50);
  cursor: pointer;
  outline: none;
  max-width: 165px;
  font-size: 13.3333px;
}

.aside button.active {
  color: var(--color-gray90);
  outline: none;
  cursor: default;
  border-left: 4px solid var(--color-brandTintDark);
  font-weight: bold;
}

.aside button.locked {
  cursor: not-allowed;
  color: var(--color-gray30);
}

.aside hr {
  height: 1px;
  border: none;
  background-color: var(--color-gray10);
  max-width: 170px;
  margin: 50px auto 20px 0;
}

.divider {
  margin-top: 40px;
  width: 215px;
  height: 0;
}

.sidebarFixed {
  position: fixed;
  overflow-y: scroll;
  height: 100%;
}

.sidebarModal {
  height: 100%;
}

.back {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.label {
  margin: 43px 0 44px 50px;
  font-weight: bold;
  font-style: normal;
  font-size: 36px;
}

.buttons {
  composes: flex-column from global;
  align-items: flex-start;
}

.addNew {
  padding-top: 5px;

  /* gets overriden by aside  >  button */
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--color-brandTintDark) !important;
  font-weight: 700;
}

.editorContainer {
  width: 215px;
  composes: flex-column from global;
  justify-content: space-around;
}

.editorInputsContainer {
  composes: kai-flex-row from global;
  padding-left: 5px;
}

.editorContainer > input {
  height: 35px;
}

.editorInputsContainer > button {
  padding: 0;
  margin: 0;
}

.applyChanges > svg {
  height: 22px;
  width: 22px;
}

.ourCategory {
  composes: kai-flex-row from global;
  justify-content: space-between;
  width: 210px;
  background-color: #fff;
}

.ourCategoryControls {
  align-self: center;
  height: 18px;
  composes: kai-flex-row from global;
  width: 45px;
}

.ourCategoryControls > button {
  padding: 0;
  width: 17px;
  height: 17px;
}

.applyChanges {
  /* gets overriden by .aside  >  button */
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--color-brandTintDark) !important;
  opacity: 0.5;
  padding-left: 5px;
}

.canApplyChanges {
  opacity: 1;
}

.ourCategoryControls > button > svg {
  height: 17px;
  width: 17px;
  display: none;
}

.ourCategory:hover > .ourCategoryControls > button > svg {
  display: block;
}

.ourCategoryName {
  flex-grow: 1;
  text-align: left;
  padding: 0 0 0 4px;
}

.deleteButtons {
  margin-top: 25px;
  composes: kai-flex-row from global;
  justify-content: flex-end;
}

.deleteButtons > button {
  margin-left: 10px;
}

.deleteWarning {
  margin-top: 25px;
}

.aside button:hover {
  color: var(--color-gray90);
}

.ourCategoryControls > button:last-child {
  padding-left: 5px;
}

.error {
  color: var(--color-brandSolar);
  padding-left: 6px;
  font-size: 14px;
}

.categoryInput {
  width: 152px;
}
