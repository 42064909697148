.option {
  display: flex;
  padding: 7.5px 10px;
  border-bottom: 1px solid var(--color-gray05);
}

.option:hover {
  background-color: var(--color-gray05);
}

.optionAvii {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.optionAvii > img {
  width: 100%;
  height: 100%;
}
