.wrapper {
  border: 3px dashed var(--color-brandShadeDark);
}

.dynamicBlockName {
  flex-grow: 1;
}

.controlsButton {
  padding: 4px;
}

.navigationButtons {
  width: 110px;
  height: 24px;
  justify-content: space-around;
}

.actionButtons {
  width: 80px;
  height: 24px;
  justify-content: space-around;
}

.containerName {
  height: 34px;
}

.defaultContentBlock {
  height: 256px;
  justify-content: center;
  padding: 26px 0;
}

.defaultContentBlock > span {
  text-align: center;
}

.checkOutTheKnowledgeBase > span {
  margin: 0;
}

.lineHeight {
  line-height: 21px;
}

.controlsWrapper {
  height: 48px;
}

.disabled {
  color: var(--color-gray60);
}

.editableTitleWrapper {
  height: 32px;
  width: 240px;
  position: relative;
}

.editableTitleWrapper > i {
  position: absolute;
  top: 9px;
  left: 9px;
}

.editableTitleWrapper > input {
  height: 32px;
  padding-left: 30px;
  background: transparent;
  font-size: var(--font-size-caption);
}
