.switcher {
  position: absolute;
  bottom: 100px;
  max-width: 250px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius: 7px;
  opacity: 0;
}

.switcher > button {
  height: 50px;
  width: 50px;
  border: none;
  background-color: var(--color-gray00);
  border-radius: 0;
}

.switcher > button:hover {
  cursor: pointer;
}

.switcher > button.active {
  background-color: #222;
  color: var(--color-gray00);
}

.switcher > button:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.switcher > button:last-child {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.review .text {
  padding-left: 29px;
}

.review .text .title {
  font-weight: 600;
  font-size: 16px;
}

.email {
  background-color: var(--color-gray00);
  min-height: 400px;
  width: 600px;
  margin: auto;
}

.email .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-gray05);
}

.email .buttons svg {
  height: 20px;
  width: auto;
  opacity: 0.5;
  margin: 10px;
}

.review {
  composes: kai-flex-row from global;
}

.description {
  font-size: 14px;
}

.review .image {
  background-size: cover;
  height: 96px;
  width: 96px;
}
