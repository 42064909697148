.BoxInput {
  width: 100%;
  font-size: 14px;
}

.SelectButton {
  margin-bottom: 16px;
}

.ImageBackground {
  background-color: white;
}
