.new button {
  cursor: pointer;
  background: var(--color-gray05);
  border: none;
  width: 100%;
  height: 114px;
}

.footer {
  display: flex;
  justify-content: center;
  padding-bottom: 128px;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.actionButton {
  border: none;
  background-color: transparent;
  font-weight: 700;
  margin-left: 33px;
  cursor: pointer;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButton > svg {
  height: 18px;
  width: 18px;
  margin-right: 13px;
}

.inactive {
  opacity: 0.5;
}

.footer .addButton {
  border: none;
  width: 100%;
  text-align: center;
  display: block;
  background-color: var(--color-gray05);
  cursor: pointer;
  padding: 66px 0;
  color: var(--color-gray50);
}

.footer > div:first-child {
  width: 100%;
}
