.node {
  display: flex;
}

.icon {
  margin-right: 8px;
}

.content {
  flex: 1;
  padding: 10px;
}

.caption {
  font-size: 12px;
  font-weight: bold;
}

.nodeBody {
  border-radius: 16px;
  padding: 15px;
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid var(--color-gray10);
  color: black;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.disabledNode {
  opacity: 0.5;
}

.disabledNode .nodeBody {
  cursor: not-allowed;
}

.selectedNode {
  border: 1px solid var(--color-blueFull);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

.erroredNode {
  border: 1px solid var(--color-redFull);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

.messageBody div,
.messageBody span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectableNode,
.action > button,
.actions > button > span {
  cursor: pointer;
}

.bottomButtons {
  margin-top: 20px;
  padding-top: 10px;
  text-align: right;
}

.channelsContainer {
  border-top: 1px solid var(--color-gray10);
  margin-top: 8px;
  padding-top: 8px;
  width: 100%;
}

.channelsContainer > svg {
  margin-right: 8px;
}

.baseNodeContainer {
  position: relative;
}

.move {
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
}

.move > button {
  padding: 0;
}

.actions {
  position: absolute;
  right: 0;
  height: 24px;
  width: 100%;
  padding: 4px 12.5px;
  text-align: right;
}

.actions > button,
.actions > button > span {
  height: 24px;
  padding: 0;
}

.move svg {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.actions > button svg {
  height: 24px;
  width: 24px;
}

.actions,
.move {
  opacity: 0;
}

.actions > .delete {
  color: var(--color-redFull);
}

.moveRight {
  right: -30px;
}

.moveRight > button {
  margin: 0 0 0 auto;
}

.moveLeft {
  left: -30px;
}

.move:hover,
.actions:hover,
.baseNodeContainer:hover > .move,
.baseNodeContainer:hover > .actions,
.baseNodeContainer:hover > button {
  opacity: 1;
}

.messageBody {
  align-items: flex-start;
  line-height: 150%;
  overflow: hidden;
}

.messageBodyCompact {
  line-height: 120%;
  height: 42px;
}

.messageBody > div:first-child,
.messageBody > span:first-child {
  font-weight: 600;
  font-size: var(--font-size-subheading);
  line-height: 24px;
}

.messageBody > div:last-child,
.messageBody > span:last-child {
  font-size: var(--font-size-caption);
  line-height: 18px;
}

.body {
  width: 100%;
}

.bodyContainer {
  padding-left: 1px;
  width: 100%;
  height: 42px;
  padding-top: 1px;
}

.bodyContainerCompact {
  height: 42px;
}

.nodeContainer {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.handle {
  border: none;
  height: 1px;
  width: 4px;
  background: #b1b1b7;
  opacity: 0;
}

.warningIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  color: var(--color-redFull);
}

.channelsList {
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  gap: 8px;
  color: var(--color-gray50);
  border-top: 1px solid var(--color-gray10);
}
