.pinnedPostsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 44px;
  margin-top: 16px;
  row-gap: 10px;
}

.sortablePostsContainer {
  width: 100%;
}

.sortablePostsContainer > li {
  width: 100%;
}

.sortablePost {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  column-gap: 8px;
  background-color: var(--color-gray05);
  border: 1px solid var(--color-gray10);
  cursor: grab;
  padding: 10px 16px;
}

.sortablePost:active {
  cursor: grabbing;
}

.sortablePost button {
  cursor: pointer !important;  /* stylelint-disable-line declaration-no-important */
}

.sortablePostTitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.postFinderContainer {
  height: calc(100vh - 216px);
  overflow: hidden;
}

.postFinderHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 19px;
  font-size: var(--font-size-body);
}
