:root {
  --insights-sidebar-width: 175px;
  --insights-scrollbar-width: 20px;
  --insights-title-header-size: 42px;
  --insights-title-subtext-size: 16px;
  --insights-title-subtext-color: var(--color-gray70);
  --insights-hover-background-change-transition-time: 0.08s;
  --insights-hover-btn-background-change-transition-time: 0.2s;
  --insights-main-section-min-height: 50vw;
}

.searchBar {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 40px;
  margin-right: 6px;
  padding: 1px 11px;
  width: 376px;
}

.searchBar:focus-within {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06), 0 8.70566px 23.2151px rgba(0, 0, 0, 0.08), 0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.pageHorizontalMargin {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: calc(var(--program-main-content-horizontal-padding) - var(--insights-scrollbar-width));
  margin-left: var(--program-main-content-horizontal-padding);
  min-height: var(--insights-main-section-min-height);
}

.containerFlexboxColumn {
  display: flex;
  flex-direction: column;
}

.titleBar h1 {
  font-size: 2em;
}

.titleBar p {
  font-size: var(--insights-title-subtext-size);
  color: var(--insights-title-subtext-color);
}

h4.subheader {
  font-size: 16px;
  padding: 20px 0;
}

.pageContainer {
  display: block;
  overflow-y: auto;
  min-height: calc(var(--insights-main-section-min-height) - 35px);
}

.main {
  flex-grow: 2;
  position: relative;
  box-sizing: content-box;
}

.sidebar {
  box-sizing: content-box;
  width: var(--insights-sidebar-width);
  min-width: var(--insights-sidebar-width);
  max-width: var(--insights-sidebar-width);
  margin-right: 15px;
}

/* the shared sidebar element has a fixed width. we need to set it manually to be smaller here */

.sidebar > div {
  width: var(--insights-sidebar-width);
}

/* allow long nav links to break if they exceed the fixed width */

.sidebar a {
  word-break: break-word;
}

/* the nav links have a default left padding from the standard css theme
   insights pages all have a standard margin so this padding is unnecessary
   we pass this class into the child component to override the default padding
   src/App/Program/Main/theme/standard-theme.module.css:100 */

.sidebar .navLinkNoPadding {
  padding-left: 0;
}

.centeredItemsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

/* metabase iframe view */

.metabaseEmbedContainer {
  width: 100%;
  position: relative;

  /* this is necessary to contain the iframe from
                          over-z-indexing the other page components.
                          Namely, the iframe will obstruct the filter dropdowns */
}

/* the loading view for the report view is split between...
   (top) loading indicator (bottom) a placeholder iframe view
   the iframe view needs to be on the DOM in order for iframeResizer to work
   For this reason, we place the loading indicator at the bottom of its view
   and on the page, this will place the indicator effective in the middle of the screen
 */

.iFrameLoadingIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: var(--color-gray70);
}

.RelatedReports {
  margin-bottom: 32px;
}

.ReportDownload {
  color: var(--color-brandFull);
  border: none;
  text-align: left;
  padding: 8px;
  display: flex;
  width: 100%;
  background-color: var(--color-gray05);
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.ReportDownload:hover {
  background-color: var(--color-gray02);
}
