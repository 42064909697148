.wrapper {
  position: relative;
  padding: 13px 96px;
  display: flex;
  height: 100%;
  overflow: auto;
}

.wrapper.modal {
  padding: 0;
  max-height: 100%;
  overflow: auto;
  display: inline-block;
}

.main {
  display: flex;
  flex-direction: column;
  width: calc(100% - 347px);

  /* 347px - sidebar width */
}

.modal .main {
  width: 100%;
}

.pagination {
  text-align: center;
  padding: 50px;
}

.imageFeatures {
  composes: flex-column from global;
}

.uploaderContainer {
  composes: flex-column from global;
  justify-content: center;
  align-items: center;
  margin-top: -90px;
  height: 100%;
}

.uploaderContainer > button {
  display: flex;
  flex-direction: column;
}

.uploadButtonContainer > button {
  height: 18px;
  padding: 0;
  color: var(--color-brandTintDark);
  font-weight: 700;
  margin-left: 4px;
}

.fullScreen {
  flex: 1;
}

.empty {
  composes: flex-column from global;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
}

.empty > span {
  font-size: 20px;
  color: var(--color-gray60);
  font-weight: 500;
}
