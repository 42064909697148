.wrapper {
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.topicOrAudience {
  display: inline-block;
  border: 1px solid silver;
  padding: 6px 15px;
  border-radius: 60px;
  margin: 3px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray90);
  box-sizing: border-box;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (min-width: 1050px) {
  .topicOrAudience {
    max-width: 200px;
  }
}

.moreTopicOrAudienceItemItems {
  padding: 10px;
  font-size: 13px;
}

.topicOrAudienceItem {
  margin-bottom: 5px;
}

.topicOrAudienceName {
  display: inline-block;
  width: 60%;
  font-weight: 600;
}

.topicOrAudienceCount {
  width: 40%;
  display: inline-block;
  text-align: right;
}

.moreItemDropdown {
  right: 0;
  top: 50px;
}
