.title {
  composes: kai-text-md from global;
  width: 400px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-gray80);
  padding: 24px 24px 24px 0;
}

.detail {
  composes: kai-text-md from global;
  color: var(--color-gray90);
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 24px;
}

.active,
.activeWithDraft,
.draft,
.publishing,
.paused,
.publishingError {
  border: none;
}

.active,
.activeWithDraft {
  background-color: #a3edaa;
}

.activeWithDraft {
  min-width: 128px;
}

.draft {
  background-color: #91d5ff;
}

.publishing {
  background-color: #91d5ff;
}

.publishingError {
  background-color: #ffa39e;
}

.paused {
  background-color: #fffbe8;
}

.publishingContainer {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.createdAt {
  width: 220px;
}

.createdBy {
  width: 210px;
  overflow: hidden;
  text-wrap: none;
  text-overflow: ellipsis;
}

.statePill {
  width: 185px;
}

.metrics {
  width: 220px;
}

@media (max-width: 1400px) {
  .detail {
    padding: 12px;
  }
}
