.totalUsers {
  display: block;
  font-size: 14px;
}

.middleText {
  font-family: inherit;
  text-align: center;
}

.icons {
  display: block;
  margin: auto;
}
