.errorText {
  font-family: inherit;
  color: var(--color-brandTintDark);
  display: inline;
  padding: inherit;
  padding: 15px;
  font-size: 14px;
}

.errorTitle {
  font-family: inherit;
  font-size: 20px;
}

.btn {
  border: none;
  background-color: inherit;
  font-size: 16px;
  cursor: pointer;
  display: inline;
  vertical-align: text-bottom;
  margin-top: 0;
  color: var(--color-gray90);
}

.link {
  text-decoration: none;
}

.arrowIcon {
  display: inline;
  color: var(--color-gray90);
}

.arrowIcon path {
  fill: currentColor;
}

.errorWrapper {
  font-size: 14px;
}

.errorBlock {
  padding-bottom: 5px;
}

.errorHead {
  padding: 12px;
}

.mainErrorBlock {
  padding: 40px;
}

.errorListSpacing {
  padding: 15px;
}
