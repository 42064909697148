.frOption {
  composes: flex-column from global;

  /* Override Froala */
  /* stylelint-disable-next-line declaration-no-important */
  padding: 8px 16px !important;
  font-family: Inter, sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.frOption:focus {
  background: #ebebeb;
}

.frOptionContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.frSubOption {
  composes: flex-column from global;

  /* Override Froala */
  /* stylelint-disable-next-line declaration-no-important */
  padding: 14px 16px !important;
  font-family: Inter, sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.frSubOption:hover {
  background-color: #ebebeb;
  cursor: pointer;
}
