.error {
  color: var(--color-redFull);
}

.file > div {
  display: flex;
  align-items: baseline;
}

.filename {
  margin-left: 12px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}

.updateAndCancel {
  display: flex;
  align-items: center;
}

.name {
  width: 100%;
}

.update {
  width: 143px;
  height: 42px;
  justify-content: center;
}

.cancel {
  margin-left: 10px;
  border: none;
  background-color: transparent;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.trash {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--color-gray70);

  /* Hack because the SVG has empty space to the right */
  margin-right: -12px;
}

.dropZone {
  position: relative;
}

.virusCheckField {
  align-items: center;
}

.scanStatus {
  display: flex;
  align-items: center;
}

.scanStatus :first-child {
  margin-right: 9px;
}

.scanning {
  width: 22px;
  height: 22px;
  animation: scanning 1.2s linear infinite;
  position: relative;
}

@keyframes scanning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
