.checkboxLabel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto 0;
}

.Icon svg {
  width: 22px;
  height: 22px;
}

.Label {
  display: flex;
  align-items: center;
}

.Label .Icon {
  margin-right: 18px;
}

.SmallIcon {
  font-size: 18px;
}
