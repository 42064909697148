.basics {
  text-align: center;
  padding: 50px;
  height: 900px;
  border: 0;
  border-radius: 5px;
}

.basics .addLayoutButton {
  display: flex;
  width: 100%;
  height: 225px;
  background-color: var(--color-gray05);
  font-size: 16px;
  margin: 0;
  justify-content: center;
  border: none;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.basics div {
  display: flex;
  justify-content: center;
}

.basics p {
  color: var(--color-gray50);
  font-weight: 600;
  margin-bottom: 50px;
}

.basics svg {
  height: 42px;
  width: 42px;
}

.basics .addLayoutButton:hover {
  color: var(--color-gray80);
}

.addLayoutButton div:nth-of-type(2) {
  margin-top: 25px;
}
