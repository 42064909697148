.Suggestion {
  margin-left: 12px;
  padding: 2px 0 0 21px;
  margin-bottom: 10px;
  position: relative;
  margin-top: 10px;
}

.Suggestion .border {
  position: absolute;
  border: 0;
  left: 0;
  top: 0;
  width: 4px;
  border-radius: 2px;
  background-color: var(--color-brandFull);
  height: 100%;
}

.SuggestionButton {
  height: 48px;
  background-color: var(--color-gray02);
  padding-left: 21px;
}

.SuggestionButton :nth-child(2) {
  flex: 1;
}

.Suggestion .IgnoreButton {
  color: var(--color-brandFull);
}

.Badge {
  width: 22px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-brandSolar);
  display: inline-flex;
  top: 0;
  left: 5px;
  position: relative;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}
