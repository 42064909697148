.EditPermissionsModal {
  max-height: 95vh;
  overflow: scroll;
}

.EditPermissionsModal span[class*='typography_Subheading'] {
  margin: 8px 0;
}

.SearchInput input::placeholder {
  color: var(--color-gray60);
}

.SearchInput svg {
  margin-right: 8px;
}

.SearchInput svg * {
  stroke: var(--color-gray60);
}

.PillsContainer {
  gap: 8px;
}

.PillsContainer div[class*='pills_Pills'],
.PillsContainer div[class*='pill_Pill'] {
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important;
}
