.wrapper {
  justify-content: flex-start;
  align-items: flex-start;
}

.list {
  list-style-type: none;
}

.settings {
  margin-left: 40px;
  margin-top: 8px;
  justify-content: flex-start;
  align-items: flex-start;
}

.quantity {
  border-radius: 4px;
  width: 130px;
  height: 32px;
  border: 1px solid var(--color-gray30);
}

.wrapper > span {
  height: 44px;
}

.quantity > div {
  font-size: var(--font-size-caption);
}

.unit {
  padding-left: 8px;
}

.list > li {
  margin: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.settings > span {
  line-height: 24px;
  padding-bottom: 8px;
}

.quantity > button {
  padding: 0 8px;
  font-size: 24px;
  margin-top: -3px;
  height: 32px;
}

.quantity > button:focus {
  box-shadow: none;
}

.list > li > div:first-child > div {
  margin-left: 16px;
  font-size: var(--font-size-body);
  line-height: 24px;
}

.unit svg {
  height: 16px;
  width: 16px;
}

.input {
  border: none;
  padding: 0;
  width: 30px;
  text-align: center;
}

.input:focus-visible {
  border: none;
  outline: none;
}
