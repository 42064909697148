.previewCard {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.cardHeading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px;
}

.cardTitle {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray90);
}

.cardLink {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-default);
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
}

.cardPlaceholder {
  padding: 24px;
  font-size: 14px;
  text-align: center;
  color: var(--color-gray60);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
