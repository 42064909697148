.overlayContainer {
  position: relative;
}

.overlayPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.overlayInner {
  width: 100%;
  height: 100%;
}
