.page {
  composes: publish-main-content from global;
  display: flex;
  background-color: var(--color-gray00);
}

.container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: stretch;
  overflow-y: auto;
}

.aside {
  margin: 66px 0 40px 48px;
  width: var(--publisher-menu-width);
  background: var(--color-gray00);
}

.main {
  padding: 76px 40px 60px 0;
}

.disabled {
  opacity: 0.6;
}

.sidebar {
  composes: sidebar from '../../publisher.module.css';
  position: absolute;
  right: 0;
  overflow-y: auto;
  background-color: var(--color-gray05);
}

.nav {
  composes: nav from '../../publisher.module.css';
}

@media screen and (min-width: 1280px) {
  .main {
    width: 670px;
  }
}

@media screen and (min-width: 1366px) {
  .main {
    width: 730px;
  }
}

@media screen and (min-width: 1600px) {
  .main {
    width: 930px;
  }
}

@media screen and (min-width: 1920px) {
  .main {
    width: 1250px;
  }
}
