
.gauge .background {
  stroke: var(--color-gray10);
}

.gauge .indicator {
  stroke: var(--color-gray70);
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
