.container > span {
  vertical-align: top;
}

.gauge {
  display: inline-block;
  position: relative;
}

.gauge .background {
  stroke: var(--color-gray30);
}

.gauge .indicator {
  stroke: var(--color-greenFull);
  transition: stroke-dashoffset 0.35s;
  transform: rotate(180deg);
}

.indicator.success {
  stroke: var(--color-greenFull);
}

.indicator.error {
  stroke: var(--color-redFull);
}

.indicator.neutral {
  stroke: var(--color-gray70);
}

.gauge .value {
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  font-family: Gilroy, sans-serif;
  color: var(--color-gray90);
  position: absolute;
  top: 39px;
  width: 100%;
}

.gauge .value .unit {
  font-size: 20px;
  font-family: Gilroy, sans-serif;
  fill: var(--color-gray90);
}

.gauge .label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--color-gray90);
  padding-top: 5px;
}

.helpIcon {
  color: var(--color-gray30);
  padding-top: 10px;
  cursor: pointer;
}

.helpIcon > div {
  display: flex;
  justify-content: space-around;
}

.tooltip {
  width: 400px;
}

.tooltipContent > div:last-child {
  padding-top: 14px;
}

/* styles for redesign */
div:global(.deliver-redesign) .gauge .label {
  text-transform: none;
  font-size: 14px;
  font-family: var(--font-family-primary);
  font-weight: normal;
  letter-spacing: initial;
  position: relative;
  top: -15px;
}

div:global(.deliver-redesign) .gauge .value {
  font-size: 21px;
  font-weight: normal;
  top: 36px;
}

div:global(.deliver-redesign) .gauge-value-error {
  color: var(--color-redFull);
}

div:global(.deliver-redesign) .gauge-value-success {
  color: var(--color-greenFull);
}
