.PopoutMenu {
  position: absolute;
  background-color: var(--color-gray00);
  box-shadow:
    0 21.53px 25.84px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
  overflow: auto;
  z-index: 1000;
}

@media screen and (max-width: 1280px) {
  .PopoutMenu {
    margin-right: -40px;
  }
}

.PopoutMenu::-webkit-scrollbar-thumb {
  background-color: var(--color-gray30);
  border-radius: 4px;
}

.PopoutMenu::-webkit-scrollbar {
  background-color: var(--color-gray05);
  width: 8px;
}
