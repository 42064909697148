.metricsPanel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  padding-top: 20px;
}

.metricCard {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: #e8e7e7 1px solid;
  border-radius: 12px;
  row-gap: 20px;
  background-color: #fff;
}

.metricCardHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
  flex-shrink: 0;
}

.metricCardHeader .metricCardText {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.metricCardHeader .row {
  display: flex;
  justify-content: space-between;
}

.metricCardBody {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.metricCardButtonGroup {
  display: flex;
  column-gap: 4px;
}

.infiniteScrollWrapper {
  max-height: 600px;
  overflow: auto;
  width: 100%;
}

.metricCardBody.center {
  height: 100%;
}

.metricCardSubtext {
  font-size: 15px;
  line-height: 18px;
}

.metricCardBody .metricTable {
  width: 100%;
  border-collapse: collapse;
}

.metricTable .row {
  width: 100%;
  border-bottom: 1px solid rgba(3, 2, 41, 0.05);
}

.metricTable .row:last-child {
  border: none;
}

.metricTable .row td {
  text-align: center;
  padding: 16px 32px;
  font-size: 14px;
  font-weight: 500;
}

.metricTable .row .heading {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 600;
}

.metricTable .row .bold {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 600;
}

.metricTable .row td:first-child {
  text-align: left;
}

.centeredText {
  text-align: center;
}

.metricsGreenText {
  font-weight: 600;
  color: #7eca04;
}

.metricsPurpleText {
  color: #8a67ff;
}

.metricCardQuarter {
  flex: 1 1 calc(25% - 50px);
  max-width: calc(25% - 8px);
}

.metricCardHalf {
  flex: 2 1 calc(50% - 50px);
  max-width: calc(50% - 8px);
}

.metricCardFull {
  flex: 0 0 100%;
}

.metricCardLegend {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  justify-content: flex-end;
  flex-grow: 1;
}

.metricCardLegendLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.metricCardLegendLabel span {
  font-size: 14px;
  font-weight: 400;
  color: rgba(100, 116, 139, 1);
}

.toolTip {
  position: absolute;
  transform: translate(-50%, calc(-100% - 5px));
  pointer-events: none;
}

.toolTip.p {
  padding: 0;
  margin: 0;
}

.toolTip .metricCardBody {
  row-gap: 0;
  align-items: flex-start;
}

.toolTip .metricCard {
  row-gap: 13px;
}

.toolTip .title {
  font-size: 15px;
  line-height: 1.1;
  width: 100%;
  text-align: center;
}

.toolTip .item {
  font-size: 15px;
  line-height: 20px;
}

.toolTip::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-color: white;
  border-bottom: 0;
  margin-left: -7px;
  margin-bottom: -6px;
}

.toolTip::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #e8e7e7;
  border-bottom: 0;
  margin-left: -8px;
  margin-bottom: -8px;
}

.metricTable .row .title {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 600;
}
