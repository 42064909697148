.filterTrigger {
  composes: kai-flex-row from global;
  padding: 10px 14px;
  font-size: 12px;
  font-weight: 600;
  background: var(--color-gray05);
  color: var(--color-gray80);
  border-radius: 6px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 6px;
  height: 40px;
}

.searchBarWrapper,
.icon {
  background-color: var(--color-gray05);
  border-radius: 6px;
  margin-right: 6px;
}

.icon {
  cursor: pointer;
  float: left;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: var(--color-gray90);
  background-color: var(--color-gray00);
}

.icon i {
  font-weight: 200;
}

.filterTrigger:hover,
.icon:hover {
  background: var(--color-gray10);
  color: var(--color-gray80);
}

.searchBarWrapper {
  border: 1px solid transparent;
  height: 40px;
  padding: 0 10px;
  width: 376px;
}

.booleanTrigger {
  cursor: default;
}

.searchBarWrapper:focus-within {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 0 1.5px 5px 2.5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBarWrapper:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.dropdown {
  border-radius: 8px;
}

.filterTrigger strong {
  margin-left: 3px;
}

.newFiltersButton {
  cursor: pointer;
  width: 50px;
}

.AllFilters {
  padding-right: 12px;
}

.filtersWrapper {
  composes: kai-flex-row from global;
  flex-wrap: wrap;
  font-size: 14px;
}

.filtersWrapper > div {
  margin-bottom: 5px;
}

.filtersWrapper > div:first-child {
  margin-right: 12px;
}

.filterItemIcons {
  display: flex;
}

.filterItemBadge {
  display: flex;
  flex-direction: row;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray10);
  border-radius: 50%;
  padding: 1px 9px;
}

.sourcesDropdownRow {
  composes: kai-flex-row from global;
  padding: 3px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  height: 32px;
  justify-content: space-between;
  text-transform: capitalize;
  font-size: inherit;
}

.sourcesDropdownRow:hover {
  background-color: var(--color-gray02);
}

.expandedDropdown {
  left: 192px;
  top: -10px;
}

.filterTitle {
  padding-left: 16px;
  text-transform: capitalize;
}

.closeButton {
  composes: flex-column from global;
  align-items: center;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  margin-top: 2px;
  margin-left: 6px;
  height: 18px;
  width: 18px;
  padding: 1px;
}

.closeButton:hover {
  background-color: var(--color-gray30);
}

.sourcesDropdownContainer {
  composes: dropdown from global;
  width: 192px;
  border-radius: 8px;
}

.sourceOption {
  padding: 14px 16px;
  border-bottom: 1px solid var(--color-gray10);
}

.sourceName {
  padding-left: 16px;
  font-size: inherit;
}

.sourcesDropdown {
  box-shadow: none;
}

.submittersContainer {
  width: auto;
}

.sourcesDropdown > div:first-child > button,
.externalSourcesDropdown > div:first-child > button {
  color: var(--color-brandTintDark);
  padding: 6px 16px;
  font-size: 12px;
}

.sourcesOptionRow {
  composes: kai-flex-row from global;
}

.sourcesTitle {
  padding: 0;
}

.modalHeader {
  height: 88px;
  width: 100%;
  border-bottom: var(--color-gray05) 1px solid;
  padding: 24px 32px 0 32px;
  align-items: flex-start;
}

.modalHeader > span {
  line-height: 30px;
  font-size: var(--font-size-heading);
  font-weight: 600;
}

.modalHeader > button > span {
  cursor: pointer;
}

.modalBody {
  background-color: var(--color-gray02);
  height: 587px;
  width: 100%;
  padding: 24px 0;
  overflow: hidden;
  border-radius: 0 0 16px 16px;
}

.modalFooter {
  height: 104px;
  padding: 32px;
  width: 100%;
  border-top: var(--color-gray05) 1px solid;
  background-color: var(--color-gray00);
  border-radius: 0 0 16px 16px;
}

.templateButton {
  height: 140px;
  width: 278px;
  border-radius: 8px;
  background-color: var(--color-gray00);
  border: var(--color-gray10) 1px solid;
  text-decoration: none;
  color: var(--color-gray90);
  padding: 0;
  margin: 16px;
}

.modalFilterBar {
  width: 100%;
  padding: 0 32px;
}

.buttonsWrapper {
  width: 100%;
  padding: 0 16px;
}

.previewNotch {
  position: absolute;
  bottom: 25px;
  left: 0;
}

.templateInfo {
  align-items: flex-start;
  padding: 16px;
  width: 278px;
  height: 140px;
  cursor: pointer;
}

.templateInfo > div:first-child {
  font-weight: 600;
  line-height: 30px;
  font-size: 20px;
  height: auto;
  width: 248px;
  text-align: left;
  text-wrap: wrap;
  overflow: hidden;
  white-space: normal;
}

.templateInfo > div:nth-child(2),
.templateInfo > div:nth-child(3) {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding-top: 4px;
}

.blankCanvasJourneyLink {
  font-weight: 600;
  line-height: 30px;
  font-size: 20px;
  align-items: flex-start;
  padding: 16px;
  width: 278px;
}

.blankCanvasJourneyLink > div:first-child {
  padding-top: 6px;
  margin-right: 8px;
}

.blankCanvasJourneyLink > div:last-child {
  line-height: 37px;
}

.templateButtonsWrapper {
  padding: 0 16px;
  max-height: 500px;
  width: 100%;
}

.templateButton:hover {
  background-color: var(--color-gray00);
  border: var(--color-gray10) 1px solid;
  cursor: pointer;
}

.rowWrapper {
  justify-content: flex-start;
}

.noItemsFound {
  flex: 1;
}
