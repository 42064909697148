.button > button > span {
  cursor: pointer;
}

.publishModalHeader {
  min-height: 72px;
  padding: 16px 24px;
  font-size: var(--font-size-heading);
  line-height: 150%;
  font-weight: 600;
}

.modalError::first-letter {
  text-transform: uppercase;
}

.modalError {
  display: block;
}

.modalErrorHeader > svg {
  color: var(--color-redFull);
}

.modalPublishWarning > svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 4px;
  color: var(--color-yellowFull);
}

.button > button:focus {
  box-shadow: none;
}

.statusString {
  color: var(--color-gray50);
  font-size: var(--font-size-caption);
}

.primaryButton {
  height: 32px;
  font-size: var(--font-size-body);
}

.saveIcon {
  font-size: var(--font-size-heading);
  color: var(--color-gray90);
}

.saveButton {
  padding: 0 8px;
  margin: 0 8px;
}

.saveButton:hover {
  background-color: var(--color-gray05);
}

.editableTitle {
  position: relative;
}

/* These classes need to be in sync so that the editableTitleLabel and editableTitle are the same size */
.editableTitleInput,
.editableTitleLabel {
  font-size: var(--font-size-subheading);
  font-weight: 600;
  line-height: 32px;
  padding: 0 8px;
  border: 1px solid transparent;
  border-radius: 4px;
  max-width: calc(100vw - 775px);
}

.editableTitleLabel {
  position: absolute;
  top: 0;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.editableTitleLabelHidden {
  max-width: none;
  visibility: hidden;
}

.editableTitleInputHidden {
  color: transparent;
}

.editableTitleInput:hover {
  border: 1px solid var(--color-gray30);
  outline: 4px solid var(--color-brandOutline);
}

.editableTitleInput:focus-visible {
  border: 1px solid var(--color-gray80);
  outline: 4px solid var(--color-brandOutline);
}

.journeyHeading > span:nth-child(3) {
  margin-right: 16px;
}
