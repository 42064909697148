.fallbackAvatar {
  height: 32px;
  width: 32px;
  font-weight: 700;
  font-size: 24px;
  border: 0.1px solid black;
  border-radius: 3px;
}

.avatar {
  height: 32px;
  width: 32px;
  object-fit: contain;
  border-radius: 3px;
}

.commentBox {
  margin-bottom: 16px;
}

.avatarBox {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  width: 64px;
}

.commentHeader {
  font-size: var(--font-size-body);
}

.authorName {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
}

.createdAt {
  color: var(--color-gray30);
  margin-left: 8px;
  margin-bottom: 0;
  margin-top: 0;
}

.deleteButton {
  margin-left: 8px;
}

.actionButtons {
  margin-top: 4px;
}

.hide {
  display: none;
}

.container {
  width: 100%;
  border-radius: 3px;
  padding: 8px 0;
}

.reported {
  border: 2px solid var(--color-redFull);
}

.repliesBox {
  width: 89%;
  margin-left: auto;
  margin-top: 8px;
}

.content {
  overflow-y: scroll;
  max-height: 700px;
  min-height: 200px;
}

.deleted {
  color: var(--color-redFull);
  font-weight: 700;
  padding-left: 8px;
}

.filter {
  padding-bottom: 25px;
  padding-top: 20px;
  padding-left: 20px;
}
