.filtersWrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-left: 15px;
}

.filterBar {
  margin-top: -12px;
  margin-bottom: 12px;
}

.filterBar > div:first-child {
  padding: 4px 8px;
}

.filterBar > div > label > svg {
  height: 14px;
  width: 14px;
  color: var(--color-gray90);
}

.filterBar > div > label > input {
  margin-left: 6px;
}
