.block {
  composes: flex-column from global;
  flex-grow: 0;
  flex-basis: 10%;
  align-self: flex-start;
}

.selected {
  flex-basis: 15%;
  align-self: stretch;
  border: 2px solid purple;
  cursor: pointer;
}

.label {
  composes: flex-column from global;
}

.label > :first-child {
  text-transform: uppercase;
  font-size: calc(1em - 3pt);
  color: var(--color-gray70);
}

.label > :last-child {
  font-size: calc(1em + 20pt);
  font-weight: bold;
  color: var(--color-gray70);
}

.active > :last-child {
  color: currentColor;
}

.measures {
  composes: flex-column from global;
  padding-top: 20px;
}

.measure {
  display: flex;
  justify-content: space-between;
}

.measure > :first-child {
  color: var(--color-gray70);
}

.measure > :last-child {
  font-weight: bold;
}
