.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--z-index-audience-builder-editor-modal-wrapper);
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  position: relative;
  background-color: var(--color-gray00);
  width: 500px;
  padding: 28px 40px;
  border-radius: 10px;
  margin: 10% auto;
}

.modalHeader {
  padding: 5px;
}

.modalHeaderClose {
  position: absolute;
  right: 40px;
  top: 32px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #888;
  border: none;
  background: none;
}

.modalBody {
  padding: 30px 5px;
}

.modalLabel {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-gray50);
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
}

.modalInput {
  width: 410px;
  height: 40px;
  background: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;
}

.modalAccept {
  padding: 8px 20px;
  width: 119px;
  height: 42px;
  background: var(--color-brandTintDark);
  border-radius: 6px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-gray00);
  margin: 0 8px;
  cursor: pointer;
}

.modalDeny {
  padding: 8px 20px;
  width: 119px;
  height: 42px;
  background: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-gray90);
  cursor: pointer;
}
