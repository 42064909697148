.summary {
  composes: kai-flex-row from global;
  background: var(--color-gray90);

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  align-items: stretch !important;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  margin-right: 10px !important;
  flex-basis: 55%;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
}

.label {
  composes: flex-column from global;
  color: #c0c0c0;
}

.label > :last-child {
  font-size: calc(1em + 10pt);
  font-weight: bold;
}

.expected {
  composes: label;
}

.actual {
  composes: label;
}

.actual > :last-child {
  color: var(--color-gray00);
}
