.wrapper {
  padding-right: 50px;
  display: flex;
  flex: 1 1;
  color: var(--color-gray90);
}

.fontData {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding-left: 25px;
}

.preview {
  height: 80px;
  width: 80px;
  border: none;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.description {
  composes: kai-line-clamp-1 from global;
  margin-top: 15px;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-gray50);
}

.button {
  color: var(--color-gray80);
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
  border: solid 1px transparent;
  background-color: transparent;
  transform: rotate(90deg);
  cursor: pointer;
  letter-spacing: 2px;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}

.colHeader {
  font-size: 9px;
  text-transform: uppercase;
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-weight: 600;
}

.colData {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-gray90);
}

.title {
  width: 320px;
  font-size: 16px;
  font-weight: 600;
}

.created {
  font-size: 12px;
  line-height: 15.6px;
  color: var(--color-gray50);
}

.createdAt {
  font-weight: 700;
  padding-right: 3px;
}

.createdBy > span {
  font-weight: 700;
}

.disabledOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: var(--color-gray05);
  left: 0;
  composes: kai-flex-row from global;
  justify-content: space-around;
  top: 0;
}

.spinnerContainer > div {
  height: 20px;
  width: 20px;
}

.spinnerContainer > div > svg:first-child {
  height: 20px;
  width: 20px;
}

.spinnerContainer > div > svg:last-child {
  height: 10px;
  width: 10px;
  top: 10px;
  left: 10px;
}

.icon {
  height: 60px;
  width: 60px;
  background-color: var(--color-brandShadeDark);
  color: var(--color-gray00);
  text-transform: uppercase;
  font-size: 37px;
  border-radius: 3px;
}

.previewModal {
  position: absolute;
  background-color: var(--color-gray00);
  width: 710px;
  z-index: 1000;
  composes: dropdown from global;
  left: 50px;
  padding: 35px;
  cursor: default;
}

.regularFont {
  font-weight: 400;
}

.boldFont {
  font-weight: 700;
}

.thinFont {
  font-weight: 200;
}

.smallFont {
  font-size: 14px;
  line-height: 14px;
}

.mediumFont {
  font-size: 24px;
  line-height: 28px;
}

.bigFont {
  font-size: 48px;
  line-height: 55px;
}

.styleName {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-gray30);
  margin-bottom: 2px;
}

.fontExamples {
  overflow-y: auto;
}

.fontExample {
  padding-bottom: 10px;
  width: 100%;
}

.fontExample > div {
  padding-bottom: 10px;
}

.styles {
  width: 200px;
}

.creator {
  width: 100px;
}

.status {
  width: 65px;
}

.filtersWrapper {
  margin-left: 10px;
}

.filters {
  margin-left: 10px;
}
