.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
}

.main div {
  padding-left: 6px;
}

.main .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowText {
  margin-right: 3px;
}
