.Title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
}

.Caption {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--color-gray60);
}

.Button {
  border-radius: 4px;
}

.Bolt {
  left: 23px;
  top: 32px;
}

.Bolt svg {
  width: 16px;
  height: 16px;
}
