.bannerColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.primaryBannerColumn {
  flex: 2;
}

.auxiliaryBannerColumn {
  flex: 1;
}

.bannerColumnHeader {
  font-size: 9px;
  font-weight: 600;
  opacity: 0.7;
  text-transform: uppercase;
  margin: 8px 0;
}

.bannerColumnBody {
  flex: 1;
}

.deleteModalIcon {
  vertical-align: middle;
  font-size: 30px;
  color: var(--color-orangeFull);
}
