.sidebar {
  flex-grow: 0;
  composes: publish-sidebar from global;
}

.sidebar .section {
  padding: 24px 24px 29px;
  border-bottom: 2px solid white;
  background-color: var(--color-gray02);
}

.sidebar .planSection {
  padding-top: 0;
  padding-bottom: 34px;
}

.planSectionHeader {
  font-size: 24px;
}

.nav {
  margin-top: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.nav :global .-NavLink- {
  text-decoration: none;
  padding-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray70);
  opacity: 0.4;
}

.nav :global .-NavLink-:hover {
  color: var(--color-brandTintDark);
  opacity: 1;
}

.nav :global .-Active-,
.nav :global .-Partial- {
  border-bottom: none;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-gray90);
  opacity: 1;
}

.audienceNeeded {
  font-size: 12px;
  font-weight: 400;
}

/* new styles for redesign */
div:global(.deliver-redesign) h3.planSectionHeader {
  font-size: 21px;
}

.BlockPanel > div:first-child { margin: 16px; }

.BlockPanel > div:last-child {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.BlockPanel > div:last-child > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.BlockPanel button {
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px var(--color-gray10);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

@media screen and (min-width: 1281px) {
  .BlockPanel button {
    width: 140px;
    height: 110px;
    margin: 10px 0;
  }
}

@media screen and (max-width: 1280px) {
  .BlockPanel button {
    width: 120px;
    height: 100px;
    margin: 5px 0;
  }
}

.BlockPanel button:hover {
  background-color: var(--color-gray05);
}
