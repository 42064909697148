.journeyDrawer {
  width: 100%;
  z-index: calc(var(--z-index-journey-drawer));
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray00);
  box-shadow: 0 0 5px var(--color-gray30);
  transition: height 350ms ease-in-out;
  display: flex;
  flex-direction: column;
}

.journeyDrawer.closed {
  height: 0;
}

.journeyDrawer.partial {
  height: 70vh;
}

.journeyDrawer.maximized {
  height: calc(100vh - 65px);
}

.journeyDrawerButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px 24px;
  gap: 8px;
}

.journeyDrawerButton {
  padding: 0;
  width: 24px;
  height: 24px;
}

.journeyDrawerButton i {
  margin-right: 0;
}

.journeyDrawerTabs {
  margin-bottom: 0;
}

.journeyDrawerScrollable {
  flex: 1;
  overflow-y: auto;
  padding: 16px 24px 0;
  background:
    linear-gradient(var(--color-gray00) 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)) center top;
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 3px;
  background-attachment: local, scroll;
}

.journeyDrawerTabContent {
  padding: 12px 0;
}

.journeyDrawerContentIcon {
  margin: 10px 16px 0 0;
  align-self: flex-start;
}

.journeyDrawerContentTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0;
  text-transform: capitalize;
  font-family: Gilroy, sans-serif;
}

.journeyDrawerContentSubtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
}

.journeyDrawerContentActions {
  align-self: flex-start;
  padding-top: 8px;
}

.journeyDrawerContentActions button:not(:last-child) {
  margin-right: 8px;
}

.error {
  color: var(--color-redFullAlt);
}

.errorBanner {
  width: 100%;
  background-color: var(--color-redTintLight);
  padding: 16px 24px;
  margin-top: 24px;
  border-radius: 4px;
  border-left: 4px solid var(--color-redFull);
}

.errorsList {
  align-items: flex-start;
}

.errorsList > div {
  font-weight: 600;
  font-size: var(--font-size-subhead);
}

.errorBanner > div:first-child {
  width: 48px;
  padding-right: 16px;
}

.errorBanner > div:first-child > svg {
  width: 32px;
  height: 32px;
}

.errorBanner > div:last-child > ul {
  padding-left: 17px;
  padding-top: 8px;
  color: var(--color-gray60);
  list-style-type: disc;
}

.fullWidth {
  width: 100%;
}

.tabButton {
  padding: 8px 16px;
}
