.meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.meta a {
  text-decoration: none;
}

.player {
  width: 100%;
  height: 100%;
}
