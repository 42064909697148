.FormSection {
  border: solid 1px var(--color-gray10);
  border-radius: 8px;
}

.FormSection > header {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.FormSubsection {
  border-bottom: solid 1px var(--color-gray10);
  padding-bottom: 45px;
  margin-bottom: 45px;
}

.FormSubsectionNoDivider {
  border-bottom: none;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.FormSubsection:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.FormSection .body {
  border-top: 1px solid var(--color-gray10);
  padding: 32px;
}

.Button {
  composes: Subheading from '../Typography/typography.module.css';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--color-brandShadeDark);
  height: 40px;
  color: var(--color-gray00);
  border: 1px solid var(--color-brandShadeDark);
  padding: 0 20px;
  cursor: pointer;
  white-space: nowrap;
}

.LabelHidden {
  visibility: hidden;
}

.ButtonSpinner {
  position: absolute;
  display: flex;
}

.Button.ButtonRounded {
  border-radius: 20px;
}

.Button:hover,
.Button:active {
  opacity: 0.8;
}

.Button:focus,
.Button:focus-within,
.ButtonFocused {
  outline: none;
  box-shadow: 0 0 0 4px var(--color-brandOutline);
}

.ButtonCompact {
  composes: Body from '../Typography/typography.module.css';
  padding: 0 16px;
  height: 32px;
}

.ButtonMinimal {
  padding: 0;
  height: auto;
  width: auto;
}

.ButtonCompact.ButtonRounded {
  border-radius: 16px;
}

.ButtonRoundedPadded {
  composes: Body from '../Typography/typography.module.css';
  background: var(--color-gray05);
  color: var(--color-gray90);
  cursor: pointer;
  font-weight: bold;
  border: 2px solid black;
  height: 32px;
  border-radius: 16px;
}

.ButtonLayoutOnly {
  border: none;
  height: 42px;
  background-color: unset;
  color: unset;
  cursor: unset;
  opacity: unset;
}

.ButtonLayoutOnly.ButtonRounded {
  border-radius: 21px;
}

.ButtonLayoutOnly:hover {
  border: none;
  background-color: unset;
  color: unset;
  cursor: unset;
  opacity: unset;
}

.ButtonLayoutOnly.ButtonCompact {
  height: 34px;
}

.ButtonLayoutOnly.ButtonCompact.ButtonRounded {
  border-radius: 17px;
}

.ButtonBorderLess {
  border: none;
}

.ButtonWarning {
  background-color: var(--color-redFull);
  border-color: var(--color-redFull);
}

.ButtonSecondary {
  background-color: var(--color-gray00);
  color: var(--color-gray90);
  border-color: var(--color-gray30);
}

.ButtonText {
  background-color: var(--color-gray00);
  color: var(--color-gray90);
  border-color: var(--color-gray00);
}

.ButtonInput {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray30);
  padding: 7px 16px;
  font-size: 14px;
  color: var(--color-gray90);
  border-radius: 4px;
}

.ButtonClearText {
  composes: ButtonText;
  background-color: transparent;
  border-color: transparent;
}

.ButtonRound {
  border-radius: 50%;
}

.ButtonCircle {
  composes: ButtonRound;
  width: 40px;
}

.ButtonCircle.ButtonCompact {
  width: 32px;
}

.ButtonLeft {
  text-align: left;
}

.ButtonText:hover {
  border-color: var(--color-gray05);
}

.ButtonSecondary:hover,
.ButtonText:hover {
  opacity: 1;
  background-color: var(--color-gray05);
}

.ButtonSecondary:hover {
  border-color: var(--color-gray40);
}

.ButtonSecondary.ButtonDisabled:hover,
.ButtonText.ButtonDisabled:hover {
  background-color: var(--color-gray00);
  color: var(--color-gray90);
}

.ButtonText.ButtonDisabled:hover {
  border-color: var(--color-gray00);
}

.ButtonLink {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.ButtonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ButtonInput .ButtonContent {
  justify-content: space-between;
  text-align: left;
}

.ButtonAlignLeft .ButtonContent {
  justify-content: left;
}

.ButtonAlignRight .ButtonContent {
  justify-content: right;
}

.ButtonBadge {
  composes: Caption from '../Typography/typography.module.css';
  color: inherit;
  background-color: rgba(12, 15, 17, 0.15);
  padding: 0 10px;
  border-radius: 10px;
  height: 32px;
  line-height: 32px;
  margin-top: 2px;
  margin-left: 12px;
}

.ButtonInput .ButtonBadge {
  background-color: unset;
  padding: unset;
  border-radius: unset;
  height: unset;
  line-height: unset;
  margin-top: unset;
  margin-left: unset;
}

.ButtonBadge.ButtonRounded {
  border-radius: 16px;
}

.ButtonLabel {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  text-decoration: inherit;
  cursor: inherit;
  height: inherit;
  overflow: hidden;
}

.ButtonIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ButtonIcon:not(:last-child),
.ButtonActions:not(:last-child) {
  margin-right: 12px;
}

.ButtonBlock {
  display: block;
  width: 100%;
}

.ButtonDisabled {
  opacity: 0.3;
  cursor: default;
}

.ButtonDisabled:hover {
  opacity: 0.3;
}

.ComboButton {
  background-color: var(--color-gray05);
  border-radius: 4px;
  composes: kai-flex-row from global;
  padding: 4px;
}

.ComboButton > * {
  flex: 1;
  border-radius: 4px;
  cursor: pointer;
}

.ComboButton span {
  font-size: var(--font-size-caption);
}

.ComboButton > *[data-active='true'] {
  background-color: var(--color-gray00);
  cursor: default;
}

.ComboButton .Button {
  width: 100%;
}

.Fieldset {
  border: none;
  width: 100%;
  margin: 0;
  padding: 0 6px 16px;

  /* 6px for outline, could be done another way */
}

.LetterCount {
  font-size: 12px;
  text-align: right;
  margin-top: 5px;
}

.MultiValuesInput {
  display: flex;
}

.MultiValuesInput input {
  flex-grow: 1;
  margin-right: 8px;
}

.MultiValuesError {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: var(--color-redFull);
}

.MultiValues {
  display: flex;
  flex-wrap: wrap;
}

.MultiValuesBill {
  margin-right: 8px;
  border-radius: 20px;
}

.MultiValuesBill span[class*='pill_CloseButton'] {
  font-size: 14px;
}

.MultiValuesBill span[class*='Caption'] {
  font-size: 14px;
}
