.wrapper {
  height: 60px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  border: none;
  border-radius: 4px;
  background-color: var(--color-gray05);
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  height: 42px;
  outline: none;
}

.button:hover {
  cursor: pointer;
  background-color: #cfd2d4;
}

.button:active {
  background-color: #d0e2ee; /* TODO: fix me, use a variable or opacity or something not-hardcoded */
}
