.hiddenCheckbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  display: none;
}

.styledCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  background-color: var(--color-gray00);
  border: solid 1px var(--color-gray30);
  border-radius: 2px;
  transition: background-color 150ms;
}

.styledCheckbox:hover {
  outline: 3px solid var(--color-brandOutline);
}

.styledCheckbox > svg {
  stroke: var(--color-gray00);
  fill: var(--color-gray00);
  color: var(--color-gray00);
  display: block;
}

.styledCheckbox > i {
  color: var(--color-gray00);
  font-size: 8px;
}

.styledCheckbox.radio {
  border-radius: 50%;
}

.styledCheckbox.bulk.checked {
  background-color: var(--color-brandShadeDark);
  color: var(--color-gray00);
}

.styledCheckbox.radio * {
  width: 8px;
  height: 8px;
  stroke: none;
}

.checked {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: var(--color-brandShadeDark) !important;
  border-color: var(--color-brandShadeDark);
}

.unchecked {
  /* placeholder until we get the mockup */
  fill: none;
}

.styledCheckbox.checked > .svgICon {
  border-radius: 8px;
  visibility: visible;
}

.styledCheckbox.disabled {
  border-color: rgba(var(--color-brandShadeDarkRGB), 0.1);
}

.styledCheckbox.disabled:hover {
  outline: none;
}

.styledCheckbox.checked.disabled {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: rgba(var(--color-brandShadeDarkRGB), 0.4) !important;
}

.checkboxContainer {
  width: 24px;
  height: 24px;
}

.label {
  display: inline-flex;
  align-items: center;
}

.label > .checkboxContainer {
  margin-right: 6px;
}

.checkboxLabel {
  margin: auto 0;
  padding-left: 20px;
}
