/* fix reset.css blowing out padding on lists */
.editor ol,
.editor ul,
.editor li {
  padding-left: revert;
  margin-left: revert;
}

.editor img {
  max-width: 100%;
}

.editor :global(.ProseMirror) {
  /* arbitrary height - footer height - toolbar height - top padding */
  height: calc(95vh - 75px - 38px - 60px);
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  padding: 1rem;
}

.tiptapButton {
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  padding: 2px;
}

.tiptapButton:hover {
  background-color: var(--color-gray05);
  cursor: pointer;
}

.tiptapButton.isActive {
  background-color: var(--color-gray10);
}

.tiptapButton.fontColorSelect {
  display: block;
  position: relative;
}

.tiptapDropdownContainer {
  min-width: 140px;
}

/* Font Color Select */
.fontColorSelectBackgroundIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -6px;
  right: -6px;
  font-size: 30px;
  z-index: 0;
}

.fontColorSelectForegroundIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -6px;
  right: -6px;
  z-index: 1;
  font-size: 30px;
  clip-path: inset(0 0 4px);
}

.fontHighlightAction {
  position: relative;
  height: 24px;
  width: 24px;
}

.fontHighlightAction.isActive {
  background: var(--color-gray10);
}

.fontHighlightToggleButton {
  clip-path: inset(0 0 0);
  z-index: 0;
}

.fontHighlightSelectColorButton {
  clip-path: inset(19px 0 -1px);
  z-index: 1;
  position: absolute;
  bottom: 0;
}

.linkModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 16px;
}

.linkModalField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 8px;
}

.linkModalInput {
  width: 275px;
}

.linkModalHeading {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray90);
}

.tiptapToolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
}

.toolbarDivider {
  border-left: 1px solid var(--color-gray20);
}

.toolbarDivider::before {
  content: ' ';
  display: block;
  height: 24px;
}
