.headerCell,
.tableCell {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 70px;
}

.headerCell {
  font-weight: 500;
  cursor: pointer;
  font-size: 13px;
}

.dataTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
}

.dataTable thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.dataTable th {
  text-align: left;
  border-bottom: 1px solid #c0c0c9;
  line-height: 2;
  padding: 0;
  vertical-align: top;
  height: 35px;
}

.dataTable td {
  text-align: left;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.5;
  white-space: pre-line;
  border-bottom: 1px solid #c0c0c963;
}

.dataTable td:first-child {
  font-weight: 600;
}

.dataTable tbody tr:last-child td {
  border: none;
}

.hidden {
  opacity: 0;
}
