.source {
  composes: flex-column from global;
}

.label {
  composes: label from '../content-summary.module.css';
  padding-top: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.measures {
  composes: flex-column from global;
  margin-top: auto;
  padding-top: 20px;
}

.measure {
  display: flex;
  justify-content: space-between;
}

.measure > :first-child {
  color: var(--color-gray70);
}

.measure > :last-child {
  font-weight: bold;
}

.unreachable {
  composes: flex-column from global;
  color: var(--color-gray70);
  padding-left: 10px;
}

.unreachable > :first-child {
  text-transform: uppercase;
}

.unreachable > :last-child {
  font-size: calc(1em + 20pt);
  font-weight: bold;
}

.unreachableContainer {
  flex-basis: 10%;
  height: 100%;
  padding-bottom: 60px;
}

.circle {
  height: 60px;
  width: 60px;
  padding-left: 10px;
}

.chart {
  stroke: #0d9e57;
  stroke-width: 6;
  fill: transparent;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.chartBackground {
  fill: none;
  stroke: var(--color-gray30);
  stroke-width: 6;
}

.chartText {
  text-anchor: middle;
  font-size: 1.5em;
  font-weight: bold;
}

.progress {
  height: 8px;
  background: var(--color-gray10);
}

.progressFill {
  height: 100%;
  background: #0d9e57;
}
