.communication {
  background-color: var(--color-brandTintDark);
}

.emptyCommunication {
  color: var(--color-brandTintDark);
  background-color: initial;
  border: 1px solid;
}

.start {
  background-color: var(--color-greenFull);
}

.condition {
  background-color: var(--color-yellowFull);
}

.delay {
  background-color: var(--color-orangeFull);
  color: var(--color-gray00);
}

.end {
  background-color: var(--color-greenFull);
  color: var(--color-gray00);
}

.icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.icon svg {
  width: 16px;
  height: 16px;
}
