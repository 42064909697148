.main {
  background-color: var(--color-gray05);
  padding: 90px 20px 35px 20px;
  font-size: 14px;
}

.totalCount {
  color: var(--color-gray90);
  font-weight: normal;
  font-size: 1.5rem;
}

.facetName {
  color: var(--color-gray70);
  text-transform: uppercase;
  margin: 30px 0 10px;
  font-size: 12px;
  font-weight: 600;
}

.value {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--color-gray90);
}

.value:not(:last-child) {
  margin-bottom: 8px;
}
