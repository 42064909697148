.SelectedAttributes {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > li {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    margin: 4px 8px 0 0;
  }
}

.Item {
  height: 32px;
  padding: 4px 8px 4px 16px;
  border-radius: 40px;
  border: 1px solid var(--color-gray30);
  background: var(--color-gray00);
  font-size: 12px;
  white-space: nowrap;
  line-height: 24px;
}

.ItemContent {
  display: inline-block;
  padding-right: 8px;
  cursor: grab;
}

.Delete {
  display: inline-block;
  padding: 0;
  background: none;
  border: none;
  vertical-align: middle;

  &:hover svg path {
    fill: var(--color-gray80);
  }
}

.Section {
  position: relative;
}

.Content {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.ContentHidden {
  composes: Content;
  opacity: 0;
}

.Spinner {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SpinnerVisible {
  composes: Spinner;
  display: flex;
}
