.main {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.info_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
}

.firstup_logo {
  margin-bottom: 10px;
}

.message {
  padding: 0 60px;
  line-height: 24px;
  text-align: center;
}
