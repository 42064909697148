.dropdownFilterItem {
  padding-left: 10px;
}

.EmailAlias span {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 40px);
  display: inline-block;
}
