.titleContainer {
  display: flex;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    padding-left: 2px;
  }

  .sparklesContainer,
  .infoContainer {
    display: flex;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;

    svg {
      flex: 1;
    }
  }
}

.spinner {
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 56px;
  margin-top: 35px;

  >* {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.messageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;

  .warningSign {
    margin-right: 5px;
  }

  .message {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .error {
    color: #dd1d1d;
  }

  .info {
    color: #676570;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}

.suggestedTopics {
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 87px;
}
