.actionsWrapper {
  padding: 5px;
  display: flex;
  flex-flow: row;
  align-items: center;
  min-height: 54px;
  padding-left: 20px;
}

.actionsLeft {
  display: flex;
  flex: 20%;
  align-items: center;
}

.actionsRight {
  display: flex;
  font-size: 13px;
  line-height: 16px;
}

.actionsRight button {
  margin-left: 10px;
}

.actionButton {
  background-color: var(--color-brandTintDark);
  border: none;
  color: var(--color-gray00);
  font-style: normal;
  padding: 10px;
  border-radius: 4px;
  font-weight: 600;
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.actionButton i,
.actionsLeft i {
  border-radius: 3.5px;
  padding: 5px;
  cursor: pointer;
}

.actionButton i:hover,
.actionsLeft i:hover {
  background-color: var(--color-gray10);
}

.actionsLeft i.dropDownToggleButton {
  margin-right: 20px;
}

.actionsLeft span {
  line-height: 26px;
}

.bulkCheck {
  color: var(--color-brandTintDark);
}

.dropdown {
  composes: has-box-shadow from global;
  background-color: var(--color-gray00);
  border-radius: 5px;
  padding: 10px;
  min-width: 150px;
}

.dropdownItem {
  padding: 10px;
}

.selectedCount {
  font-size: var(--font-size-body);
  display: inline-block;
  margin-left: 10px;
}

.dropdownItem:hover,
.dropdownItem[class*='active'] {
  cursor: pointer;
  background-color: var(--color-gray05);
}

.selectionInfo {
  background: var(--color-gray05);
  border-radius: 6px;
  padding: 10px 25px;
  display: inline-block;
  font-size: 14px;
}

.more-list {
  composes: floating from global;
  padding: 6px;
}

.more-button {
  background-color: var(--color-gray05);
  border: none;
  border-radius: 6px;
  color: var(--color-gray90);
  font-weight: bold;
  line-height: 32px;
  margin-left: 10px;
  padding: 0 15px;
}

.more-button:hover {
  background: var(--color-gray10);
  color: var(--color-gray80);
}

.menu-item {
  white-space: nowrap;
  margin: 0;
  padding: 10px;
  background-color: var(--color-gray00);
  font-size: 15px;
  line-height: 1;
}

.menu-item:hover {
  background-color: var(--color-gray02);
  cursor: pointer;
}
