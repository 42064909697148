.gauges {
  margin-top: 10px;
  composes: kai-flex-row from global;
  justify-content: space-evenly;
}

div:global(.deliver-redesign) .gauges {
  border-bottom: 1px solid var(--color-gray10);
  padding-bottom: 4px;
  margin-bottom: 23px;
}

.suggestion {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  text-align: center;
  padding-top: 20px;
}
