.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  composes: kai-flex-row from global;
  align-items: flex-start;
  width: 100%;
  padding-left: 64px;
}

.listWrapper {
  flex: 1;
}

.filtersWrapper {
  composes: kai-flex-row from global;
  flex-wrap: wrap;
  font-size: 14px;
}

.filtersWrapper > div {
  margin-bottom: 5px;
}

.filtersWrapper > div:first-child {
  margin-right: 12px;
}
