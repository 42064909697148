.HoverLabel {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 16px;
  background-color: var(--color-brandShadeLight);
  color: #fff;
  composes: Body from 'DesignSystem/Typography/typography.module.css';
  composes: Semibold from 'DesignSystem/Typography/typography.module.css';
}

.EditIcon {
  display: none;
  position: absolute;
  top: 48px;
  right: -52px;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
}

.HighlightableContainer {
  position: relative;
  border: 2px solid transparent;
  overflow: visible;

  &:hover {
    border-color: var(--color-brandShadeLight);

    & .HoverLabel {
      display: block;
    }

    & .EditIcon {
      display: block;
    }
  }
}

.HighlightableContainer.Active {
  border-color: var(--color-brandShadeLight);
}
