.SectionHeading {
  color: var(--color-gray90);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  margin-bottom: 16px;
}

.Placeholder {
  color: var(--color-gray70);
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.Item {
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.EnabledItem {
  composes: Item;
  cursor: pointer;
}

.ActiveItem {
  composes: EnabledItem;
  background-color: var(--color-gray05);
}

.ItemList {
  margin-bottom: 24px;

  :global(.ui-sortable-helper) {
    border-radius: 8px;
    background: var(--color-gray00);
    border: 1px dashed var(--color-brandFull, #4a33a0);

    .ActiveItem {
      background-color: transparent;
    }
  }
}

.DragIcon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  cursor: grab;
}

.ItemTitle {
  flex: 1 1;
  padding: 0 16px;
}

.ItemToggle {
  flex: 26px 0 0;
  padding-left: 16px;
}

.HomeIcon {
  flex: 24px 0 0;
}
