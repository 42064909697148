.SortableItem {
  display: grid;
  grid-template-columns: min-content 38px 1fr repeat(2, min-content);
  align-items: center;
  column-gap: 4px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--color-gray10);
  background-color: var(--color-gray10);
  cursor: grab;
  margin: 8px 0;

  &:active {
    cursor: grabbing;
  }
}

.Icon {
  border-radius: 4px;
  aspect-ratio: 1;
  width: 100%;
}

.Texts {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  word-break: break-all;
}

.ClampedText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.Title {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  composes: Semibold from 'DesignSystem/Typography/typography.module.css';
  composes: ClampedText;
}

.Description {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  composes: ClampedText;
}

.ActionButton {
  & i {
    margin: 0;
  }
}

.ItemAddButton {
  margin-top: 8px;
}
