.block {
  display: flex;
}

.block div {
  flex-basis: 50%;
}

.totalUsers span {
  margin-left: 4px;
}

.empty {
  font-size: 14px;
}
