.list {
  width: 560px;
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray05);
  border-radius: 4px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 4px 24px 4px 24px;
}

.list > div {
  align-items: flex-start;
}

.iconContainer {
  padding: 2px 8px 0 0;
}

.iconContainer > svg {
  width: 16px;
  height: 16px;
  color: var(--color-redFullAlt);
}

.listItem {
  height: 45px;
  width: 100%;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  border-radius: 0;
}

.listItem:focus {
  box-shadow: none;
}

.listItem:not(:last-child) {
  border-bottom: 1px var(--color-gray10) solid;
}

.listItem > span:first-child {
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
}

.errorText {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.errorFlex {
  width: 100%;
}
