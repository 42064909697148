.container {
  display: flex;
}

.left {
  composes: flex-column from global;
  flex-basis: 60%;
  margin-right: 4px;
}

.left > :last-child {
  flex-grow: 1;
}

.right {
  flex-basis: 40%;
}

.thumbnail {
  display: flex;
}

.thumbnail > :first-child {
  flex-shrink: 0;
  margin-right: 20px;
}

.summary {
  composes: flex-column from global;
}

.header {
  font-weight: bold;
}

.text {
  padding-top: 10px;
}

.setting {
  composes: flex-column from global;
  padding-bottom: 20px;
}

.setting:last-child {
  padding-bottom: 60px;
}

.setting > :first-child {
  font-size: calc(1em - 2pt);
  color: var(--color-gray70);
  padding-bottom: 4px;
}

.setting > :last-child {
  font-weight: bold;
}

.rule {
  composes: kai-flex-row from global;
}

.rule > :first-child {
  align-self: flex-start;
  color: green;
  flex-basis: 20%;
  font-weight: bold;
}

.rule > :last-child {
  flex-basis: 80%;
}
