.subSection:not(:last-child) {
  margin-bottom: 32px;
}

.subsectionTitle {
  margin-bottom: 8px;
}

.subsectionTitle > span {
  margin: 0;
}

.subsectionDescription {
  margin-bottom: 16px;
}

.subsectionDescription > span {
  margin: 0;
}

.dropdownItem {
  padding: 8px 16px;
  margin-bottom: 8px;
}

.searchInput {
  height: 32px;
}

.textInput {
  font-size: var(--font-size-body);
}
