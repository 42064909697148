@media screen and (min-width: 1281px) {
  .fieldWrapper {
    padding: 16px;
  }
}

@media screen and (max-width: 1280px) {
  .fieldWrapper {
    padding: 8px;
  }
}
