.masonry {
  display: flex;
}

.masonry:global.-of-1 > * {
  width: 100%;
}

.masonry:global.-of-2 > * {
  width: 50%;
}

.masonry:global.-of-3 > * {
  width: 33.333%;
}

.masonry:global.-of-4 > * {
  width: 25%;
}

.masonry:global.-of-5 > * {
  width: 20%;
}
