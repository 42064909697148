.web {
  background-color: var(--color-gray00);
  min-height: 400px;
  width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address {
  border: solid 8px #e6edf1;
  color: #aaa;
  width: 100%;
  margin-bottom: 33px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 598px;
}

.latest {
  display: flex;
  width: 598px;
  margin-bottom: 33px;
}

.others {
  display: flex;
}

.image {
  display: flex;
  background-size: cover;
  background-position: center;
  width: 246px;
  height: 164px;
  flex-shrink: 0;
}

.web .dotsContainer {
  display: flex;
  justify-content: center;
  position: relative;
  top: 15px;
  margin: auto auto 0;
}

.web .dots {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  background: #fff;
  border-radius: 18px;
}

.web .dots .ellipse {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #c4c4c4;
  margin: 0 5px;
}

.web .dots .ellipse.black {
  background-color: #000;
}

.itemImage {
  background-color: var(--color-gray30);
  width: 180px;
  height: 90px;
}

.itemTitle {
  font-weight: 600;
  font-style: normal;
  font-size: 13px;
  line-height: 127%;
  color: var(--color-gray90);
}

.item {
  opacity: 0.5;
}

.item:not(:last-child) {
  margin-right: 29px;
}

.text {
  margin-left: 11px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 127%;
  color: var(--color-gray90);
  margin-bottom: 7px;
  font-style: normal;
  text-align: initial;
  composes: kai-line-clamp-2 from global;
}

.summary {
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  font-style: normal;
  text-align: initial;
}
