.Canvas {
  width: 100%;
  height: 100%;
  padding-left: 308px;
}

.journeyCanvas {
  width: 100%;
  height: 100vh;
  padding-top: 48px;
  transition: height 350ms ease-in-out;
  user-select: none;
}

.journeyCanvas.open {
  height: 30vh;
}

.Loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
