.container {
  display: flex;
  margin-bottom: 24px;
  border: 1px solid transparent;
  position: relative;
  background-color: var(--color-gray05);
}

.container:hover {
  cursor: pointer;
  background-color: var(--color-gray00);
  box-shadow: 0 15px 22px rgba(0, 0, 0, 0.06), 0 3px 8px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.08), 0 3px 1px rgba(0, 0, 0, 0.04);
}

.draggingContainer {
  background-color: var(--color-gray00);
}

.icon {
  margin: 32px 0;
  height: 100px;
  width: 100px;
  background-size: cover;
  background-position: center center;
  margin-right: 17px;
  flex-shrink: 0;
}

.body {
  composes: flex-column from global;
  padding: 41px 0;
}

.body > div:not(:last-child) {
  margin-bottom: 8px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.description {
  font-size: 14px;
  color: var(--color-gray90);
}

.link {
  font-size: 14px;
  color: var(--color-gray70);
}

.grabber {
  display: flex;
  height: 100px;
  align-items: center;
  padding: 32px 14px;
  width: 6px;
  box-sizing: content-box;
  flex-shrink: 0;
}

.grabber > svg {
  display: none;
}

.dragging > svg {
  display: block;
}

.container:hover > .grabber > svg {
  display: block;
}

.trash {
  position: absolute;
  top: 20px;
  right: 17px;
}

.trash button {
  outline: none;
  background: transparent;
  cursor: pointer;
  border: none;
}
