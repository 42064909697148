.tooltipBlock {
  composes: tooltip from global;
  margin-top: -10px;
  padding: 16px;
  width: 300px;
}

.tooltipTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
}

.tooltipDescription {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.6px;
  margin-top: 6px;
}
