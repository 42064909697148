.Container {
  max-width: 1224px;
  width: 100%;
  padding: 0 24px;
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  /* Web experience does not have a 'secondary' font family for headings. Setting them to primary since that matches the web experience font family. See text.css */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-family-primary);
  }
}

.HeaderSection {
  margin-top: 42px;
  text-align: unset;
  background-color: var(--color-gray00);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.CoverImage__Container {
  height: 186px;
  width: 100%;
}

.CoverImage {
  /* Header has 2px white border so need to make radius smaller by 2px */
  --image-border-radius: calc(0.5rem - 2px);

  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: var(--image-border-radius) var(--image-border-radius) 0 0;
}

.CoverImageDefault {
  composes: CoverImage;
  background-color: var(--primary-default);
}

.CoverImageDefaultLabel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.TopicImage__Container {
  position: absolute;
  top: 57px;
  left: 14px;
  width: 140px;
  height: 140px;
  border-color: #fff;
  z-index: 1;
}

.TopicImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.TopicImageDefault {
  composes: TopicImage;
  background-color: var(--color-gray30);
}

.HeaderContent__Container {
  position: relative;
  margin: 23px 14px 0 14px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.HeaderContent {
  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: 4px;
  margin: 22px 14px 0 14px;
}

.HeaderContent__Texts {
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.HeaderActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 4px;
}

.HeaderTabs {
  position: relative;
  margin: 14px 16px 0;
  z-index: 1;
  width: fit-content;
}

.MainSection {
  margin-top: 22px;
  display: grid;
  grid-template-columns: 1fr 240px;
  gap: 16px;
}

.HighlightableArea__NoCover {
  padding-top: 24px;
  display: flex;
  flex-direction: row;

  & .HeaderContent {
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 0;

    & .HeaderActions {
      align-items: start;
    }
  }

  & .TopicImage__Container {
    transform: unset;
    position: unset;
    top: unset;
    margin-left: 14px;
  }
}
