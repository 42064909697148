.preview {
  background-color: var(--color-gray02);
  border: none;
  box-shadow: inset 0 -25px 15px -25px var(--color-gray10);
  composes: flex-column from global;
  cursor: pointer;
  height: auto; /* set by javascript */
  overflow: hidden;
  position: relative;
  text-align: left;
  transition: all 0.1s linear;
  width: 100%;
}

.preview:hover {
  background-color: var(--color-gray00);
  box-shadow: 0 0 42px rgba(0, 0, 0, 0.09), 0 0 5.25905px rgba(0, 0, 0, 0.045);
}

.title {
  padding: 0 25px;
  line-height: 70px;
  height: 70px;
  font-size: 16px;
  width: 100%;
  display: block;
}

.thumbnail {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-top: 40px;
}

.title + .thumbnail {
  margin-top: 0;
  max-height: calc(100% - 70px);
}

.raw {
  display: flex;
  justify-content: center;
}

.iframe {
  overflow: hidden;
}

.iframe iframe {
  pointer-events: none;
  visibility: hidden; /* is made visible later when ready */
  border: none;
}

/* loading background with shadow effect */

.effects {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}

.shadow {
  height: 100%;
  width: 100%;
  margin: auto;
}

.shadow::before {
  height: 100%;
  margin: auto;
  transform: rotate(7deg) translate(-50%, 10%);
  transform-origin: bottom left;
  box-shadow: 0 0 35px rgba(var(--color-gray90-rgb), 0.3);
  width: 50%;
  display: block;
  content: '';
}

.shadow::after {
  height: 100%;
  margin: auto;
  transform: rotate(-7deg) translate(50%, -100%);
  transform-origin: top right;
  box-shadow: 0 0 35px rgba(var(--color-gray90-rgb), 0.3);
  width: 50%;
  display: block;
  content: '';
}

.loading {
  position: relative;
  top: -100%;
  height: 100%;
  margin: 0 auto;
  background-color: var(--color-gray00);
  color: var(--color-gray50);
  text-align: center;
  padding-top: 30px;
  font-size: 13px;
  letter-spacing: 1px;
}

.loading svg {
  opacity: 0.2;
}
