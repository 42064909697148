.topControls {
  padding-top: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-gray10);
  width: var(--program-modal-editor-width);
}

.title {
  color: var(--color-gray90);
  font-size: 18px;
  font-weight: 700;
}

.buttons {
  display: flex;
}

.controlButton {
  composes: kai-flex-row from global;
  height: 32px;
  padding: 6px 9px;
  background-color: var(--color-gray05);
  margin-left: 10px;
}

.preview > div:first-child {
  padding-top: 3px;
}
