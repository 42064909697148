.form div[class*='fieldset_labelContainer'] {
  color: var(--color-gray70);
  display: flex;
  align-items: center;
  padding-top: 0;
}

.form div[class*='fieldset_input'] {
  padding: 24px 32px;
  color: var(--color-gray90);
}

.textField {
  width: 100%;
}

.marginRight {
  margin-right: 16px;
}

.addressFields input {
  margin-bottom: 24px;
}

.stateZipInputs {
  display: flex;
  justify-content: space-between;
}

.stateZipInputs input {
  margin-bottom: 0;
}

.stateZipInputs input[placeholder] {
  text-overflow: ellipsis;
}

.stateZipInputs > input:first-of-type {
  width: 65%;
}

.stateZipInputs > input:last-of-type {
  width: 34%;
}

.topicsSelector {
  margin-top: 10px;
}
