.topicForm div[class*='fieldset_labelContainer'] {
  color: var(--color-gray70);
  display: flex;
  align-items: center;
  padding-top: 0;
}

.topicForm div[class*='fieldset_input'] {
  padding: 24px 32px;
  color: var(--color-gray90);
}

.imageField {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.imageField button {
  font-size: 14px;
}

.imagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: 50% 50%;
}

.imagePreview button {
  position: absolute;
  border: none;
  background-color: var(--color-gray90);
  top: 28px;
  right: 33px;
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 16px;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagePreview > button > svg {
  height: 12px;
}

.imagePreview > button > svg path {
  stroke: var(--color-gray05);
}

.imagePreview > button > svg rect {
  stroke: var(--color-gray05);
}

.textField {
  width: 100%;
}

.marginRight {
  margin-right: 16px;
}

.autoFollowSelector {
  margin-top: 10px;
}

.autoPublishInput {
  margin-top: 24px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldsetLabel {
  color: var(--color-gray70);
  margin-bottom: 10px;
}

.sortableFieldset {
  padding: 24px 32px;
}

.sortableItem {
  background-color: var(--color-gray02);
  border: 1px solid var(--color-gray10);
  padding: 18px 20px 18px 28px;
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 26px;
  cursor: grab;
}

.sortableItem:active {
  cursor: grabbing;
}

.imageContainer {
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid var(--color-gray70);
  background-color: #e6f0f8;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.imageContainer::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background-color: lightskyblue;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.imageContainer i {
  font-size: 24px;
}

.imageSection {
  text-align: right;
  width: var(--main-editor-grid-col-width);
  margin-right: var(--main-editor-grid-gap-width);
  flex-shrink: 0;
  direction: rtl;
}

.imageWrapper {
  display: flex;
}

.bannerText {
  font-family: Arial, sans-serif;
  font-size: 20px;
  margin-left: 20px;
  color: #808080;
}
