/* Banner Item */

.basic {
  width: 274px;
  height: 80px;
  padding: 0 30px 0 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.statCol {
  display: flex;
  flex-direction: column;
  height: 80px;
}

.statHeader {
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 600;
}

.stats {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-gray90);
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  width: 320px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
}

.title:hover {
  content: attr(.title);
}

.description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  margin: 4px 0;
}

.bannerWrapper {
  padding-right: 50px;
  display: flex;
  flex: 1;
}

.statsWrapper {
  display: flex;
  width: 350px;
  justify-content: space-between;
}

.section {
  margin-bottom: 20px;
}

.roleDetailRow:not(:last-child) {
  display: flex;
  margin-bottom: 20px;
}

.roleDetailRow > span {
  display: inline-block;
}

.roleDetailTitle {
  width: 30%;
  color: var(--neutral-dark-3);
  font-weight: 600;
}

.roleDetailBody {
  width: 70%;
  word-break: break-word;
}

.headerIcon {
  cursor: pointer;
}
