.userLabel {
  padding: 8px 0;
}

.variableSelect {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-bottom: 16px;
}

.optionLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.userOptionLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-left: 10px;
}

.userCount {
  font-size: 12px;
  color: var(--color-gray70);
}

.userCountSelected {
  font-size: 12px;
  color: white;
}

.userAvatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
