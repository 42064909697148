.mainLayout {
  display: flex;
}

.container {
  align-items: flex-start;
  clear: both;
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
}

.main {
  box-sizing: border-box;
  padding-right: 50px;
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.pageActionWrapper {
  display: flex;
  flex-direction: row-reverse;
  min-height: 54px;
  padding: 6px 5px;
}

.addTopicIcon {
  margin: 4px 13px 0 0;
}

.searchBar {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 40px;
  margin-right: 6px;
  padding: 1px 11px;
  width: 376px;
}

.searchBar:focus-within,
.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.actionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basic {
  width: 274px;
  height: 80px;
  padding: 0 30px 0 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.statCol {
  display: flex;
  flex-direction: column;
  height: 80px;
}

.statHeader {
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 600;
}

.stats {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-gray90);
}

.statsStatus {
  width: 130px;
}

.statsStatusPill {
  float: left;
}

.statsSettings {
  width: 160px;
}

.statsSubscribers {
  width: 80px;
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  width: 320px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
}

.title:hover {
  content: attr(.title);
}

.description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  margin: 4px 0;
  height: 3em;
}

.bannerWrapper {
  padding-right: 50px;
  display: flex;
  flex: 1;
}

.statsWrapper {
  display: flex;
  gap: 120px;
}

.filters {
  display: flex;
  width: 100%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterTrigger {
  composes: kai-flex-row from global;
  padding: 10px 14px;
  font-size: 12px;
  line-height: 15px;
  background: var(--color-gray05);
  color: var(--color-gray80);
  border-radius: 6px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 6px;
  max-height: 40px;
}

.filterTrigger:hover {
  background: var(--color-gray10);
  color: var(--color-gray80);
}

.closeButton {
  composes: kai-flex-row from global;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 10px;
  height: 18px;
  width: 18px;
  padding: 1px;
}

.closeButton:hover {
  background: var(--color-gray30);
}

.btnMore {
  padding: 13px 14px;
  font-size: 10px;
  background: var(--color-gray05);
  color: var(--color-gray80);
  border-radius: 6px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 6px;
  border: none;
  height: 40px;
}

.moreButtonMenu {
  composes: dropdown from global;
  border-radius: 8px;
  padding: 5px;
}

.filtersMenuItem {
  border-radius: 6px;
  font-size: 14px;
  padding: 5px 15px 5px 10px;
}

.filtersMenuItem:hover {
  background-color: var(--color-gray02);
  cursor: pointer;
}

.moreDropdown {
  display: inline-block;
}

.dropdownWrapper {
  display: inline-block;
  vertical-align: middle;
}

.draftPill {
  border: 0;
  background-color: #bfb3eb;
}

.activePill {
  border: 0;
  background-color: #b7f29a;
}

.activeWithDraftPill {
  border: 0;
  background-color: #a3d3ff;
}

.archivedPill {
  border: 0;
  background-color: #f3a3a3;
}

.archivedDraftPill {
  border: 0;
  background-color: #ffd591;
}

.archivedWithDraftPill {
  border: 0;
  background-color: #fdf1a1;
}

.topicListActions {
  align-items: center;
  display: flex;
  gap: 8px;
}

.topicLimitPillContainer {
  display: inline-block;
}

.topicLimitCriteriaDisplayContainer,
.formLimitValueRow {
  margin-top: 32px;
}

.formToggleRow {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.formLimitValueInputContainer {
  display: flex;
  margin-top: 8px;
}
