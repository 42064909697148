.container {
  composes: flex-column from global;
  flex: 1;
}

.row {
  flex-wrap: nowrap;
}

.blockspace {
  flex: 1;
  padding-top: 70px;
  padding-bottom: 70px;
}

.container > :last-child {
  margin-bottom: 0;
}

.labelContainer {
  composes: flex-column from global;
}

.labelContainer > :first-child {
  font-size: smaller;
  color: var(--color-gray70);
}

.labelContainer > :last-child {
  font-weight: bold;
  margin-top: 8px;
}

.thumbnail {
  display: flex;
}

.thumbnail > :first-child {
  flex-shrink: 0;
  margin-right: 20px;
}

.errors {
  opacity: 1;
}
