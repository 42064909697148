.container {
  position: relative;
  flex: 1;
  margin-right: 100px;
  width: 100%;
}

.lastFieldset {
  margin: 0;
}

.delete {
  display: flex;
  justify-content: flex-end;
  margin: 0 -2px;
  background: inherit;
}

.delete button {
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  padding-right: 36px;
  padding-bottom: 23px;
}

.delete button > svg > path,
.delete button > svg > rect {
  stroke: var(--color-redFull);
}
