.block {
  display: block;
  font-size: 14px;
}

.timeIcon {
  display: block;
  margin: auto;
}

.text {
  font-family: inherit;
  text-align: center;
  font-size: 16px;
}
