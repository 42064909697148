.notReady {
  composes: flex-column from global;
  align-items: center;
}

.mainLayout {
  display: flex;
  margin-top: -5px;

  /* PUB-1387 */
  min-height: 100%;
}

.container {
  display: flex;
  align-items: flex-start;
  flex-grow: 2;
  justify-content: space-between;
  overflow: hidden;
}

.main {
  flex-grow: 2;
  padding-right: 50px;
  margin-top: 40px;
  width: 100%;
}

.empty {
  padding-top: 38px;
  padding-left: 15px;
}

.fileUploadButtonContainer {
  composes: kai-flex-row from global;
  justify-content: space-between;
  align-self: flex-end;
  height: 35px;
  align-items: center;
  width: 100%;
}

.fileUploadButtonContainer > button {
  height: 35px;
  align-items: center;
  justify-content: center;
}

.dropZoneContainer {
  flex: 1;
}

.dropZoneContainer > button {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: var(--color-gray50);
  font-weight: normal;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.notReady > div:first-child {
  padding-bottom: 50px;
}

.loadingTextContainer {
  padding: 15px 0;
}

.loadingTextContainer > span:last-child {
  opacity: 0.7;
  margin-left: 7px;
}

.uploaderWrapper {
  composes: flex-column from global;
}

.dropZoneWrapper {
  height: 100%;
}

.bigDropZone {
  composes: flex-column from global;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  color: var(--color-gray60);
}

.bigDropZone > h2 {
  padding-bottom: 20px;
}

.topRow {
  composes: kai-flex-row from global;
  line-height: 21px;
}

.topRow > button {
  background-color: transparent;
  color: var(--color-brandTintDark);
  padding: 0 2px;
}

.dropZoneContainer > button > h2 {
  font-family: 'Inter', sans-serif;
  margin-bottom: 15px;
}
