.journeyDesignEditorModal {
  position: fixed;
  background-color: var(--color-gray00);
  z-index: var(--z-index-journey-design-editor-modal);
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);
}

.footer {
  flex-grow: 1;
}

.preview {
  padding-left: 24px;
}

.roundedContainer {
  padding: 32px;
  border-radius: 8px;
  border: 1px solid var(--color-gray10);
}

.content {
  height: calc(100vh - var(--header-height) - var(--footer-height));
  max-width: 824px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
}
