.mainWrapper {
  padding: 15px;
  background-color: white;
  font-weight: 600;
  border-radius: inherit;
}

.yearWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.yearCell {
  margin: 5px;
  width: 60px;
  height: 40px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.yearCell:hover {
  border: solid 2px var(--color-brandFull);
}

.yearCell.default {
  color: #1b1b2f;
}

.yearCell.selected {
  background-color: var(--color-brandShadeDark);
  color: white;
}
