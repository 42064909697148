.bannerWrapper {
  padding-right: 20px;
  display: flex;
  flex: 1;
}

.basic {
  width: 274px;
  height: 80px;
  padding: 0 30px 0 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  width: 320px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
}

.title:hover {
  content: attr(.title);
}

.description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  margin: 4px 0;
}

.additionalWrapper {
  display: flex;
  width: 380px;
  justify-content: space-between;
}

.additionalCol {
  display: flex;
  flex-direction: column;
  height: 80px;
}

.additionalCol:last-child {
  margin-right: 0;
  width: 145px;
}

.additionalHeader {
  color: var(--color-gray70);
  margin-bottom: 5px;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 600;
}

.additionalInfo {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-gray90);
  text-transform: capitalize;
}
