.Icon {
  margin-left: 14px;
}

.Sorted svg path {
  fill: var(--color-gray90);
}

.EditAttributeSection {
  width: 860px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.ViewTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.AttributeValue {
  padding-bottom: 5px;
}

.DefaultCell {
  padding-left: 20px;
}

.EditTable {
  width: 100%;
  font-size: 12px;
  background-color: var(--color-gray02);
}

.EditTable thead {
  border-bottom: 5px solid black;
}

.EditTableHeader {
  composes: Subheading from 'DesignSystem/Typography/typography.module.css';
  composes: Bold from 'DesignSystem/Typography/typography.module.css';
}

.TableheaderText {
  font-size: 20px;
}

.EditTable th {
  text-align: left;
  font-size: 20px;
  height: 60px;
  background-color: #efecfa;
}

.EditTable tr {
  height: 56px;
}

.EditTable th,
.EditTable td {
  padding: 12px 24px 12px 24px;
}

.CheckboxCell {
  /* Center horizontally */
  margin: 0 auto;
}

.TextEditCell {
  width: 240px;
  height: 32px;
}

.TooltipIcon {
  display: inline-block;
  position: relative;
  left: 6px;
  top: 4px;
  cursor: default;
  text-align: right;
}

.TooltipIcon > :global(.material-symbols-rounded) {
  font-size: 19px;
}

.CustomLabel {
  margin-left: 5px;
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  composes: Italic from 'DesignSystem/Typography/typography.module.css';
  composes: ColorGray50 from 'DesignSystem/Typography/typography.module.css';
}
