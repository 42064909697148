.container {
  flex: 1;
}

.title {
  font-size: 24px;
  padding: 2px 5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  word-wrap: break-word;
  color: var(--color-gray70);
  max-width: 250px;
}

.description {
  font-size: 15px;
  padding: 5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-gray70);
}

.tags {
  font-size: 13px;
  padding: 5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-gray70);
}

.tagsIcon {
  position: absolute;
  top: 7px;
  left: 0;
  color: var(--color-gray70);
  font-size: 14px;
}

.inputRow {
  position: relative;
  display: flex;
}

.inputRow input {
  border: 2px solid transparent;
  border-radius: 4px;
  width: 250px;
  text-overflow: ellipsis;
}

.inputRow input:hover {
  border-color: var(--color-gray10);
}

.inputRow input:focus {
  border-color: var(--color-brandTintDark);
  outline-color: var(--color-brandTintDark);
}

.inputRow input::placeholder {
  opacity: 1;
}

.inputRow input.title:focus::placeholder {
  color: transparent;
}

.unsaved {
  font-size: 14px;
  color: var(--color-gray80);
  line-height: 115%;
  letter-spacing: -0.02em;
  background-color: var(--color-gray10);
  font-weight: 700;
  height: 24px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 4px 8px;
}

.PermissionsNote {
  font-size: 14px;
  color: var(--neutral-dark-2);
  line-height: 115%;
  letter-spacing: -0.02em;
  background-color: var(--neutral-medium-3);
  font-weight: 700;
  margin-top: 5px;
  margin-left: 5px;
  padding: 4px 8px;
}

@media screen and (min-width: 1600px) {
  .title {
    width: 400px;
  }
}
