.controlsWrapper {
  margin-left: 40px;
  composes: kai-flex-row from global;
  align-items: center;
  column-gap: 10px;
}

.controlsWrapper > button:not(:first-child) {
  width: 53px;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #e3e1eb;
  border-radius: 6px;
}

.pageWrapper {
  max-width: 1224px;
  margin: 32px auto;
  padding: 0 32px;
  height: 100%;
}

.pageWrapper > .block:not(:first-child) {
  margin-top: 32px;
}

.metrics {
  width: 100%;
}

.metrics > header {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-gray10);
}

/* stylelint-disable no-descending-specificity */

/* rule disabled due to conflict with 'controlsWrapper > button:not(:first-child)' */

/* to keep it DRY */
.metrics > header button,
.metrics > header a,
.metrics a[class='metrics-button'],
.metrics a[class='metrics-button'] button {
  height: 32px;
  font-size: 14px;
  text-decoration: none;
}
/* stylelint-enable no-descending-specificity */

.metrics > header > span:last-child {
  margin-top: 10px;
}

.metrics div[class='embedded-media-item'] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-gray10);
  border-radius: 8px;
}

.metrics div[class='embedded-media-item'] > div > i {
  color: var(--color-gray90);
  margin-right: 10px;
}

.metrics section {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.metrics hr {
  width: calc(100% - 40px);
  margin: 0;
}

.metrics .subsection {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.metricsV2SubtitleBold {
  font-weight: 600;
  font-size: 14px;
  color: var(--color-gray60);
}

.metricsV2Subtitle {
  color: var(--color-gray60);
  font-size: 14px;
}

.metricsV2Caption {
  color: var(--color-gray60);
  font-size: 12px;
}

.button {
  color: var(--color-gray80);
  text-align: center;
  background-color: transparent;
  letter-spacing: 2px;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  cursor: default;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 8px;
  transform: rotate(0deg);
}
