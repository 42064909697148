.itemWrapper {
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 22px;
  align-items: center;
  line-height: 25px;
  border-bottom: 1px solid var(--color-gray20);
  padding-bottom: 22px;
}

.wrapper {
  margin-left: -150px;
  margin-bottom: 50px;
}

.section {
  padding-bottom: 30px;
}

.section > span,
.prefix > span {
  padding-bottom: 10px;
  display: block;
  font-size: 24px;
  border-bottom: 1px solid var(--color-gray20);
  margin-bottom: 22px;
}

.prefix {
  padding-top: 90px;
}

.listItem > div:last-child {
  font-size: 15px;
  padding-left: 4px;
}

.scope {
  width: 100px;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  margin-right: 20px;
}

.value {
  width: 50px;
  composes: kai-flex-row from global;
  justify-content: flex-end;
}

.listItemName {
  font-weight: 700;
  padding-left: 4px;
}

.listItemDescription {
  color: var(--color-gray50);
}

.programScope {
  background-color: var(--color-brandTintLight);
}

.globalScope {
  background-color: var(--color-blueTintDark);
}

.listItemWrapper {
  align-items: flex-start;
}
