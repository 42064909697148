.styles {
  padding-bottom: 30px;
}

.styles fieldset {
  border: none;
  padding: 0;
  margin-bottom: 45px;
}

.smallFieldsetPadding {
  /* stylelint-disable-next-line declaration-no-important */
  margin-bottom: 6px !important;
}

.mediumFieldsetPadding {
  /* stylelint-disable-next-line declaration-no-important */
  margin-bottom: 24px !important;
}

.styles legend {
  text-transform: uppercase;
  color: var(--color-gray70);
  font-weight: 600;
  font-size: 12px;
  padding: 0;
  height: auto;
  margin-bottom: 15px;
}

.slider span {
  display: inline-block;
  width: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.customToggle {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 15px;
  padding: 10px 0;
  font-size: 12px;
  background-color: var(--color-gray05);
}

.revertToGlobal {
  height: 64px;
  border-top: 1px solid var(--color-gray10);
  composes: flex-column from global;
  justify-content: space-around;
  padding: 16px;
  background-color: var(--color-gray05);
}

.revertToGlobalButton {
  border: 1px solid var(--color-gray10);
}

.modalButtonRow {
  width: 100%;
  margin-top: 10px;
}

.revertToGlobalButton:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray10);
}

.toggleLabelLayout {
  display: flex;
}

.customToggle > div > svg {
  flex-shrink: 0;
  height: 36px;
  width: 36px;
}

.customToggle > div {
  flex: 1;
  padding-left: 16px;
}

.customToggle > div > label {
  display: flex;
  cursor: pointer;
}

.customToggle > div > label > span {
  flex: 1;
}

.customToggle p {
  font-size: 12px;
  color: var(--color-gray90);
  margin: 2px 0;
}

.styles fieldset > div {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles fieldset > div > div {
  display: flex;
}

.styles fieldset > div > strong:first-child,
.styles fieldset > div > div > strong:first-child {
  font-size: 13px;
  font-weight: 400;
}

.styles fieldset > div > strong:first-child::first-letter,
.styles fieldset > div > div > strong:first-child::first-letter {
  text-transform: uppercase;
}

.title {
  display: block;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-gray90);
  line-height: 23px;
}

.sidebarPadding {
  padding: 0 16px;
}

@media screen and (min-width: 1281px) {
  .sidebarPadding {
    padding: 0 16px;
  }
}

@media screen and (max-width: 1280px) {
  .sidebarPadding {
    padding: 0 8px;
  }
}

.sidebarMargin {
  margin-top: 0;
  padding: 0;
}

.sidebarMarginOld {
  margin-top: 80px;
}

.newEditor {
  padding-bottom: 0;
}

/* padding under the new ContentDesignTabs */
.newEditor > .sidebarPadding {
  padding-top: 24px;
}

.tabs {
  composes: kai-flex-row from global;
  margin: 23px 0;
}

.tabs button {
  flex-shrink: 0;
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: left;
  margin-right: 30px;
  padding: 0 30px 10px 5px;
}

.tabs .active {
  border-bottom: solid 3px var(--color-brandTintDark);
  font-weight: 700;
}

.slider {
  composes: kai-flex-row from global;
  height: 26px;
}

.slider input {
  width: 110px;
  height: 4px;
}

.slider input[type='range'] {
  -webkit-appearance: none;
  border: 1px solid white;
}

.slider input[type='range']::-webkit-slider-runnable-track {
  background: var(--color-gray90);
  width: 110px;
  height: 4px;
  border: none;
  border-radius: 3px;
}

.slider input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  margin-top: -6px;
}

.slider input[type='range']:focus {
  outline: none;
}

.slider input[type='range']:focus::-webkit-slider-runnable-track {
  background: var(--color-gray90);
}

.slider input[type='range']::-moz-range-track {
  width: 110px;
  height: 4px;
  background: var(--color-gray90);
  border: none;
  border-radius: 3px;
}

.slider input[type='range']::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--color-gray00);
  border: 1px solid var(--color-gray90);
}

/* hide the outline behind the border */
.slider input[type='range']:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

.slider input[type='range']:focus::-moz-range-track {
  background: var(--color-gray90);
}

.dropdown {
  left: -273px;
}

.preview {
  display: block;
  padding: 0;
  border-radius: 2px;
  border: solid 1px var(--color-gray30);
  height: 20px;
  width: 20px;
}

.swatch {
  margin: 0;
  border-radius: inherit;
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.fonts {
  text-transform: uppercase;
  color: var(--color-gray90);
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 15px;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
}

.chevron {
  padding: 0;
  background: none;
  border: none;
  composes: flex-column from global;
  height: 12px;
}

.fieldsetLegend {
  composes: kai-flex-row from global;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-bottom: 0;
}

.enabled {
  color: var(--color-gray90);
}

.divider {
  height: 16px;
  overflow: hidden;
  z-index: 1000;
  margin-left: -20px;
  background-color: var(--color-gray05);
  margin-bottom: 20px;
}

.newEditor .divider {
  display: none;
}

.checkboxChecked {
  border: 1px solid var(--color-brandTintDark);
  background-color: var(--color-brandTintDark);
}

.checkmark {
  position: relative;
  font-size: 20px;
  bottom: 3px;
  left: 1px;
  color: white;
}

.arrangement {
  margin-bottom: 4px;
}
