.aiButton,
.aiButton:hover {
  cursor: pointer;
  height: 30px;
}

.aiButtonBackgroundHover:hover {
  background: var(--color-gray05);
}

.button {
  padding: 4px;
  border-radius: 4px;
}
