.mobile {
  position: relative;
  width: 500px;
  height: 1011.72px;
  margin: auto;
  background: #141414;
  border: 4px solid rgb(59, 59, 59);
  border-radius: 80px;
  overflow: hidden;
  padding: 56px 15px;
}

.content {
  height: 100%;
  width: 100%;
  background: var(--color-gray00);
  overflow-y: auto;
  position: relative;
  border-radius: 15px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.content::-webkit-scrollbar {
  display: none;
}
