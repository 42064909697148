.wrapper {
  position: relative;
  padding: 13px 96px;
  display: flex;
  height: 100vh;
  overflow: auto;
  flex-direction: row;
}

.rightBar {
  padding-top: 55px;
  font-size: 14px;
}

.rightBar .description {
  text-align: right;
}

.rightBar .links a {
  padding: 15px;
  text-decoration: none;
  color: #000;
  display: block;
}

.rightBar .links a.disabled {
  color: #59585e;
  cursor: default;
}

.stats {
  list-style: none;
  width: 50%;
  background-color: #e3e1ea;
  border-radius: 5px;
  padding: 10px;
}

.stats li {
  font-size: small;
  margin-bottom: 8px;
}

.stats li span {
  float: right;
}

.footer {
  background-color: #000;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;
  font-size: 16px;
}

.close {
  border-style: none;
  background-color: transparent;
  color: var(--color-gray00);
  cursor: pointer;
  padding: 0;
  height: 75px;
  display: block;
  margin-left: 20px;
}

.close .fa {
  margin-right: 10px;
}

.rowInfo {
  display: flex;
  margin-right: 25px;
  border-bottom: solid 1px #fff;
}

.rowInfo .label {
  width: 25%;
  padding: 10px 20px;
  border-right: 1px solid #fff;
  display: block;
}

.rowInfo .description {
  width: 75%;
  padding-top: 10px;
  padding-left: 12px;
}

.fileUploadButton {
  background-color: var(--color-brandShadeDark-main);
}

.havingTrouble {
  color: #5d5c65;
}

.downloadTemplateLink {
  color: #5d5c65;
  text-decoration: none;
  font-weight: 700;
}
