.dropdownWrapper {
  display: inline-block;
  width: fit-content;
}

.filterItemsWrapper {
  padding: 0;
}

.filterItem {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterItemLabel {
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
