.uploader {
  position: relative;
  flex-basis: 50%;
}

.closeEditors {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  transform: translate(32px, -32px);
  cursor: pointer;
}
