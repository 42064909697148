.wrapper {
  position: relative;
}

.label {
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  height: 37px;
  composes: kai-flex-row from global;
  composes: transparent from global;
  background-color: var(--color-gray10);
  margin-bottom: 2px;
}

.label:disabled {
  opacity: 0.6;
}

.label > span {
  padding-right: 4px;
  min-width: 50px;
  border-radius: 6px;
  font-size: 14px;
}

.selectorWrapper {
  composes: flex-column has-box-shadow from global;
  align-items: center;
  width: 388px;
  background-color: var(--color-gray00);
  height: 214px;
  z-index: var(--z-index-shared-duration-input-selector-wrapper);
}

.durationButton {
  composes: transparent from global;
  cursor: pointer;
}

.labels {
  composes: kai-flex-row from global;
  justify-content: space-around;
  margin-top: 20px;
  background-color: var(--color-gray05);
  border-radius: 6px;
  padding: 2px;
}

.controls > input {
  text-align: center;
  -moz-appearance: textfield;
}

.controls > input::-webkit-outer-spin-button,
.controls > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.labels > button {
  cursor: pointer;
  border-color: transparent;
  font-size: 12px;
  line-height: 26px;
  height: 30px;
  padding: 0 16px;
  border-radius: 6px;
}

.body {
  padding-top: 47px;
  flex: 1;
  composes: flex-column from global;
  align-items: center;
}

.description {
  color: var(--color-gray70);
  font-size: 12px;
  background-color: var(--color-gray05);
  height: 44px;
  width: 100%;
  composes: kai-flex-row from global;
  justify-content: space-around;
  align-items: center;
}

.selectedUnit {
  font-weight: 700;
  background-color: var(--color-gray00);
}

.selectedUnit:focus {
  outline: none;
  border: none;
}

.selected {
  outline: 2px solid var(--color-brandTintDark);
}
