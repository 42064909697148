.variable {
  display: inline;
  position: relative;
  background-color: rgba(241, 249, 255, 0.7);
  padding: 3px 0;
  border-radius: 5px;
  font-family: monospace;
  font-size: 0.95em;
  color: var(--color-blueShadeLight);
  cursor: pointer;
  user-select: none;
}

.variable::before {
  content: attr(data-content);
}

.variable + .variable {
  margin-left: 1px;
}

.hidden {
  display: none;
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-click-dropdown);
}

.popup div {
  background-color: var(--color-gray00);
  box-shadow: 0 5px 15px rgba(var(--color-gray90-rgb), 0.1);
  border-radius: 10px;
  width: 370px;
}

.popup small {
  display: block;
  padding: 20px 20px 0;
  font-size: 13px;
  font-weight: 700;
}

.frTitle {
  composes: flex-column from global;

  /* override froala */
  /* stylelint-disable-next-line declaration-no-important */
  padding: 16px 26px !important;
  /* stylelint-disable-next-line declaration-no-important */
  height: 48px !important;
  /* stylelint-disable-next-line declaration-no-important */
  width: 237px !important;
  /* stylelint-disable-next-line declaration-no-important */
  font-size: 16px !important;
}

.frTitle:hover {
  /* override froala */
  /* stylelint-disable-next-line declaration-no-important */
  background-color: var(--color-gray05) !important;
}

.frTitle > div {
  font-size: 16px;
  font-weight: 400;
  line-height: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 48px;
  width: calc(90% + 20px);
  white-space: nowrap;
  display: inline-block;
}

.frTitle > div > span {
  font-size: 16px;
  font-weight: 400;
  line-height: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 48px;
  width: 90%;
  white-space: nowrap;
  display: inline-block;
}

.popup > div > span {
  display: flex;
  flex-direction: column;
}

.popup > div > span:first-child {
  font-size: 14px;
  padding: 20px 0 5px 20px;
  line-height: 21px;
  color: var(--color-gray90);
}

.popup > div > span > span:nth-of-type(1) {
  border-bottom: 1px solid #f1f0f7;
  padding: 0 20px 30px 20px;
}

.popup > div > span > span:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.popup > div > span > button:hover {
  background-color: rgba(var(--color-greenFull-rgb), 0.2);
}

.popup > div > span > span > button:nth-of-type(1) {
  border: 1px solid var(--color-gray30);
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0 0 10px;
  color: var(--color-gray90);
  background-color: white;
  font-size: 16px;
  width: 77px;
  align-self: flex-end;
  height: 40px;
}

.popup > div > span > span > button:nth-of-type(2) {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0 0 10px;
  color: white;
  background-color: #362574;
  font-size: 16px;
  width: 77px;
  align-self: flex-end;
  height: 40px;
}

.popup > div > span > span > input {
  width: 100%;
  height: 35px;
  padding: 5px 7px;
  border: 1px solid var(--color-gray20);
  border-radius: 2px;
  color: #676570;
}

.popup p {
  padding: 0 20px 20px;
  margin: 0;
  font-size: 13px;
}

.customLabel {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  composes: Italic from 'DesignSystem/Typography/typography.module.css';
  composes: ColorGray50 from 'DesignSystem/Typography/typography.module.css';
}
