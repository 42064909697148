.filterTrigger {
  composes: kai-flex-row from global;
  padding: 10px 14px;
  font-size: 12px;
  font-weight: 600;
  background: var(--color-gray05);
  color: var(--color-gray80);
  border-radius: 6px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 6px;
  height: 40px;
}

.searchBarWrapper,
.icon {
  background-color: var(--color-gray05);
  border-radius: 6px;
  margin-right: 6px;
}

.icon {
  cursor: pointer;
  float: left;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: var(--color-gray90);
  background-color: var(--color-gray00);
}

.icon i {
  font-weight: 200;
}

.filterTrigger:hover,
.icon:hover {
  background: var(--color-gray10);
  color: var(--color-gray80);
}

.searchBarWrapper {
  border: 1px solid transparent;
  height: 40px;
  padding: 0 10px;
  width: 376px;
}

.booleanTrigger {
  cursor: default;
}

.searchBarWrapper:focus-within {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 0 1.5px 5px 2.5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBarWrapper:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.dropdown {
  border-radius: 8px;
}

.filterTrigger strong {
  margin-left: 3px;
}

.newFiltersButton {
  cursor: pointer;
  width: 50px;
}

.AllFilters {
  padding-right: 12px;
}

.filtersWrapper {
  composes: kai-flex-row from global;
  flex-wrap: wrap;
  font-size: 14px;
}

.filtersWrapper > div {
  margin-bottom: 5px;
}

.filtersWrapper > div:first-child {
  margin-right: 12px;
}

.filterItemIcons {
  display: flex;
}

.filterItemBadge {
  display: flex;
  flex-direction: row;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray10);
  border-radius: 50%;
  padding: 1px 9px;
}

.sourcesDropdownRow {
  composes: kai-flex-row from global;
  padding: 3px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  height: 32px;
  justify-content: space-between;
  text-transform: capitalize;
  font-size: inherit;
  color: var(--color-gray90);
}

.sourcesDropdownRow:hover {
  background-color: var(--color-gray02);
}

.expandedDropdown {
  left: 192px;
  top: -10px;
}

.filterTitle {
  padding-left: 16px;
  text-transform: capitalize;
  color: var(--color-gray90);
}

.closeButton {
  composes: flex-column from global;
  align-items: center;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  margin-top: 2px;
  margin-left: 6px;
  height: 18px;
  width: 18px;
  padding: 1px;
}

.closeButton:hover {
  background-color: var(--color-gray30);
}

.sourcesDropdownContainer {
  composes: dropdown from global;
  width: 192px;
  border-radius: 8px;
}

.sourceOption {
  padding: 14px 16px;
  border-bottom: 1px solid var(--color-gray10);
}

.noBorderBottom {
  border-bottom: none;
}

.sourceName {
  padding-left: 16px;
  font-size: inherit;
  color: var(--color-gray90);
}

.sourcesDropdown {
  box-shadow: none;
}

.submittersContainer {
  width: auto;
}

.sourcesDropdown > div:first-child > button,
.externalSourcesDropdown > div:first-child > button {
  color: var(--color-brandTintDark);
  padding: 6px 16px;
  font-size: 12px;
}

.sourcesOptionRow {
  composes: kai-flex-row from global;
}

.sourcesTitle {
  padding: 0;
  color: var(--color-gray90);
}

.topRowWrapper {
  width: 100%;
  padding-bottom: 19px;
  /* stylelint-disable-next-line declaration-no-important */
  margin-right: 0 !important;
}

.topRowWrapper > div:first-child {
  display: flex;
  flex: 1;
  margin-right: 8px;
}

.topRowWrapper > div:first-child > div:first-child {
  flex: 1;
}
