:root {
  --report-card-internal-padding: 24px;
  --report-card-external-margin: 4px;
  --report-card-btn-margin: 8px;

  /* header and body container heights need to be relative to font size
     since the containers hide overflowing text
     need to multiply container heights by a fudge factor to properly contain hanging letters
  */
  --report-card-header-title-font-size: 20px;
  --report-card-header-clamped-lines: 2;
  --report-card-header-clamped-height: calc(1.05 * var(--report-card-header-title-font-size) * var(--report-card-header-clamped-lines));
  --report-card-body-font-size: 14px;
  --report-card-body-clamped-lines: 4;
  --report-card-body-clamped-height: calc(1.02 * var(--report-card-body-font-size) * var(--report-card-body-clamped-lines));
}

.flexWrapStartContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flexWrapMobileFillItem {
  flex: 1 1 0;
}

@media (min-width: 1200px) {
  .flexWrapMobileFillItem {
    width: 33.33%;
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 1199px) {
  .flexWrapMobileFillItem {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
}

@media (max-width: 920px) {
  .flexWrapMobileFillItem {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

/*
  Below are the styles for reportCardItems
  Each card contains a Header, body and footer
  Headers, Body and footers are constrained vertically
  title and descriptions are clamped to 2 and 4 lines respectively
  headers can have an optional panel to display user info
  optional panel will expand the header height and thus the card height
*/

.reportCardItem {
  margin: var(--report-card-external-margin);
  padding: var(--report-card-internal-padding);
  transition: var(--insights-hover-background-change-transition-time);
  background-color: var(--color-gray02);
  border-radius: 4px;
}

.reportCardItem:hover {
  background-color: var(--color-gray02);
}

.reportCardItem:focus-within {
  background-color: var(--color-gray05);
}

.reportCardItem:focus-within a {
  outline: none;
}

.reportCardHeader h3 {
  box-sizing: content-box;
  display: -webkit-box;
  overflow: hidden;
  font-size: var(--report-card-header-title-font-size);
  line-height: var(--report-card-header-title-font-size);
  font-family: Inter, sans-serif;
  height: var(--report-card-header-clamped-height);
  -webkit-line-clamp: var(--report-card-header-clamped-lines);
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.reportCardBody {
  box-sizing: content-box;
  display: -webkit-box;
  overflow-y: hidden;
  padding-top: 5px;
  font-size: var(--report-card-body-font-size);
  line-height: 19.6px;
  height: var(--report-card-body-clamped-height);
  -webkit-line-clamp: var(--report-card-body-clamped-lines);
  -webkit-box-orient: vertical;
  color: var(--color-gray70);
  text-decoration: none;
}

.reportCardFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  padding: 0;
}

.reportCardFooterItem {
  margin-right: var(--report-card-btn-margin);
  display: flex;
  align-items: center;
}

.reportCardFooter > :not(.reportCardFooterItemPullRight):hover {
  cursor: pointer;
  color: var(--color-gray30);
}

.reportCardItem * {
  text-decoration: none;
}

.reportCardFooterItemPullRight {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.reportCardFooterItem:hover {
  cursor: pointer;
}

.reportCardFooterItem:focus {
  outline: none;
}

.reportCardHeaderPanel {
  display: flex;
  margin-bottom: 10px;
  color: var(--color-gray70);
}

.reportCardHeaderPanelInfo {
  margin-left: 14px;
}

.reportCardHeaderPanelInfo .headerTitle {
  font-size: 14px;
  line-height: 10px;
  margin: 0;
}

.reportCardHeaderPanelInfo .headerSubtitle {
  font-size: 12px;
  margin: 5px 0 0 0;
}
