.container {
  composes: kai-flex-row flex-centered from global;
  font-weight: 700;
}

.small {
  composes: container;
  composes: icon-small from global;
  font-size: 10px;
}

.medium {
  composes: container;
  composes: icon-medium from global;
  font-size: 20px;
}

.large {
  composes: container;
  composes: icon-large from global;
  font-size: 37px;
}
