.fontName {
  width: 100%;
}

.fallbackFontsContainer {
  width: 200px;
}

.filename {
  margin-left: 5px;
}

.spinnerContainer {
  margin-left: 10px;
}

.dropZone {
  flex: 1;
}

.variants {
  composes: kai-flex-row from global;
  padding-bottom: 8px;
}

.errors {
  background-color: var(--color-gray80);
  padding: 8px 16px;
  align-items: flex-start;
}

.errors > span {
  color: var(--color-gray00);
  white-space: nowrap;
}

.error {
  margin-left: 5px;
}

.dropZone > button {
  border-radius: 0;
  border-color: var(--color-gray20);
}

.dropZone > div:first-child {
  flex: 1;
  justify-content: flex-start;
}

.addVariantButtonContainer > button > span {
  cursor: pointer;
  padding: 2px;
  color: var(--color-brandTintLight);
}

.variants > span:first-child,
.variants > div:first-child {
  width: 180px;
  margin-right: 40px;
}

.variants > span:nth-child(2),
.variants > div:nth-child(2) {
  width: 150px;
  margin-right: 40px;
}

.variants > div:first-child > div,
.variants > div:nth-child(2) > div {
  border-radius: 0;
}

.addVariantButtonContainer > button {
  cursor: pointer;
  padding: 2px;
  color: var(--color-brandTintLight);
}

.variants > button:last-child,
.variants > button:last-child > span {
  cursor: pointer;
  padding: 0;
}

.dropZone > div:first-child > button {
  border-radius: 0;
}

.controlsContainer > button:first-child > span {
  cursor: pointer;
}

.controlsContainer > button:first-child > span > span > div {
  display: flex;
}

.dropZone > div:first-child > button:last-child,
.dropZone > div:first-child > button:last-child > span {
  cursor: pointer;
  padding: 2px;
}
