.sourceMenuButton {
  display: flex;
  justify-content: flex-start;
  padding: 0 3px;
  height: 32px;
  font-size: var(--font-size-body);
}

.sourceMenuPopover {
  width: 220px;
}

.uploadIcon {
  width: 15px;
  height: 17px;
}

.uploadIcon > svg {
  height: 15px;
  width: 15px;
}

.libraryIcon > svg {
  margin-top: 5px;
}

.linkIcon {
  width: 16px;
  height: 17px;
}

.linkIcon > svg {
  transform: rotateY(0deg) rotate(45deg);
  margin-left: -2px;
  width: 21px;
  height: 17px;
}

.linkPreviewSideBar {
  cursor: move;
  padding: 8px 16px;
}

.linkPreviewSideBar > div,
.linkPreviewSideBar > button {
  flex-basis: auto;
  flex-shrink: 0;
}

.linkPreviewSideBar .linkPreviewTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 1.8em;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 0;
  flex-grow: 1;
}
