.BannerWrapper div[class*='banner-list-item_main'] {
  height: 93px;
}

.Banner {
  width: 100%;
  align-items: center;
  padding-right: 50px;
  gap: 24px;
}

.Thumbnail {
  border-radius: 3px;
  height: 60px;
  overflow: hidden;
  width: 60px;
}

.BannerContent {
  align-items: start;
  gap: 24px;
  width: calc(100% - 75px);
}

.NameCol {
  width: 300px;
  margin-right: 50px;
}

.TextWrapper {
  overflow: auto;
  float: left;
}

.NameCol span[class*='typography_Bold'] {
  width: 100%;
  margin-top: 0;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.NameCol .Description {
  width: 100%;
  min-height: 24px;
  max-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.CreatorCol {
  margin-top: 10px;
  width: 181px;
}

.CreatorCol span[class*='typography_Body__'] {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.DateCol {
  margin-top: 10px;
  min-width: 144px;
}

.TypeCol {
  margin-top: 10px;
  min-width: 127px;
}

.TypePile {
  width: 59px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--color-gray90);
  padding: 4px 16px;
  border-radius: 40px;
}

.UserTypePile {
  background-color: var(--color-blueTintDark);
}

.TeamTypePile {
  width: 63px;
  background-color: var(--color-greenTintDark);
}

.ProgramTypePile {
  width: 75px;
  background-color: var(--color-tealTintDark);
}

.ColHeader {
  font-size: 9px;
  font-weight: 600;
  color: var(--color-gray90);
  opacity: 0.7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding: 0 0 9px 0;
}
