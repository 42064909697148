.modal {
  display: flex;
  border-radius: 16px;
}

.banner {
  border-bottom: 1px solid var(--color-gray05);
}

.bannerContent,
.body,
.footerContent {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.actions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}
