.clear {
  padding: 3px 15px;
  color: var(--color-brandTintDark);
  cursor: pointer;
  white-space: nowrap;
  appearance: none;
  background-color: inherit;
  font-size: inherit;
  border-style: none;
}

.searchBar {
  border-bottom: none;
}

.header {
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid var(--color-gray10);
}
