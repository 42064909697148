.menu-item {
  white-space: nowrap;
  margin: 0;
  background-color: var(--color-gray00);
  font-size: 15px;
  line-height: 1;
}

.menu-item a {
  text-decoration: none;
}

.active {
  composes: menu-item;
}

.active:hover {
  background-color: var(--color-gray02);
  cursor: pointer;
}

.active a {
  color: var(--color-brandTintDark);
}

.disabled {
  composes: menu-item;
  color: var(--color-gray30);
}

.icon {
  margin-right: 10px;
  width: 15px;
}

.journeysLayout {
  padding: 0;
}

.journeysLayout > a {
  padding-right: 100px;
  display: block;
}

.MenuItems {
  padding: 10px 20px;
}
