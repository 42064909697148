.filter-dropdown {
  background-color: var(--color-gray00);
  box-shadow: 0 9px 40px rgba(0, 0, 0, 0.14), 0 3.75998px 16.711px rgba(0, 0, 0, 0.10064), 0 2.01027px 8.93452px rgba(0, 0, 0, 0.083455), 0 1.12694px 5.00862px rgba(0, 0, 0, 0.07), 0 0.598509px 2.66004px rgba(0, 0, 0, 0.056545), 0 0.249053px 1.1069px rgba(0, 0, 0, 0.0393604);
  border-radius: 4px;
}

.no-search > div:last-child {
  border-radius: inherit;
}

.filter-dropdown * {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
}

.filter-item {
  border-radius: 8px;
  height: 32px;
}
