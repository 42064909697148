.row {
  display: flex;
  padding: 10px 0;
}

.row:not(:last-child) {
  border-bottom: 1px solid var(--color-gray10);
}

.measure {
  flex-grow: 2;
}

.value {
  flex-basis: 20%;
  text-align: right;
}

.percent {
  composes: value;
  color: var(--color-gray70);
}

.count {
  composes: value;
  font-weight: bold;
}
