.icon-small {
  width: 25px;
  height: 25px;
  border-radius: 2px;
}

.icon-medium {
  width: 50px;
  height: 50px;
  border-radius: 3px;
}

.icon-large {
  border-radius: 3px;
  width: 60px;
  height: 60px;
}
