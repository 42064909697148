.layout {
  position: relative;
  padding-left: 320px;
}

.layout h2 {
  margin: 68px 0 15px;
  font-size: 32px;
}

.layout aside {
  width: 240px;
  position: fixed;
  top: 40px;
  left: 80px;
}

.layout aside h2 {
  font-family: Gilroy, sans-serif;
  margin-bottom: 30px;
}

.main {
  margin: 80px auto 0;
  width: 693px;
}

.apply {
  justify-content: center;
  width: 100%;
}

.applyButton,
.cancelButton {
  margin-bottom: 15px;
  padding: 8px 80px;
  background-color: var(--color-brandTintDark);
  border-radius: 6px;
  border: none;
  color: var(--color-gray00);
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  cursor: pointer;
  width: 215px;
}

.cancelButton {
  color: var(--color-gray90);
  background-color: var(--color-gray05);
  font-size: 15px;
}

.applyButton:disabled {
  cursor: default;
  opacity: 0.5;
}

.errors ul {
  margin-top: 50px;
  list-style-type: none;
}

.errors li {
  color: var(--color-redFull);
}

.errors strong {
  display: block;
  color: var(--color-gray80);
}

.dropdown {
  composes: flex-column from global;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  width: 215px;
}

.dropdown button {
  border: none;
  cursor: pointer;
  background-color: var(--color-gray00);
  font-size: 16px;
  padding: 0 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.dropdown button:hover {
  background-color: var(--color-gray05);
}

.dropdownWrapper {
  top: 75px;
}
