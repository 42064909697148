.form {
  height: calc(100vh - var(--main-editor-footer-height));
}

.itemWrapper {
  height: 80px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  line-height: 40px;
  display: grid;
  grid-template-columns: 42px 8fr minmax(80px, 3fr) 1fr;
  align-items: start;
}

.messageRow {
  text-align: start;
  line-height: 20px;
  display: grid;
  grid-template-columns: 42px 11fr 1fr;
  align-items: start;
}

.avatarImage {
  padding-top: 3px;
  align-self: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.avatarImg {
  padding-left: 4px;
}
