.EditTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 12px;
}

.Checkbox {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.CheckboxLabel {
  margin-left: 12px;
}

.textField {
  width: 100%;
}

.Inputs {
  margin-top: 14px;
}

.RichTextBlock ul,
.RichTextBlock ol,
.Inputs ul,
.Inputs ol {
  padding: revert;
}

.LimitedHeightBlock {
  max-height: 120px;
  overflow: hidden;
}

.ShowAllBtn {
  margin-top: 16px;
}

.SectionMarginTop {
  margin-top: 32px;
}

.GdprHeading {
  margin-bottom: 8px;
}

.GdprSection {
  display: flex;
  align-items: center;
}

.GdprDescription {
  width: 320px;
  min-width: 320px;
  margin-right: 8px;
}

.Link {
  color: var(--color-brandFull);
  text-decoration: underline;
}

.Link:hover {
  text-decoration: none;
}

.Link svg path {
  fill: var(--color-brandFull);
}
