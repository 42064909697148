.wrapper {
  padding-top: 60px;
  font-size: 14px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.row > div {
  padding: 15px 10px;
  text-align: center;
  border-left: 2px solid var(--color-gray00);
}

.col > div {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.col > div > span:last-child {
  max-width: 220px;
  text-align: right;
}

.actionsWrapper {
  padding: 20px;
}

.actionsWrapper > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 600;
  padding: 0 15px;
}

.actionsWrapper > div.Active {
  cursor: pointer;
}

.actionsWrapper > div.Disabled {
  color: var(--color-gray40);
  pointer-events: none;
}

.actionsWrapper > div.Disabled svg path {
  color: var(--color-gray40);
}

.row > div > div {
  margin-top: 15px;
  font-size: 12px;
  color: var(--color-gray70);
}

.row > div:first-child {
  border-left: none;
}

.col,
.actionsWrapper {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}

.row,
.col {
  border-top: 2px solid var(--color-gray00);
  border-bottom: 2px solid var(--color-gray00);
}

.col {
  border-top: none;
}

.actionsWrapper > div > span:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  margin-right: 15px;
  color: var(--color-gray90);
}

.passwordLinkWarning {
  color: var(--color-gray70);
  margin-bottom: 10px;
}

.passwordLinkWrapper {
  border: 1px solid var(--color-gray10);
  border-radius: 6px;
  color: var(--color-gray90);
  margin-bottom: 20px;
  padding: 5px 10px;
  font-size: 14px;
  overflow: hidden;
}

.copyButton {
  float: right;
  border: none;
  border-radius: 6px;
  background-color: var(--brand-primary-light-1);
  color: var(--color-brandTintDark);
  font-weight: 600;
  padding: 5px 20px;
}
