.wrapper {
  width: 100px;
  background-color: var(--color-gray00);
  height: 4px;
  border-radius: 4px;
}

.slider {
  width: 40px;
  height: 4px;
  background-color: var(--color-gray90);
  animation: progress 5s linear infinite alternate;
  border-radius: 4px;
}

@keyframes progress {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 60px;
  }
}
