.uploader {
  background-color: var(--color-gray05);
  border: 1px dashed var(--color-gray30);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px 0;
  gap: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.filePreviewListItem {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 8px;
  gap: 16px;
  height: 64px;
  border: 1px solid var(--color-gray10);
  border-radius: 8px;
}

.filePreviewListItem img {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}

.filePreviewNameContainer {
  display: flex;
  flex-grow: 1;
}

.errors {
  margin-bottom: 16px;
}

.errors ul {
  margin-left: 15px;
}

.errors * {
  color: var(--color-redFull);
}
