.wrapper {
  height: 100%;
}

.button {
  border: none;
  background-color: #f2f6f8;
  color: #555;
  text-align: center;
  display: block;
  font-size: 13px;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0 18px;
}

.button:hover {
  background-color: #e0f2fe;
  color: #0190ef;
}

.button:active {
  background-color: #d0e2ee;
  color: #0190ef;
}
