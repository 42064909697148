.PersonalizedFieldsPanel > div { margin: 16px; }

.UploadButton,
.ReportButton {
  border-radius: 4px;
}

div[id="Upload Dataset File-"] {
  --modal-height: 232px;
  --modal-width: 632px;
}

.Placeholder {
  composes: BorderGray10 from "DesignSystem/Typography/typography.module.css";
  composes: BorderStyleDashed from "DesignSystem/Typography/typography.module.css";
  composes: BorderWidthTwo from "DesignSystem/Typography/typography.module.css";
  composes: BorderRadiusEight from "DesignSystem/Typography/typography.module.css";
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  height: var(--modal-height);
  margin-top: 16px;
  width: var(--modal-width);
  align-items: center;
  justify-content: center;
}

.Placeholder > div[class^="file-drop-zone_dropzone__"] {
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  margin: -2px;
  border: 2px dashed color-mix(in sRGB, var(--color-gray90) 50%, transparent);
  border-radius: 8px;
  opacity: 0.8;
  background-color: var(--color-brandFull);
  background-image: none;
}

.Placeholder.Dragging > span[class^="typography_Subheading__"] {
  color: var(--color-gray00);
  z-index: 1001;
}

.ExampleCSVLink {
  composes: Caption from "DesignSystem/Typography/typography.module.css";
  cursor: pointer;
  margin-top: 16px;
  text-decoration: underline;
}

.CSVInput {
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.Disclaimer {
  composes: Caption from "DesignSystem/Typography/typography.module.css";
  display: block;
  position: relative;
  top: 8px;
  width: var(--modal-width);
}

.ErrorsForm {
  overflow: auto;
  width: 720px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--color-gray05);
}

.UpdateForm {
  background-color: var(--color-gray05);
  position: relative;
  width: 720px;
  padding: 16px;
}

.UpdateForm.Errors {
  padding: 8px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.SettingsSelect {
  top: -4px;
  font-size: 14px;
  width: 400px;
}

.SettingsSelect div[class$="-control"] {
  padding: 2px 16px 2px 16px;
}

.SettingsSelect div[class$="-ValueContainer"] {
  padding: 0;
}

.SettingsSelect span[class$="-indicatorSeparator"] {
  display: none;
}

.SettingsSelect div[class$="-indicatorContainer"] {
  padding-bottom: 2px;
  padding-top: 2px;
}

.SettingsSelect div[class$="-indicatorContainer"] svg[class$="-Svg"] {
  color: var(--color-gray90);
  height: 16px;
  width: 16px;
}

.SettingsSelect div[class$="-indicatorContainer"] svg[class$="-Svg"]:hover {
  color: var(--color-gray90);
}

.SettingsSelect div[class$="-menu"] {
  width: 352px;
  max-width: 688px;
}

.SettingsSelect div[class$="-menu"] > div[class*="css-"] {
  max-height: 232px;
  padding: 4px 0;
}

.SettingsSelect div[class$="-menu"] div[class$="-option"] {
  padding: 10px 16px 13px 16px;
  word-wrap: break-word;
}

.SettingsSelect div[class$="-menu"] span[class^="material-symbols-rounded"] {
  position: relative;
  top: 3px;
  font-size: 18px;
  margin-right: 16px;
}

.SettingsIdFieldSelect div[class$="-control"] {
  padding: 2px 16px 2px 40px;
}

.SettingsIdFieldSelect div[class$="-control"]::after {
  display: block;
  position: absolute;
  content: ' ';
  background-size: cover;
  background-image: url('data:image/svg+xml;utf8,<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.8062 20.3306L14.0312 14.5806C13.5312 14.9973 12.9562 15.3223 12.3062 15.5556C11.6562 15.789 10.9896 15.9056 10.3062 15.9056C8.58958 15.9056 7.13958 15.314 5.95624 14.1306C4.77291 12.9473 4.18124 11.4973 4.18124 9.78064C4.18124 8.08064 4.77291 6.63464 5.95624 5.44264C7.13958 4.25131 8.58958 3.65564 10.3062 3.65564C12.0062 3.65564 13.4479 4.24731 14.6312 5.43064C15.8146 6.61397 16.4062 8.06397 16.4062 9.78064C16.4062 10.4973 16.2896 11.1806 16.0562 11.8306C15.8229 12.4806 15.5062 13.0473 15.1062 13.5306L20.8812 19.3056C21.0146 19.439 21.0812 19.6056 21.0812 19.8056C21.0812 20.0056 21.0062 20.1806 20.8562 20.3306C20.7062 20.4806 20.5272 20.5556 20.3192 20.5556C20.1106 20.5556 19.9396 20.4806 19.8062 20.3306ZM10.3062 14.4056C11.5896 14.4056 12.6772 13.9556 13.5692 13.0556C14.4606 12.1556 14.9062 11.064 14.9062 9.78064C14.9062 8.49731 14.4606 7.40564 13.5692 6.50564C12.6772 5.60564 11.5896 5.15564 10.3062 5.15564C9.00624 5.15564 7.91058 5.60564 7.01924 6.50564C6.12724 7.40564 5.68124 8.49731 5.68124 9.78064C5.68124 11.064 6.12724 12.1556 7.01924 13.0556C7.91058 13.9556 9.00624 14.4056 10.3062 14.4056Z" fill="rgb(27,26,31)"/></svg>');
  height: 16px;
  width: 16px;
  left: 16px;
  top: 10px;
}

.SettingsSelect.SettingsIdFieldSelect div[class$="-menu"] div[class$="-option"] {
  padding: 14px 16px;
}

.TooltipIcon {
  display: inline-block;
  position: relative;
  left: 6px;
  top: 4px;
  cursor: default;
}

.TooltipIcon > :global(.material-symbols-rounded) {
  font-size: 19px;
}

.AttrCheckboxes {
  display: flex;
  width: 400px;
  flex-direction: row;
  gap: 32px;
}

.AttrCheckboxes > div {
  height: 100%;
  width: 184px;
}

.AttrCheckboxes label {
  height: 24px;
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
}

.AttrCheckboxes label div[class^="Checkbox_checkboxLabel__"] {
  padding-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AttrCheckboxes div[class^="Checkbox_styledCheckbox__"] {
  color: var(--brand-purple-shade-dark);
}

.IndeterminateCheckbox {
  composes: styledCheckbox from "shared/Checkbox/Checkbox.module.css";
  composes: checkbox from "shared/Checkbox/Checkbox.module.css";
  composes: checked from "shared/Checkbox/Checkbox.module.css";
  composes: disabled from "shared/Checkbox/Checkbox.module.css";
  color: white;
  font-size: 22px;
}

.ErrorTypePill {
  border: 1px solid #ffa39e;
  background-color: #ffa39e;
}

.Overlay {
  background-color: color-mix(in sRGB, var(--color-gray05) 50%, transparent);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.Overlay > div[class^="loading-spinner_dualRing__"] {
  position: relative;
  left: calc(50% - 35px);
  top: calc(50% - 35px);
}

.FileInfoTable {
  font-size: 12px;
  width: 688px;
}

.FileInfoTable tr {
  border-bottom: 1px solid var(--color-gray10);
}

.FileInfoTable th {
  text-align: left;
}

.FileInfoTable th,
.FileInfoTable td {
  margin-right: 16px;
  padding-bottom: 8px;
}

.FileInfoTable td {
  height: 42px;
  padding-top: 8px;
}

.Tag {
  padding: 6px 12px;
  border-radius: 13px;
}

.ErrorTag {
  composes: Tag;
  background-color: var(--color-redTintDark);
}

.OKTag {
  composes: Tag;
  background-color: var(--color-greenTintDark);
}

.ReportButton {
  margin-top: 8px;
}

hr:first-of-type + div {
  /* has to override overflow property set in element's style attribute */
  /* stylelint-disable-next-line declaration-no-important */
  overflow-y: visible !important;
}

.ConfirmForm {
  composes: UpdateForm;
}

.ConfirmForm div:global(.click-dropdown-target) {
  width: 224px;
}

.ConfirmForm :global(.click-dropdown-target) button {
  padding-left: 7px; /* there is a bit of inherent space in the icon itself */
  padding-right: 8px;
}

.ConfirmForm :global(.click-dropdown-target) button :global(.icon) {
  margin-right: 8px;
}

.ConfirmForm div[class^="calendar_mainWrapper__"] {
  border: 1px solid var(--color-gray05);
  border-radius: 8px;
  padding-bottom: 16px;
}

.ConfirmForm div[class^="calendar_mainWrapper__"] div {
  margin-bottom: 4px;
}

.FilePreview {
  position: relative;
  margin: 16px 0 16px 0;
  border-radius: 4px;
  background-color: var(--color-gray05);
}

.FilePreview.Failed {
  border: 1px solid var(--color-redTintDark);
  background-color: var(--color-redTintLight);
}

.FilePreview.NeedsAction {
  border: 1px solid var(--color-orangeTintDark);
  background-color: var(--color-orangeTintLight);
}

.FilePreview.Failed.NoBorder,
.FilePreview.NeedsAction.NoBorder {
  border: none;
}

.FilePreview > div[class^="layout_Flex__"] > button {
  height: 24px;
  margin-left: 4px;
  padding: 4px;
  width: 24px;
}

.FilePreview > div[class^="layout_Flex__"] > button:hover {
  background-color: color-mix(in sRGB, var(--color-gray90) 10%, transparent);
}

.FilePreview.Failed > div[class^="layout_Flex__"] > button:hover {
  background-color: color-mix(in sRGB, var(--color-gray90) 9%, transparent);
}

div[id="Upload Dataset File-"] hr:last-of-type {
  display: none;
}

div[id="Upload Dataset File-"] div[class^="popover_Popover__"] > div {
  padding: 0 8px;
}

.ResetContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 6px;
  height: 40px;
  width: 253px;
}

.ResetContainer.Deleting {
  background-color: var(--color-gray70);
}

.ResetContainer.Deleting button[class^="forms_Button__"]:last-of-type {
  margin: 4px 4px 4px 0;
}

.ResetContainer.Deleting button[class^="forms_Button__"]:first-of-type {
  font-weight: 600; /* see Typography.Semibold */
  font-size: var(--font-size-body);
  color: var(--color-gray00);
}

.ResetContainer.Deleting button[class^="forms_Button__"]:first-of-type:focus,
.ResetContainer.Deleting button[class^="forms_Button__"]:first-of-type:focus-within {
  box-shadow: none;
}

.ResetContainer.Deleting button[class^="forms_Button__"]:first-of-type > span {
  cursor: pointer;
}
