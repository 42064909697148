.web {
  background-color: var(--color-gray00);
  margin: auto;
  max-width: 900px;
  width: 100%;
}

.address {
  border: solid 8px #e6edf1;
  color: #aaa;
  margin: auto;
}

.content {
  height: 75vh;
  overflow-y: auto;
  padding: 50px;
}
