.dateInput input {
  border: none;
  outline: none;
  width: 115px;
  height: 24px;
}

.dateInput {
  margin-left: 10px;
  width: 70%;
}

.dateInput input::-webkit-calendar-picker-indicator {
  display: none;
}

.dateInput input::-webkit-datetime-edit {
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  line-height: 150%;
  font-size: 16px;
  font-weight: 600;
}

.dateInput .disabled::-webkit-datetime-edit {
  font-weight: 400;
  font-size: 12px;
}

.dateInput ::-webkit-datetime-edit-fields-wrapper {
  display: flex;
  justify-content: space-around;
}

.dateInput .disabled::-webkit-datetime-edit-text {
  display: none;
}

.dateInput input.disabled {
  background-color: var(--color-gray00);
  height: 18px;
}
