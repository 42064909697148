.AddStepEdge {
  height: 50px;
  position: absolute;
  z-index: 1001;
  pointer-events: all;
}

.AddStepEdgeButton {
  color: var(--color-blueFull);
  background-color: var(--color-gray05);
  pointer-events: all;
}

.AddStepEdgeButton:focus,
.AddStepEdgeButton:focus-within {
  box-shadow: unset;
}

.AddStep {
  position: absolute;
  transform: translate(50%, -50%);
  padding: 12px;
  border-radius: 16px;
  border: 1px solid var(--color-gray10);
  background: var(--color-gray00);
}

.AddStepOptionButton {
  pointer-events: all;
  padding: 4px;
}

.AddStepOptionIcon {
  border-radius: 4px;
  margin-right: 8px;
  padding: 4px;
}

.AddStepOptionIcon > span {
  font-size: 16px;
}

.EdgeLabel {
  position: absolute;
  transform: translate(0, -50%);
  padding: 10px;
  font-size: 14px;
  width: 150px;
  background: #fff;
  border: 1px solid var(--color-gray10);
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;
  z-index: 1002; /* Can be removed once AddStepEdge is correctly sized in FE-7261 */
}
