.container {
  composes: flex-column from global;
  padding: 60px 80px 60px 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.fullHeight {
  height: 100%;
}

.label {
  composes: flex-column from global;
}

.label > :first-child {
  text-transform: uppercase;
  font-size: calc(1em - 3pt);
  color: var(--color-gray70);
}

.label > :last-child {
  font-size: calc(1em + 20pt);
  font-weight: bold;
}
