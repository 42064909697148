.FlexContainer {
  display: flex;
  flex-wrap: wrap;
}

.Pill {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 20px;
  height: 32px;
  border: 1px solid var(--color-gray10);
  padding: 20px;
}

.TextField {
  width: 100%;
}

.Input {
  margin-bottom: 32px;
}

.Error {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: var(--color-redFull);
}

.Terms {
  border: 1px solid var(--color-gray10);
  padding: 10px;
  height: 300px;
}

.TermsLink {
  margin: 20px 0;
}

.TermsCheckbox {
  margin: 20px 0;
}

.TermsAcceptButton {
  text-align: right;
}

.TermsMessage {
  color: var(--color-redFull);
  margin-top: 10px;
}
