.container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.-row-item {
  width: 215px;
  height: 230px;
  margin: 15px 12px;
  border-radius: 2px;
}

.-row-item:nth-child(3n + 1) {
  margin-left: unset;
}

.-row-item:nth-child(3n) {
  margin-right: unset;
}

.image {
  composes: -row-item;
  position: relative;
}

.image > button {
  position: absolute;
  border: none;
  background-color: var(--color-gray90);
  top: 15px;
  right: 15px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image > button > svg > path,
.image > button > svg > rect {
  fill: none;
  stroke: var(--color-gray00);
}

.image > img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.plus {
  composes: -row-item;
  background-color: var(--color-gray05);
  color: var(--color-gray50);
  border: none;
  font-size: 42px;
  font-weight: 100;
  margin-left: 0;
}

.plus > * {
  cursor: pointer;
}

.plus > div:first-child {
  width: 100%;
  height: 100%;
}

.plus > div:first-child > div:first-child {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.loading {
  composes: -row-item;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
}

.row.-of-size-1 + .plus,
.row.-of-size-2 + .plus {
  position: relative;
  left: 12px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.actionButton {
  border: none;
  background-color: transparent;
  font-weight: 700;
  margin-left: 33px;
  cursor: pointer;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButton > svg {
  height: 18px;
  width: 18px;
  margin-right: 13px;
}

.image > button > svg {
  height: 14px;
}

.inactive {
  opacity: 0.5;
}

.spinnerOverlay {
  position: absolute;
  height: 230px;
  width: 215px;
  background-color: rgba(255, 255, 255, 0.9);
}
