.dropdown {
  composes: lightThemed from '../styles.module.css';
  list-style-type: none;
  box-sizing: border-box;
  width: 120%;
}

.journeysDropdown {
  border-radius: 4px;
}

.normalMenuItems a {
  color: var(--color-gray90);
}

.dangerMenuItems a {
  color: var(--color-redFull);
}

.horizontal {
  opacity: 0.2;
}

.profileName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 110px;
  margin: auto;
  padding-bottom: 8px;
}

.journeysLayoutDivider {
  margin: 8px 28px 8px -8px;
  opacity: 1;
  background-color: var(--color-gray10);
}
