.wrapper {
  height: 14px;
  width: 26px;
}

.sizeLg.wrapper {
  width: 40px;
  height: 24px;
}

.sizeXl.wrapper {
  width: 46px;
  height: 26px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 26px;
  height: 14px;
  border: 1px solid var(--color-gray40);
  border-radius: 8px;
  position: relative;
  transition: background-color 0.2s;
  background-color: var(--color-gray40);
}

.label:hover {
  outline: 4px solid var(--color-brandOutline);
}

.rounded .label {
  border-radius: 30px;
}

.sizeLg .label {
  width: 40px;
  height: 24px;
}

.sizeXl .label {
  width: 46px;
  height: 26px;
}

.checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

.button {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 10px;
  height: 10px;
  transition: 0.2s;
  border-radius: 50%;
  background-color: var(--color-gray00);
}

.sizeLg .button {
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
}

.sizeXl .button {
  width: 22px;
  height: 22px;
}

.rounded .button {
  border-radius: 30px;
}

.checked {
  background-color: var(--color-brandShadeDark);
  border-color: var(--color-brandShadeDark);
}

.checked .button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background-color: var(--color-gray00);
}

.wrapper.equalTheme .button {
  background-color: var(--color-gray00);
}

.wrapper.inactiveTheme {
  opacity: 0.7;
}

.wrapper.inactiveTheme .button {
  background-color: var(--color-gray30);
  cursor: not-allowed;
}

.wrapper.equalTheme .label {
  border: 1px solid var(--color-gray80);
  background-color: var(--color-gray40);
}

.wrapper.inactiveTheme .label {
  border: 1px solid var(--color-gray30);
  background-color: var(--color-gray00);
  cursor: not-allowed;
}

.wrapper.inactiveTheme .checked .button {
  background-color: var(--color-gray30);
  cursor: not-allowed;
}
