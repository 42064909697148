.PinnedPosts__Container {
  padding: 13.5px;
  margin-bottom: 18px;
  border: 2px solid var(--color-gray05);
  position: relative;
  z-index: 1;
}

.PinnedPosts__Empty__Content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 388px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.PinnedPosts__Empty__TextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.PinnedPosts__Empty__Description {
  color: var(--color-gray60);
}

.Loading {
  margin-left: calc(
    50% - 17px
  ); /* margin-auto wont work, this is an approximation */
}

/* Post component contains buttons, so instead ow wrapping it in button, we put button overlay on top */
.editPinnedPosts_WrapperButton {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.pinnedPostsLabel {
  padding-bottom: 8px;
}

.feedWrapper {
  position: relative;
}

/* force the color of the label to be black.
These labels are not in the web experience, and are just there since we dont have access to the comment/like counts
making them black here pulls attention away from them, and also signifies that they are not interactive */
.cardActionButton {
  color: var(--color-gray90);
}
