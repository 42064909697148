.control {
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.control > * {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  color: black;
}

.control > *:first-child {
  border-right: solid 1px #eee;
}

.selected {
  display: flex;
  justify-content: space-between;
  cursor: default;
}

.selected button {
  font-weight: bold;
}

.control > * > div {
  font-size: 13px;
  font-weight: 100;
  cursor: pointer;
}

.selected > div:first-child {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.selected > div:first-child:not(.manual) {
  background: var(--color-gray05);
}

.manual {
  border: 1px solid var(--color-brandTintDark);
}

.selected > div span {
  color: var(--color-gray00);
  width: 30px;
  height: 30px;
  font-size: 16px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected:first-child > div > span {
  background: var(--color-gray90);
  margin-left: 15px;
}

.selected:last-child {
  margin-left: 5px;
}

.selected:last-child > div > .ring {
  margin-left: 15px;
  border-radius: 100%;
  height: 36px;
  width: 36px;
  border: 2px solid var(--color-brandShadeLight);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.selected > div:first-child:hover:not(.manual) {
  background: var(--color-gray10);
}

.selected:last-child > div > div > span {
  background: var(--color-brandShadeLight);
}

.control .label {
  font-size: 14px;
  margin-left: 10px;
}

.control button {
  border: 0;
  background: none;
  outline: 0;
  cursor: pointer;
}

.control .calendarIcon {
  font-size: 18px;
  margin-right: 15px;
}

.control .caretDirections {
  border-left: 1px solid var(--color-gray10);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: var(--color-gray05);
  height: 62px;
  width: 34px;
}

.control .caretDirections button {
  height: 100%;
  width: 100%;
}

.control .caretDirections button:hover {
  background: var(--color-gray10);
}

.control .caretDirections i {
  color: var(--color-gray70);
}
