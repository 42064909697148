.channelSelectionHeader {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.channelSelectionGroups {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.checkboxGroupContainer {
  padding-left: 32px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
