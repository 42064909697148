.Container {
  background: var(--color-gray00);
}

.Content {
  max-width: 824px;
  margin: 0 auto;
  padding-bottom: 32px;
}

.Section {
  padding: 32px;
  border-radius: 8px;
  border: 1px solid var(--color-gray10);
}

.Section h2 {
  color: var(--color-gray90);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%; /* 24px */
  margin: 0 0 16px;
}

.SectionContainer {
  display: flex;
  gap: 32px;
  flex-direction: column;
}

.FieldSet {
  display: flex;
  width: 760px;
  padding: 8px 0 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.Field {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0 8px 16px;
  margin-bottom: 8px;
}

.FieldWithError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 0 8px 16px;
  margin-bottom: 8px;
  align-items: flex-start;
}

.FieldSet > .Field {
  margin-bottom: 0;
  padding: 0;
}

.ToggleField {
  composes: Field;
  padding-left: 0;
}

.Control {
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.Label {
  color: var(--color-gray90);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.Errors {
  margin-bottom: 16px;
}

.ErrorList {
  list-style-type: disc;
  list-style-position: inside;
}
