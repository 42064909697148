.email {
  background-color: var(--color-gray00);
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 20px rgba(var(--color-gray90), 0.07);
}

.header {
  display: flex;
  align-items: center;
  height: 52px;
  background-color: var(--color-gray02);
  border-bottom: solid 1px var(--color-gray05);
  border-radius: inherit;
}

.header :first-child {
  margin-left: 21px;
}

.header :nth-child(2) {
  margin-left: 20px;
}

.header :nth-child(3) {
  margin-left: 80px;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 302px;
  height: 28px;
  background: rgba(0, 0, 0, 0.05);
  opacity: 0.5;
}

.body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 600px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  padding-top: 15px;
  height: 100%;
  background-color: var(--color-gray02);
}

.sidebar > * {
  opacity: 0.7;
  margin-bottom: 25px;
}

.inbox {
  width: 331px;
  height: 100%;
  background-color: var(--color-gray02);
}

.mail {
  display: flex;
  flex-direction: column;
  padding: 15px 17px;
  width: 331px;
  height: 124px;
  opacity: 0.5;
  overflow: hidden;
}

.mail:first-child {
  background: var(--color-gray00);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.mail .info {
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  color: var(--color-gray70);
}

.info :first-child {
  font-size: 18px;
  line-height: 23px;
}

.info :last-child {
  font-size: 14px;
  line-height: 18px;
}

.title {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-gray70);
  composes: kai-line-clamp-1 from global;
}

.rtlTitle {
  text-align: right;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-gray70);
  composes: line-clamp-1 from global;
}

.summary {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-gray30);
  composes: kai-line-clamp-3 from global;
}

.rtlSummary {
  text-align: right;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-gray30);
  composes: line-clamp-3 from global;
}

.menu {
  display: flex;
  justify-content: flex-start;
  padding: 14px;
  background-color: var(--color-gray00);
  flex: 1;
}

.menu > * {
  margin-right: 20px;
  opacity: 0.6;
}

.timeAndAttach {
  display: flex;
  align-items: center;
}

.timeAndAttach :first-child {
  margin-right: 12px;
}
