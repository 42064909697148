.wrapper {
  position: relative;
  overflow: hidden;
}

.scrollable {
  overflow: auto;
  max-height: 100%;
  padding-bottom: 5px;
  display: grid;
  grid-template-rows: 0 1fr;
  max-width: 100%;
}

.shadow {
  position: sticky;
  top: 50px;
  width: 100%;
  height: 5px;
  background-color: #fff;
  z-index: 3;
}

.shadow::before {
  content: ' ';
  width: 100%;
  height: 10px;
  background-color: var(--color-gray00);
  margin-top: -10px;
  position: relative;
  display: block;
}

.week {
  display: flex;
  max-height: 100%;
  max-width: 100%;
  flex: 1;
  position: relative;
}

.scrolled > .shadow {
  box-shadow: 0 1px 4px rgba(var(--color-gray90-rgb), 0.2),
    0 7px 7px rgba(var(--color-gray90-rgb), 0.1);
}
