.burger {
  padding: 10px;
  font-size: 20px;
  background-color: inherit;
  color: inherit;
  border-style: none;
  border-radius: 0;
  border-color: transparent;
  cursor: pointer;
}

.dropdown {
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.2) 3px 4px 14px 0, rgba(0, 0, 0, 0.05) 0 0 0 1px;
  box-sizing: border-box;
  background-color: var(--color-gray00);
}

.section {
  border-bottom: 1px var(--color-gray10) solid;
  padding: 12px 0;
}

.item {
  cursor: pointer;
  width: 100%;
  composes: transparent from global;
  composes: kai-flex-row from global;
}

.item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.item span,
.item button,
.item a {
  padding: 6px 12px;
  font-size: 1rem;
  color: var(--color-gray80);
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
}

.item:not(.disabled):hover span,
.item:not(.disabled):hover button,
.item:not(.disabled):hover a {
  background-color: var(--color-gray02);
}

.stateLink {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
