/* A mapping from old colors to new design language colors.
 * These will have an effect in legacy stylesheets that use variables. */

/* TODO convert to new variables */
:root {
  --color-gray90-rgb: 17, 17, 19;
  --color-gray80-rgb: 52, 51, 56;
  --color-gray70-rgb: 93, 92, 101;
  --color-gray50-rgb: 140, 137, 149;
  --color-gray30-rgb: 188, 186, 196;
  --color-gray10-rgb: 227, 225, 234;
  --color-gray05-rgb: 244, 243, 246;
  --color-gray02-rgb: 249, 249, 251;
  --color-gray00-rgb: 255, 255, 255;
  --color-greenFull-rgb: 93, 157, 82;
  --color-redFull-rgb: 226, 92, 51;
  --color-yellowFull-rgb: 243, 170, 71;
}
