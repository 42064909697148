.Link {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  color: #5533c9 !important;
  cursor: pointer;
  margin-left: 8px;
}

.Link:hover {
  text-decoration: underline;
}
