.successMessage {
  font-family: inherit;
  text-align: center;
  margin-bottom: 14px;
}

.mainSuccessBlock {
  padding: 50px;
}

.successBlock {
  padding-bottom: 5px;
  margin: 0 auto;
}

.successIcon {
  display: block;
  margin: auto;
  padding-bottom: 20px;
}
