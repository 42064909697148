.text {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}

.suggestionText {
  color: var(--color-brandTintDark);
}

.applySuggestionButton {
  background-color: var(--color-brandTintDark);
  color: white;
  border: 0;
  padding: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
}
