.container {
  width: 100%;
  padding: 24px;
}

.memberCount {
  color: var(--color-gray60);
  font-size: 14px;
  line-height: 150%; /* 21px */
  margin-bottom: 24px;
}

.memberList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  gap: 24px;
  justify-items: stretch;
}

.member {
  width: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  text-align: center;
  line-height: 150%;
}

.name {
  color: var(--color-gray90);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-height: 72px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.department {
  color: var(--color-gray60);
  font-size: 14px;
}
