.avatarListWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.avatarList {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.avatarImg:not(:first-child) {
  border-radius: 50%;
  margin-left: -8px;
  border: 1px solid white;
}
