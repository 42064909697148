.block {
  display: block;
  font-size: 14px;
}

.icons {
  position: relative;
  left: 29%;
}

.errorIcon {
  display: block;
  margin: auto;
}

.text {
  font-family: inherit;
  text-align: center;
}
