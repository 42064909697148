.Popover {
  padding: 16px;
  background-color: var(--color-gray00);
  box-shadow: 0 16px 24px -5px rgba(0, 0, 0, 0.12), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.PopoverCenteredShadow {
  box-shadow: 0 0 24px -5px rgba(0, 0, 0, 0.32), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.PopoverNoPadding {
  padding: 0;
}
