.SettingsCard {
  margin: 15px 32px 15px 32px;
  margin-bottom: 24px;
}

.EditButton {
  position: absolute;
  top: 0;
  right: 1px;
}

.Editing {
  position: relative;
  background-color: var(--color-gray02);
  border: 1px solid var(--color-gray10);
  padding: 23px;
  border-radius: 8px;
}

.TopBorder {
  border-top: 1px solid;
  border-color: var(--color-gray10);
  padding-top: 25px;
}

.TopBorder .EditButton {
  top: 25px;
}

.Divisor {
  height: 0;
  border-top: 1px solid var(--color-gray10);
  margin: 30px 32px 30px 32px;
}
