.Tooltip {
  text-align: left;
  width: auto;
  max-width: 400px;
  margin: 0;
  font-family: var(--font-family-primary);
  z-index: 10001;
  overflow-wrap: break-word;
}

.border-rounded {
  border-radius: 4px;
}

.border-square {
  border-radius: 0;
}

.size-small {
  padding: 8px 16px;
  font-size: 12px;
}

.size-large {
  padding: 16px;
  font-size: 14px;
}

.type-light {
  background-color: var(--color-gray20);
  color: var(--color-gray90);
}

.type-dark {
  background-color: var(--color-gray90);
  color: var(--color-gray00);
}
