.imageField > div:first-child {
  width: 136px;
  margin-top: 6px;
}

.uploadSourceMenuItem {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
}

.imagePlaceholder {
  border-radius: 4px;
  height: 150px;
  background-color: var(--color-gray20);
  composes: flex-column from global;
  justify-content: center;
  align-items: center;
}

.uploadingImages {
  color: var(--color-redShadeLight);
  line-height: 150%;
  font-size: var(--font-size-caption);
  padding-top: 8px;
}

.section {
  padding-bottom: 16px;
}

.sectionHeader {
  cursor: default;
  text-transform: uppercase;
  font-size: var(--font-size-caption);
  font-feature-settings: 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv09' on;
  line-height: 150%;
  color: var(--color-gray90);
  padding-bottom: 8px;
  font-weight: 500;
}

.tabs {
  background-color: var(--color-gray05);
  border-radius: 4px;
  composes: kai-flex-row from global;
  padding: 4px;
}

.externalSource {
  padding-left: 0;
}

.externalSource > input {
  height: 32px;
  border-radius: 4px;
}

.externalSource > button {
  margin-left: 5px;
  height: 32px;
}

.imageMenu > button {
  height: 36px;
  padding: 0 16px;
  font-size: var(--font-size-body);
}

.tabs > button,
.tabs > button:hover {
  height: 32px;
  width: 50%;
  cursor: pointer;
  color: var(--color-gray60);
  font-size: var(--font-size-body);
  font-weight: 500;
  border-radius: 4px;
}

.uploadActive > button:first-child {
  background-color: var(--color-gray00);
  color: var(--color-gray90);
}

.urlActive > button:last-child {
  background-color: var(--color-gray00);
  color: var(--color-gray90);
}

.image {
  max-width: 100%;
  display: block;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid var(--color-gray05);
}

.change .button {
  background-color: var(--color-gray10);
}

.imageMenu {
  height: 36px;
  background-color: var(--color-gray05);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  composes: kai-flex-row from global;
  justify-content: space-between;
}

.imageMenu > button:last-child {
  color: var(--color-redShadeLight);
}

.immageMenu > button span {
  /* gets overriden by <Button> styles */
  /* stylelint-disable-next-line declaration-no-important */
  cursor: pointer !important;
}

.errorMessage {
  color: var(--color-redFull);
  font-size: 14px;
  margin-top: 12px;
}
