.container {
  composes: kai-flex-row from global;

  /* the footer is 75px tall */
  height: calc(100vh - 75px);
  background-color: var(--color-gray00);
  flex-grow: 1;
  display: flex;
  justify-content: stretch;
  overflow-y: auto;
  align-items: flex-start;
}

.container.SplitBars {
  height: 100vh;
  padding: 48px 0 64px;
}

.page {
  display: flex;
  background-color: var(--color-gray00);
  composes: publish-main-content from global;
}

.container aside {
  margin: 66px 0 40px 48px;
  width: var(--publisher-menu-width);
  background: var(--color-gray00);
}

.main {
  padding: 86px 48px 60px 0;
  flex-grow: 1;
}

@media screen and (min-width: 1600px) {
  .main {
    width: 930px;
  }
}

@media screen and (min-width: 1920px) {
  .main {
    width: 1250px;
  }
}

.sidebar {
  width: var(--publish-sidebar-width);
  min-width: var(--publish-sidebar-width);
  padding: 60px 30px 40px;
  background-color: var(--color-gray02);
  height: 100%;
}

.field,
.permissions {
  background-color: var(--color-gray05);
  width: 100%;
  align-items: flex-start;
}

.field > div:first-child {
  padding: 25px;
  height: 100%;
}

.field > div:last-child {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  padding: 14px 23px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.field > textarea:focus {
  border: 1px solid var(--color-gray90);
  box-sizing: border-box;
  border-radius: 6px;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
}

.sectionTitle {
  width: 110px;
  text-align: left;
}

.inputWrapper {
  width: 100%;
}

.textInput {
  height: 40px;
}

.textInput:focus {
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 6px;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
}

.permissionsOption {
  composes: flex-column from global;
  height: 80px;
  flex: 1;
  margin: 0 1px 2px 1px;
  background-color: var(--color-gray05);
  justify-content: space-between;
  padding: 27px 0 18px;
  align-items: center;
  border-color: transparent;
}

.permissions > div:last-child {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
  display: flex;
}

.permissionsOptions {
  padding-top: 14px;
}

.permissionsButton {
  text-align: left;
  line-height: 18px;
  cursor: pointer;
  background-color: var(--color-gray00);
  color: var(--color-gray90);
  border: 1px solid var(--color-gray30);
  border-radius: 6px;
  margin-right: 3px;
  margin-bottom: 3px;
  padding: 7px 13px;
}

.activePermission {
  border: 2px solid var(--color-brandTintDark);
}

.thumbnail {
  composes: kai-flex-row from global;
  justify-content: space-between;
}

.thumbnailPreview {
  height: 80px;
  width: 80px;
  background-size: contain;
}

.thumbnail > div:last-child {
  background-size: contain;
  background-repeat: no-repeat;
}

.textarea {
  font-size: 14px;
  line-height: 155%;
}

.textarea:focus {
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 6px;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
}

.uploaderTooltip {
  font-size: 12px;
  line-height: 15.6px;
  color: var(--color-gray30);
  padding-top: 2px;
}

.dropZone {
  height: 32px;
  width: 153px;
  padding: 7.5px 13px;
  line-height: 16px;
}

.buttonsContainer {
  composes: kai-flex-row from global;
}
