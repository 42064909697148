.disableSetting button,
.disableSetting label {
  pointer-events: none;
  color: var(--color-gray70);
}

.disableSetting .button {
  background-color: var(--color-gray70);
}

.disableCheckbox {
  opacity: 0.6;
}

.disabledSetting {
  opacity: 0.6;
}

.tooltip {
  margin-left: -5px;
}

.disableSetting .fieldIcon svg path {
  stroke: var(--color-gray70);
}

.radio-group {
  composes: kai-flex-row from global;
  flex-wrap: wrap;
  min-height: 39px;
  padding: 0 15px;
}

.radio-button {
  border: 0;
  cursor: pointer;
  height: 37px;
  min-width: 50px;
  font-size: 13px;
  background-color: var(--color-gray10);
  margin-right: 2px;
  margin-bottom: 2px;
}

.radio-button:disabled {
  opacity: 0.6;
}

.radio-button:focus {
  outline: 0;
}

.radio-button.selected {
  border: 2px solid var(--color-brandTintDark);
}

.fieldset {
  composes: kai-flex-row from global;
  align-items: flex-start;
}

.fieldset + .fieldset {
  margin-top: 15px;
}

.fieldset h4 {
  margin-bottom: 5px;
  width: 140px;
  font-size: 13px;
  font-weight: 700;
  flex-shrink: 0;
}

.fieldsetColumn {
  composes: flex-column from global;
  position: relative;
}

.fieldsetColumn > h4 {
  font-size: 14px;
  color: var(--color-gray50);
  font-weight: 400;
  padding-bottom: 15px;
}

.fieldsetColumnTitle {
  composes: kai-flex-row from global;
}

.fieldset > div {
  flex: 1;
}

.infoIconContainer {
  height: 20px;
  width: 20px;
  padding-left: 5px;
  cursor: pointer;
  display: inline-block;
}

.priorityDescription {
  color: var(--color-gray80);
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin: 0;
}

.priorityExamples {
  color: var(--color-gray70);
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 4px 0 0 0;
}

.iconFieldWrapper {
  composes: flex-column from global;
  height: 130px;
  flex: 1;
  margin: 0 1px 2px 1px;
  background-color: var(--color-gray05);
  justify-content: space-between;
  padding: 27px 0 18px;
  align-items: center;
}

.iconFieldWrapper button {
  outline: none;
  border: none;
  background-color: transparent;
}

.iconFieldWrapper:last-child {
  margin: 0 0 2px 1px;
}

.fieldIcon {
  composes: flex-column from global;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
}

.fieldIcon > span > svg {
  height: 22px;
}

.overrideButton > div {
  padding-left: 11px;
}

.authorAlias {
  padding: 30px;
  background-color: var(--color-gray10);
}

.flipSwitchFieldWrapper {
  composes: kai-flex-row from global;
}

.scheduleBlock {
  flex-basis: 50%;
  position: relative;
}

.scheduleBlock.disabled * {
  cursor: default;
}

.publishingTimeDisclaimer {
  padding: 15px;
  font-size: 12px;
  position: absolute;
  background-color: var(--color-gray00);
  composes: has-box-shadow from global;
  top: 30px;
  left: 35px;
  z-index: var(--z-index-publisher-settings-publish-time-disclaimer);
  width: 250px;
}

.overrideButton {
  background-color: var(--color-gray80);
  padding: 7px 15px;
}

.buttonLabel {
  align-self: center;
}

.channels {
  composes: flex-column from global;
}

.channelsLabel {
  composes: kai-flex-row from global;
  margin-bottom: 8px;
}

.channelsWrapper {
  font-size: 13px;
}

.fieldLabel {
  min-height: 120px;
}

.prioritiesContainer > div {
  width: 25%;
  margin-left: 1px;
  margin-right: 1px;
}

.channelsWrapper > label {
  display: flex;
  padding-bottom: 7px;
}

.channelsWrapper > label > div {
  width: 21px;
  height: 21px;
}

.channelsCheckboxes {
  padding: 10px 0 0 20px;
}

.selectorContainer > div > div {
  border: 1px solid var(--color-gray10);
  font-size: 14px;
  color: var(--color-gray90);
  width: auto;
}

.channelsLabel > div:last-child {
  color: var(--color-gray90);
  margin-left: 8px;
}

.channelsLabel > div:first-child {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.laterLabelWrapper {
  composes: kai-flex-row from global;
  font-size: 12px;
}

.laterLabelWrapper > span {
  padding-right: 6px;
}

.noCampaignDurationDisclaimer {
  padding: 15px;
  font-size: 12px;
  position: absolute;
  background-color: var(--color-gray00);
  composes: has-box-shadow from global;
  top: 85px;
  left: 75px;
  z-index: var(--z-index-publisher-settings-no-campaign-duration-disclaimer);
  width: 250px;
}

.description {
  color: var(--color-gray70);
  font-size: 12px;
  background-color: var(--color-gray05);
  padding-top: 10px;
  width: 100%;
  composes: kai-flex-row from global;
  justify-content: space-around;
  align-items: center;
}

.topicSelect {
  composes: flex-column from global;
}

.topicSelect > div:last-child {
  width: 100%;
}

.channelsWrapper > label > span:last-child {
  padding-left: 7px;
}

.iconFieldWrapper > div > span:last-child {
  font-size: 12px;
  font-weight: 400;
}

.selectorContainer {
  width: 70%;
}

.contentRulesSection > div:last-child > div > div {
  padding: 0;
  background-color: var(--color-gray00);
}

.archiveLabel {
  composes: kai-flex-row from global;
  max-width: 270px;
  padding-bottom: 7px;
  font-size: 14px;
  font-weight: 700;
}

.archiveOption {
  padding-left: 7px;
}

.datePickerContainer {
  composes: flex-column from global;
  font-size: 14px;
  padding-left: 26px;
}

.datePickerButton {
  height: 32px;
  min-width: 137px;
  max-width: 180px;
  border: var(--color-gray30);
  border-radius: 6px;
  background-color: var(--color-gray00);
  composes: kai-flex-row from global;
  padding: 0 16px;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 600;
}

.contentRules {
  margin-top: 25px;
}

.help {
  color: var(--color-gray90);
}

.priorityBlock {
  font-size: 16px;
  font-weight: 600;
  height: 136px;
  composes: flex-column from global;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  width: 100%;
  font-family: 'Inter', sans-serif;
}

.fieldArchive {
  margin: 10px 32px;
}

@media screen and (min-width: 1367px) {
  .priorityBlock {
    font-size: 18px;
  }
}

@media screen and (min-width: 1600px) {
  .priorityBlock {
    font-size: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .prioritiesContainer > div {
    width: 100%;
  }
}

.hoverTooltip {
  animation: fadeIn linear 1s;
  -webkit-animation: fadein linear 1s;
  -moz-animation: fadein linear 1s;
  -o-animation: fadein linear 1s;
  -ms-animation: fadein linear 1s;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
