.fr-view table.fr-none-borders td,
.fr-view table.fr-none-borders th {
  border-style: none;
}

.fr-view img.fluid-image.fr-dib {
  margin: 5px auto;
}

.fr-view img.fluid-image.fr-dib.fr-fil {
  margin: 5px auto 5px 0;
}

.fr-view img.fluid-image.fr-dib.fr-fir {
  margin: 5px 0 5px auto;
}

.fr-view img.fluid-image.fr-dii {
  margin: 5px 15px;
}

.fr-view img.fluid-image.fr-dii.fr-fil {
  margin: 5px 15px 5px 0;
}

.fr-view img.fluid-image.fr-dii.fr-fir {
  margin: 5px 0 5px 15px;
}

.fr-view h1,
.fr-view h2,
.fr-view h3,
.fr-view h4,
.fr-view h5,
.fr-view h6 {
  font-weight: 700;
}

.fr-element table td.fr-selected-cell,
.fr-element table th.fr-selected-cell {
  /* stylelint-disable-next-line declaration-no-important */
  border: 1px double #0098f7 !important;
}
