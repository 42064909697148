.form {
  width: 500px;
  padding-top: 15px;
}

.buttonsWrapper {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 5px;
}

.cancelWrapper {
  margin-left: 16px;
}

.button {
  margin-left: 10px;
}

.spinner {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
