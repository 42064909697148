.mainLayout {
  display: flex;
}

.container {
  align-items: flex-start;
  clear: both;
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
}

.main {
  box-sizing: border-box;
  padding-right: 50px;
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.pageActionWrapper {
  display: flex;
  flex-direction: row-reverse;
  min-height: 54px;
  padding: 6px 5px;
}

.addTopicIcon {
  margin: 4px 13px 0 0;
}

.searchBar {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 40px;
  margin-right: 6px;
  padding: 1px 11px;
  width: 376px;
}

.searchBar:focus-within,
.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.actionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
}

.basic {
  width: 38%;
  height: 80px;
  padding: 0 30px 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lastCol,
.statCol {
  display: flex;
  flex-direction: column;
  height: 80px;
}

.statCol {
  width: 27%;
}

.lastCol {
  width: 8%;
}

.statHeader {
  font-size: 9px;
  text-transform: uppercase;
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-weight: 600;
}

.stats {
  composes: kai-line-clamp-4 from global;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-gray90);
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  width: 320px;
  max-width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
}

.title:hover {
  content: attr(.title);
}

.description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  margin: 4px 0;
}

.bannerWrapper {
  padding-right: 50px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.filters {
  display: flex;
  width: 100%;
}

.textInput,
.textInputError {
  width: 100%;
}

.textInputError {
  outline: 1px solid #f74857;
}

.textInputError:focus {
  border: 1.5px solid #f74857;
}

.noBottomLine {
  border-bottom: none;
}

.formFieldInfo,
.formFieldExample,
.campaignFormLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #676570;
  margin: 12px 0;
  display: block;
}

.formFieldErrorMessage {
  color: #f74857;
  font-weight: 600;
  font-size: 14px;
  margin: 12px 0;
  display: block;
}

.campaignFormWrap {
  margin-top: 32px;
  margin-bottom: 60px;
}

.campaignFormButton > button {
  margin: 0 auto;
  display: block;
}

.campaignFormLabel {
  display: block;
  text-align: center;
  margin: 24px auto;
}

.formFieldExample {
  font-style: italic;
}

.selectedCampaignIcon {
  width: 60px;
  height: 60px;
}

.selectedCampaignInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.selectedCampaignContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selectedCampaignButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
