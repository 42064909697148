.Priority {
  background-color: var(--color-gray05);
  border-color: var(--color-gray05);
  color: #000;
}

.FieldSet {
  padding: 0 0 16px;
}

.FieldSet input {
  height: 40px;
}

.InputPrefix {
  margin: auto 10px auto 0;
  white-space: nowrap;
}

.ctaErrorMessageDisabled {
  opacity: 0.6;
}

.ctaErrorMessage {
  color: var(--color-redFull);
  font-size: var(--font-size-caption);
}

.warningSign {
  font-size: 14px;
  float: left;
  margin-right: 8px;
}
