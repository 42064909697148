/* renamed to avoid name collisions with tailwind */
.kai-pl-20 {
  padding-left: 20px;
}

.kai-p-24 {
  padding: 24px;
}

.kai-mt-4 {
  margin-top: 4px;
}
