.Container {
  background: var(--color-gray00);
  padding-bottom: 32px;
}

.Content {
  max-width: 824px;
  margin: 0 auto;
}

.VerticallySpaced {
  display: flex;
  flex-direction: column;
}

.HorizontallySpaced {
  display: flex;
  flex-direction: row;
}

.Flex {
  display: flex;
}

.Gap32 {
  gap: 32px;
}

.Gap16 {
  gap: 16px;
}

.Gap8 {
  gap: 8px;
}

.HalfWidth {
  width: 50%;
}

.VerticallyCentered {
  align-items: center;
}

.WarningBanner {
  justify-content: space-between;
  border-left-color: #fa8d15;
  border-left-width: 2px;
  border-left-style: solid;
  background-color: #fef9e6;
  border-radius: 4px;
  padding: 16px;
}

.WarningBannerContent {
  font-size: 14px;
  color: var(--color-gray70);
}
