.fieldset {
  margin-bottom: 30px;
}

.fieldset .field > div {
  border: none;
}

.fieldset .field > div:last-child {
  padding-right: 24px;
}

.fieldset .field label {
  font-size: 14px;
  font-weight: 600;
}

.image {
  max-width: 100%;
  display: block;
}

.actions {
  composes: kai-flex-row flex-spread flex-centered from global;
}

.button {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--color-gray90);
  font-weight: 700;
  padding: 5px 15px;
  border-radius: 6px;
}

.change .button {
  background-color: var(--color-gray10);
}

.remove .button {
  /* margin-right: -12px; */
}

.squaredLeft {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.squaredRight {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.externalInline {
  display: flex;
  flex: 1;
  padding-left: 20px;
}
