.theme {
  composes: publish-main-content from global;
  display: flex;
}

.menu {
  width: 56px;
  align-self: flex-start;
  justify-content: flex-start;
  height: 100%;
  background-color: var(--color-gray00);
  padding: 12px 8px;
  border-right: 1px solid var(--color-gray05);
}

.sectionContainer {
  margin-bottom: 20px;
}

.sidebarMarginOld {
  margin-top: 70px;
}

.sidebarMargin {
  margin-top: 16px;
}

.YaToolTipWrapper {
  color: var(--color-gray90);
  position: relative;
}

.YaToolTipWrapper a {
  color: inherit;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  padding: 5px;
}

.YaToolTip {
  position: absolute;
  left: 100%;
  top: 0;
  background-color: rgba(var(--color-gray90-rgb), 0.8);
  color: var(--color-gray02);
  z-index: var(--z-index-shared-overlay-modal);
  border-radius: 2px;
  padding: 0 16px;
  line-height: 36px;
  white-space: nowrap;
}

.menu > a {
  color: var(--color-gray90);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.active {
  background-color: var(--color-gray05);
  border-radius: 4px;
}

.theme > aside {
  width: 261px;
  background-color: var(--color-gray05);
  padding: 66px 32px 0 48px;
}

.theme > main {
  width: 100%;
}

.theme > aside > h1 {
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  font-size: 42px;
}

.theme > aside > ul {
  padding-top: 40px;
  list-style-type: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-left: 5px;
}

.theme > aside > ul > li {
  padding-bottom: 24px;
}

.theme > aside > ul > li > a {
  color: var(--color-gray90);
  text-decoration: none;
}

.container {
  composes: kai-flex-row from global;
  height: calc(100vh - 75px);
  overflow: hidden;
}

.compactTheme {
  height: calc(100vh);
  margin: 0;
  padding-top: 48px;
  padding-bottom: 64px;
}

.compactTheme > main {
  flex: 1;
}

.compactTheme .container {
  height: calc(100vh - 112px);
}

.sidebar {
  background: var(--color-gray00);
  height: 100%;
  overflow-y: auto;
  padding: 0 16px;
  flex-shrink: 0;
}

@media screen and (min-width: 1281px) {
  .sidebar {
    width: 320px;
  }
}

@media screen and (max-width: 1280px) {
  .sidebar {
    width: 280px;
  }
}

.styleSidebar {
  padding: 0;

  /* Magic to fix the scrollbar appearing over the color picker in Safari */
  -webkit-transform: translate3d(0, 0, 0);
}

.sidebar.nopadding {
  padding: 0;
}

.title {
  font-weight: 600;
  font-size: 18px;
}

.theme aside .title {
  font-size: 2.3em;
  font-weight: bold;
  padding-bottom: 40px;
}

.nav {
  padding: 0 3px;
  composes: nav from '../publisher.module.css';
}

.nav :global .-NavLink- {
  display: flex;
  align-items: center;
  padding-bottom: unset;
}

.nav :global .-NavLink-:not(:first-child) {
  margin-top: 23px;
}

.nav :global .-Active- {
  border-right: 4px solid var(--color-brandTintDark);
}

@media screen and (max-width: 1280px) {
  .nav :global .-NavLink- {
    justify-content: center;
  }

  .nav :global .-NavLink- svg {
    box-sizing: border-box;
    padding-right: unset;
  }

  .nav span.text {
    display: none;
  }

  .theme :global .page-header {
    display: none;
  }

  .nav :global .-Active- {
    border-right: 3px solid var(--color-brandTintDark);
  }

  .theme > aside {
    padding-left: unset;
    padding-right: unset;
    padding-top: 54px;
    width: 96px;
    max-width: 96px;
    min-width: 96px;
  }
}

.sidebar.wide {
  width: 400px;
}

.container .main {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 90px 70px 0;
  text-align: center;
  transition: padding-top 0.5s ease;
}

.container .main > div {
  width: 100%;
  margin: auto;
  text-align: left;
}

.linkCopied {
  padding: 3.5px 12px;
  font-size: 1rem;
  color: var(--color-gray80);
  composes: kai-flex-row from global;
  justify-content: center;
  align-items: center;
}

.linkCopied > svg {
  color: var(--color-greenFull);
}

.YaToolTipWrapper a svg {
  fill: current-color;
  height: 18px;
  width: 18px;
  margin: 9px 11px;
}

.menu > a > svg {
  height: 18px;
  width: 18px;
  margin: 9px 11px;
}

.nav :global .-NavLink- svg {
  box-sizing: content-box;
  padding-right: 16px;
}

.shareOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-right: 10px;
}

.previewNote {
  color: var(--color-gray70);
  font-size: 12px;
  font-weight: 500;
}

.previewNote span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.YaToolTipWrapper a:hover {
  border-radius: 4px;
  background-color: var(--color-gray05);
}

.sendTestCampaign {
  position: absolute;
  bottom: 80px;
  width: 248px;
}
