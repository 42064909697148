.panel {
  composes: kai-flex-row flex-centered from global;
  color: var(--color-gray50);
  border: solid 1px var(--color-gray30);
  border-radius: 1px;
  margin-left: 0;
  margin-right: 0;
  cursor: text;
}

.panel:focus-within {
  border-color: var(--color-brandTintDark);
}

.label {
  composes: kai-flex-row flex-centered from global;
  flex: 1;
}

.input {
  composes: kai-text-sm from global;
  flex: 1;
  color: var(--color-gray80);
  border: none;
  outline: none;
}

.button {
  border: none;
  background: none;
  padding: 2px;
  display: block;
  opacity: 0.5;
}

.button:hover {
  opacity: 1;
}

.button:focus {
  outline-color: var(--color-brandTintDark);
}

.button:disabled {
  opacity: 0;
}

.panel.compact {
  padding: 3px 8px;
}
