.section {
  margin-bottom: 20px;
}

.itemTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.itemDesc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 14px;
  font-weight: 400;
  color: #676570;
}

.itemValue {
  font-weight: bold;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  text-anchor: middle;
}

div.row {
  margin-top: 20px;
}

.input {
  width: 100%;
}
