.Item {
  font-size: 13px;
  position: relative;
}

.Item svg {
  width: 10px;
  position: absolute;
  top: 4px;
  left: -1px;
}

.LetterCount {
  font-size: 12px;
  text-align: right;
  margin-top: 5px;
}

.InputPlaceholder {
  opacity: 0.5;
}

.ErrorInput {
  border: 1px solid var(--color-redFull);
  color: var(--color-redFull);
}
