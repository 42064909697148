.dropdown-align-left {
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0;
}

.dropdown-align-right {
  padding-top: 10px;
  right: 0;
}

.dropdown-align-center {
  padding-top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.dropup-align-left {
  padding-bottom: 10px;
  left: 0;
  bottom: 100%;
}

.dropup-align-right {
  padding-bottom: 10px;
  right: 0;
  bottom: 100%;
}

.tooltip {
  background-color: rgba(17, 18, 19, 0.75);
  color: var(--color-gray00);
}

.dropdown {
  box-shadow: 0 21.53px 25.84px rgba(0, 0, 0, 0.06), 0 8.70566px 23.2151px rgba(0, 0, 0, 0.08), 0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
  background-color: var(--color-gray00);
}

.shadow-heavy {
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  background-color: var(--color-gray00);
}
