.dualRing {
  display: inline-block;
  width: 71px;
  height: 71px;
  animation: dualRing 0.5s linear infinite;
  position: relative;
}

.dualRing > svg:last-child {
  position: absolute;
  top: 35px;
  left: 35px;
}

@keyframes dualRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.xSmallSize {
  height: 18px;
  width: 18px;
}

.xSmallSize > svg:first-child {
  height: 18px;
  width: 18px;
}

.xSmallSize > svg:last-child {
  height: 9px;
  width: 9px;
  top: 9px;
  left: 9px;
}

.smallSize {
  height: 20px;
  width: 20px;
}

.smallSize > svg:first-child {
  height: 20px;
  width: 20px;
}

.smallSize > svg:last-child {
  height: 10px;
  width: 10px;
  top: 10px;
  left: 10px;
}

.mediumSize {
  height: 24px;
  width: 24px;
  margin-left: auto;
}

.mediumSize > svg:first-child {
  height: 24px;
  width: 24px;
}

.mediumSize > svg:last-child {
  height: 12px;
  width: 12px;
  top: 12px;
  left: 12px;
}

.largeSize {
  height: 32px;
  width: 32px;
  margin-left: auto;
}

.largeSize > svg:first-child {
  height: 32px;
  width: 32px;
}

.largeSize > svg:last-child {
  height: 16px;
  width: 16px;
  top: 16px;
  left: 16px;
}
