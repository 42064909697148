.insights {
  color: var(--color-gray90);
  padding-top: 24px;
}

.row {
  composes: kai-flex-row from global;
  width: 100%;
}

.hintRow {
  align-items: start;
  margin-bottom: 12px;
}

.label {
  margin-right: 10px;
  font-size: 14px;
  white-space: nowrap;
  font-weight: bold;
  line-height: 135%;
  width: 30%;
}

.hintText {
  width: 70%;
}

.day {
  font-size: 14px;
  width: 20%;
  white-space: nowrap;
  line-height: 28px;
}

.channelsGrid {
  display: grid;
  grid-template-columns: 1fr 3fr auto;
  align-items: center;
  column-gap: 10px;
}

.value {
  width: 10%;
  font-weight: 700;
  font-size: 14px;
}

.progressBar {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  width: 70% !important;
  padding-left: 45px;
  padding-right: 24px;
}

.progressBar > div:first-child {
  height: 15px;
  background-color: var(--color-gray90);
}

.insights th {
  vertical-align: top;
  font-size: 16px;
  text-align: right;
  white-space: nowrap;
  padding-right: 10px;
}

.insights td,
.insights th {
  vertical-align: top;
  padding-bottom: 10px;
}

.insights p {
  list-style: none;
  font-size: 14px;
  line-height: 140%;
  margin: 0 0 8px;
}

.highlighted {
  color: var(--color-brandTintDark);
}

.tooltip {
  composes: tooltip from global;
  margin-top: -10px;
  padding: 16px;
  width: auto;
}

.facetsTooltip {
  composes: tooltip from global;
  margin-top: -10px;
  padding: 12px;
  width: 230px;
}

.tooltipTitle {
  font-size: 16px;
  font-weight: 600;
}

.tooltipDescription {
  font-size: 12px;
  padding-top: 6px;
}

.barsContainer {
  composes: kai-flex-row from global;
  justify-content: space-around;
  margin-top: 27px;
  height: 115px;
  margin-bottom: 25px;
  align-items: flex-start;
}

.bar {
  height: 49px;
  width: 25%;
}

.barCount {
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 34.5px;
  padding-top: 7px;
  text-align: center;
}

.barName {
  padding-top: 4px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.header {
  composes: kai-flex-row from global;
}

.targetRow {
  composes: kai-flex-row from global;
  justify-content: space-between;
}

.total {
  font-size: 14px;
  line-height: 28px;
  color: var(--color-gray90);
}

.total > span {
  font-weight: 700;
}

.facet {
  margin-bottom: 36px;
}

.facet:last-child {
  margin-bottom: 12px;
}

.facetWrapper {
  composes: flex-column from global;
}

.facetName {
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  color: var(--color-gray50);
  text-transform: uppercase;
  margin-bottom: 8px;
}

.facetRow {
  composes: kai-flex-row from global;
  justify-content: space-between;
  line-height: 130%;
}

.facetRow > div:first-child {
  font-size: 14px;
}

.facetRow > div:last-child {
  font-size: 16px;
  font-weight: 600;
}

.note {
  margin-top: 24px;
  font-size: 12px;
  font-weight: 400;
}

/* new styles for redesign */

div:global(.deliver-redesign) .insights {
  border-bottom: 1px solid var(--color-gray10);
  padding-bottom: 15px;
  margin-bottom: 33px;
  padding-top: 0;
}

div:global(.deliver-redesign) .day {
  font-size: 12px;
}

div:global(.deliver-redesign) .value {
  font-size: 12px;
}

div:global(.deliver-redesign) .progressBar {
  padding-left: 23px;
}

div:global(.deliver-redesign) .hintText {
  font-size: 13px;
}
