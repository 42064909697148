.Button {
  width: 100%;
}

.ButtonText {
  margin-left: 10px;
}

.processing .Button {
  margin-left: -5px;
}
