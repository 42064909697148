.input {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray30);
  padding: 9px 16px;
  font-size: 14px;
  color: var(--color-gray90);
  border-radius: 4px;
}

.input:focus {
  border: 1px solid var(--color-brandTintDark);

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
}

.input:disabled {
  background-color: var(--color-gray02);
  border: 1px solid var(--color-gray30);
  color: var(--color-gray50);
}

.error,
.error:focus {
  border: 1px solid var(--color-redFull);
  color: var(--color-redFull);
}

.input::placeholder {
  color: var(--color-gray50);
}

.compact {
  height: 38px;
}
