body {
  font-family: Inter, sans-serif;
}

div,
span,
button,
a,
p,
pre,
sub,
sup,
strong,
li,
i,
em,
u,
s,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
}

/*
Ellipsis-overflow and line-clamping are two ways to get a trailing "...".

The differences are
  `.ellipsis-overflow` is better supported by browsers
  `.line-clamp-N` hides lines but doesn't show "..." in IE11
  `.ellipsis-overflow` requires a width, default is 100%
  `.line-clamp-N` requires a height, default is a best guess
*/

.ellipsis-overflow {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* these appear to be copy-pastes of tailwind's line-clamp classes, but renaming them anyway to avoid changes here causing hard to debug collisions with tailwind. */
.kai-line-clamp,
.kai-line-clamp-1 {
  display: -webkit-box;
  overflow: hidden;
  height: 1.3em;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.kai-line-clamp-2 {
  display: -webkit-box;
  overflow: hidden;
  height: 2.5em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kai-line-clamp-3 {
  display: -webkit-box;
  overflow: hidden;
  height: 3.7em;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.kai-line-clamp-4 {
  display: -webkit-box;
  overflow: hidden;
  height: 4.1em;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* Some common font sizes with their normal weights
  (renaming to avoid tailwind collisions) */

.kai-text-sm {
  font-size: 12px;
}

.kai-text-md {
  font-size: 16px;
}

.kai-text-lg {
  font-size: 19px;
  font-weight: normal;
}

/*
  Typography
  https://www.figma.com/file/M7wSClgpb8SBCkYBGmasGr/WIP-Studio?node-id=8960%3A0
 */

.page-header {
  font-family: Gilroy, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 115%;
  letter-spacing: -0.02em;
  color: var(--color-gray90);
  cursor: default;
}

@media screen and (max-width: 1367px) {
  .page-header {
    font-size: 32px;
  }
}

.modal-header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 115%;
  letter-spacing: -0.02em;
  color: var(--color-gray90);
}

.sidebar-header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  text-transform: uppercase;
  color: var(--color-gray90);
}

.section-header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: var(--color-gray90);
}

.link {
  text-decoration: underline;
  color: var(--color-brandTintDark);
}

.link:hover {
  text-decoration: none;
  color: var(--color-gray50);
}

.nowrap {
  white-space: nowrap;
}

.fr-popup.fr-active {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 1001 !important;
}
