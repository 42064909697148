.Button {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #362574;
  color: #362574;
}

.Button:hover {
  border: 1px solid #362574;
  background-color: var(--color-gray05);
}

.SchedulePopup {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 512px;
}

.Error {
  color: var(--color-brandSolar);
}

.ButtonError {
  border-color: var(--color-brandSolar);
}

.Icon > span {
  font-size: 16pt;
  margin: 0 5px;
  line-height: 1.4;
}

.Label {
  margin-right: 5px;
}
