.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  min-height: 134px;
  z-index: 1;
  background: var(--color-gray00);
}

.search {
  display: flex;
  width: 564px;
  height: 35px;
  margin: 0 6px;
}

.searchIcon {
  width: 30px;
  height: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.searchInput {
  padding-left: 30px;
  width: 362px;
  background: var(--color-gray05);
  color: var(--color-gray50);
  font-weight: 600;
  font-size: 12px;
}

.searchAppliedFilters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  width: 155px;
  background: var(--color-gray05);
  color: var(--color-gray50);
  border-radius: 6px;
  font-size: 12px;
  font-weight: normal;
}

.searchAllFilters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  width: 35px;
  background: var(--color-gray05);
  border-radius: 6px;
}

.selection {
  display: flex;
  justify-content: space-between;
  margin: 0 6px;
}

.selected {
  background-color: transparent;
  border: none;
}

.quickFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 219px;
  height: 30px;
  background: var(--color-gray05);
  border-radius: 6px;
}

.quickFilters button {
  padding: 0;
  cursor: pointer;
  outline: none;
  flex-grow: 1;
  background: transparent;
  border: 1px solid transparent;
  height: 100%;
  border-radius: 6px;
}

.quickFilters button.active {
  background: var(--color-gray00);
  border: 1px solid var(--color-gray10);
}
