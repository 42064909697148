.frOptions::after {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #333;
  right: 2px;
  top: 18px;
  content: "";
}

.frOptions.frSelected::after {
  border-top: 0;
  border-bottom: 4px solid #333;
}
