.Container {
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.NoAbout {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  align-items: center;
}

.NoAbout__Texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}

.NoAbout__Texts__Description {
  color: var(--color-gray60);
}

.AddAboutButton {
  position: relative;
  z-index: 1;
}

.EditButton::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.EditButton {
  width: 100%;
}
