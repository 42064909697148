.filterLandingHeader {
  font-family: Inter, sans-serif, Arial;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.filterLandingBody {
  padding-bottom: 24px;
}

.filterLandingText {
  font-family: Inter, sans-serif, Arial;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
