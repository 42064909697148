.form div[class*='fieldset_labelContainer'] {
  color: var(--color-gray70);
  display: flex;
  align-items: center;
  padding-top: 0;
}

.form div[class*='fieldset_input'] {
  padding: 24px 32px;
  color: var(--color-gray90);
}

.textField {
  width: 100%;
}
