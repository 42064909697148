.clickable,
.editIcon {
  cursor: pointer;
}

.editButton {
  margin-left: 8px;
  height: 16px;
  width: 16px;
  padding: 0;
  color: var(--color-blueFull);
}

.editButtonMainHeading {
  padding: 0 8px;
  color: var(--color-blueFull);
}

.inlineButton {
  margin-left: 9px;
}
