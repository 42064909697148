.textInput {
  width: 100%;
}

.error {
  display: inline-block;
  color: var(--color-redFullAlt);
  font-size: var(--font-size-caption);
  margin-top: 8px;
}

.SectionsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.AdministrativeAction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
}

.RootFolderSelectionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.RootFolderActionsContainer {
  display: flex;
  gap: 16px;
}

.RootFolderLabel {
  composes: Body from 'DesignSystem/Typography/typography.module.css';
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
