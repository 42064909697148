.Dropdown {
  width: 320px;
  max-width: 100%;
}

.InputContainer {
  width: 320px;
  max-width: 100%;
  position: relative;
}

.Input {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.Icon {
  position: absolute;
  margin-top: -8px;
  top: 50%;
  width: 16px;
  height: 16px;
  font-size: 12px;
  padding: 2px;
  pointer-events: none;
  color: var(--color-gray90);
}

.SearchIcon {
  composes: Icon;
  left: 16px;
}

.ArrowIcon {
  composes: Icon;
  right: 16px;
}
