.calendarHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 var(--program-main-content-horizontal-padding) 65px;
}

.calendarTitle {
  font-family: Gilroy, sans-serif;
  color: var(--color-gray90);
  letter-spacing: -0.02em;
  font-size: 42px;
}

.calendarControls {
  display: flex;
  align-self: flex-end;
  height: 53px;
}

.datePickerWrapper {
  composes: kai-flex-row from global;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}

.datePickerWrapper i {
  cursor: pointer;
  font-size: 12px;
}

.calendarContainer {
  position: absolute;
  right: 0;
  top: 75px;
  background-color: var(--color-gray05);
  z-index: 100500;
  border: 1px solid var(--color-gray10);
}

/* same as calendar container above, but for DesignSystem */

.PickerPopup {
  position: absolute;
  right: 90px;
  top: 120px;
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.calendarWrapper {
  position: relative;
  margin: 0;
  width: 100%;
}

.searchBarPanel {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 35px;
  margin-right: 6px;
  padding: 0 11px;
  width: 376px;
}

.searchBarPanel:focus-within {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBarPanel:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.viewSelector {
  composes: kai-flex-row from global;
}

.viewSelector > div {
  padding: 0 15px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
}

.viewSelector > div:first-child {
  margin-left: 17px;
}

.viewSelector > div:last-child {
  margin-right: 17px;
}

.datePickerWrapper div:first-child {
  margin-right: 33px;
}

.datePickerWrapper div:last-child {
  margin-left: 33px;
}

.selectedView {
  background-color: var(--color-gray05);
}

.searchBar {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 40px;
  margin-right: 6px;
  padding: 1px 11px;
  width: 376px;
}

.searchBar:focus-within {
  background-color: #fff;
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
}

.arrow:last-child {
  justify-content: flex-end;
  width: 25px;
}

.pageTitle {
  font-size: 42px;
}

.CalendarData {
  height: 100%;
  overflow: hidden;
  margin-right: -32px;
}
