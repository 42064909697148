.mainLayout {
  display: flex;
}

.titleBar {
  padding: 0 var(--program-main-content-horizontal-padding);
  padding-right: 0;
}

.pageTitle {
  font-size: 42px;
}

.titleActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 14px;
}

.container {
  align-items: flex-start;
  clear: both;
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
}

.main {
  box-sizing: border-box;
  padding-right: 50px;
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.loading {
  position: absolute;
  top: -48px;
}

.nav {
  min-width: 249px;
}

.actionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
}

.searchBar {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 40px;
  margin-right: 6px;
  padding: 1px 11px;
  width: 376px;
}

.searchBar:focus-within,
.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.moreButtonMenu {
  composes: dropdown from global;
  border-radius: 8px;
  padding: 5px;
}

.filtersMenuItem {
  border-radius: 6px;
  font-size: 14px;
  padding: 5px 15px 5px 10px;
}

.filtersMenuItem:hover {
  background-color: var(--color-gray02);
  cursor: pointer;
}

.filters {
  display: flex;
  width: 100%;
}

.filterTrigger {
  composes: kai-flex-row from global;
  padding: 10px 14px;
  font-size: 12px;
  line-height: 15px;
  background: var(--color-gray05);
  color: var(--color-gray80);
  border-radius: 6px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 6px;
  max-height: 36px;
}

.filterTrigger:hover {
  background: var(--color-gray10);
  color: var(--color-gray80);
}

.closeButton {
  composes: kai-flex-row from global;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 10px;
  height: 18px;
  width: 18px;
  padding: 1px;
}

.closeButton:hover {
  background: var(--color-gray30);
}

.pageActionWrapper {
  display: flex;
  flex-direction: row-reverse;
  min-height: 54px;
  padding: 6px 5px;
}

.pageActionWrapper button {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
}

.addUserBtn {
  background-color: var(--color-gray10);
  color: var(--color-gray90);
}

.importUsers {
  background-color: var(--color-gray05);
  color: var(--color-brandTintDark);
}

.addAudienceIcon {
  margin: 4px 13px 0 0;
}

.col1 {
  width: 300px;
  height: 80px;
  padding: 0 30px 0 15px;
}

.statColWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
}

.statCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.statCol:last-child {
  display: none;
}

.statHeader {
  font-size: 9px;
  font-weight: 600;
  color: var(--color-gray90);
  opacity: 0.7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.stat {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray90);
  margin-top: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  width: 100px;
  word-wrap: break-word;
}

.statFooter {
  composes: kai-text-sm from global;
  color: var(--color-gray90);
  opacity: 0.7;
  margin-top: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  width: 320px;
  height: 20px;
  font-weight: 600;
  color: var(--color-gray80);
}

.title:hover {
  content: attr(.title);
}

.description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  color: var(--color-gray70);
  margin: 4px 0;
}

.attributes {
  composes: kai-text-sm kai-line-clamp-3 from global;
  color: var(--color-gray70);
  margin: 4px 0;
}

.attributes > ul {
  display: inline-block;
  padding: 0;
  list-style-position: inside;
}

.attributes > ul > li {
  display: inline;
}

.attributes > ul > li:not(:last-child)::after {
  content: '\2022';
  letter-spacing: 1em;
}

.attributes > ul > li > span {
  margin-right: 10px;
}

.restrictionsExpandedDropdown {
  left: 250px;
  top: -10px;
}

.restrictionsDropdownContainer {
  composes: dropdown from global;
  min-width: 250px;
  max-width: 250px;
  border-radius: 8px;
}

.restrictionsTitle > p {
  padding-right: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.menuFooter {
  font-size: small;
  padding: 10px;
  color: var(--color-gray50);
  margin: -8px;
  background-color: var(--color-gray05);
  padding-left: 40px;
  margin-top: 5px;
}

.menuFooter strong {
  margin-top: 5px;
  display: block;
}

.btnMore {
  background-color: var(--color-gray05);
  color: var(--color-gray50);
  border-radius: 6px;
  border: none;
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 6px;
  padding: 13px;
}

.btnMore:hover {
  background: var(--color-gray10);
  color: var(--color-gray80);
  cursor: pointer;
}

.normalMenuItems a {
  color: var(--color-gray90);
  font-weight: 500;
}

@media screen and (min-width: 1600px) {
  .statCol:last-child {
    display: flex;
    flex-direction: column;
  }
}
