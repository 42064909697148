.Description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  margin: 4px 0;
}

.Date {
  width: 184px;
  color: var(--color-gray90);
}

.List div[class*='layout_FilterBar'] {
  margin-top: 18px;
  margin-bottom: 12px;
}

.Banner {
  width: 100%;
  align-items: center;
  padding-right: 50px;
  gap: 24px;
}

.BannerContent {
  align-items: start;
  gap: 24px;
  width: calc(100% - 75px);
}

.BannerWrapper div[class*='banner-list-item_main'] {
  height: 93px;
}

.NameCol {
  width: 450px;
  margin-right: 50px;
}

.NameCol span[class*='typography_Bold'] {
  width: 100%;
  margin-top: 0;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.NameCol .Description {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.CreatorCol {
  margin-top: 10px;
  width: 181px;
}

.CreatorCol span[class*='typography_Body__'] {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.CreatedByCol {
  margin-top: 10px;
  width: 250px;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ColHeader {
  font-size: 9px;
  font-weight: 600;
  color: var(--color-gray90);
  opacity: 0.7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding: 0 0 9px 0;
}
