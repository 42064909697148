.wrapper {
  position: relative;
  background-color: var(--color-gray00);
  display: flex;
  justify-content: center;
  align-content: center;
  border: solid 1px var(--color-gray30);
  border-radius: 4px;
  padding: 3px 8px;
}

.wrapper:hover,
.wrapper:focus-within {
  outline: 4px solid var(--color-brandOutline);
  cursor: text;
}

.wrapper:focus-within {
  border-color: var(--color-brandShadeDark);
}

.wrapper.hasClearButton {
  padding-right: 32px;
}

.input {
  border: 0;
  color: var(--color-gray90);
  font-size: 12px;
  padding: 0;
  min-width: 100px;
  background: transparent;
  flex: 1;
  cursor: text;
}

.input:focus {
  outline: none;
}

.label {
  composes: kai-flex-row from global;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  cursor: text;
}

.label svg {
  vertical-align: middle;
  cursor: pointer;
}

.label svg path {
  fill: var(--color-gray70);
}

.button {
  composes: button-reset from global;
  color: var(--color-gray70);
}

.button:disabled {
  display: none;
}

.closeButton {
  position: absolute;
  right: 4px;
  composes: transparent from global;
  cursor: pointer;
  border-radius: 50%;
  padding: 0;
}

.closeButton:hover {
  background-color: var(--color-gray10);
}

.hasError {
  border: 1px solid var(--color-redFullAlt);
}

.error {
  color: var(--color-redFullAlt);
  font-size: var(--font-size-caption);
  padding-top: 4px;
}
