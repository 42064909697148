.pageControlContent {
  composes: Body from 'DesignSystem/Typography/typography.module.css';
  width: 100%;
  align-items: flex-start;
}

.pageControlHeaderContainer {
  margin-bottom: 16px;
  width: 100%;
}

.pageControlHeader {
  align-items: flex-start;
}

.pageControlTitle {
  column-gap: 16px;
}

.pageControlHeaderContainer button {
  cursor: pointer !important; /* stylelint-disable-line declaration-no-important */
}
