.wrapper {
  padding-top: 72px;
}

.row {
  padding: 24px;
  border-top: 2px solid var(--color-gray00);
}

.action {
  font-weight: 600;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.action > span:first-of-type {
  font-size: 14px;
  margin-right: 26px;
  display: flex;
}

.action svg path,
.action svg rect {
  stroke: var(--color-gray90);
}
