.container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.08);
}

.imageContainer {
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.imageContainer img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.author {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  column-gap: 8px;
}

.avatar {
  border: 1px solid var(--color-gray00);
}

.authorName {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  composes: Semibold from 'DesignSystem/Typography/typography.module.css';
}

.title {
  composes: Subheading from 'DesignSystem/Typography/typography.module.css';
  composes: Semibold from 'DesignSystem/Typography/typography.module.css';
}

.subtitle {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  color: var(--color-gray70);
}
