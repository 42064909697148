.empty {
  composes: panel kai-flex-row flex-spread from global;
  outline: none;
  height: 98px;
  padding: 0 20px;
  margin-bottom: 2px;
  background-color: var(--color-gray00);
  border-radius: 8px;
}

/* when used in a list, space them out */

.empty + .empty {
  margin-top: 10px;
}

.main {
  composes: kai-text-md kai-flex-row flex-spread from global;
  flex: 1;
  width: 100%;
  height: 80px;
  color: var(--color-gray50);
}

.col1 {
  width: 50%;
  padding: 0 30px 0 15px;
}

.col2 {
  composes: kai-text-sm from global;
  width: 25%;
}

.col3 {
  color: var(--color-gray80);
  width: 25%;
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  height: 28px;
  font-weight: 600;
  color: var(--color-gray80);
}

.description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  margin: 0;
}

.button {
  color: var(--color-gray80);
  text-align: center;
  background-color: transparent;
  letter-spacing: 2px;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  cursor: default;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 8px;
  transform: rotate(0deg);
}

.empty:hover {
  background-color: var(--color-gray02);
  box-shadow: none;
}

.empty:hover .button {
  border-color: var(--color-gray10);
}

.selectedItem {
  background-color: var(--color-gray05);
}

.selectedItem:hover {
  background-color: var(--color-gray10);
}

.empty .button:hover {
  background-color: var(--color-gray10);
  border: none;
  cursor: pointer;
}

.selectedItem .button:hover {
  background-color: var(--color-gray20);
}

.empty:hover .button:hover {
  border-color: var(--color-gray70);
}

.stat {
  composes: kai-text-sm kai-flex-row from global;
  margin: 3px 0;
}

/* icon: svg or fa */

.stat > *:first-child {
  display: inline-block;
  margin-right: 10px;
}

.stat > *:first-child,
.stat path {
  color: var(--color-greenFull);
  fill: var(--color-greenFull);
}

.selectHandle {
  cursor: pointer;
  position: relative;
}

.checkbox {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  text-align: center;
  background-color: var(--color-brandTintDark);
  opacity: 0.7;
  display: none;
}

.checkboxBox {
  position: relative;
  border: solid 1px transparent;
}

.empty.checkboxBox {
  padding-left: 70px;
}

.checkboxSelectable:hover {
  cursor: pointer;
}

.clickable:hover {
  cursor: pointer;
}

.checkmark {
  position: absolute;
  color: var(--color-gray30);
  top: 0;
  left: 0;
  display: none;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
}

.checkmark i {
  font-size: 18px;
}

.selectedItem .checkmark,
.checkmark:hover {
  display: block;
  color: var(--color-brandTintDark);
}

.checkboxBox .checkmark {
  display: block;
  top: calc(50% - 12px);
  height: 24px;
  width: 24px;
  left: 19px;
  border: none;
  padding: 0;
  overflow: visible;
}

.checkboxBox:hover .checkmark {
  display: block;
}

.hoverDropdown {
  right: 10px;
}

.deleteWarning {
  color: var(--color-gray90);
  margin-bottom: 20px;
  font-size: 14px;
}

.actionButton {
  border: none;
  border-radius: 6px;
  background-color: var(--brand-primary-light-1);
  color: var(--color-brandTintDark);
  font-weight: 600;
  padding: 5px 20px;
  cursor: pointer;
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.menu {
  height: 40px;
  width: 40px;
}

.banner-pill-item {
  border: none;
  background-color: var(--color-gray05);
}

.banner-pill-more {
  border: none;
  font-weight: 700;
  background-color: transparent;
  padding-top: 10px;
  text-transform: lowercase;
  letter-spacing: 1px;
}

.selectedItem :global .banner-pill-item {
  background-color: var(--color-gray10);
}

.empty:hover :global .banner-pill-item {
  border: none;
  background-color: var(--color-gray10);
}

.empty.selectedItem:hover :global .banner-pill-item {
  background-color: var(--color-gray20);
}

.journeysLayout {
  right: 90px;
  top: 5px;
}
