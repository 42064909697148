.mobile .meta {
  display: flex;
  font-size: 13px;
  color: var(--color-gray70);
  padding-top: 0;
}

.mobile .meta > * {
  margin-right: 20px;
}

.mobile .meta > * svg {
  margin-right: 6px;
}

.mobile .data .image {
  display: flex;
  background-color: gray;
  height: 275px;
  margin: -30px -30px 30px -30px;
  background-size: contain;
  padding-top: 34px;
  background-repeat: no-repeat;
  background-position: center;
}

/* Large image previews should be a 3:4 aspect ratio */
.mobile .data .image.large {
  height: 346px;
}

.mobile .data .image .dotsContainer {
  display: flex;
  justify-content: center;
  position: relative;
  top: 15px;
  margin: auto auto 0;
}

.mobile .data .image .dots {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  background: #fff;
  border-radius: 18px;
}

.mobile .data .image .dots .ellipse {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #c4c4c4;
  margin: 0 5px;
}

.mobile .data .image .dots .ellipse.black {
  background-color: #000;
}

.title {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 10px;
  composes: kai-line-clamp-2 from global;
  text-align: initial;
}

.description {
  composes: kai-line-clamp-3 from global;
  height: auto;
  max-height: 3.7em;
  text-align: initial;
}

.mobile > div {
  background-color: var(--color-gray00);
  padding: 30px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.mobile .data {
  min-height: 300px;
}
