.input {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  border-radius: 6px;
  padding: 9px 16px;
  font-size: 14px;
  color: var(--color-gray90);
}

.input:focus {
  border: 1px solid var(--color-brandTintDark);

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
}

.input:disabled {
  background-color: var(--color-gray02);
  border: 1px solid var(--color-gray30);
  color: var(--color-gray50);
}

.error,
.error:focus {
  border: 1px solid var(--color-redFull);
  color: var(--color-redFull);
}

.inputAndClear {
  display: flex;
  flex: 1;
  white-space: nowrap;
}

.multiValueTextInput {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray30);
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 36px;
}

.multiValueTextInput input[type='text'] {
  background-color: inherit;
  flex: 1;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 14px;
  height: 20px;
  margin: 4px 0 4px 4px;
}

.selected {
  composes: pill kai-text-sm kai-flex-row button from global;
  font-weight: 600;
  margin: 4px;
  background-color: var(--color-gray00);
  border-color: var(--color-gray30);
  border-radius: 40px;
  cursor: default;
  min-height: initial;
  height: 25px;
}

.selected > span {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 1em;
  color: var(--color-gray80);
}

.clear {
  composes: kai-inline-flex-row kai-text-sm from global;
  font-weight: 600;
  text-decoration: none;
  color: var(--color-gray80);
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}

.clear span {
  font-weight: 300;
  position: relative;
  margin-right: 2px;
}

.remove {
  font-size: 13px;
  color: var(--color-gray30);
  display: inline-block;
  margin-left: 8px;
  margin-right: -4px;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border-style: none;
}

.icon {
  padding-left: 16px;
}

.callToAction {
  color: var(--color-gray90);
  font-size: 14px;
  opacity: 0.7;
  padding-left: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: text;
}

.selected:hover {
  background-color: var(--color-gray02);
}

.remove svg {
  height: 10px;
}

.remove path {
  fill: var(--color-gray20);
}

.remove:hover {
  cursor: pointer;
}

.remove:hover path {
  fill: var(--color-gray90);
}
