.suggestion {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: var(--color-gray50);
  text-align: center;
  padding-top: 20px;
}

.sidebar {
  composes: sidebar from '../../../publisher.module.css';
  position: absolute;
  right: 0;
  overflow-y: auto;
}

.btn {
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  display: inline;
  vertical-align: text-bottom;
  margin-top: 0;
  color: var(--color-gray90);
}

.container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: stretch;
  overflow-y: auto;
}

.emailIcon {
  display: inline;
}

.blockSpacing {
  padding: 3px 30px;
}

.sidebarText {
  color: var(--color-gray90);
}
