.progressBarTall {
  height: 100%;
  composes: flex-column from global;
  align-items: center;
}

.progressBarWide {
  width: 100%;
  composes: kai-flex-row from global;
  align-items: center;
}

.dotsTall {
  flex-grow: 1;
  border-left: 1px dotted;
}

.dotsWide {
  flex-grow: 1;
  border-top: 1px dotted;
}

div:global(.deliver-redesign) .dotsWide {
  border-top: 2px dotted;
  /* stylelint-disable-next-line declaration-no-important */
  border-color: var(--color-gray30) !important;
}
