.RelativeWrapper {
  position: relative;
}

.AbsoluteChild {
  overflow-y: auto;
  max-height: 50vh;
  position: absolute;
  top: 56px;
  right: 0;
}

.Rounded {
  border-radius: 40px;
  font-size: 14px;
  font-weight: 400;
}

.PillButton {
  display: flex;
  gap: 4px;
  font-size: 14px;
}
