.createPreviewButton {
  background-color: hotpink;
  border: none;
}

.clientList {
  padding-left: 10px;
}

.clientItem {
  font-size: 12px;
}

.screenshotLink {
  color: hotpink;
}
