.MainSection section[class*='forms_FormSubsection'] {
  margin-top: 32px;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.MainSection section[class*='forms_FormSubsection']:first-of-type {
  margin-top: 0;
}

.MainSection section[class*='forms_FormSubsection']:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
}

.MainSection section[class*='forms_FormSubsectionNoDivider'] {
  padding-bottom: 0;
}

.AccessSettings {
  display: flex;
}

.AccessSettings div[class*='pills_Pills'] {
  margin-right: 16px;
}

.EnabledToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AccessSettingsCheckbox label {
  align-items: center;
}

.AccessSettingsCheckbox span[class*='typography_Subheading'] {
  margin-bottom: 0;
  line-height: 1;
}

.AccessSettingsCheckbox span[class*='typography_Body'] {
  margin-top: 4px;
  line-height: 1;
}

.TextField {
  width: 100%;
}

.DomainInput {
  display: flex;
}

.DomainInput input {
  flex-grow: 1;
  margin-right: 8px;
}

.Domains {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.Domains div[class*='pill_Pill'],
.MainSection div[class*='pill_Pill'] {
  margin: unset;
  height: 32px;
}

.Domains div[class*='pills_Pills'],
.MainSection div[class*='pills_Pills'] {
  margin: unset;
  height: 32px;
}

.DomainBill {
  margin: unset;
}

.DomainBill span[class*='Caption'] {
  font-size: 14px;
}

.DefaultBill {
  display: flex;
  align-items: center;
  margin-left: 8px;
  height: 26px;
  font-size: 12px;
  font-weight: normal;
  background-color: var(--color-greenTintDark);
  border-radius: 40px;
  padding: 4px 16px;
}

.Title {
  display: flex;
  align-items: center;
}

.Error {
  display: flex;
  align-items: center;
  color: var(--color-redFull);
  margin-top: 8px;
}

.Error span[class*='warning'] {
  margin-right: 9px;
}

.UserVerification {
  margin-top: 16px;
}

.DomainName {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 20px;
  height: 32px;
  border: 1px solid var(--color-gray10);
  padding: 20px;
}

.SsoSectionContainer {
  margin-top: 32px;
}

.DisabledSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 136px;
}

.DisabledSection a[class*='forms_Button'] {
  margin-top: 24px;
}

.SsoInput {
  margin-bottom: 32px;
}

.Radio {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.RadioLabel {
  margin-left: 12px;
}

.CertificateInput {
  display: flex;
  align-items: center;
}

.CertificateInput input {
  flex-grow: 1;
  margin-right: 8px;
}

.CertificateInput a {
  color: var(--color-gray90);
}

.SsoUrls span[class*='typography_Body'] {
  word-break: break-all;
}

.SetDefaultButton {
  color: var(--color-brandFull);
  border: none;
  margin-left: auto;
  padding: 0;
  margin-right: 8px;
}

.SetDefaultButton:hover {
  background: unset;
}

.SetDefaultButton:focus {
  box-shadow: unset;
}

.SsoSectionContainer header > div:first-child,
.MainSection header > div:first-child {
  flex-grow: 1;
}

.CharCounter {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin-top: 8px;
}
