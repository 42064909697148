.notReady {
  width: 100%;
  text-align: center;
  padding: 50px;
}

.thumbnail {
  display: block;
  width: 100%;
  height: auto;
  background-color: var(--color-gray05);
}

.item {
  position: relative;
  margin: 16px 8px;
}

.pagination {
  composes: kai-flex-row flex-centered from global;
}

.item button {
  cursor: pointer;
  visibility: hidden;
  position: absolute;
  padding: 8px 20px;
  height: 42px;
  width: 163px;
  left: calc(50% - 80px);
  top: calc(50% - 21px);
  font-weight: 600;
  font-size: 16px;
  background: var(--color-gray00);
  border: 1px solid var(--color-gray30);
  border-radius: 6px;
}

.item .selection {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 2px var(--color-brandTintDark);
  box-shadow: inset 0 0 0 1px var(--color-gray50);
}

.item .selection::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-brandTintDark);
  opacity: 0.33;
}

.item.selectable:hover button {
  visibility: visible;
}

.letters {
  composes: kai-flex-row flex-centered from global;
  height: 120px;
  width: 100%;
  background-color: var(--color-gray05);
  color: var(--color-gray70);
  font-size: 48px;
  font-weight: 900;
  text-transform: uppercase;
}

.contentData {
  padding: 5px;
}

.block {
  background-color: var(--color-gray05);
  border-radius: 3px;
  border: 5px solid transparent;
  overflow: hidden;
  min-height: 60px; /* room for a centered "Select" button */
  display: flex; /* good enough attempt at appearing responsive */
  flex-direction: column;
  justify-content: center;
}

.closeButton {
  /* gets overriden by .item button */
  /* stylelint-disable-next-line declaration-no-important */
  top: 10px !important;
  /* stylelint-disable-next-line declaration-no-important */
  right: 10px !important;
  /* stylelint-disable-next-line declaration-no-important */
  width: 20px !important;
  /* stylelint-disable-next-line declaration-no-important */
  height: 20px !important;
  /* stylelint-disable-next-line declaration-no-important */
  left: initial !important;
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important;
  composes: kai-flex-row from global;
}

.spinnerItem {
  composes: flex-column from global;
  margin: 30px;
  justify-content: center;
  align-items: center;
  height: 120px;
}
