.dropdown {
  position: absolute;
  z-index: var(--z-index-click-dropdown);
  min-width: 650px;
  background-color: white;
  box-shadow: 0 2px 13px 0 #0003;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
}

.delimiter {
  font-size: 14px;
  font-weight: 600;
}

.container {
  padding: 10px 14px;
  font-size: 12px;
  font-weight: 600;
  background: var(--color-gray00);
  color: var(--color-gray90);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 6px;
  border-radius: 16px;
  border: 1px solid var(--color-gray40);
  display: flex;
  height: 32px;
}

.container:hover {
  background: var(--color-gray05);
  border-color: var(--color-gray90);
}

.value {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.container svg {
  margin-left: 6px;
  position: relative;
  top: 1px;
}

.dropdownRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #e3e1eb;
  padding: 16px 0;
}

.dropdownRow:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  justify-content: flex-end;
}

.dropdownRow:first-of-type {
  padding-top: 0;
}

.buttonWhite {
  background-color: white;
  color: black;
  font-size: 14px;
}

.done {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--color-brandShadeDark);
  height: 32px;
  color: var(--color-gray00);
  border: 1px solid var(--color-brandShadeDark);
  padding: 0 20px;
  cursor: pointer;
  white-space: nowrap;
}

.datePickerDropdown {
  padding: 0;
  border-radius: 8px;
}

.datePickerHeader {
  background-color: #ebe6fe;
  border-radius: 8px 8px 0 0;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e1eb;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  gap: 24px;
}

.datePickerHeaderChevronContainer {
  display: flex;
  gap: 8px;
}

.datePickerHeaderLeft {
  width: 50%;
  color: #181544;
}

.datePickerHeaderRight {
  display: flex;
  color: #181544;
  gap: 8px;
  margin-left: 16px;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}

.monthButton {
  background-color: transparent;
}

.monthButtonContainer button {
  background-color: transparent;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.monthsContainer {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  padding: 0 16px;
}

.monthContainer {
  flex: 1;
}

.monthHeader {
  text-align: left;
  font-weight: 600;
  margin-bottom: 24px;
  font-size: 16px;
  color: var(--color-gray90);
}

.monthBody {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  text-align: center;
}

.datePickerBody {
  padding: 24px 0;
}

.datePickerBodyHeader {
  color: var(--color-gray60);
  font-weight: 500;
  padding-bottom: 16px;
  font-size: 14px;
}

.inactive button {
  background-color: white;
  color: black;
}

.inRange button {
  background-color: var(--color-gray05);
}

.active button {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: var(--color-brandShadeDark) !important;
  /* stylelint-disable-next-line declaration-no-important */
  color: white !important;
}

.dayButton button {
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-gray90);
}

.dayButton button:disabled {
  color: var(--color-gray40);
}

.dayButton {
  width: 100%;
  text-align: center;
}

.dayButton button:hover:not(:disabled) {
  background-color: var(--color-gray05);
}

.datePickerFooter {
  padding: 16px 24px;
  justify-content: space-between;
  font-weight: 600;
  border-top: 1px solid #e3e1eb;
}

.emptyDay {
  width: 36px;
  height: 36px;
}

.monthYearLabel {
  font-size: 16px;
  font-weight: 600;
  padding: 0 16px;
}

.relativeRangeDropdown {
  min-width: 242px;
  background: white;
  border-radius: 8px;
  padding: 0;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2);
}

.filterApplied {
  background: var(--color-gray05);
  border-color: var(--color-gray90);
}

.relativeRangeButton {
  width: 100%;
  border: 0;
  outline: 0;
  background: none;
  display: block;
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: normal;
  color: #111;
  cursor: pointer;
  border-radius: 0;
  padding: 8px 16px;
}

.relativeRangeButton:hover {
  background-color: var(--color-gray05);
}

.customRangeButton {
  border-top: 1px solid var(--color-gray20);
  background-color: #f1f0f7;
  width: 100%;
}

.customRangeIcon {
  width: 16px;
  height: 16px;
  color: var(--color-gray60);
}

.customRangeDropdown {
  /* stylelint-disable-next-line declaration-no-important */
  margin-left: 10px !important;
  top: -810%;
  left: 100%;
}
