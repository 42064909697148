.header {
  margin-left: 32px;
  width: 40%;
}

.title {
  composes: kai-text-md kai-line-clamp-2 from global;
  width: 320px;
  height: 20px;
  font-weight: 600;
  color: var(--color-gray80);
}

.rtlTitle {
  composes: text-md line-clamp-2 from global;
  text-align: right;
  width: 320px;
  height: 20px;
  font-weight: 600;
  color: var(--color-gray80);
}

.summary {
  composes: kai-line-clamp-3 from global;
  font-size: 12px;
  color: var(--color-gray90);
  opacity: 0.7;
  line-height: 15.6px;
}

.reachWrapper {
  margin-left: 5%;
  composes: flex-column from global;
  align-items: start;
  color: var(--color-gray90);
  height: 88px;
  justify-content: center;
}

.reach {
  font-size: 24px;
  font-weight: 700;
  width: 150px;
  text-align: center;
}

.priority {
  margin-top: 15px;
  font-size: 13px;
  font-weight: 600;
}

.state {
  color: var(--color-gray50);
  font-size: 15px;
  text-transform: capitalize;
}

.label {
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 5px;
}

.authorName {
  font-size: 14px;
  font-weight: 700;
}

.publishedAt {
  font-size: 12px;
  line-height: 15.6px;
}

.strong {
  padding-right: 3px;
  font-weight: 700;
}

.details {
  width: 44%;
  flex-grow: 2;
}

.statistics {
  composes: kai-flex-row flex-spread from global;
}

.infos {
  margin-top: 30px;
  composes: kai-flex-row flex-spread from global;
}

.info {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-gray90);
  display: flex;
  margin-bottom: 3px;
}

.info > svg {
  margin-right: 7px;
}

.topics {
  composes: panel from global;
  padding: 15px 15px 15px 20px;
}

.closeTopics {
  position: absolute;
  top: 10px;
  right: 10px;
}

.highlight {
  color: var(--color-redFull);
}
