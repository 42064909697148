.container {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;
}

.reporterList {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-right: 1px solid var(--color-gray05);
  overflow: auto;
  row-gap: 16px;
}

.reporterListItem {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr min-content;
  column-gap: 16px;
  grid-template-areas:
    "avatar name"
    "avatar title";
}

.reporterAvatar {
  grid-area: avatar;
  align-self: center;
}

.reporterName {
  grid-area: name;
  composes: Body from 'DesignSystem/Typography/typography.module.css';
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reporterTitle {
  grid-area: title;
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  color: var(--color-gray60);
}

.contentView {
  overflow: auto;
  background-color: var(--color-gray02);
  padding: 24px;
}
