.control {
  flex: 1;
  cursor: default;
  padding: 0 25px;
  background-color: var(--color-gray05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-gray90);
}

.dateRange {
  font-size: 12px;
  font-weight: normal;
  color: var(--color-gray70);
}
