.configSection {
  &:not(:first-child) {
    margin-top: 32px;
    border-top: 1px solid var(--color-gray10);
  }
}

.tagsSection {
  margin-top: 12px;
  margin-bottom: 32px;
}

.tag {
  margin: 0 16px 0 0;
  padding: 4px 8px;
}
