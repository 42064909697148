.Attributes {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.Item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  width: auto;
  margin: 0 8px 8px 0;
  height: 32px;
  padding: 4px 16px;
  border-radius: 40px;
  border: 1px solid var(--color-gray30);
  background: var(--color-gray00);
  font-size: 12px;
  white-space: nowrap;
  line-height: 24px;
}
