.tableauVizContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  position: relative;
}

.hidden {
  visibility: hidden;
}

.helplink {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px 0 0;
}
