.statusTag {
  width: auto;
  padding: 5px 16px;
  margin: 0;
}

.statusTagContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.hoverable {
  cursor: pointer;
}
