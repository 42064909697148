.option {
  display: block;
  width: 194px;
  border-radius: 4px;
  border: solid 2px var(--color-gray10);
  background-color: transparent;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  overflow: visible;
}

.option:hover {
  background-color: var(--color-gray02);
}

.checked {
  width: 100%;
  height: 100%;
  left: -2px;
  top: -2px;
  box-sizing: content-box;
  position: absolute;
  border: solid 2px var(--color-brandTintDark);
  border-radius: inherit;
  background-color: transparent;
  opacity: 1;
}

.option .checked::after {
  display: block;
  content: ' ';
  position: absolute;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  background-color: var(--color-brandTintDark);
}

.menu {
  position: absolute;
  background-color: var(--color-gray00);
  box-shadow:
    0 21.53px 25.84px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
  overflow: auto;
  z-index: 1000;
}

.menu::-webkit-scrollbar-thumb {
  background-color: var(--color-gray30);
  border-radius: 4px;
}

.menu::-webkit-scrollbar {
  background-color: var(--color-gray05);
  width: 8px;
}

.icon {
  background-color: transparent;
  border: none;
  border-left: solid 14px black;
  padding: 3px;
  cursor: pointer;
}

.icon div:first-child {
  width: 16px;
  border-bottom: solid 3px black;
  margin-bottom: 2px;
}

.icon div:last-child {
  width: 12px;
  border-bottom: solid 3px black;
}

.util-text {
  background-color: var(--color-gray90);
  margin: 8px;
}

.util-picture {
  background-color: var(--color-gray50);
  margin: 8px;
}

.util-fade {
  margin: 8px;
  height: 40px;
  width: 150px;
  background-image: linear-gradient(to bottom, var(--color-gray50), var(--color-gray05));
}
