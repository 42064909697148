.layout {
  width: 810px;
  height: 100%;
  display: flex;
  background-color: var(--color-gray00);
  border-radius: 6px;
}

.timezoneLayout {
  width: 1040px;
  height: 100%;
  display: flex;
  background-color: var(--color-gray00);
  border-radius: 6px;
}

.range {
  flex: 0 0 160px;
  border-right: solid 1px #eee;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.summary {
  flex: 0 0 60px;
  justify-self: flex-start;
  border-radius: 0;
  padding: 23px 65px;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
}

/* the height of the calendar section is constrained by the calendar day span heights
   see "month.module.css" */
.calendars {
  flex: 1;
}

.buttons {
  flex: 0 0 45px;
  justify-self: flex-end;
  border-top: solid 1px #eee;
  display: flex;
}

.buttons > div:first-child {
  flex: 1;
}
