@keyframes loading {
  0% {
    transform: translateX(0);
  }

  50%,
  100% {
    transform: translateX(460px);
  }
}

.Skeleton {
  height: 100%;
}

.SkeletonItem {
  width: 100%;
  height: 100%;
  min-height: 16px;
  background: #f2f2f2;
  margin-top: 3px;
  position: relative;
  overflow: hidden;
}

.SkeletonItem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}
