.PreviewCardTitle {
  composes: Body from 'DesignSystem/Typography/typography.module.css';
  composes: Semibold from 'DesignSystem/Typography/typography.module.css';
}

.PreviewCardContent {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
}

.PreviewCardContainer {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid var(--neutrals-10-gray, #e3e1eb);
}

.AboutSidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.EmptyPreview {
  padding: 32px 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.PreviewCardEditButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: var(--color-gray05);
}
