.selector {
  width: 100%;
}

.dropdown {
  box-shadow: rgba(0, 0, 0, 0.2) 3px 4px 14px 0, rgba(0, 0, 0, 0.05) 0 0 0 1px;
  box-sizing: border-box;
  background-color: var(--neutral-light-3);
}

.dropdown .row {
  padding: 0 20px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
