.main {
  width: 618px;
  height: 492px;
  composes: flex-column from global;
  background-color: var(--color-gray00);
}

.footer {
  height: 52px;
  background-color: var(--color-gray02);
  composes: kai-flex-row from global;
  justify-content: flex-start;
  padding-left: 40px;
}

.footer > span {
  font-size: 14px;
  margin-left: 11px;
}

.body {
  flex: 1;
  composes: kai-flex-row from global;
  align-items: flex-start;
  padding: 40px 43px;
}

.year {
  composes: flex-column from global;
  padding-right: 43px;
}

.year > h3 {
  font-size: 28px;
  font-family: Inter, sans-serif;
}

.isActiveMonth {
  /* .monthName is overriding these styles */
  /* stylelint-disable-next-line declaration-no-important */
  font-size: 16px !important;
  /* stylelint-disable-next-line declaration-no-important */
  line-height: 21px !important;
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--color-gray90) !important;
}

.controls {
  composes: kai-flex-row from global;
  justify-content: space-between;
}

.controls > button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 -5px;
}

.monthName {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 12px;
  color: var(--color-gray70);
  text-transform: uppercase;
  line-height: 15.6px;
  font-weight: 700;
  margin-bottom: 12px;
}

.month {
  composes: flex-column from global;
  margin-right: 60px;
}

.thisWeek {
  background-color: transparent;
  border: 1px solid var(--color-gray30);
  width: 100%;
  height: 32px;
  margin-top: 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}
