.metricItems {
  column-gap: 16px;
  min-height: 42px;
}

.metricItem {
  border-color: var(--color-gray20);
}

.metricValue {
  margin-right: 8px;
}

.metricRate {
  display: inline-block;
  background-color: var(--color-blueTintLight);
  color: var(--color-blueFull);
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
}

.reportingSectionBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 8px;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 300px;
}
