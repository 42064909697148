.StatsContainer {
  display: flex;
  font-size: 12px;
}

.WordCountStatsBox {
  width: 126px;
  margin-right: 8px;
}

.ReadTimeStatsBox {
  width: 124px;
}

.StatValue {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
}
