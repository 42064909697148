.userImportsPage div[class*='label'] {
  color: var(--color-gray70);
}

.userImportsPage div[class*='input'] {
  word-break: break-all;
}

.pill {
  padding: 20px;
  background-color: var(--color-gray10);
  border-radius: 7px;
  font-size: 14px;
  color: var(--color-gray90);
}

.twoCols {
  display: flex;
  justify-content: space-between;
  padding: 0 0 30px 0;
}

.twoCols:last-child {
  padding: 0;
}

.twoCols > span:first-child {
  font-weight: 500;
}

.twoCols > span:last-child {
  text-align: right;
}

.scope {
  padding: 0 0 8px 0;
  text-align: right;
  max-width: 230px;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scope:last-child {
  padding: 0;
}

.wordWrap {
  max-width: 220px;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mainLayout {
  display: flex;
}

/* Banner Item */

.basic {
  width: 250px;
  height: 100px;
  padding: 0 30px 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.statCol {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 140px;
  flex-grow: 1;
}

.statHeader {
  composes: kai-text-sm from global;
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-size: 9px;
  font-weight: 600;
}

.stats {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.7;
  max-height: 70px;
  color: var(--color-gray90);
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subStats {
  composes: stats;
  color: var(--color-gray70);
  font-size: 12px;
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  width: 320px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
  text-transform: capitalize;
}

.description {
  margin: 4px 0;
  color: var(--color-gray70);
  font-size: 12px;
}

.bannerWrapper {
  padding-right: 20px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}
