/* stylelint-disable declaration-no-important */

/* if your component uses react-select, use these styles for consistency */

/* https://react-select.com/styles#using-classnames */

/* There are two variations, one with a grayish input, and one
 * with a white input. By default, the gray one is used. The sidebar
 * of the main theme uses the white one. You can opt into the white
 * one by providing `classNamePrefix="white"` to the component. */

/* Many styles require the unfortunate `!important` flag to override
 * the library styles that it comes with. */

.gray__option {
  max-width: 200px;
  cursor: pointer !important;
}

.gray__option--is-selected {
  cursor: default !important;
}

.gray__indicator {
  height: 25px;
}

.gray__indicators {
  height: 25px;
}

.gray__indicator-separator {
  display: none;
}

.gray__value-container {
  display: inline-block;
  width: auto;
  height: 25px;
}

.gray__control {
  width: auto !important;
  display: inline-block !important;
  height: 25px;
  background-color: var(--color-gray05) !important;
  border-style: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.gray__control:hover {
  background: var(--color-gray02) !important;
  color: var(--color-brandTintDark) !important;
}

.gray__menu {
  width: 200px !important;
  background-color: var(--color-gray00) !important;
}

.gray__single-value {
  display: contents;
  font-weight: 500;
  width: 100%;
  line-height: 32px;
  color: inherit !important;
}

.white__option {
  max-width: 100%;
  cursor: pointer !important;
}

.white__option--is-focused {
  background-color: var(--color-gray10) !important;
  color: var(--color-gray90) !important;
}

.white__option--is-selected {
  background-color: var(--color-brandTintDark) !important;
  color: var(--color-gray00) !important;
  cursor: default !important;
}

.white__indicator {
  height: 25px;
}

.white__indicators {
  height: 25px;
}

.white__indicator-separator {
  display: none;
}

.white__value-container {
  width: 100%;
  height: 25px;
}

.white__control {
  max-width: 100%;
  display: flex;
  height: 25px;
  background-color: var(--color-gray00) !important;
  border-style: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.white__menu {
  width: 100%;
  background-color: var(--color-gray05) !important;
}
