.userImportsPage div[class*=label] {
  color: var(--color-gray70);
}

.userImportsPage div[class*=input] {
  word-break: break-all;
}

.pill {
  padding: 10px;
  background-color: var(--color-gray10);
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray90);
}

.twoCols {
  display: flex;
  justify-content: space-between;
  padding: 3.5px 0;
}

.twoCols > span:last-child {
  text-align: right;
}
