.messageWrapper {
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  margin: 10px 0;
  background-color: rgba(17, 18, 19, 0.75);
}

.buttonContainer {
  align-self: flex-start;
  margin-top: 4px;
  composes: kai-flex-row from global;
  flex-grow: 1;
  justify-content: flex-end;
}

.buttonContainer > button {
  width: 8px;
  height: 8px;
  padding: 0;
  margin: 0;
  display: flex;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.buttonContainer > button > i {
  font-size: 10px;
  color: var(--color-gray00);
}

.info {
  border-left: 4px solid var(--color-greenFull);
}

.warning {
  border-left: 4px solid yellow;
}

.error {
  border-left: 4px solid var(--color-redFull);
}

.progress {
  border-left: 4px solid var(--color-tealFull);
}

.link {
  color: var(--color-greenTintLight);
}

.info > div:first-child {
  color: var(--color-greenFull);
}

.error > div:first-child {
  color: var(--color-redFull);
}

.progress > div:first-child {
  color: var(--color-tealFull);
}

.text {
  padding-left: 20px;
  color: var(--color-gray00);
}

.message {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
  margin-right: 16px;
  padding: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;

  /* useful for splitting a message into lines, ex: validation errors */
  white-space: pre-line;
}

.inlineChildren {
  float: right;
  margin-left: 32px;
  margin-right: 16px;
}

.navigateButton {
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 8px 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  color: var(--color-gray00);
  background-color: rgba(0, 0, 0, 0);
}

.details {
  font-size: 12px;
}

.storyWrapper {
  position: absolute;
  width: 500px;
  height: 500px;
}

.storyWrapper > div:first-child {
  position: relative;
  height: 250px;
}

.slidingEnter {
  opacity: 0;
  transform: translateX(-100%);
}

.slidingEnterActive {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms ease;
}

.slidingEnterDone {
  opacity: unset;
  transform: unset;
}

.slidingExit {
  opacity: 1;
  transform: translateX(0);
}

.slidingExitActive {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 300ms ease;
}

.slidingExitDone {
  display: none;
}

.progress > .text > div[class^='progress-bar_progressBar'] {
  background-color: var(--color-gray00);
  border-radius: 4px;
  margin-bottom: 7px;
  margin-top: 18px;
  width: calc(100% - 16px);
}

.progress > .text > div[class^='progress-bar_'] > div {
  border-radius: 4px;
}

.progress > .text > div > div[class^='progress-bar_dots'] {
  display: none;
}
