:root {
  --overlay-slide-in-timeout: 350ms;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: var(--z-index-shared-overlay-modal);
}

.content {
  background-color: var(--color-gray00);
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.willChange {
  will-change: transform;
}

.in-start {
  transform: translateX(100vw);
}

.in-progress {
  transform: translateX(0);
  transition: transform var(--overlay-slide-in-timeout) linear;
}

.in-done {
  transform: translateX(0);
}

.out-start {
  transform: translateX(0);
}

.out-progress {
  transform: translateX(100vw);
  transition: transform var(--overlay-slide-in-timeout) linear;
}

.out-done {
  display: none;
}
