.SplitFooter {
  align-items: stretch;
  background-color: var(--color-gray00);
  bottom: 0;
  box-shadow: 0 0 5px var(--color-gray30);
  display: flex;
  height: 64px;
  justify-content: space-between;
  left: 0;
  padding: 0 24px;
  position: fixed;
  width: 100%;
  z-index: var(--z-index-navigation-footer);
}

.SplitFooter.top {
  bottom: unset;
  height: 48px;
  top: 0;
}

.SplitFooter :global .-NavLink- {
  text-decoration: none;
  margin: 0 16px;
  display: flex;
  align-items: center;
  padding: 4px 0;
  border-bottom: solid 2px transparent;
  color: var(--color-gray50);
}

.SplitFooter :global .-NavLink-.-Disabled- {
  border-bottom: solid 2px transparent;
  color: var(--color-gray30);
  cursor: default;
}

.SplitFooter :global .-NavLink-:hover {
  border-bottom: solid 2px var(--color-gray20);
  color: var(--color-gray90);
}

.SplitFooter :global .-NavLink-.-Disabled-:hover {
  border-bottom: solid 2px transparent;
  color: var(--color-gray30);
}

.SplitFooter :global .-NavLink-.-Partial-,
.SplitFooter :global .-NavLink-.-Active- {
  border-bottom: solid 2px var(--color-brandSolar);
  color: var(--color-brandShadeDark);
  cursor: default;
}

.SplitFooter :global .-NavLink- > *:first-child {
  line-height: 18px;
  margin-right: 4px;
  color: inherit;
  opacity: 0.8;
}

.SplitFooter :global .-NavLink- > *:last-child {
  padding-right: 4px;
  color: inherit;
}

/*
 * There was a weird UI bug that Joey could not figure out.
 * When using the Button element from the Design System,
 * this would cause the window to lose focus of what was going on.
 * I could never find a loop, the profiler seemed cool, and it
 * would freeze until something reminded the browser to get to work
 * again. Resizing the browser would consistent fix it. 🤷
 *
 * Faking a button with a div made the problem go away.
 */
.Button {
  cursor: pointer;
  user-select: none;
  padding: 4px 8px;
  border-radius: 4px;
}

.Button:not(.Disabled):hover {
  background-color: var(--color-gray05);
}

.Button.Disabled {
  cursor: default;
  opacity: 0.5;
}
