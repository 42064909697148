.FieldTitle {
  composes: Body from 'DesignSystem/Typography/typography.module.css';
  composes: Semibold from 'DesignSystem/Typography/typography.module.css';
}

.Form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  padding: 0 4px;

  & label {
    composes: FieldTitle;
    display: block;
    margin-bottom: 8px;
  }

  & input,
  & textarea {
    font-weight: initial;
    width: 100%;
  }
}

.Fieldset {
  border: 0;
  padding: 0;
  margin: 0;

  & label {
    margin: 0;
  }

  & legend {
    composes: FieldTitle;
  }
}

.FieldsetLegend__Container {
  margin-bottom: 8px;
}
