.filtersWrapper {
  display: flex;
  flex-wrap: wrap;
}

.newFilterButton {
  padding: 6px 16px;
  margin-right: 16px;
}

.filterDropdownContainer {
  display: flex;
  flex-direction: column;
  min-width: 220px;
  border-radius: var(--insights-filter-dropdown-border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-gray00);
}
