/* Banner Item */

.basic {
  width: 250px;
  height: 100px;
  padding: 0 30px 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.statCol {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 140px;
  flex-grow: 1;
}

.statHeader {
  composes: kai-text-sm from global;
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-size: 9px;
  font-weight: 600;
}

.stats {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.7;
  color: var(--color-gray90);
}

.subStats {
  composes: stats;
  color: var(--color-gray70);
  font-size: 12px;
}

.title {
  composes: kai-text-md ellipsis-overflow from global;
  width: 320px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
  text-transform: capitalize;
}

.description {
  margin: 4px 0;
  color: var(--color-gray70);
  font-size: 12px;
}

.bannerWrapper {
  padding-right: 20px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}
