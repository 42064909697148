.editor > a i {
  display: none;
}

/* Froala styles are a bit of a pain to override, in this case !important is the best option.
   The other route would be to repeat attr selectors in order to increase specificity. */
/* stylelint-disable declaration-no-important */
.editor > a[data-cmd='html'] {
  display: none !important;
}

.sectionTitle {
  margin: 16px 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: var(--color-gray90);
}

.label.disabled {
  color: var(--color-gray50);
}

.loadingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--color-gray90);
  opacity: 0.7;
}
