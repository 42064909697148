.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 20px;
  background: rgba(0, 0, 0, 0);
}

.modalBody {
  min-width: 490px;
  max-width: 700px;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 5px;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.modalTitle {
  width: auto;
  font-size: 22px;
  font-weight: 600;
}

.closeIcon {
  margin-top: -3px;
  width: auto;
  font-size: 25px;
  color: var(--color-gray70);
  cursor: pointer;
  border: transparent;
  background-color: transparent;
}

.modalContent {
  width: 100%;
}

@media screen and (min-width: 481px) {
  .modal {
    padding: 0 50px;
    z-index: 9999;
  }
}
