:root {
  --layout-main-nav-width: 216px;
  --layout-main-nav-collapsed-width: 70px;
  --layout-site-header-height: 48px;

  /* TODO check for usage */
  --program-sidebar-width: 80px;
  --program-main-content-horizontal-padding: 64px;
  --program-main-content-vertical-padding: 24px;
  --program-footer-width: 394px;
  --program-modal-editor-width: 692px;
}

.Flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Flex.AlignStart {
  align-items: flex-start;
}

.Flex.Start {
  justify-content: flex-start;
}

.Flex.End {
  justify-content: flex-end;
}

.Flex.Spread {
  justify-content: space-between;
}

.Flex.Center {
  justify-content: space-around;
}

.Flex.Wrap {
  flex-wrap: wrap;
}

.Flex.Column {
  flex-direction: column;
}

.Flex.Widen {
  flex: 1;
}

.Centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageTemplate {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
}

.MainNavigation {
  composes: Centered;
  width: var(--layout-main-nav-width);
  min-width: var(--layout-main-nav-width);
  background-color: var(--color-gray02);
  flex-direction: column;
}

.FirstUpLogo {
  height: 40px;
  width: 40px;
  margin: 32px 0 20px;
}

.NavLinks {
  flex: 1;
  width: 100%;
  padding: 12px 8px;
}

.NavLinks textarea {
  resize: none;
  margin-top: 10px;
  margin-bottom: 5px;
}

.MainBody {
  flex: 1;
  background-color: var(--color-gray00);
  position: relative;
}

.MainBody > main {
  height: calc(100vh - var(--layout-site-header-height));
  overflow: auto;
}

.SiteHeader {
  composes: Centered;
  justify-content: flex-end;
  height: var(--layout-site-header-height);
  padding: 0 40px;
}

.SiteHeaderShadow::after {
  width: 100%;
  height: 5px;
  position: absolute;
  top: var(--layout-site-header-height);
  margin-top: -5px;
  left: 0;
  background-color: transparent;
  content: ' ';
  z-index: 2;
  box-shadow: 0 2px 2px -2px var(--color-gray30),
    0 5px 6px -2px var(--color-gray50);
}

.UserMenu {
  margin: 0 10px;
  gap: 20px;
  composes: kai-flex-row from global;
}

.SupportDropdown {
  composes: dropdown from global;
  padding: 16px;
  border-radius: 8px;
  width: 320px;
}

.SupportDropdownItem {
  composes: kai-flex-row from global;
  padding: 8px;
  text-decoration: none;
}

.Notifications {
  width: 264px;
  composes: shadow-heavy from global;
  padding: 8px 0;
}

.NotificationsItem {
  composes: kai-flex-row from global;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  padding: 9px 18px;
  text-decoration: none;
  color: var(--color-gray90);
}

.NotificationsItem:hover {
  background-color: var(--color-gray02);
}

.Breadcrumbs li div {
  display: inline-block;
}

.NotificationsBellContainer {
  border-radius: 2px;
  padding: 2px 0;
  composes: kai-flex-row from global;
  cursor: pointer;
}

.NotificationsBellContainer:hover {
  background-color: var(--color-gray02);
}

.NotificationsBellContainer > div:first-child {
  margin-right: 3px;
}

.NotificationsCounter {
  composes: Caption Bold from '../Typography/typography.module.css';
  padding: 0 3px;
  border-radius: 2px;
  background-color: var(--color-redFull);
  color: var(--color-gray00);
  cursor: pointer;
}

.WithBorder {
  border-bottom: 1px solid var(--color-gray10);
}

.AvatarImage {
  composes: Flex;
  vertical-align: middle;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.PageHeader {
  border-bottom: solid 1px var(--color-gray10);
  margin-bottom: 32px;
}

.FormPageHeader {
  padding: 0 32px;
  width: 100%;
}

.FormPage {
  max-width: 824px;
  margin: 32px auto;
  min-height: 100%;
  padding: 0 32px 72px;
}

.ListPage {
  padding: 0 32px;
}

.ListItems {
  padding-right: 32px;
}

.MainLink {
  composes: Subhead from '../Typography/typography.module.css';
  color: var(--color-gray60);
  text-decoration: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.MainLink svg {
  height: 20px;
}

.MainLinkLabel {
  flex: 1;
  padding-left: 16px;
}

.MainLink:hover {
  background-color: var(--color-gray10);
}

.MainLinkActive {
  background-color: var(--color-gray05);
}

.MainLinkIndicator {
  background-color: var(--color-brandSolar);
  font-size: 0;
  border-radius: 1.5px;
  width: 3px;
  height: 20px;
  display: none;
}

.MainLinkActive .MainLinkIndicator {
  display: initial;
}

.MainLinkDisabled {
  cursor: default;
  color: var(--color-gray20);
}

.MainNavFooter {
  width: 100%;
  padding: 8px;

  /* matches .NavLinks */
}

.CreateButton {
  composes: Subhead from '../Typography/typography.module.css';
  width: 100%;
  padding: 16px;
}

.CreateButton * {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

.CreateButton button {
  width: 100%;
  height: 40px;
  border-color: var(--color-gray30);
  margin-bottom: 0;
}

.CreateButton svg {
  height: 16px;
  width: 16px;
}

.CreateButton svg path {
  stroke: var(--color-gray90);
}

.TitleActions {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.Actions {
  display: flex;
}

.Actions > * {
  margin-left: 12px;
}

.HeaderDescription {
  margin-bottom: 32px;
}

.HeaderDescription > *:first-child {
  display: block;
  max-width: 750px;
}

.Tabs {
  margin: 20px 0;
}

.Tabs:last-child {
  margin-bottom: 0;
}

.Tabs:not(:last-child) {
  border-bottom: solid 1px var(--color-gray10);
}

.Tabs li {
  display: inline-block;
}

.TabButton {
  composes: Button ButtonText from '../Form/forms.module.css';
  text-decoration: none;
  background-color: transparent;
  border: none;
  height: 48px;
  color: var(--color-gray60);
  border-bottom: 3px solid transparent;
  margin-bottom: -2px;
  margin-right: 10px;
}

.TabButton:not(.TabButtonActive):hover {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid white;
  padding-bottom: 2px;
}

.TabButtonActive {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--color-gray90);
  border-bottom-color: var(--color-brandSolar);
}

.TabButtonActive:hover {
  background-color: transparent;
  border-bottom-color: var(--color-brandSolar);
  color: var(--color-gray90);
  cursor: default;
}

.Breadcrumbs li {
  display: inline-block;
  padding: 0 10px;
  color: var(--color-gray30);
  text-decoration: none;
  font-size: var(--font-size-body);
}

.Breadcrumbs li a {
  text-decoration: inherit;
  color: var(--color-gray90);
}

.Breadcrumbs li::after {
  padding-left: 20px;
  display: inline-block;
  content: '/';
}

.Breadcrumbs li:first-child {
  font-weight: 900;
}

.Breadcrumbs li:first-child::after,
.Breadcrumbs li:last-child::after {
  content: '';
}

.InfiniteContainerShadow::before {
  width: 100%;
  height: 5px;
  position: absolute;
  top: -6px;
  left: 0;
  background-color: transparent;
  content: ' ';
  z-index: 1;
  box-shadow: 0 2px 2px -2px var(--color-gray30),
    0 5px 6px -2px var(--color-gray50);
}

.FilterBar {
  margin-top: -12px;
  margin-bottom: 12px;
}

.ListHeader {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 32px;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-gray60);
  border-bottom: 1px solid var(--color-gray10);
}

/* WIP responsiveness, change 1px to something like 1365px */

@media screen and (max-width: 1px) {
  .MainLinkLabel {
    display: none;
  }

  .MainLink:hover {
    position: relative;
    width: 180px;
    z-index: 1000;
    box-shadow: 2px 2px 4px -2px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
  }

  .MainLink:hover .MainLinkLabel {
    display: block;
  }

  .MainLinkIndicator {
    margin-left: 4px;
  }

  .MainNavigation {
    width: var(--layout-main-nav-collapsed-width);
  }

  .CreateButton {
    padding-left: 8px;
    padding-right: 8px;
  }

  .CreateButton button span:first-child {
    margin: 0;
  }

  .CreateButton button span:last-child {
    display: none;
  }
}

/* pulled in from legacy style sheet */

@font-face {
  font-family: 'Gilroy';
  src: url('../../shared/Fonts/Gilroy-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('../../shared/Fonts/Inter-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url('../../shared/Fonts/Inter-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('../../shared/Fonts/Inter-Semi-Bold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('../../shared/Fonts/Inter-Semi-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('../../shared/Fonts/Inter-Bold.otf') format('opentype');
  font-weight: 800;
}

.publishButton {
  composes: box-shadow from global;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray00);
  height: 44px;
  width: fit-content;
  color: var(--color-gray00);
  font-size: 16px;
  line-height: 16px;

  /* should match the font-size for best centering results */
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: auto;
  margin-bottom: 50px;
  padding: 0 15px;
}

.publishButton:hover {
  border-color: var(--color-brandTintDark);
  box-shadow: inset 0 0 0 1px var(--color-brandTintDark);
}

.publishButtonIcon {
  font-size: 18px;
  color: var(--color-gray90);
  margin: 0 8px 0 0;
}

.publishButtonLabel {
  display: inline-block;
  color: var(--color-gray90);
  padding-top: 2px;
}

.publishDropdownContainer {
  padding: 16px;
  z-index: 1000;
}

.publishHeader {
  margin-bottom: 16px;
}

.publishDropdownContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.publishDropdownItem {
  width: 368px;
  background-color: var(--color-gray02);
  border-radius: 8px;
  border: 1px solid var(--color-gray10);
  text-decoration: none;
}

.publishDropdownLabel {
  flex-grow: 1;
  display: flex;
}

.programPickerDropdown {
  position: absolute;
  display: flex;
  bottom: 48px;
  left: -170px;
  min-width: 200px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 10px 20px 0 rgba(0, 0, 0, 0.17);
  background-color: var(--color-gray00);
  border: solid 1px var(--color-gray10);
}

.programPickerDropdown.withLogo {
  height: auto;
  width: 240px;
}

.programPickerDropdown > div:first-child {
  width: 100%;
}

.programPickerDropdown .logo {
  width: 100%;
  height: auto;
  display: block;
  padding: 5px;
}

.GoToProgram {
  font-size: 15px;
  font-weight: 600;
  color: var(--color-gray90);
  line-height: 28px;
  display: inline-block;
  margin: 10px;
  text-decoration: underline;
}

.programTrigger {
  composes: kai-flex-row flex-centered from global;
  padding: 20px;
  color: var(--color-gray50);
  cursor: default;
}

.programTrigger img {
  flex: 0;
  width: 32px;
  height: 32px;
}

.programTrigger span {
  display: none;
  flex: 1;
}

.ProgramTrigger {
  display: flex;
  align-items: center;
  font-size: var(--font-size-caption);
  border-top: solid 1px var(--color-gray10);
  cursor: pointer;
}

.ProgramTrigger:hover {
  background-color: var(--color-gray10);
  border-radius: 6px;
}

.ProgramTrigger *:nth-child(2) {
  flex: 1;
}

.ProgramTrigger *:nth-child(3) {
  padding: 0 10px;
}

.ProgramTrigger img {
  width: 32px;
  height: 32px;
  margin: 12px;
}

.ProgramTrigger span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.publishButtonIcon svg path:first-of-type {
  stroke: var(--color-brandShadeDark);
}

.publishButtonIcon svg path:nth-of-type(2) {
  stroke: var(--color-brandTintDark);
}

.publishButtonIcon svg path:nth-of-type(3) {
  stroke: var(--color-brandTintDark);
}

@media screen and (min-width: 1366px) {
  :root {
    --program-sidebar-width: 196px;
  }

  .programPickerDropdown {
    left: -170px;
  }

  .programPickerDropdown.withLogo {
    width: 240px;
  }

  .programPickerDropdown > div:first-child {
    width: 100%;
  }

  .programPickerDropdown .logo {
    width: 100%;
    display: block;
  }

  .publishButton {
    margin-bottom: 35px;
    padding: 0 25px;
    padding-top: 2px;
  }

  .publishButtonIcon {
    margin: 0 8px 0 0;
  }

  .publishButtonLabel {
    display: inline-block;
  }

  .programTrigger img {
    margin-right: 20px;
  }

  .programTrigger span {
    display: initial;
    flex: 1;
  }
}

.MessageBubble {
  display: block;
  padding: 1rem 1.5rem;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.MessageBubble.MessageBubble--info {
  background-color: var(--color-blueTintLight);
  border-color: var(--color-blueTintDark);
}

.MessageBubble.MessageBubble--success {
  background-color: var(--color-greenTintLight);
  border-color: var(--color-greenTintLight);
}

.MessageBubble.MessageBubble--warning {
  background-color: var(--color-orangeTintLight);
  border-color: var(--color-orangeTintLight);
}

.MessageBubble.MessageBubble--danger {
  background-color: var(--color-redTintLight);
  border-color: var(--color-redTintLight);
}

.noStyleButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.CalendarPageBody > main,
.CalendarPageBody .ListPage,
.CalendarPageBody .PageContentList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
}

.CalendarPageBody .ListPage {
  overflow: hidden;
}

.menu {
  height: 40px;
  width: 40px;
}

.button {
  color: var(--color-gray80);
  text-align: center;
  background-color: transparent;
  letter-spacing: 2px;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  margin-left: 8px;
  cursor: default;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 8px;
  transform: rotate(0deg);
}

.button:hover {
  background-color: var(--color-gray02);
  box-shadow: none;
}

.hoverDropdown {
  right: 10px;
}

.ActionsGroup {
  display: flex;
}
