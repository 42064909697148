.stopMessage {
  color: var(--color-gray00);
  font-size: var(--font-size-caption);
}

.stopProcessingButton {
  color: var(--color-gray00);
  text-decoration: underline;
  font-size: var(--font-size-caption);
  cursor: pointer;
}

.stopProcessingButton:hover {
  background: none;
  color: var(--color-gray00);
}
