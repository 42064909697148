.monthlyCalendar {
  display: flex;
  align-items: center;
  height: 100%;
}

.leftShift,
.rightShift {
  border: 0;
  outline: 0;
  background: none;
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 0;
  cursor: pointer;
  color: #666;
}

.leftShift:hover,
.rightShift:hover {
  color: black;
}

.leftMonth,
.rightMonth {
  flex: 1;
  height: 100%;
  margin: 0 10px;
}

.rightMonth > * {
  margin: 0 0 0 auto;
}
