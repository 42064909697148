.CreatePill {
  margin-left: 6px;
  margin-top: 6px;
}

.InitiativePill {
  margin-bottom: 0;
  max-width: 85%;
}

.CreateButton {
  height: auto;
  text-align: left;
}
