.Title {
  font-weight: 600;
  font-size: 20px;
}

.circleCheckAlmostReady rect {
  stroke: var(--color-orangeFull);
}

.circleCheckAlmostReady path {
  fill: var(--color-orangeFull);
}
