.Icon svg {
  width: 22px;
  height: 22px;
}

.Label {
  display: flex;
}

.Label .Icon {
  margin-right: 10px;
}
