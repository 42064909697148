.bannerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.headerContainer {
  display: flex;
  width: 100%;
  flex-grow: 1;
}

.topicsAndAudiences {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  max-width: 40%;
}
