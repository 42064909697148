.groupBuilder {
  background-color: var(--color-gray05);
  padding: 16px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  border-radius: 5px;
  border: 1px solid transparent;
  width: 100%;
  max-width: 1000px;
  min-height: 72px;
}

.operatorSwitch {
  margin: 0 -11px 8px -11px;
}

.addGroupButton,
.addCriteriaButton {
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  height: 40px;
}

.addCriteriaButton {
  /* Necessary to compensate for 24px bottom margin on default audience builder row */
  margin-top: -16px;
}

.addCriteriaButton:hover,
.addGroupButton:hover {
  cursor: pointer;
}

.count {
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: 600;
}
