.pageActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  position: relative;
}

.buttonMore {
  padding: 8px;
}

.moreDropdown {
  right: 20px;
}
