.LinkList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.LinkItem {
  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: 8px;
  align-items: center;
}

.LinkItem__Content {
  background: var(--color-gray02);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.AddLinkButton {
  width: fit-content;
}

.RemoveLinkButton {
  & i {
    margin: 0;
  }
}

.Label {
  display: block;
  margin-bottom: 8px;
}

.ErrorMessage {
  composes: Caption from 'DesignSystem/Typography/typography.module.css';
  color: var(--color-redFull);
}
