.textInput {
  width: 100%;
}

.imagePreview {
  height: 88px;
  width: 88px;
  margin-bottom: 16px;
}

.headerImagePreview {
  height: auto;
  width: 100%;
  max-width: 720px;
}

.removeButton {
  background-color: var(--color-gray00);
  border: none;
  color: var(--color-gray90);
  font-weight: 400;
  padding: 5.5px 16px;
  border-radius: 4px;
}

.changeButton {
  padding: 5.5px 16px;
  font-weight: 400;
  background-color: var(--color-gray00);
  color: var(--color-gray90);
  border-radius: 4px;
}

.linkList {
  margin-bottom: 32px;
}

.removeLinkItem {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px;
  margin-right: 16px;
  background-color: transparent;
  border: none;
  color: var(--color-redShadeLight);
  padding: 11px 13px;
}

.removeLinkItem > * i {
  margin-right: 0;
}

.linkList > li {
  border-radius: 8px;
  background-color: var(--color-gray02);
  padding: 16px;
  position: relative;
  margin: 0 0 16px 0;
  text-indent: 0;
  list-style-type: none;
}

.linkList > section {
  border-radius: 8px;
  background-color: var(--color-gray02);
  padding-bottom: 8px;
  margin-bottom: 0;
}

.linkList > section > span {
  margin: 8px 0;
}

.linkList > li > section > span:first-child {
  font-weight: 600;
  margin-top: 0;
  padding-top: 0;
}

.addLinkButton {
  padding: 5.5px 16px;
  font-weight: 400;
  background-color: var(--color-gray00);
  color: var(--color-gray90);
  border-radius: 4px;
  line-height: 150%;
  font-size: 14px;
}

.errorLabelContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  color: var(--color-redFull);
}

.selectValuesList {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 8px;
  flex-wrap: wrap;
}

.selectValuesList > li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.selectValues {
  border-radius: 40px;
  padding: 6px 16px;
  font-size: 12px;
  line-height: 18px;
}

.hiddenSelectedItemsLabel {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-primary30);
  justify-content: center;
  align-items: center;
  height: 100%;
}

.enableContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
}
