
.List {
  list-style-type: none;
  overflow: auto;
}

.Item {
  display: block;
}

.ItemList {
  width: 100%;
  margin: 3px 0;
}

.ItemGrid {
  composes: Item;
  margin: 3px;
  float: left;
}

.ItemGridSm {
  composes: ItemGrid;
  width: 76px;
  height: 76px;
}
