.tooltip {
  position: absolute;
  width: 300px;
}

.helpIcon {
  min-height: 16px;
  cursor: pointer;
  margin-left: 5px;
}

.tooltipContent > div:last-child {
  padding-top: 14px;
}

.portal {
  position: absolute;
  z-index: var(--z-index-shared-hover-tooltip);
}

.portal :global .dropdown-align-right {
  z-index: calc(var(--z-index-shared-hover-tooltip) + 1);
}
