.CtaImage {
  width: 256px;
  height: 140px;
  border-radius: 4px;
  border: 1px solid var(--color-gray10);
}

.CtaDataContainer {
  display: flex;
  align-content: center;
}

.AuthorAvatar {
  width: 24px;
  height: 24px;
}

.AuthorName {
  margin: auto 0 auto 12px;
}

.CtaTitle {
  font-weight: 600;
  font-size: 16px;
}

.CtaSummary {
  line-height: 150%;
  font-size: 14px;
}

.border {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #e5e7eb;
}
