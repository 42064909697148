.PreviewContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Preview {
  width: 60px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 4px;
}
