.input {
  background: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
}

.field span {
  display: block;
  align-self: center;
  font-weight: 600;
  font-size: 14px;
}

.lastFieldset {
  composes: lastFieldset from './link-fetcher.module.css';
}

.urlContainer {
  margin: 0;
}

.url {
  position: relative;
  margin-bottom: unset;
  width: var(--program-modal-editor-width);
}

.url input {
  width: 100%;
  height: 40px;
}

.url button {
  cursor: pointer;
  border: none;
  background: transparent;
  position: absolute;
  opacity: 0.25;
  outline: none;
  right: 40px;
  top: 54%;
}

.title textarea {
  height: 65px;
}

.description textarea {
  height: 87px;
}

.call-to-action textarea {
  height: 40px;
}

.removeImage > div {
  position: relative;
  text-align: center;
  top: 10px;
  left: -45px;
  display: block;
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
  padding: 3px 0 0 0;
}

.linkField > div:first-child {
  width: 136px;
}

.url > div:last-child {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  padding-bottom: 0 !important;
}

.title textarea,
.description textarea,
.call-to-action textarea,
.alt-text textarea {
  resize: none;
  padding: 9px 16px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 22.6px;
}

.imageField .placeholder {
  display: flex;
  background: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  box-sizing: border-box;
  border-radius: 6px;
  color: var(--color-gray00);
  align-items: center;
  justify-content: center;
}

.imageField > div:last-child {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.imageField > div:first-child {
  width: 136px;
}

.imageField .image {
  border-radius: 6px;
}

.errors {
  composes: flex-column from global;
  align-items: center;
  margin-top: 10px;
}

.imageUpload {
  outline: none;
  padding: unset;
  border: none;
  background: transparent;
  width: 387px;
  height: 237px;
}

.imageUpload.custom {
  width: 477px;
  height: 250px;
}

.removeImage {
  width: 0;
  overflow: visible;
  position: absolute;
  top: 0;
  right: 0;
}

.contentUrl {
  composes: kai-flex-row from global;
  margin-left: 35px;
}

.contentUrl > svg {
  margin-right: 10px;
}

.removeImage svg {
  transform: scale(0.75, 0.75);
  opacity: 0.75;
}

.removeImage svg,
.removeImage svg > rect,
.removeImage svg > path {
  stroke: white;
}

.removeImage:hover > div {
  box-shadow: 0 0 1px 1px white;
}

.removeImage:hover > div svg {
  opacity: 1;
}

.linkField > div:last-child {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  padding-right: 32px !important;
}

.contentUrl > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 400px;
}

.unableToProcess {
  composes: flex-column from global;
  color: var(--color-gray70);
  justify-content: space-around;
  border: 1px solid var(--color-gray20);
  padding: 20px;
}
