.column {
  flex: 1;
  width: 17%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
}

.header {
  padding-left: 2px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.today .header {
  font-weight: bold;
}

.header .title {
  font-size: 28px;
  line-height: 34px;
  font-weight: inherit;
}

.header .sub {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: inherit;
}

.header > :first-child {
  display: block;
}

.column .spinner {
  text-align: center;
  padding-top: 20px;
}

.item,
.empty {
  min-width: 129px;
  background-color: var(--color-gray02);
  height: 189px;
  padding: 0; /* The content is padding from the link element below */
  margin: 8px 4px; /* The vertical is collapsing, but the horizontal isn't. */
  overflow: visible; /* For the pop-out menu */
  position: relative; /* For the shadow effect on hover  */
  z-index: 1;
  transition: all 0.1s linear;
  border-radius: 4px;
}

.item {
  color: var(--color-gray90);
}

.item:hover {
  z-index: 2;
  background-color: var(--color-gray10);
  box-shadow: none;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--color-gray70);
  text-align: center;
}

.empty svg {
  margin-bottom: 10px;
}

.item .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.item .cover {
  display: inline-block;
  height: 32px;
  width: 32px;
  margin-right: 12px;
  background-color: var(--color-gray10);
  background-size: contain;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
}

.item .time {
  white-space: nowrap;
}

.item .author,
.item .time {
  color: var(--color-gray70);
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
}

.item .reach {
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
}

.item .draft {
  background-color: var(--color-gray10);
  line-height: 28px;
  font-weight: 600;
  color: var(--color-gray70);
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding: 0 12px;
  border-radius: 60px;
}

.item .hotspot {
  position: absolute;
  bottom: 12px;
  left: 12px;
  height: 20px;
  cursor: pointer;
  min-width: 20px;
}

.item > a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.item > a > * {
  /* consistent spacing for each "thing" in the item */
  margin-bottom: 8px;
}

.item > a > *.spread {
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0;
}

.DayHeader {
  justify-content: center;
  position: sticky;
  min-height: 50px;
  height: 50px;
  top: 0;
  background: #fff;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  padding-bottom: 5px;
}

.today .header * {
  font-weight: bold;
}

.seeAll {
  text-decoration: none;
  font-size: 14px;
  color: var(--color-gray90);
  padding: 6px 16px;
  border-radius: 8px;
}

.seeAll:hover {
  background-color: var(--color-gray05);
}
