.titleBar {
  composes: titleBar from '../insight.module.css';
  display: flex;
}

.titleBar .titleContainer {
  flex-grow: 1;
}

.reportLoadingSpinnerContainer {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleBar .reportButtonsContainer {
  flex-grow: 0;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  position: relative;
}

.titleBar .reportButtonWrapper {
  margin-left: 10px;
  padding: 13px 15px;
  width: 24px;
  height: 24px;
  box-sizing: content-box;
  background-color: var(--color-gray05);
  border-radius: 100%;
  transition: var(--insights-hover-background-change-transition-time);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleBar .reportButtonWrapper:hover {
  background-color: var(--color-gray30);
  cursor: pointer;
}

.titleBar .reportButtonWrapper:focus {
  outline: none;
}

.titleBar .reportButtonWrapper.active {
  background-color: var(--color-gray30);
}

.banner {
  font-size: 23px;
  font-weight: 400;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  color: rgb(0, 0, 0) !important;
  height: 56px;
  padding: 20px 28px 0 28px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.modalActions {
  border-top: 1px solid #eaeaea;
  padding: 18px 28px;
  font-size: 15px;
}

.copyButton input {
  border: none;
  color: #3e3e40;
  font-size: 12px;
  height: 39px;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 10px 8px;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.modalActions div:first-child {
  margin-bottom: 25px;
  line-height: 1.6;
}

.modalActions div:first-child input {
  width: 100%;
  margin-top: 10px;
  height: 42px;
  background-color: var(--color-gray00);
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  padding: 8px;
}

.visiblityOption {
  float: left;
}

.visiblityOption small {
  font-size: 13px;
  color: rgb(123, 123, 128);
}

.modalActions .actions {
  display: flex;
  justify-content: flex-end;
}

.modalActions .actions .primary {
  background-color: #2185d0;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--color-gray00) !important;
  text-shadow: none;
}

.uiButtons {
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Inter", 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
}

.uiButtons:hover {
  background-color: #cacbcd;
}

.uiButtons:disabled {
  opacity: 0.45;
}

.downloadInProgress {
  font-size: 15px;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  background: var(--color-gray90);
  color: var(--color-gray00);
  padding: 10px 15px;
  border-radius: 10px;
}

.downloadInProgress > small {
  display: block;
  font-size: 13px;
  font-weight: normal;
  margin-top: 4px;
}

.shareModal {
  animation: fadeIn 0.25s forwards;
  position: absolute;
  padding: 15px;
  background-color: var(--color-gray00);
  border: 2px solid var(--color-gray05);
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
  width: 362px;
  z-index: var(--z-index-share-url-modal);
  top: 140px;
  right: 45px;
}

.shareModal h4 {
  margin-bottom: 3px;
  font-size: 11px;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--color-gray30);
}

.shareModal .copyButton {
  border: 2px solid var(--color-gray05);
  display: flex;
}

.copyButton button {
  font-size: 13px;
  font-weight: 400;
  color: #448aff;
  background-color: var(--color-gray02);
  border: none;
  border-left: 2px solid var(--color-gray05);
  cursor: pointer;
  padding: 0.75em 1em;
}

.copyButton button:hover {
  background: var(--color-gray05);
}

.alertMessage {
  padding: 10px;
  border: 1px solid rgb(255, 30, 0);
  border-radius: 7px;
  color: rgb(255, 30, 0);
  background-color: rgb(252, 223, 223);
}

.inputError {
  color: rgb(255, 30, 0);
  font-size: 13px;
}

.toastStyle {
  opacity: 1;
  text-align: center;
  border-radius: 5px;
  padding: 10px 10px;
  position: absolute;
  z-index: 999; /* Make sure the it's on top of everything */
  top: -39px;
  left: -49px;
  height: auto;
  background: blue;
  color: white;
  animation: fadeOut 1s forwards;
}

.hideToast {
  display: none;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
