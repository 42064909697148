.Icon svg {
  width: 22px;
  height: 22px;
}

.Description > span {
  height: 20px;
}

.Description {
  margin-top: 5px;
}

.DescriptionWrapper {
  width: calc(100% - 93px);
}

.DescriptionWrapper,
.Description > span {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NotificationContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
