.panel {
  height: 440px;
  width: max-content;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 6px 0 0 6px;
  background-color: var(--color-gray00);
}

.panel.inactive {
  background-color: #f8fafb;
}

.panel > div {
  padding: 15px 0 7px 0;
}

.panel > div > strong {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  padding-left: 16px;
  padding-bottom: 12px;
}

.panel button {
  border: 0;
  outline: 0;
  background: none;
  display: flex;
  width: 100%;
  padding-left: 16px;
  font-size: 13px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: normal;
  color: #111;
  cursor: pointer;
}

.panel > div > div:hover {
  background-color: var(--color-gray10);
}

.panel button.selected,
.panel > div > div.selected {
  color: white;
  background-color: var(--color-brandTintDark);
  cursor: default;
}

.panel > div button.selected,
.panel > div > div.selected {
  background-color: var(--color-brandShadeDark);
}

.panel ::-webkit-scrollbar {
  width: 8px;
}

.panel ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.panel ::-webkit-scrollbar-thumb {
  background: var(--color-gray10);
}

/* Handle on hover */
.panel ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
