.mainWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  font-size: 12px;
}

.separator {
  font-size: 12px;
}

.dateButton {
  height: 32px;
  width: 118px;
  font-size: 12px;
}

.dateButton span[class*='icon'] {
  margin-right: 0;
}

.filterDropdownContainer {
  display: flex;
  flex-direction: column;
  min-width: 220px;
  border-radius: var(--insights-filter-dropdown-border-radius);
  overflow: hidden;
  box-shadow: 0 21.53px 25.84px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
  background-color: white;
}
