.wrapper {
  top: 40%;
  background-color: var(--color-gray00);
  left: 55%;
}

.dropdown {
  composes: flex-column from global;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 0;
  width: 207px;
}

.dropdown .button {
  border: none;
  cursor: pointer;
  background-color: var(--color-gray00);
  color: var(--color-gray90);
  font-size: 15px;
  padding: 0;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.dropdown .button:hover {
  background-color: var(--color-gray05);
}

.dropdown .button > * {
  padding: 0 10px;
}
