.userPage div[class*='labelContainer'] {
  color: var(--color-gray70);
  padding: 24px 32px;
  display: flex;
}

.userPage div[class*='fieldset_input'] {
  padding: 24px;
  color: var(--color-gray90);
}

.slimFitFieldGroupContainer {
  width: fit-content;
}

.profilePicture {
  max-width: 150px;
}

.avatarWrapper {
  width: fit-content;
}

.statusFieldGroup {
  height: 72px;
}

.statusWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.statusWrapper > div {
  border-right: 2px solid #fff;
  flex: 1;
  padding: 10px 24px;
}

.statusWrapper > div:last-child {
  border-right: none;
}

.textField {
  width: 100%;
}

.showMore span:first-child {
  padding-right: 10px;
}

.showMoreText {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.empty {
  color: var(--color-gray70);
  font-size: 14px;
}

.userStatusGroup {
  composes: flex-column flex-centered from global;
}

.userStatusIcon {
  color: var(--color-gray70);
}

.userStatusIcon.activeIcon {
  color: var(--color-gray90);
}

.userStatusLabel {
  font-size: 12px;
  margin-top: 5px;
}

.dropdownWrapper {
  composes: dropdown from global;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dropdownItem {
  border-radius: 6px;
  font-size: 14px;
  padding: 5px 15px 5px 10px;
}

.dropdownItem:hover {
  background-color: var(--color-gray02);
  cursor: pointer;
}

.itemOptionsWrapper {
  float: left;
  width: 200px;
  padding: 5px;
}

.itemDescriptionWrapper {
  float: left;
  width: 400px;
  min-height: 150px;
  padding: 15px;
  background-color: var(--color-gray05);
}

.itemDescriptionWrapper h2 {
  color: var(--color-gray80);
}

.topicBlock {
  width: 50%;
}

.topicAvatarWrapper {
  composes: kai-flex-row from global;
}

.topicAvatar {
  display: inline-block;
  margin-right: 24px;
}

.topicAvatar img {
  height: 40px;
  width: 40px;
}
