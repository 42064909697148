.header {
  border-bottom: 1px solid var(--color-gray02);
  display: flex;
  padding: 15px 10%;
}

.fullPage {
  composes: publish-main-content from global;
  flex: 1;
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.leftPanel {
  width: 75%;
  float: left;
  background-color: var(--color-gray00);
  padding: 0;
  min-height: 500px;
  padding-bottom: 64px;
  padding-top: 25px;
}

.actionWrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
}

.buttons {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons button {
  background-color: var(--color-gray05);
  border: none;
  border-radius: 6px;
  color: var(--color-gray90);
  cursor: pointer;
}

.btnPreview {
  composes: kai-flex-row from global;
  width: 110px;
  height: 35px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
}

.btnPreview svg {
  width: 25px;
  height: 14px;
  margin-right: 5px;
}

.btnMore {
  composes: kai-flex-row from global;
  height: 35px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
  padding: 0 10px;
}

.btnMore svg {
  margin-left: 10px;
}

.moreButtonMenu {
  width: 120%;
  text-align: left;
  padding-top: 10px;
  background-color: var(--color-gray00);
  border: solid 1px silver;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.moreButtonWrapper {
  display: inline-block;
}

.moreButtonMenu p {
  font-size: small;
  padding: 15px 10px 15px 30px;
  background-color: var(--color-gray05);
  color: var(--color-gray50);
  margin: 0;
}

.moreButtonMenu p strong {
  margin-top: 5px;
  display: block;
}

.disabledToggleDeactivatedUsers {
  opacity: 0.4;
  cursor: default;
}

.toggleDeactivatedUsers {
  display: flex;
  justify-content: space-between;
  color: var(--color-gray90);
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
  padding: 10px 30px;
  margin-bottom: 6px;
  background-color: var(--color-gray00);
  line-height: 1;
}

.toggleDeactivatedUsers:hover {
  background-color: var(--color-gray02);
  cursor: pointer;
}

.audienceBuilder {
  font-size: medium;
  padding: 48px 10% 0 10%;
  height: 90%;
  overflow-y: auto;
}

.criteriaTerm {
  background-color: var(--color-gray05);
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.simpleExpression {
  position: relative;
  clear: both;
}

.criteriaSelectorLeft {
  float: left;
  padding: 0 5px 24px 0;
  width: 45%;
  cursor: pointer;
  position: relative;
}

.normalMenuItems a {
  color: var(--color-gray90);
  font-weight: 600;
}

.dangerMenuItems {
  border-top: 1px solid var(--color-gray10);
  padding-top: 6px;
  padding-bottom: 10px;
}

.dangerMenuItems a {
  color: #fc5f69;
  font-weight: 600;
}

.dropMenu {
  position: absolute;
  background-color: var(--color-gray00);
  width: 150%;
  top: 30px;
  left: 0;
  border: solid 1px silver;
  padding: 10px;
}

.dropMenuLeftPanel {
  float: left;
  width: 50%;
  text-align: left;
  padding-left: 25px;
}

.dropMenuRightPanel {
  float: left;
  width: 45%;
  text-align: left;
}

.criteriaSelectorMiddle {
  float: left;
  width: 15%;
  padding: 0 5px 24px 0;
}

.criteriaSelectorRight {
  width: 40%;
  float: left;
  cursor: pointer;
  position: relative;
  padding: 0 5px 24px 0;
}

.criteriaSelectorDelete {
  position: absolute;
  top: 12px;
  right: -60px;
  cursor: pointer;
  padding: 0;
}

.criteriaSelectorDelete > button {
  border: 0;
  color: #5e6465;
  cursor: pointer;
  background: none;
}

.buttons button:hover {
  background-color: var(--color-gray02);
  color: var(--color-brandTintDark);
}

.buttons button:disabled {
  background-color: var(--color-gray05);
  cursor: url('assets/images/cursor-notallowed.png'), not-allowed;
  color: var(--color-gray50);
}

button.btnFavorite {
  background: none;
  border: none;
  font-size: 16px;
  outline: none;
  display: inline-block;
}

button.btnFavorite:hover {
  background-color: none;
  color: var(--color-gray90);
}

button.btnFavorite:disabled {
  background-color: transparent;
  border: none;
  color: var(--color-gray50);
}

button.btnSave,
button.btnSave:disabled {
  width: 84px;
  height: 35px;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 rgba(150, 184, 208, 0.4);
  background-color: var(--color-brandTintDark);
  color: var(--color-gray00);
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
}

button.btnSave:disabled {
  opacity: 0.5;
}

.valueDropMenu {
  position: absolute;
  text-align: left;
  background-color: var(--color-gray00);
  width: 100%;
  top: 30px;
  left: 0;
  border: solid 1px silver;
  padding: 10px;
  padding-left: 30px;
}

.valueDropMenu li {
  float: none;
}

.button {
  background: none;
  border: none;
}

.clickable {
  cursor: pointer;
}

.menuWrapper {
  background-color: var(--color-gray05);
  width: 200%;
  border: solid 1px silver;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  z-index: var(--z-index-audience-builder-menu-wrapper);
}

.offsetSpacer {
  position: absolute;
  height: 350px;
  width: 100%;
}

.menuItemsWrapper {
  z-index: var(--z-index-audience-builder-menu-items-wrapper);
  width: 50%;
  float: left;
  position: relative;
  border-right: 1px solid #deebff;
}

.permissionDesc {
  z-index: var(--z-index-audience-builder-permission-description);
  font-size: small;
  padding: 20px 40px 0 0;
  position: relative;
  left: 20px;
  float: left;
  width: 50%;
}

.genericError {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-redFull);
  border-radius: 5px;
}

.requiredError {
  color: var(--color-redFull);
  padding-top: 8px;
  margin-left: 1px;
}

.operatorLabel {
  font-size: 14px;
  color: var(--color-gray50);
  padding: 8px 0 24px 0;
}

.sidebar {
  composes: publish-sidebar from global;
  background-color: var(--color-gray05);
  margin-bottom: -40px;
  overflow-y: scroll;
  min-height: 100vh;
}

.expressionStatus {
  position: absolute;
  top: 13px;
  right: 18px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray50);
}

.expressionStatusRelative {
  position: relative;
  top: 13px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray50);
}

.expressionStatusError {
  composes: expressionStatus;
  color: #f0453a;
}

.expressionStatusRelativeError {
  composes: expressionStatusRelative;
  color: #f0453a;
}

.advancedBuilderContainer {
  height: 70vh;
  position: relative;
  border: 1px solid var(--color-gray10);
}

.textbox {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  outline: none;
  padding: 46px 18px;
}

.expressionHeader {
  clear: both;
  padding: 12px 0 60px 0;
}

.criteriaTerm .expressionHeader {
  padding-bottom: 44px;
}

.expressionHeader .count {
  float: left;
  font-weight: bold;
  font-size: 24px;
}

.criteriaTerm .expressionHeader .count {
  font-size: 18px;
}

.expressionHeader .operator {
  float: right;
  position: relative;
  top: 10px;
}

.criteriaTerm .expressionHeader .operator {
  top: 4px;
  left: 4px;
}

.expressionOperatorLabel {
  cursor: pointer;
  font-size: 12px;
  margin: 0 11px 0 11px;
  position: relative;
  top: -3px;
  display: inline-block;
  transition: color 0.2s;
}

.expressionOperatorLabel:last-child {
  margin-right: 0;
}

.expressionOperatorLabel.inactive {
  color: var(--color-gray30);
}

.addSimple {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
}

.addComplex {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background-color: var(--color-gray05);
  padding: 16px 24px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
}

.addExpressionIcon {
  padding-right: 4px;
  position: relative;
  top: 2px;
}

.expressionOperator {
  display: inline-block;
}

.audienceCountIcon {
  position: relative;
  top: 3px;
  padding-right: 4px;
}

.advancedBuilderInput {
  display: flex;
  height: 100%;
}

.criteriaMenu {
  overflow-y: scroll;
  background-color: var(--color-gray05);
  width: 350px;
  padding: 18px 0;
}

.criteriaMenuItem {
  cursor: pointer;
}

.criteriaCategory {
  padding: 10px 18px;
}

.criteriaCategoryText {
  padding-left: 18px;
  font-weight: 400;
  font-size: 14px;
}

.criteriaLabel {
  padding: 11px 0;
  cursor: pointer;
}

.criteriaLabel:hover {
  background-color: var(--color-gray10);
}

.criteriaLabelText {
  padding-left: 52px;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.toggleItem {
  float: left;
  padding-left: 10px;
  padding-top: 10px;
}

.toggleItem2 {
  float: left;
  padding-left: 5px;
  padding-top: 4px;
  font-size: 20px;
}

.toggleItem3 {
  float: left;
  padding-left: 15px;
  margin-top: 4px;
}

.sparkle {
  float: left;
  fill: coral;
}

.rowMember {
  flex: 1;
  height: 30px;
  float: left;
  vertical-align: middle;
}

.assistantHeader {
  width: 100%;
  position: relative;
  height: 20px;
}

.assistantTitle {
  float: left;
  margin: 10px 0;
}

.assistantFeedback {
  float: right;
  margin-bottom: 10px;
}

.questionMark {
  float: left;
  padding-left: 10px;
}

.aiTooltip {
  width: 100px;
  float: left;
  margin-left: 10px;
  margin-right: 0;
}

.feedbackContainer {
  float: right;
  width: 300px;
  text-align: right;
}

.promptTextboxContainer {
  position: relative;
  width: 100%;
  height: max-content;
  margin-top: 10px;
}

.promptTextbox {
  position: relative;
  width: 100%;
  height: 100px;
}

.AIHelperBottomRow {
  height: 40px;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.generateButton {
  float: right;
  font-size: 15px;
  height: 100%;
  width: 145px;
}

.errorBanner {
  float: left;
  height: 100%;
  width: 325px;
}

.sidestripe {
  width: 4px;
  height: 100%;
  background-color: orange;
  border-radius: 4px 0 0 4px;
  border: 1px solid orange;
  float: left;
}

.bannerContent {
  background-color: rgb(255, 242, 218);
  border-radius: 4px;
  border: 1px solid transparent;
  height: 100%;
}

.warningSign {
  /* stroke: black; */
  height: 20px;
  width: 20px;
  float: left;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.errorText {
  color: gray;
  font-size: 12px;
  float: left;
  margin-top: 12px;
}

.xSign {
  float: right;
  margin: 8px;
  margin-right: 15px;
  cursor: pointer;
}

.sensitiveDataContainer {
  float: left;
  width: 200;
  height: 100%;
  margin-top: 10px;
  font-size: 12;
}

.spinner {
  height: max-content;
  width: 20px;
  margin: 10px 20px 0 0;
  float: right;
}

.disabledSelect {
  cursor: not-allowed;
}

.disabledSelect > div > div {
  background-color: var(--color-gray10);
  opacity: 0.7;
}
