.SectionContainer {
  margin-bottom: 20px;
  border: solid 1px var(--color-gray10);
  border-radius: 8px;
  background-position: right 0;
  background-size: 187px;
  background-repeat: no-repeat;
  background-origin: revert-layer;
  background-color: white;
  position: relative;
}

.SectionContainer .caption {
  margin-top: 16px;
}

.BigHeader {
  padding: 32px;
  padding-right: 180px;
  padding-bottom: 0;
  margin-bottom: 31px;
}

.SmallHeader {
  padding: 32px;
  border-bottom: 1px solid var(--color-gray10);
}

.HeaderBottomBorder {
  border-bottom: 1px solid var(--color-gray10);
}

.overlay {
  position: absolute;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  background-color: var(--color-gray00);
  z-index: 1000;
}
