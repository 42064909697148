.Avatar {
  composes: flex-column from global;
  justify-content: space-around;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 16px;
}

.Avatar,
.Avatar > svg {
  width: 32px;
  height: 32px;
}
