.wrapper {
  padding-top: 72px;
}

.row {
  padding: 24px;
  border-top: 2px solid var(--color-gray00);
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
  font-size: 14px;
  line-height: 1.4;
}

.stats > span:first-of-type {
  font-weight: 400;
}

.stats > span:last-of-type {
  font-weight: 500;
}

.action {
  font-weight: 600;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.action > span:first-of-type {
  font-size: 14px;
  margin-right: 26px;
  display: flex;
}

.header {
  font-weight: 600;
  line-height: 1.3;
  font-size: 16px;
}

.topContributor {
  margin-top: 19px;
  height: 50px;
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background-size: cover;
  background-position: 50% 50%;
}

.avatar > img {
  width: 40px;
  border-radius: 20px;
}

.role {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--color-gray90);
}

.title {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
}
