:root {
  --header-height: 48px;
  --footer-height: 64px;
  --left-panel-width: 56px;
}

.Heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-gray40);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

  & > strong {
    color: var(--color-gray90);
    font-weight: 600;
  }
}

.HeadingSeparator {
  font-size: 20px;
  margin: 0 12px;
}

.HeadingStatus {
  margin-top: 8px;
  margin-left: 12px;
}

.HeaderActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ActionButton {
  padding: 4px;

  &:last-child {
    margin-left: 32px;
  }
}

.SaveStatus {
  color: var(--color-gray60);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  padding: 4px 16px;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
  }
}

.StatusUnsaved {
  composes: SaveStatus;

  &::before {
    background: var(--color-redFull);
  }
}

.StatusSaving {
  composes: SaveStatus;

  &::before {
    background: var(--color-orangeFull);
  }
}

.StatusSaved {
  composes: SaveStatus;

  &::before {
    background: var(--color-greenFull);
  }
}

.Toolbar {
  position: fixed;
  left: 0;
  top: 0;
  width: var(--left-panel-width);
  height: 100%;
  padding: calc(12px + var(--header-height)) 8px 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  border-right: 1px solid #e3e1eb;
  background: #fff;
  z-index: 10;
}

.ToolbarAction {
  padding: 8px;
  width: 40px;
  height: 40px;
}

.Container {
  position: relative;
  top: var(--header-height);
  padding-left: var(--left-panel-width);
  height: calc(100vh - var(--header-height) - var(--footer-height));
  overflow-y: auto;
}

.Sidebar {
  position: fixed;
  top: 48px;
  right: 0;
  bottom: var(--footer-height);
  width: 320px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);

  &.SidebarOpen {
    transform: translateX(0);
  }
}

.SidebarLeft {
  composes: Sidebar;
  left: var(--left-panel-width);
  right: auto;
  transform: translateX(-100%);
}

.SidebarRight {
  composes: Sidebar;
  right: 0;
}

.SidebarHeader {
  background-color: inherit;
  border-bottom: 1px solid var(--color-gray10);
  padding: 16px;
  position: sticky;
  top: 0;
  color: var(--color-gray90);
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.SidebarClose {
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  height: 24px;
  width: 24px;
}

.SidebarBody {
  padding: 16px;
}

.PageHeading {
  composes: PageHeading from 'DesignSystem/Typography/typography.module.css';
  display: block;

  /* stylelint-disable-next-line declaration-no-important */
  margin: 40px 0 32px !important;
}
