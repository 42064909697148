.scanningAnimation {
  width: 22px;
  height: 22px;
  animation: scanning 1.2s linear infinite;
  position: relative;
}

@keyframes scanning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
