.form div[class*='fieldset_labelContainer'] {
  color: var(--neutral-dark-3);
  display: flex;
  align-items: center;
  padding-top: 0;
}

.form div[class*='fieldset_input'] {
  padding: 24px 32px;
  color: var(--neutral-dark-1);
}

.textField {
  width: 100%;
}

.textField:not(:last-child) {
  margin-bottom: 36px;
}

.Section {
  margin-bottom: 32px;
  border: 1px solid var(--color-gray10);
  border-radius: 8px;
}

.SectionHead {
  padding: 32px;
  border-bottom: 1px solid var(--color-gray10);
}

.SectionHead header {
  display: flex;
  justify-content: space-between;
}

.SectionHead > span {
  display: block;
}

.SectionHead > span:not(:first-child) {
  margin-top: 16px;
}

.SectionBody {
  padding: 32px;
}

.Loading {
  display: flex;
  justify-content: center;
}

.PermissionRow {
  border-bottom: 1px solid var(--color-gray10);
  padding: 16px 0;
}

.PermissionRow:last-child {
  border-bottom-color: transparent;
}

.Permission {
  box-sizing: border-box;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PermissionInfo {
  display: flex;
  padding-right: 10px;
}

.PermissionCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.PermissionIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-right: 16px;
}

.Title {
  margin-bottom: 4px;
}

.SubPermissionContainer {
  padding-left: 59px;
}

.SubPermissionContainer .PermissionRow {
  border-color: transparent;
}
