.Sidebar {
  padding: 40px 0;
}

div:global(.deliver-redesign) .Sidebar h3:global(.planSectionHeader) {
  font-size: 23;
  background-color: red;
}

div:global(.deliver-redesign) .Sidebar h3:global(.section-header) {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.DeliverSidebar:hover,
.DeliverSidebar:focus {
  max-height: 100%;
  min-height: 100%;
  overflow: auto;
}

.DeliverSidebarColumn {
  align-self: baseline;
}
