.bannerContent,
.body,
.footerContent {
  padding: 16px;
}

.confirmButton {
  color: var(--color-brandFull);
}

.loadingContainer {
  width: 100%;
}

.loadingContainer > div {
  margin-left: 0;
}

.centerBody {
  text-align: center;
  margin-bottom: 15px;
}

.visibilityHidden {
  visibility: hidden;
}
