.metricsItem {
  align-items: flex-start;
  justify-content: space-around;
  height: 90px;
  background-color: var(--color-gray02);
  border: 1px solid var(--color-gray10);
  border-radius: 8px;
  text-decoration: none;
  padding: 16px;
}

.itemName,
.itemStats {
  font-style: normal;
  color: var(--color-gray90);
}

.itemName {
  font-weight: 400;
  font-size: 14px;
}

.itemStats {
  font-weight: 600;
  font-size: 16px;
}

.itemStats > span:not(:first-child) {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray60);
}

.highlight {
  color: var(--color-redFull);
}
