.mainLayout {
  display: flex;
}

.container {
  align-items: flex-start;
  clear: both;
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
}

.main {
  box-sizing: border-box;
  padding-right: 50px;
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.pageActionWrapper {
  display: flex;
  flex-direction: row-reverse;
  min-height: 54px;
  padding: 6px 5px;
}

.addRuleIcon {
  margin: 4px 13px 0 0;
}

.searchBar {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 40px;
  margin-right: 6px;
  padding: 1px 11px;
  width: 376px;
}

.searchBar:focus-within,
.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.actionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
}

.basic {
  width: 274px;
  height: 80px;
  padding: 0 30px 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  width: 320px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
  display: flex;
}

.title:hover {
  content: attr(.title);
}

.terms {
  composes: kai-text-md ellipsis-overflow from global;
  width: auto;
  max-width: 100%;
}

.bannerWrapper {
  padding-right: 50px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.explanation {
  margin-top: 15px;
  font-size: 13px;
  font-weight: 600;
  composes: kai-line-clamp-2 from global;
}

.arrowContainer {
  margin-top: -2px;
}
