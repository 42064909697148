/* note: this file uses `!important` a lot because it is overriding styles from
 * third-party components */

div[id='select-box content'] hr {
  margin-top: 0;
  margin-bottom: 0;
}

div[id='select-box content'] div + hr + div + hr + div,
div[id='select-box content'] div + hr + div + hr {
  display: none;
}

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.PickerWrapper {
  width: 771px;
  height: 478px;
}

.PickerWrapper > [id='content-picker-container'] {
  height: 100%;
}

.PickerWrapper [class~='be-header'],
.PickerWrapper footer[class~='bcp-footer'] {
  background-color: transparent;
  padding: 0 24px;
}

.PickerWrapper footer [class~='bcp-footer-actions'] button {
  margin-left: 16px !important; /* stylelint-disable-line declaration-no-important */
  border-radius: 6px;
  height: 38px !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 18px !important; /* stylelint-disable-line declaration-no-important */
  padding-right: 18px !important; /* stylelint-disable-line declaration-no-important */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
}

.PickerWrapper [class~='bcp-footer-actions'] button:first-of-type {
  border: 1px solid rgb(158, 152, 163) !important; /* stylelint-disable-line declaration-no-important */
  width: 92px !important; /* stylelint-disable-line declaration-no-important */
}

.PickerWrapper [class~='bcp-footer-actions'] button:first-of-type::after {
  content: 'Cancel';
}

.PickerWrapper [class~='bcp-footer-actions'] button:last-of-type {
  background-color: rgb(24, 21, 71);
  border: none !important; /* stylelint-disable-line declaration-no-important */
  width: 108px !important; /* stylelint-disable-line declaration-no-important */
}

.PickerWrapper [class~='bcp-footer-actions'] button:last-of-type::after {
  content: 'Continue';
}

.PickerWrapper footer [class~='bcp-footer-actions'] button [class~='btn-content'] {
  display: none;
}

body :global(.dropdown-menu-element) {
  z-index: 10001;
}
