.previewContainer {
  background-color: var(--color-gray05);
  padding: 24px 0 0 0;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 112px);
  overflow-y: auto;
}

.switcher {
  bottom: 32px;
}

.previewOptions {
  width: 336px;
  flex-grow: 1;
  align-self: flex-start;
  align-items: flex-start;
  padding-right: 24px;
}
