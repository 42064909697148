.wrapper {
  padding-left: 57px;
  padding-right: 64px;
}

.title {
  font-size: 42px;
  line-height: 48px;
}

.description {
  margin-top: 9px;
  font-size: 16px;
  color: var(--color-gray70);
}

.moreLikePreview {
  height: 270px;
  width: 262px;
  background-color: var(--color-gray02);
  overflow: hidden;
}

.moreLikePreview > div {
  width: 100%;
  margin: 0;
}

.header {
  composes: kai-flex-row from global;
  justify-content: space-between;
}

.controlsWrapper {
  margin-left: 40px;
  composes: kai-flex-row from global;
}

.ControlsWrapper {
  margin-left: 40px;
  composes: kai-flex-row from global;
  align-items: center;
}

.ControlsWrapper > :last-child:not(:first-child) {
  width: 53px;
  margin-left: 10px;
}

.previewWrapper {
  margin-top: 41px;
  background-color: var(--color-gray05);
  height: 400px;
  margin-bottom: 39px;
  composes: flex-column from global;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 11px;
  overflow: hidden;
}

.body {
  composes: kai-flex-row from global;
  align-items: flex-start;
}

.moreLikeThis {
  width: 262px;
  margin-right: 4px;
  margin-bottom: 24px;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}

.preview {
  height: 100%;
}

.previewHidden {
  opacity: 0;
}

.info {
  width: 350px;
  margin-left: 100px;
}

.moreLikeThisWrapper {
  flex: 1;
}

.sectionTitle {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-gray90);
}

.fullScreenPreviewOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.fullScreenPreview {
  max-height: calc(100vh - 30px);
  margin: 15px 64px 15px calc(var(--program-sidebar-width) + 64px);
  position: relative;
  background-color: var(--color-gray05);
  composes: kai-flex-row from global;
  justify-content: space-around;
  align-items: flex-start;
  overflow-y: auto;
}

.closeFullscreenPreview,
.openFullscreenPreview {
  position: absolute;
  top: 29px;
  left: 37px;
  width: 99px;
  height: 32px;
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--color-gray00);
  border: 0.5px solid var(--color-gray10);
  composes: kai-flex-row from global;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 14px;
}

.closeFullscreenPreview > span,
.openFullscreenPreview > span {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-top: 1px;
}

.openFullscreenPreview {
  width: 111px;
}

.fullPreview {
  width: 800px;
  margin: 12px 0;
}

.moreLikeThisThumbnails {
  composes: kai-flex-row from global;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 29px;
}

.infoSectionHeader {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 7px;
  color: var(--color-gray90);
}

.avatarWrapper {
  composes: kai-flex-row from global;
}

.avatar {
  background-size: cover;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  margin-right: 9px;
  background-position: center center;
}

.avatar > svg {
  height: 28px;
  width: 28px;
}

.infoSection {
  margin-bottom: 18px;
}

.infoSection > div:last-child {
  font-size: 14px;
  color: var(--color-gray70);
}

.avatarWrapper > span {
  color: var(--color-gray70);
  font-size: 14px;
}

.assetType {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-gray70);
  margin-bottom: 10px;
  margin-top: 10px;
}

.previewTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--color-gray90);
}

.controlsWrapper > a,
.controlsWrapper > button {
  height: 53px;
  width: 53px;
  border-radius: 26px;
  background-color: var(--color-gray05);
  color: var(--color-gray90);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.previewWrapper > button > span {
  font-weight: 600;
  margin-left: 12px;
}
