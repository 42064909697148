.durationWrapper {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  top: 0 !important;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
}

.rangeMenuWrapper {
  left: -340px;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  width: auto !important;
}

.offsetSpacer {
  position: absolute;
  height: 400px;
  width: 100%;
}

.offsetSpacerRange {
  position: absolute;
  height: 600px;
  width: 100%;
}
