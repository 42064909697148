.HorizontallySpaced {
  display: flex;
  flex-direction: row;
}

.Flex {
  display: flex;
}

.Gap8 {
  gap: 8px;
}

.VerticallyCentered {
  align-items: center;
}

.Banner {
  justify-content: space-between;
  border-left-width: 4px;
  border-left-style: solid;
  border-radius: 5px;
  padding: 0 5px;
  margin-top: 10px;
}

.warning {
  border-left-color: var(--color-orangeTintDark);
  background-color: var(--color-orangeTintLight);
}

.BannerContent {
  font-size: 13px;
  color: var(--color-gray70);
  margin: 10px 0;
}
