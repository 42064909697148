.section {
  margin-bottom: 32px;
}

.topicStatus {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.topicUpdatedAt {
  color: var(--color-gray60, #676570);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.metricsList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;
}

.loadingPlaceholder {
  display: flex;
  flex-direction: column;
  padding: 32px;
  justify-content: center;
  align-items: center;
}

.topicPosts {
  composes: section;

  .post {
    box-shadow: none;
    border: 1px solid var(--color-gray10);
    pointer-events: none;
  }
}

.topicShortcuts {
  composes: section;
}

.shortcutsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 8px;
  width: 100%;
}

.shortcut {
  pointer-events: none;
}

.searchWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.searchBar {
  max-width: 160px;
}

.resultsCount {
  color: var(--color-gray60);
  font-size: 14px;
}
