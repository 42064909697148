.list {
  box-sizing: border-box;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  margin: 0;
  background-color: var(--color-gray00);
  border: 1px var(--color-gray05);
  overflow-x: hidden;
}

.highlighted {
  background-color: var(--color-gray02);
  cursor: pointer;
}

.list:focus .highlighted {
  background-color: var(--color-gray02);
}

.loading {
  composes: kai-flex-row flex-centered from global;
  padding: 50px;
}

.noItemsFound {
  padding-top: 70px;
  text-align: center;
}
