.fieldset {
  background-color: var(--color-gray05);
  display: flex;
  flex-direction: column;
  border-style: none;
  padding: 0;
}

.field {
  display: flex;
  font-size: 14px;
  word-break: break-word;
}

.labelContainer {
  width: 195px;
  font-style: normal;
  font-size: 13px;
  line-height: 24px;
  color: var(--color-gray90);
  border-right: 2px solid var(--color-gray00);
  padding-left: 32px;
  padding-top: 24px;
}

.label {
  font-size: 14px;
  composes: kai-flex-row from global;
}

.help {
  color: var(--color-gray50);
}

.error {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: var(--color-redFull);
  font-size: 14px;
}

.input {
  flex: 1;
  padding: 24px 48px 0 48px;
  position: relative;
}

.loading {
  width: calc(100% - 72px);
  height: calc(100% - 24px);
  top: 24px;
  left: 48px;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 100px;
}

.field:last-child .input {
  padding-bottom: 24px;
}

.helpIcon {
  height: 16px;
}

.tooltip {
  width: 300px;
}

.fieldGroupContainer {
  border-bottom: 2px solid var(--color-gray00);
}
