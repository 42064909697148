.wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-gray10);
  composes: flex-column from global;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.logoWrapper {
  width: 190px;
  height: 143px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
