.fullHtmlEditor {
  border: none;
  max-height: calc(85vh - 75px);
}

.fullHtmlEditorPreview {
  background-color: white;
  min-height: 16px;
}

.activeTab {
  border-bottom: 1px solid var(--color-brandSolar);
  border-radius: 0;
}

.tabButton {
  cursor: pointer;
  border-radius: 0;
}

.tabButton > span {
  cursor: pointer;
}

.tabButton:hover {
  background-color: transparent;
  border-bottom: 1px solid var(--color-brandSolar);
}

.buttonCancel {
  margin-right: 16px;
}

.sectionVisibilityWrapper {
  padding: 0 40px 24px;
}

.modalFooter {
  border-top: 1px solid var(--color-gray05);
  padding: 16px 24px;
  height: 75px;
}

.modalHeader {
  border-bottom: 1px solid var(--color-gray05);
  padding: 16px 24px 0;
  height: 60px;
}

.editorContent {
  max-height: calc(85vh - 75px - 60px);
  padding: 0 24px;
  overflow-y: auto;
}

.editorNodeViewWrapper {
  display: flex;
}

.wrapperContent {
  flex-grow: 1;
}

.editorNodeViewWrapper > .wrapperContent {
  border-radius: 0.5rem;
}

.editorErrors {
  display: flex;
  width: 100%;
  position: relative;
  background: #fff7e6;
  height: auto;
  margin-bottom: 24px;
  border: 1px solid #f1f0f7;
  border-left: solid #fa8c16 5px;
  border-radius: 4px;
}

.editorWarningBadge {
  margin: 20px;
}

.editorErrorMessage > * {
  display: block;
  font-style: normal;
  font-size: 14px;
}

.editorErrorMessage > strong {
  margin-top: 20px;
}

.editorErrorMessage > ul {
  margin: 10px;
}

.editorErrorMessage > ul > li {
  padding: 4px;
}
