.DateContainer {
  width: 350px;
  padding-right: 20px;
}

.StatsSection strong {
  font-weight: 700;
}

.StatsLine {
  display: flex;
  line-height: 150%;
}

.SectionCard {
  margin-bottom: 16px;
}

.PercentageStats {
  margin: auto 0;
  font-size: 14px;
  flex-grow: 5;
  width: 480px;
}

.PercentageStats .StatLine:not(:last-child) {
  margin-bottom: 8px;
}

.BreakdownLink {
  cursor: pointer;
  margin-top: 9px;
  margin-right: 20px;
  margin-left: auto;
  color: var(--color-brandSolar);
}

.InformationalMessage {
  gap: 8px;
  padding-bottom: 32px;
}
