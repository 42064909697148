.placeholder {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  line-height: 150%;

  a {
    color: var(--primary-default);
    text-decoration: underline;
  }
}

.title {
  color: var(--color-gray90);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.description {
  color: var(--color-gray60);
  font-size: 14px;
  margin: 0;
  padding: 0;
}
