.header {
  display: flex;
  justify-content: space-between;
  margin: 45px var(--report-card-external-margin) 15px;
}

.header h3 {
  font-size: 16px;
  text-transform: capitalize;
}

.container:last-child {
  margin-bottom: 40px;
}
