.section {
  width: 100%;
  margin-bottom: 25px;
}

.body {
  width: 100%;
}

.title {
  width: 100%;
  text-align: left;
  font-weight: 600;
  composes: section-header from global;
  cursor: default;
  padding-bottom: 20px;
}

@media (min-width: 1366px) {
  .section {
    flex-direction: row;
    display: flex;
  }

  .title {
    text-align: right;
    width: var(--main-editor-grid-col-width);
    margin-right: var(--main-editor-grid-gap-width);
    flex-shrink: 0;

    /*
     This keeps the title right justified, even if it's one word that's longer than it's container.
     Without this it gets pushed to the right; it to overhang to the left if it needs to
     */
    direction: rtl;
  }
}

.row {
  display: flex;
  justify-content: stretch;
  width: 100%;
}

.row + .row {
  margin-top: 2px;
}

.block {
  border: 0;
  background-color: var(--color-gray05);
  padding: 20px 30px;
  justify-self: stretch;
  flex-grow: 1;
}

button:not(:disabled).block {
  cursor: pointer;
}

button:not(:disabled).block:hover {
  background-color: var(--color-gray10);
}

.block:focus {
  outline: 0;
}

.selected:not(:disabled) {
  background-color: var(--color-gray10);
  border: 2px solid var(--color-brandTintDark);
  cursor: default;
}

.row > .block {
  content: '';
  margin: 0 1px;
}

.row > .block:first-child {
  margin-left: 0;
}

.row > .block:last-child {
  margin-right: 0;
}

.priorityIcon {
  color: var(--color-gray30);
}

.selected:not(:disabled) > div:first-child {
  color: var(--color-brandTintDark);
}

@media screen and (max-width: 1000px) {
  .row {
    flex-direction: column;
  }
}
