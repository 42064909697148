.canvas {
  --canvas-rl-padding: 70px;

  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  /* bottom padding ensures room for froala popups */
  padding: 90px 70px 75px;
  text-align: center;
  transition: padding-top 0.3s ease-out;

  /* here: undo parts of css global reset for the scope of the canvas. global reset not part of campaign render, so this reflects how it will render in the end */
  a {
    text-decoration: underline;
  }
}

.canvas > div {
  width: 100%;
  background-color: var(--color-gray00);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  margin: auto;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  .canvas > div {
    max-width: 800px;
  }
}

@media screen and (max-width: 1919px) {
  .canvas > div {
    max-width: 600px;
  }
}

.canvas.inserting {
  padding-top: 45px;
}

.instances {
  background-color: var(--color-gray05);
  box-shadow: inset 0 3px 1px var(--color-gray00),
    inset 0 -3px 1px var(--color-gray00),
    inset -3px 0 1px var(--color-gray00),
    inset 3px 0 1px var(--color-gray00);
}

.instances.start {
  border: solid 2px var(--color-gray00);
}

.instances.list {
  margin-bottom: 50px;
}

.loading {
  composes: kai-flex-row flex-centered from global;
  min-height: 130px;
}

.inlineCanvas {
  padding: 40px;
}

.insertActions > div {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 20px;
}

.placeholder {
  font-weight: 600;
  text-align: center;
  padding: 50px;
  color: var(--color-gray70);
  border: 2px dashed var(--color-gray30);
}

.placeholder + * {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  height: 0 !important;

  /* hides the placholder-placeholder  */
}

.placeholder.dragging,
.placeholder.hovering {
  border-color: var(--color-brandTintDark);
}

.placeholder img {
  height: 190px;
}

.instance {
  margin: 0;
  position: relative;
  background-color: var(--color-gray00);
  cursor: default;
}

.instance .menu {
  position: absolute;
  top: 0;
  right: -136px;
  height: 100%;
  overflow: visible;
  padding: 0 100px 0 5px;
  visibility: hidden;
}

.instance .showMenu {
  visibility: visible;
}

.instance.dragging {
  opacity: 0.8;
  background-color: var(--color-gray00);
  box-shadow: -1px 0 0 var(--color-gray05), -7px 0 0 var(--color-gray10);
}

.instance:hover .menu {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.instances .selected .instance {
  box-sizing: border-box;
  outline: 1px solid var(--color-gray05);
  box-shadow: -3px -3px 0 0 var(--color-gray90),
    3px -3px 0 0 var(--color-gray90),
    3px 3px 0 0 var(--color-gray90),
    -3px 3px 0 0 var(--color-gray90);
  background-color: var(--color-gray05);
  z-index: var(--z-index-publisher-block-controls);
}

.instances .unselected:hover .instance {
  border-right: 1px solid var(--color-gray05);
  box-shadow: 3px 0 0 -1px var(--color-gray90);
  margin-right: -1px;
}

.menu ul {
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 33px;
  cursor: default;
  position: sticky;
  padding: 0;
  top: 0;
  height: min(100%, 25vh);
  z-index: var(--z-index-publisher-block-controls);
}

.menu li {
  width: 33px;
}

:global .js-editor ul,
:global .js-editor ol {
  margin: 0;
  padding: 0;
  margin-left: -2px;
}

:global .js-editor ul {
  list-style-type: disc;
}

:global .js-editor ol {
  list-style-type: decimal;
}

:global .js-editor ul > ul,
:global .js-editor ul > ol,
:global .js-editor ol > ul,
:global .js-editor ol > ol {
  margin-left: 20px;
}

:global .js-editor ul li,
:global .js-editor ol li {
  list-style-position: outside;
  margin: 15px 0 15px 20px;
}

.menu li:first-child {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.menu li:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.menu li > * {
  display: block;
  font-size: 13px;
  line-height: 30px;
  height: inherit;
  width: inherit;
}

.instance dl {
  display: flex;
  margin: 0;
  padding: 0;
}

.instance dt {
  font-weight: 100;
  margin: 0;
  margin-right: 10px;
}

.instance dd {
  font-weight: 600;
  padding: 0;
  margin: 0;
  margin-right: 30px;
}

.dropdown {
  right: 27px;
  top: 0;
}

.deleteControl {
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: inherit;
  font-size: 12px;
  height: 36px;
  margin-bottom: -6px;
  box-shadow: 0 21.53px 25.84px rgba(var(--color-gray90-rgb), 0.06),
    0 8.71px 23.22px rgba(var(--color-gray90-rgb), 0.08),
    0 2.9px 2.9px rgba(var(--color-gray90-rgb), 0.08);
  background-color: var(--color-gray02);
  overflow: hidden;
}

.deleteControl button {
  border: none;
  cursor: pointer;
}

.deleteIconWrapper {
  margin: 0;
  padding: 0;
  width: 30px;
  z-index: var(--z-index-publisher-block-controls-cancel-delete-icon);
  background-color: inherit;
}

.deleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: inherit;
}

.deleteConfirmText {
  position: relative;
  text-align: center;
  padding: 0 10px;
  width: 120px;
  height: 100%;
  white-space: nowrap;
  background-color: inherit;
  animation-name: slideOut;
  animation-duration: 0.2s;
  z-index: var(--z-index-publisher-block-controls-confirm-delete-text);
}

@keyframes slideOut {
  from {
    width: 0;
    opacity: 0;
  }

  to {
    width: 120px;
    opacity: 1;
  }
}

.insertButton button {
  padding: 0;
  height: 22px;
  width: 22px;
  outline: none;
  border: 2px solid var(--color-gray70);
  color: var(--color-gray70);
  border-radius: 5px;
  background-color: transparent;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
}

.insertActions button {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}

.basics button {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}

.insertActions button:hover {
  color: var(--color-brandTintDark);
}

.deleteControl button:hover {
  background-color: var(--color-gray05);
}

.instance .menu .menuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 30px;
}

.instance .menu li > button > svg {
  height: 20px;
  width: 20px;
}

.instance .menu li > button:hover {
  opacity: 1;
}

.instance .menu button.move {
  cursor: move;
}

.instance .menu button.move > * {
  margin-left: 6px;
}

.instance .menu button.trash > * {
  margin-left: 3px;
}

.instance .menu button.icon > * {
  height: 20px;
  width: 20px;
}

.instance .menu button.move path,
.instance .menu button.edit path {
  fill: var(--color-gray90);
}

.instance .menu button.trash path {
  stroke: var(--color-gray90);
}

.basics button span {
  padding: 8px;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background-color: var(--color-gray05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.basics strong {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
}

.instance .errors {
  opacity: 0.8;
  padding: 10px;
  background-color: var(--color-redFull);
  color: var(--color-gray00);
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  z-index: var(--z-index-block-error-banner);
}

.instance:focus-within .errors {
  opacity: 0.2;
}

.instance .errors div {
  margin: 5px;
}

.instance .errors span {
  opacity: 0.8;
}

.instance .errors strong {
  text-shadow: 1px 1px 0.5px var(--color-gray70);
}

:global .editable .js-editor {
  cursor: text;
  outline-color: transparent;
  outline-style: dashed;
  outline-width: 1px;
}

:global .editable .js-editor:focus-within {
  outline-color: var(--color-gray30);
  padding: 10px;
}

.insertBar {
  z-index: 1;
  position: relative;
  overflow: visible;
  background-color: transparent;
  cursor: default;
}

.insertBar .insertButton {
  position: absolute;
  padding: 40px 10px;
  width: 54px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: -50px;
  bottom: -50px;
  opacity: 0.01;
  transition: opacity 0.5s ease-in-out;
}

@media screen and (max-width: 1280px) {
  .insertBar .insertButton {
    left: -35px;
  }
}

@media screen and (min-width: 1281px) {
  .insertBar .insertButton {
    left: -45px;
  }
}

.insertBar .insertActions {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.3s ease;
  opacity: 0;
}

.insertBar.open .insertActions {
  height: 90px;
  background-color: var(--color-gray05);
  opacity: 1;
}

.insertBar.peek .insertButton {
  opacity: 1;
}

.insertBar.open .insertButton {
  opacity: 1;
}

.editorToolbarContainer {
  position: sticky;
  top: 0;
  height: 0;
  z-index: 1000;
  display: none;
}

.selected .editorToolbarContainer {
  display: block;
}

.editorToolbar {
  position: absolute;
  transform: translateY(-90%);
  left: 0;
  right: 0;
}

.editorToolbar :global(.fr-toolbar):global(.fr-desktop):global(.fr-top) {
  border-bottom-color: rgb(204, 204, 204);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 10px;
}

.errorsContain {
  max-height: 100px;
  overflow-y: auto;
  opacity: 0.8;
  padding: 10px;
  background-color: var(--color-redFull);
  color: var(--color-gray00);
  position: absolute;
  width: 100%;
  top: 0;
  z-index: var(--z-index-block-error-banner);
}

.fieldInput {
  padding: 0 4px 0 0;
}
