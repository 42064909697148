.image {
  width: 359px;
  height: 239px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2px;
  border: 1px solid var(--color-gray10);
}

.placeholder {
  flex: 1;
  height: 239px;
  width: 359px;
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  border-radius: 2px;
  composes: flex-column flex-centered from global;
}

.placeholder svg {
  height: 50px;
  width: 50px;
}

.loading {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
