/* global import for theme classes */

/* TODO fonts and icons */

/* TODO make shared color variables */

body {
  margin: 0;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: var(--color-gray05);
  color: var(--color-gray80);
  box-sizing: border-box;
  overflow: hidden;
}

body * {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
li {
  margin: 0;
  padding: 0;
}
