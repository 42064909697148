.closeButton {
  position: absolute;
  top: 11px;
  height: 40px;
  width: 36px;
}

.right {
  right: 8px;
}

.left {
  left: 0;
}

.ConfirmModalHeader {
  padding: 24px;
  border-bottom: 1px solid var(--color-gray10);
}

.ConfirmModalBody {
  padding: 24px;
  border-bottom: 1px solid var(--color-gray10);
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.ConfirmModalActions {
  padding: 16px 24px;
}

.ErrorModalHeader {
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-gray05);
  font-size: var(--font-size-heading);
  font-weight: 600;
}

.ErrorModalHeader > svg {
  color: var(--color-redFull);
}

.ErrorModalBody {
  padding: 24px;
  border-bottom: 1px solid var(--color-gray05);
  color: var(--color-gray60);
  font-size: var(--font-size-body);
}

.ErrorModalFooter {
  padding: 16px 24px;
}

.formModal > hr {
  margin: 0;
  background-color: var(--color-gray05);
}

.formModalHeader {
  padding: 0 16px;
}

.formModalHeader > button {
  padding: 0;
  height: 40px;
  width: 40px;
}

.formModalHeaderTitle {
  height: 40px;
}

.formModalBody {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.formModalFooter {
  padding: 16px 24px 8px;
}

@media screen and (max-width: 1280px) {
  .boxPanel {
    width: 280px;
  }
}

@media screen and (min-width: 1281px) {
  .boxPanel {
    width: 325px;
  }
}
