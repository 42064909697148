.durationInput {
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: var(--color-gray10);
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  font-size: 14px;
}

.durationInput > div {
  width: 100%;
}

.btnSelect {
  background-color: transparent;
  border: none;
}

.dropdownIndicator {
  float: right;
}

.label {
  padding: 6px;
  line-height: 30px;
}

.disabledInput {
  opacity: 0.7;
}
