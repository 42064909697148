/* import all shared global stylesheets here */
@import '~@socialchorus/shared-ui-components/dist/style.css';
@import '~@socialchorus/box-components/dist/style.css';

body {
  font-family: 'Inter', sans-serif;

  /* allow scrolling for popup content that extends past the bottom of the page */
  overflow-y: auto;
}

@import './shared/styles/reset.css';
@import './shared/styles/text.css';
@import './shared/styles/z-index.css';
@import './shared/styles/containers.css';
@import './shared/styles/form-elements.css';
@import './shared/styles/filters.css';
@import './shared/styles/images.css';
@import './shared/styles/dropdowns.css';
@import './shared/styles/spacing.css';
@import './shared/styles/tooltip.css';
@import './shared/styles/froala-custom.css';

/* Layouts */
@import './shared/layouts/MainEditor/main-editor.css';
@import './DesignSystem/color-conversions.css';

/* 3rd party integrations */
@import './shared/styles/react-select.css';
@import '~froala-editor/css/froala_style.min.css';
@import '~froala-editor/css/froala_editor.min.css';
@import '~froala-editor/css/plugins/colors.min.css';
@import '~froala-editor/css/plugins/image.min.css';
@import '~froala-editor/css/plugins/quick_insert.min.css';
@import '~froala-editor/css/plugins/table.min.css';
@import '~froala-editor/css/plugins/code_view.min.css';
@import '~froala-editor/css/plugins/video.min.css';
@import '~@szhsin/react-menu/dist/index.css';

/* Overrides of 3rd party integrations */
@import './shared/styles/froala-overrides.css';
