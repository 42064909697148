.TopicSettingsList {
  list-style-type: disc;
  display: flex;
  gap: 16px;
  flex-direction: column;
  font-size: 14px;
}

.Container {
  padding-left: 32px;
}
