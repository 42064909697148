.swatch {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  border: 2px solid var(--color-gray00);
}

.swatch.hard2see {
  border-color: var(--color-gray05);
}

.swatch.noColor {
  background-color: white;
  width: 100%;
}

.selection {
  border-color: var(--color-gray30);
  box-shadow: inset 0 0 0 1px var(--color-gray00);
}

.popover {
  position: absolute;
  z-index: var(--z-index-shared-color-preview-popover);
}

.popover .cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pickerWrapper {
  background-color: var(--color-gray00);
  box-shadow: 0 21.53px 25.84px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.previewColor {
  height: 222px;
  composes: flex-column from global;
  justify-content: flex-end;
  padding: 16px;
}

.previewColor.hard2see {
  border-bottom: 2px solid var(--color-gray05);
}

.valueWrapper {
  font-size: 16px;
  cursor: default;
  opacity: 0.7;
}

.valueWrapper:hover,
.valueWrapper:focus-within {
  opacity: 1;
}

.optionsWrapper {
  padding: 12px;
  width: 278px;
}

.brandColors {
  padding-top: 12px;
}

.sectionTitle {
  font-size: 12px;
  font-weight: 700;
  padding-left: 4px;
  padding-bottom: 4px;
}

.allColors {
  padding-top: 10px;
}

.input {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: transparent;
  font-size: 16px;
  text-transform: uppercase;
  padding: 2px 5px;
  outline: none;
}

.error {
  composes: Bold Caption Block from '../../DesignSystem/Typography/typography.module.css';
  color: var(--color-redFull);
}
