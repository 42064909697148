.controlButton {
  text-transform: capitalize;
  cursor: pointer;
  background-color: var(--color-gray05);
  border: 1px solid var(--color-gray05);
}

.controlButton:hover {
  background-color: var(--color-gray10);
  border-radius: 3px;
  border: 1px solid var(--color-gray10);
}

.active {
  background-color: var(--color-gray00);
  border-radius: 3px;
  border: 1px solid var(--color-gray10);
}

.active:hover {
  background-color: var(--color-gray05);
  border-radius: 3px;
  border: 1px solid var(--color-gray10);
}

.controlBox {
  align-content: flex-end;
  background-color: var(--color-gray05);
  border-radius: 3px;
  padding-bottom: 1px;
}
