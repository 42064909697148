.form div[class*='fieldset_labelContainer'] {
  color: var(--color-gray70);
  display: flex;
  align-items: center;
  padding-top: 0;
}

.form div[class*='fieldset_input'] {
  padding: 24px 32px;
  color: var(--color-gray90);
}

.multipleFields div[class*='fieldset_input'] {
  padding: 0 32px;
}

.firstField div[class*='fieldset_input'] {
  padding-top: 24px;
  padding-bottom: 24px;
}

.typeField div[class*='fieldset_input'] {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
}

.textField {
  width: 100%;
}

.typeSelector {
  width: 100%;
  display: flex;
  height: 72px;
}

.typeSelector button {
  flex-grow: 1;
  background: none;
  border: 4px solid transparent;
}

.typeOption {
  flex-grow: 1;
  box-sizing: border-box;
  cursor: pointer;
  border: 4px solid transparent;
}

.typeOption.selectedType {
  border-color: var(--color-brandTintDark);
}

.selectedType svg path {
  fill: var(--color-brandTintDark);
}

.clickDropDown {
  width: 100%;
}

.dropdown {
  composes: dropdown from global;
  box-sizing: border-box;
  background-color: var(--color-gray00);
  width: 100%;
}

.dropdownItem {
  composes: kai-flex-row from global;
  align-items: center;
  height: 30px;
}

.dropdownButton {
  composes: button-reset kai-flex-row from global;
  padding: 8px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--color-gray10);
  cursor: pointer;
  background-color: var(--color-gray00);
}

.dropdownSelectedItem {
  composes: kai-flex-row from global;
  flex-grow: 1;
  width: 90%;
}

.expandArticlesSwitch {
  margin-bottom: 24px;
}

.marginRight {
  margin-right: 16px;
}

.disabledDropdownButton {
  color: var(--color-gray50);
}
