.variableTextInput {
  display: inline-block;
  background-color: var(--color-gray10);
  color: var(--color-gray80);
  border: solid 1px var(--color-gray10);
  padding: 0 3px;
  font-family: monospace;
  margin: 0 1px 0 2px;
  border-radius: 5px;
  text-transform: lowercase;
  font-size: 0.9em;
  cursor: default;
}

.variableTextInput:focus {
  background-color: var(--color-gray80);
  border-color: var(--color-gray80);
  color: var(--color-gray02);

  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
}

.dropdown {
  position: absolute;
  top: 3px;
  left: 0;
  background-color: var(--color-gray00);
  border: solid 1px var(--color-gray10);
  padding: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: var(--z-index-shared-variable-text-input-dropdown);
  border-radius: 6px;
}

.dropdown > div {
  cursor: pointer;
  padding: 3px 5px 5px;
}

.variable {
  display: inline-block;
  font-family: monospace;
  border-radius: 5px;
  background-color: var(--color-gray10);
  border: solid 0.5px var(--color-gray10);
  color: var(--color-gray70);
  font-size: 0.8em;
  overflow: hidden;
  padding: 2px 5px;
  margin: 0 5px 0 0;
  font-style: normal;
}

.description {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8em;
}

.input {
  padding: 9px 16px;
  font-size: 14px;
  position: relative;
  word-break: break-all;
  cursor: text;
}

.input:focus-within {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
}

.input.nochannel {
  padding: 6px 10px 6px 10px;
}

.input :global .channel {
  position: absolute;
  left: 0.4rem;
  top: 0.4rem;
  height: 25px;
  width: 25px;
  background-size: contain;
  opacity: 0.3;
  z-index: var(--z-index-shared-variable-text-input-channel);
}

.input > div > span {
  display: inline;
  line-height: 1.2em;
  min-width: 3px;
}

.input > div > span:last-child {
  display: inline;
}

.input > div > span:first-child:last-child {
  display: inline-block;
  width: 100%;
  min-height: 1em;
}

.invalid {
  border: 1px solid var(--color-redFull);
  color: var(--color-redFull);
}

.input.invalid {
  border: 1px solid var(--color-redFull);
  color: var(--color-redFull);
}

.input.disabled {
  background-color: var(--color-gray02);
  border: 1px solid var(--color-gray30);
  color: var(--color-gray50);
}

.invalid .variableTextInput {
  background-color: var(--color-redFull);
  border-color: var(--color-redFull);
  color: var(--color-gray00);
}

.placeholder {
  color: var(--color-gray30);
}

.editable {
  outline: none;
  word-break: break-word;
}

.editable[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.dropdown :global .hover {
  background-color: var(--color-gray10);
}

.dropdown :global .hover:first-child {
  border-radius: 4px 4px 0 0;
}

.dropdown :global .hover:last-child {
  border-radius: 0 0 4px 4px;
}

.dropdown :global .hover :local .variable {
  background-color: var(--color-gray30);
}
