.Container {
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 14px;
  position: relative;
}

.ShortcutsGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 8px;
  width: 100%;
}

.Shortcut {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  row-gap: 4px;
}

.Shortcut__Icon {
  width: 40px;
  aspect-ratio: 1;
}

.Container__Empty {
  composes: Container;
  position: relative;
  align-items: center;
}

.NoShortcuts {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  align-items: center;
}

.NoShortcuts__Texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}

.NoShortcuts__Texts__Description {
  color: var(--color-gray60);
}

.OpenShortcutsSidebarButton::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.AddShortcutButton {
  position: relative;
  z-index: 1;
}
