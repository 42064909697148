.Button {
  height: 50px;
  width: 50px;
  padding: 10px 11px;
  cursor: pointer;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.Button:hover {
  background-color: var(--color-gray05);
  border-radius: 6px;
}

.Input {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray30);
  padding: 9px 16px;
  font-size: 14px;
  color: var(--color-gray90);
  border-radius: 4px;
  text-align: center;
  width: 50px;
}

.Input:focus {
  border: 1px solid var(--color-brandTintDark);
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(88, 55, 108, 0.1);
}

.label {
  text-align: center;
  display: block;
}

.wrapper {
  padding: 20px;
}

.dots {
  width: 23px;
  text-align: center;
  padding-bottom: 25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield;
}
