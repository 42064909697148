.wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-gray10);
  composes: flex-column from global;
  justify-content: space-around;
  align-items: center;
  top: 0;
  left: 0;
}

.progressBar {
  position: relative;
  width: 190px;
  margin-top: 30px;
}

.progressBar > svg:last-child {
  animation: turn 4s linear 1.75s infinite;
  position: absolute;
  left: 0;
  top: 6px;
}

.logoWrapper {
  width: 190px;
  height: 143px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

@keyframes turn {
  0% {
    left: 0;
  }

  50% {
    left: 119px;
  }

  100% {
    left: 0;
  }
}
