:root {
  --insights-filter-dropdown-border-radius: 8px;
  --insights-filter-label-max-width: 350px;
}

.filterLabel {
  display: inline-block;
  padding: 10px 14px;
  font-size: 12px;
  margin: 6px 6px 0 0;
  background: var(--color-gray05);
  color: var(--color-gray70);
  border-radius: 6px;
  transition: background
    var(--insights-hover-btn-background-change-transition-time);

  /* limit the width of label and add ellipses if overflow */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: var(--insights-filter-label-max-width);
}

.filterLabel:hover {
  cursor: pointer;
  background: var(--color-gray10);
}

/* Design System version */

.FilterLabel {
  margin-right: 10px;
}

.FilterBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.filterLabelCloseBtn {
  border-radius: 50%;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 15px;
  height: 15px;
  margin-left: 6px;
  transition: var(--insights-hover-btn-background-change-transition-time);
}

.filterLabelCloseBtn:hover {
  background: var(--color-gray30);
}

.pillButtonDropdownContainer {
  position: relative;
  display: inline-block;
}

.pillButtonDropdown {
  box-sizing: content-box;
  position: absolute;
  left: 6px;
  top: 50px;
  z-index: var(--z-index-click-dropdown);
}

.filterDropdownContainer {
  display: flex;
  flex-direction: column;
  min-width: 220px;
  border-radius: var(--insights-filter-dropdown-border-radius);
  overflow: hidden; /* dropdown is round. force child views to clip edges */
  box-shadow: 0 21.53px 25.84px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
  background-color: white;
}

.filterDropdownHeader {
  flex-grow: 0;
  border-bottom: solid 1px var(--color-gray10);
}

.filterDropdownHeaderSearchGroup {
  position: relative;
  padding: 10px;
}

.filterDropdownHeaderSearchGroupCloseBtn {
  position: absolute;
  top: 15px;
  right: 5px;
  border: none;
  background: transparent;
}

.filterDropdownHeaderSearchLabel {
  border-top-right-radius: var(--insights-filter-dropdown-border-radius);
  border-top-left-radius: var(--insights-filter-dropdown-border-radius);
  border: 1px solid var(--color-gray10);
  padding: 14px 12px;
  transition: border var(--insights-hover-background-change-transition-time);
}

.filterDropdownHeaderSearchLabel input {
  color: black;
  margin-left: 8px;
}

.filterDropdownHeaderSearchInput {
  padding-right: 22px;
}

.filterDropdownHeaderSearchLabelFocused {
  border: 1px solid var(--color-brandTintDark);
}

.filterDropdownHeaderSelectionClearButton {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 12px;
  padding: 8px 12px;
  text-align: left;
  color: var(--color-brandTintDark);
}

.filterDropdownHeaderSelectionClearButton:hover {
  cursor: pointer;
}

.filterDropdownEmptyBody {
  padding: 10px 14px;
}

.filterDropdownBody {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.filterDropdownBodyListItemContainer {
  box-sizing: border-box;
  padding: 0 8px;
  overflow-y: hidden;
}

.filterDropdownBodyListItemSubheader {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-gray70);
  font-size: 12px;
  padding: 10px 0 0 6px;
}

/* if changing these values, be sure to update getInfiniteListItemVariableHeight() */
.filterDropdownBodyListItem {
  padding: 6px 8px 6px 32px;
  font-size: 13px;
  border-radius: 6px;
  position: relative;
  list-style: none;
  word-wrap: break-word;

  /* constrain line height to 3 lines */
  display: -webkit-box;
  line-height: 22px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.filterDropdownBodyListItem:hover {
  cursor: pointer;
  background: var(--color-gray02);
}

/* radio pseudo-element */
.filterDropdownBodyListItem.filterDropdownBodyListItemRadio::before {
  content: '';
  box-sizing: content-box;
  position: absolute;
  left: 0;
  top: 8px;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 1px solid var(--color-gray80);
}

.filterDropdownBodyListItemRadio.filterDropdownBodyListItemSelected::before {
  content: '';
  box-sizing: content-box;
  position: absolute;
  left: 0;
  top: 10px;
  height: 16px;
  width: 16px;
  background-color: black;
  border-radius: 100%;
  border: 1px solid black;
}

.filterDropdownBodyListItemRadio.filterDropdownBodyListItemSelected::after {
  content: '';
  position: absolute;
  top: 15px;
  left: 5px;
  height: 6px;
  width: 6px;
  background-color: white;
  border-radius: 100%;
  border: 1px solid white;
}

/* checkbox pseudo-element */
.filterDropdownBodyListItem.filterDropdownBodyListItemCheckbox::before {
  content: '';
  box-sizing: content-box;
  border-radius: 2px;
  background: white;
  position: absolute;
  left: 1px;
  top: 6px;
  height: 16px;
  width: 16px;
  border: 1px solid var(--color-gray80);
}

.filterDropdownBodyListItemCheckbox.filterDropdownBodyListItemSelected::before {
  background: black;
}

.filterDropdownBodyListItemCheckbox.filterDropdownBodyListItemSelected::after {
  content: '✓';
  position: absolute;
  font-weight: bold;
  color: white;
  left: 4px;
  top: 4px;
}

.filterDropdownBodyListItemCheckbox.filterDropdownBodyListItemPartial::before {
  background: black;
}

.filterDropdownBodyListItemCheckbox.filterDropdownBodyListItemPartial::after {
  content: "—";
  position: absolute;
  font-weight: bold;
  color: black;
  color: white;
  left: 4px;
  top: 4px;
}

.filterDropdownFooter {
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: solid 1px var(--color-gray10);
}

.filterDropdownDoneBtn {
  background: transparent;
  border: none;
  border-top: 1px solid var(--color-gray10);
  border-bottom-left-radius: var(--insights-filter-dropdown-border-radius);
  border-bottom-right-radius: var(--insights-filter-dropdown-border-radius);
  font-weight: bold;
  padding: 12px 0;
  width: 100%;
  font-size: 12px;
  color: var(--color-brandTintDark);
}

.filterDropdownDoneBtn:hover {
  cursor: pointer;
}

.filterDropdownInputTextBody {
  padding: 10px 20px;
  position: relative;
}

.filterDropdownInputText {
  text-align: center;
  padding: 5px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--color-gray10);
  box-sizing: border-box;
  outline: none;
  transition: border var(--insights-hover-background-change-transition-time);
}

.filterDropdownInputText:focus {
  border: 1px solid var(--color-brandTintDark);
}

.filterDropdownInputNumberBtn {
  position: absolute;
  top: 14px;
  width: 20px;
  height: 20px;
  background: var(--color-gray05);
  border-radius: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
}

.filterDropdownInputNumberBtn:hover {
  background: var(--color-gray10);
}

.filterDropdownInputNumberBtn:active {
  background: var(--color-gray30);
}

.filterDropdownInputNumberMinusBtn {
  left: 25px;
}

.filterDropdownInputNumberPlusBtn {
  right: 25px;
}
