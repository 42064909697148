.configInfo {
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 8px;
  max-width: 624px;
  margin-bottom: 24px;
  color: black;
  font-size: 12px;
  line-height: 150%;
}

.condition {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 24px;
}

.conditionLeft {
  margin-right: auto;
  max-width: 1000px;
  width: calc(100% - 165px);
}

.conditionDescription {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.conditionDescription > input {
  width: 253px;
}

.indexInput {
  width: 30px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.orderButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orderButton {
  padding: 0;
  height: 24px;
}

.operatorSwitch {
  margin-bottom: 8px;
  margin-left: -11px;
}
