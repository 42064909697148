.close {
  border-style: none;
  background-color: transparent;
  color: var(--color-gray00);
  cursor: pointer;
  padding: 0;
  display: block;
  height: inherit;
  width: inherit;
  position: relative;
}

.exit {
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 9px;
}

.close:hover {
  background-color: var(--color-gray80);
}

.arrowIcon {
  position: absolute;
  top: calc(50% - 14.5px);
  left: calc(50% - 12.5px);
  transition: top 0.2s ease-out;
}

.closeIcon {
  position: absolute;
  top: calc(50% - 8.5px);
  left: calc(50% - 8.5px);
  transition: top 0.2s ease-out;
}

.close:hover > :first-child {
  top: calc(50% - 15px);
}

.close > .exit {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  position: absolute;
  top: 65%;
  left: 0;
}

.close:hover > .exit {
  opacity: 1;
}

/** --------------------------------------------------------------------------
 *  The height of this footer affects other parts of the layout.
 *  If you change the "75px" to something else, be sure to update those too:
 *  - src/shared/styles/containers.css .publish-main-content
 *  - src/components/publisher/theme/compose.module.css .container
 *  - src/components/publisher/pages/Blocks/Sidebar/sidebar.module.css :root
 -------------------------------------------------------------------------- */

.navigation {
  background-color: black;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  color: var(--color-gray00);
  font-size: 16px;
  z-index: var(--z-index-navigation-footer);
}

.continue {
  composes: publish-sidebar from global;
  background-color: var(--color-brandTintDark);
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  color: var(--color-gray00);
  font-size: 18px;
  padding: 0 24px;
  cursor: pointer;
  width: var(--publish-sidebar-width);
}

.save {
  composes: continue;
  justify-content: left;
}

.saveBg {
  composes: flex-column flex-centered from global;
  background-color: rgba(var(--color-gray00), 0.3);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding-right: 4px;
  padding-bottom: 4px;
}

.saveIcon {
  color: var(--color-gray00);
  margin-right: 10px;
}

.inlineSaveIcon {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.info {
  padding: 0 24px;
  cursor: pointer;
  height: 75px;
  composes: flex-column from global;
  justify-content: center;
}

.hoverInfo {
  position: absolute;
  z-index: 10000;
  display: none;
  height: 75px;
  bottom: 0;
  padding: 0 24px;
  background-color: var(--color-gray80);
  cursor: pointer;
  composes: flex-column from global;
  justify-content: center;
  border-left: 2px solid var(--color-gray80);
}

.iconContainer svg {
  height: 20px;
  margin-top: 4px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info .title {
  max-width: 300px;
}

.hoverInfo .title {
  white-space: nowrap;
}

.navigation .status {
  font-size: 9px;
  text-transform: uppercase;
  color: var(--color-gray50);
  font-weight: 600;
}

.navigation .links {
  display: flex;
}

.inlineSave {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  text-align: right;
  padding: 0 5px;
  height: 72px;
  cursor: pointer;
}

.inlineSave:hover {
  background-color: var(--color-gray80);
}

.lastSave {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-align: right;
}

.statusCircle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background-color: green;
}

.navigation :global .-NavLink- {
  font-weight: 300;
  text-decoration: none;
  opacity: 0.8;
  color: inherit;
  padding: 0 20px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.navigation :global .-NavLink-.-Partial-,
.navigation :global .-NavLink-.-Active- {
  font-weight: 700;
  opacity: 1;
}

.navigation :global .-NavLink-.-Active- {
  font-weight: bold;
}

.subNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.disabled {
  background-color: var(--color-gray70);
  opacity: 0.7;
  cursor: default;
}

.noMenu {
  opacity: 0.7;
}

.closeButtonContainer {
  width: 75px;
  height: 100%;
  border-right: 2px solid var(--color-gray80);
}

.controls {
  composes: kai-flex-row from global;
  width: 400px;
}

.rightMenuItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 72px;
  padding-top: 3px;
  width: 180px;
}

.saveButton {
  background-color: transparent;
  color: white;
  border: none;
}

.saveInfo {
  padding: 0 3px;
}

.saveInfo:hover {
  background-color: var(--color-gray80);
}

.infoWrapper:hover .hoverInfo {
  display: flex;
}

.titleWrapper {
  composes: kai-flex-row from global;
}

.spinnerWrapper {
  padding-top: 2px;
  width: 30px;
}

@media screen and (max-width: 1400px) {
  .continue {
    min-width: 150px;
    width: 150px;
    max-width: 150px;
  }

  .save {
    min-width: 150px;
    width: 150px;
    max-width: 150px;
  }

  .rightMenuItems {
    width: 90px;
  }

  .inlineSave {
    width: 32px;
  }

  .lastSave {
    justify-content: center;
  }

  .statusCircle {
    margin-right: 0;
  }

  .controls {
    width: 300px;
  }

  .text {
    width: 200px;
  }

  .links {
    padding-right: 10%;
  }

  .info .title {
    max-width: 200px;
  }
}
