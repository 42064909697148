.Button {
  width: 100%;
}

.ButtonText {
  margin-left: 10px;
}

.Button svg path {
  fill: "#1B1A1F";
}
