.mainLayout {
  display: flex;
}

.container {
  align-items: flex-start;
  clear: both;
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
}

.main {
  box-sizing: border-box;
  padding-right: 50px;
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.pageActionWrapper {
  display: flex;
  flex-direction: row-reverse;
  min-height: 54px;
  padding: 6px 5px;
}

.addTopicIcon {
  margin: 4px 13px 0 0;
}

.searchBar {
  border: 1px solid transparent;
  background-color: var(--color-gray05);
  border-radius: 6px;
  height: 40px;
  margin-right: 6px;
  padding: 1px 11px;
  width: 376px;
}

.searchBar:focus-within,
.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray90);
  box-shadow: 5px 1.5px 5px rgba(0, 0, 0, 0.06),
    0 8.70566px 23.2151px rgba(0, 0, 0, 0.08),
    0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

.searchBar:hover {
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray70);
}

.actionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Basic {
  width: 274px;
  height: 80px;
  padding: 0 30px 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statCol {
  display: flex;
  flex-direction: column;
  height: 80px;
}

.statHeader {
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 600;
}

.stats {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-gray90);
}

.Title {
  composes: kai-text-md ellipsis-overflow from global;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray80);
}

.title:hover {
  content: attr(.title);
}

.Description {
  composes: kai-text-sm kai-line-clamp-2 from global;
  margin: 4px 0;
}

.List div[class*='banner-list-item_empty'] {
  border-bottom: 1px solid var(--color-gray10);
  border-radius: 0;
}

.BannerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.InfoWrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.Message {
  display: -webkit-box;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-gray90);
  flex: 1;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  padding-right: 25px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.Message a {
  color: var(--color-brandFull);
  text-decoration: none;
}

.Date {
  width: 184px;
  color: var(--color-gray90);
}

.List div[class*='layout_FilterBar'] {
  margin-top: 18px;
  margin-bottom: 40px;
}

.ListHeader {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.ListHeader > div:first-of-type {
  min-width: 334px;
}

.ListHeader > div:nth-child(2) {
  flex: 1;
  min-width: 300px;
}

.ListHeader > div:nth-child(3) {
  min-width: 184px;
  text-align: start;
}

.ListHeader .Sortable {
  cursor: pointer;
}

.Icon {
  margin-left: 14px;
}

.Sorted svg path {
  fill: var(--color-gray90);
}
