.infoBox {
  background-color: var(--color-gray05);
  border-radius: 4px;
  font-weight: 700;
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
}

.statusItems li {
  margin-top: 16px;
  font-size: 14px;
  line-height: 150%;
}

.tooltipText {
  cursor: pointer;
  color: var(--color-brandSolar);
}

.usersCount {
  margin-left: 9px;
  font-weight: 600;
  font-size: 16px;
}

.TooltipPopover {
  padding: 16px;
  max-height: 300px;
  overflow-y: auto;
}

.platform {
  display: flex;
  align-items: center;
  gap: 4px;
}

.platformText {
  font-size: 16px;
}

.icon {
  font-size: 20px;
}

.WarningBanner {
  justify-content: space-between;
  border-left-color: #fbdd20;
  border-left-width: 4px;
  border-left-style: solid;
  background-color: #fefbe8;
  border-radius: 4px;
  padding: 16px;
  border-top: '1px solid var(--color-gray05)';
  border-bottom: '1px solid var(--color-gray05)';
  border-right: '1px solid var(--color-gray05)';
}
