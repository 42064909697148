.dropdown {
  composes: dropdown from global;
  box-sizing: border-box;
  background-color: var(--color-gray00);
  width: 300px;
}

.dropdownItem {
  composes: kai-flex-row from global;
}

.dropdownItem > span {
  padding-left: 10px;
}

.avatar {
  width: 20px;
  height: 20px;
  composes: flex-column from global;
  justify-content: space-around;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.avatar > svg {
  width: 20px;
  height: 20px;
}

.title {
  margin: 10px 0;
}

.button {
  composes: button-reset kai-flex-row from global;
  padding: 8px;
  width: 300px;
  border-radius: 6px;
  border: 1px solid var(--color-gray10);
  cursor: pointer;
  background-color: var(--color-gray00);
}

.selectedItem {
  composes: kai-flex-row from global;
  flex-grow: 1;
  width: 90%;
}

.displayName {
  height: 20px;
  font-size: 14px;
  margin-left: 5px;
  line-height: 21px;
  color: var(--color-gray90);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.dropdownFilterItem {
  padding-left: 10px;
  color: var(--color-gray90);
}

.senderDropdown {
  min-width: 600px;
}

.disabledSetting {
  opacity: 0.6;
}
