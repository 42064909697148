.modalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px 44px;
}

.comparisonTable {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  border-radius: 4px;
  border: 1px solid var(--color-gray20);
  padding: 8px 8px 24px 8px;
  height: 100%;
}

.comparisonItem {
  width: 100%;
  padding: 8px;
  height: 100%;
}

.comparisonTable > .comparisonItem:not(:first-child) {
  border-left: 1px solid var(--color-gray20);
}

.mb8 {
  margin-bottom: 8px;
}

.comparisonTextarea {
  /* Require !important to override the components module CSS */
  /* stylelint-disable-next-line declaration-no-important */
  border: none !important;
  outline: none;
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
  margin: 0;
  background: none;
  box-shadow: none;
  /* stylelint-disable-next-line declaration-no-important */
  resize: none !important;
  overflow: hidden;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.aiError {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: medium;
  color: var(--color-redFull);
  padding: 100px;
}

.errorIcon {
  margin-right: 5px;
}

.regenerateButton,
.regenerateButton:hover {
  cursor: pointer;
}

.regenerateButton:hover {
  background: var(--color-gray05);
}
