.ErrorList {
  width: 560px;
  background-color: var(--color-gray00);
  border: 1px solid var(--color-gray05);
  border-radius: 4px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 8px 24px 8px 24px;
}

.ErrorList li button {
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  width: 100%;
}

.RedIcon {
  color: var(--color-redFull);
}

.ErrorList li:not(:last-child) {
  border-bottom: 1px var(--color-gray10) solid;
}
