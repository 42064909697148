.add {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border: none;
  background: var(--color-gray00);
}

.question {
  resize: none;
  padding-top: 9px;
  padding-left: 16px;
  background: var(--color-gray00);
  border: 1px solid var(--color-gray10);
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  height: 87px;
  font-family: 'Inter', Helvetica, sans-serif;
}

.expand {
  /* TODO document or fix me */
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--color-gray90) !important;
}

.answer {
  position: relative;
}

.answer input {
  width: 100%;
  margin-bottom: 8px;
}

.answer button {
  cursor: pointer;
  border: none;
  background: transparent;
  position: absolute;
  right: 5px;
  top: 25%;
  opacity: 0.25;
  outline: none;
}

.assets {
  display: flex;
  justify-content: center;
  background-color: var(--color-gray00);
  border-radius: 6px;
  border: solid 1px var(--color-gray10);
  min-height: 62px;
  box-sizing: border-box;
}

.assets img {
  height: 30px;
  width: 30px;
  margin: 15px;
}
