.container {
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: row;
}

.content {
  flex-basis: 72%;
  flex-grow: 1;
  flex-shrink: 1;
}

.contentNoSidebar {
  composes: content;
  flex-basis: 100%;
}

.sidebar {
  flex-basis: 29%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: stretch;
  justify-content: flex-start;
}

.previewCard {
  width: auto;
  padding: 16px;
}
