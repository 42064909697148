.disableCheckbox {
  opacity: 0.6;
}

.Label {
  display: flex;
}

.Label .lcon {
  margin-right: 10px;
}

.Fieldset {
  padding: 0;
}

.Fieldset input {
  height: 40px;
}

.errorsContainer {
  width: 100%;
  padding-top: 5px;
}

.error {
  color: var(--color-redFull);
  font-size: var(--font-size-caption);
}

.communicationApprovalWarning {
  padding: 4px 0;
}

.communicationApprovalWarning,
.communicationApprovalWarning > span {
  color: var(--color-redFull);
}

.communicationApprovalWarning > i {
  margin-top: -2px;
}

.communicationApprovalLabel {
  padding-left: 8px;
}

.configSection {
  margin-top: 32px;
  border-top: 1px solid var(--color-gray10);
}

.configSectionContainer {
  display: flex;
  padding-bottom: 10px;
}
