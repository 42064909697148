.FilterTrigger {
  composes: kai-flex-row from global;
  padding: 10px 14px;
  font-size: 12px;
  font-weight: 600;
  background: var(--color-gray00);
  color: var(--color-gray90);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 6px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid var(--color-gray40);
}

.FilterTrigger:hover {
  background: var(--color-gray10);
  color: var(--color-gray80);
}

.FilterTriggerApplied {
  background: var(--color-gray05);
  border-color: var(--color-gray90);
}

.FilterTriggerCloseButton {
  composes: flex-column from global;
  align-items: center;
  background: none;
  border: none;
  border-radius: 50%;
  color: var(--color-gray40);
  cursor: pointer;
  justify-content: center;
  margin: 0 -10px 0 6px;
  height: 24px;
  width: 24px;
  padding-top: 2px;
}

.FilterTriggerCloseButton:hover {
  color: var(--color-gray90);
  background-color: var(--color-gray20);
}

.FilterTrigger span + svg {
  margin-left: 6px;
  position: relative;
  top: 1px;
}

.CompactLayout {
  margin-right: 8px;
}

.allFiltersJourneys {
  margin: 0;
  padding: 10px;
}

.allFiltersJourneys > span:first-child > span:first-child {
  margin-right: 8px;
}

.SelectedLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100px;
}

.FlexView {
  display: flex;
}

.FontWeight400 {
  font-weight: 400;
}

.IconBox {
  margin-left: 8px;
}

.SmallIcon {
  font-size: 18px;
  width: 14px;
}
