.button {
  display: flex;
  border-radius: 6px;
  font-size: 16px;
  padding: 14px 20px 12px;
  margin: 0;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.compact {
  font-size: 14px;
  padding: 10px 16px;
}

.button:disabled {
  cursor: inherit;
}

.left {
  padding-right: 12px;
}

.right {
  padding-left: 12px;
}

.compact .left {
  padding-right: 10px;
}

.compact .right {
  padding-left: 10px;
}

.primary {
  background-color: var(--color-brandTintDark);
  color: var(--color-gray00);
}

.secondary {
  background-color: var(--color-gray00);
  color: var(--color-gray90);
  border: 1px solid var(--color-gray30);
}

.tertiary {
  background-color: var(--color-gray90);
  color: var(--color-gray00);
}

.blank {
  /* intentionally empty to allow classNames prop to apply classes */
}

.primary:disabled {
  background-color: var(--color-brandTintLight);
  color: rgba(255, 255, 255, 50%);
}

.secondary:disabled {
  color: var(--color-gray30);
}

.tertiary:disabled {
  background-color: var(--color-gray70);
  color: rgba(255, 255, 255, 50%);
}
