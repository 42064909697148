.panel {
  height: 100%;
  width: 100%;
  overflow: auto;
  border-radius: 6px 0 0 6px;
  background-color: var(--color-gray00);
}

.panel.inactive {
  background-color: #f8fafb;
}

.panel > div {
  padding: 15px 0 7px 0;
}

.panel > div > strong {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  padding-left: 16px;
  padding-bottom: 12px;
}

.panel button {
  border: 0;
  outline: 0;
  background: none;
  display: block;
  padding-left: 16px;
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: normal;
  color: #111;
  cursor: pointer;
}

.panel > div > div:hover {
  background-color: var(--color-gray10);
}

.panel button.selected,
.panel > div > div.selected {
  color: white;
  background-color: var(--color-brandTintDark);
  cursor: default;
}

.panel > div button.selected,
.panel > div > div.selected {
  background-color: var(--color-brandShadeDark);
}
