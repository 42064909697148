.sidebar {
  composes: light-container from global;
}

.sidebarMarginOld {
  margin-top: 70px;
}

.sidebarMargin {
  margin-top: 16px;
}

.sidebar > strong {
  font-size: 15px;
  display: block;
  margin-bottom: 20px;
  text-transform: uppercase;
  opacity: 0.5;
}

.sidebar fieldset {
  border: none;
  margin: 0 0 20px;
  padding: 0;
}

.sidebar legend {
  text-transform: uppercase;
  color: var(--color-gray70);
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
}

.radio-button {
  width: 128px;
  height: 43px;
  left: 431px;
  top: 60px;
  background: #f4f3f6;
  border: 0;
  cursor: pointer;
  color: #5d5c65;
}

@media screen and (max-width: 1280px) {
  .radio-button {
    width: 120px;
  }
}

@media screen and (min-width: 1281px) {
  .radio-button {
    width: 128px;
  }
}

.radio-button:first-child {
  border-radius: 6px 0 0 6px;
}

.radio-button:last-child {
  border-radius: 0 6px 6px 0;
}

.radio-button.selected {
  border: 4px solid var(--color-brandTintDark);
  font-weight: 600;
  color: #111113;
}

/* Design form */
.designContainer {
  display: flex;
  padding-top: 40px;
  overflow-y: auto;
  height: calc(100vh - 100px);
}

.formContainer {
  width: 250px;
  composes: flex-column from global;
}

.title {
  font-weight: 600;
  font-size: 18px;
}

.ctaImageOption > div {
  background-size: cover;
  width: 50px;
  height: 50px;
}

/* CARD */
.cardImageWrapper {
  composes: kai-flex-row from global;
  flex-wrap: wrap;
}

.ctaImageOption {
  display: flex;
  width: 60px;
  height: 60px;
  border: none;
  padding: 0;
  margin: 5px;
  cursor: pointer;
}

.ctaImageOptionSelected {
  background-color: var(--color-brandTintDark);
}

.ctaImageOptionSelected.ctaImageOptionDisabled {
  background-color: gray;
}

.ctaImageOptionDisabled {
  opacity: 0.5;
  cursor: default;
}

.ctaImageOptionDisabled > div {
  cursor: default;
}

.cardImage {
  height: 300px;
  width: 100%;
  background-size: contain;
}

.ctaTextStrippedWarning {
  font-size: 12px;
  margin: 30px 0;
  color: var(--color-redFull);
}

.cardPreviewContainer {
  composes: flex-column from global;
}

.cardText {
  padding: 10px;
}

.ctaMetricsPreview {
  composes: kai-flex-row from global;
  padding: 10px;
}

.ctaImageUploadWrapper {
  composes: kai-flex-row from global;
  position: relative;
}

.previewBlocksWrapper {
  composes: flex-column from global;
}

.previewBlocksWrapper .menu {
  display: none;
}

.altTextFieldset > legend {
  display: flex;
}

.disabledSetting {
  opacity: 0.6;
}

.ctaInvalidInput {
  /* stylelint-disable-next-line declaration-no-important */
  border: 1px solid var(--color-redFull) !important;
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--color-redFull) !important;
}

.ctaInvalidInput:disabled,
.ctaErrorMessageDisabled {
  opacity: 0.6;
}

.ctaErrorMessage {
  color: var(--color-redFull);
  font-size: var(--font-size-caption);
}

.cardFieldset {
  position: relative;
}

.count {
  position: absolute;
  right: 0;
  top: 0;
  margin: 11px 10px;
}

.number {
  margin-left: 5px;
}

.titleInput {
  padding-right: 60px;
}

.count > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctaAiWarning {
  font-size: var(--font-size-fieldlabel);
  margin-top: 10px;
}

.infoSign {
  height: 13px;
  width: 13px;
  float: left;
  margin-top: 10px;
  margin-right: 10px;
  color: #13c2c2;
}

.warningSign {
  font-size: 14px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 6px;
}
